import { contentCreatorConstants, userConstants } from "../_constants";
import { tutorialConstants } from "../_constants/Learner/tutorial.constants";

const initialState = {
  tutorialList: [],
  tutorialData: [],
  editTutorial: {},
  isLoading: false,
  PreviewData: {},
  tutorialById: {},
  errors: [],
  mediaUrl: {},
  downloadAnswer: false
};
export function CurriculumTutorial(state = initialState, action) {
  switch (action.type) {
    case tutorialConstants.REMOVE_COLLABORATOR_FROM_TUTORIAL_REQUEST:
      return {
        ...state,
        isCollaboratorRequestInProgress: true
      };

    case tutorialConstants.REMOVE_COLLABORATOR_FROM_TUTORIAL_SUCCESS:
      return {
        ...state,
        tutorialById: {
          ...state.tutorialById,
          data: { ...state.tutorialById.data, roles: action.data.data }
        },
        isCollaboratorRequestInProgress: false
      };

    case tutorialConstants.REMOVE_COLLABORATOR_FROM_TUTORIAL_FAILURE:
      return {
        ...state,
        errors: action.data,
        isCollaboratorRequestInProgress: false
      };

    case tutorialConstants.ADD_COLLABORATOR_TO_TUTORIAL_REQUEST:
      return {
        ...state,
        isCollaboratorRequestInProgress: true
      };

    case tutorialConstants.ADD_COLLABORATOR_TO_TUTORIAL_SUCCESS:
      return {
        ...state,
        tutorialById: {
          ...state.tutorialById,
          data: { ...state.tutorialById.data, roles: action.data.data }
        },
        isCollaboratorRequestInProgress: false
      };

    case tutorialConstants.ADD_COLLABORATOR_TO_TUTORIAL_FAILURE:
      return {
        ...state,
        errors: action.data,
        isCollaboratorRequestInProgress: false
      };

    case contentCreatorConstants.GET_ALL_TUTORIALS_SUCCESS:
      return { ...state, tutorialData: action.data, isLoading: false };

    case contentCreatorConstants.GET_ALL_TUTORIALS_FAILURE:
      return {
        ...state,
        tutorialData: [],
        isLoading: false,
        errors: action.data
      };
    case contentCreatorConstants.GET_ALL_TUTORIALS_REQUEST:
      return { ...state, isLoading: true };
    case contentCreatorConstants.GET_TUTORIAL_LIST_REQUEST:
      return { ...state, isLoading: true };
    case contentCreatorConstants.GET_TUTORIAL_LIST_SUCCESS:
      return { ...state, tutorialList: action.data, isLoading: false };
    case contentCreatorConstants.GET_TUTORIAL_LIST_FAILURE:
      return {
        ...state,
        tutorialList: [],
        isLoading: false,
        errors: action.data
      };
    case contentCreatorConstants.GET_TUTORIAL_SUCCESS:
      return { ...state, editTutorial: action.data, isLoading: false };
    case contentCreatorConstants.GET_TUTORIAL_FAILURE:
      return {
        ...state,
        editTutorial: {},
        isLoading: false,
        errors: action.data
      };
    case contentCreatorConstants.GET_TUTORIAL_REQUEST:
      return { ...state, isLoading: true };
    case contentCreatorConstants.COPY_TUTORIAL_SUCCESS:
      state.tutorialList.data.tutorials_created.push(action.data.data);
      return { ...state, tutorialList: state.tutorialList, isLoading: false };
    case contentCreatorConstants.COPY_TUTORIAL_FAILURE:
      return { ...state, isLoading: false, errors: action.data };
    case contentCreatorConstants.DELETE_TUTORIAL_SUCCESS:
      let index = state.tutorialList.data.tutorials_created.findIndex(
        e => e.id === action.id
      );
      state.tutorialList.data.tutorials_created.splice(index, 1);
      return { ...state, tutorialList: state.tutorialList, isLoading: false };
    case contentCreatorConstants.DELETE_TUTORIAL_FAILURE:
      return { ...state, isLoading: false, errors: action.data };
    case contentCreatorConstants.CREATE_TUTORIAL_SUCCESS:
      // If from Import tutorial then in [] or if from create tutorial then it comes in {}
      let tutData =
        action.data.data &&
        (Array.isArray(action.data.data)
          ? action.data.data
          : [action.data.data]);
      // added below line since can't get the recently created tutorial in the left pane section of the tutorial in Tutorial Management
      state.tutorialList.data &&
        state.tutorialList.data.tutorials_created &&
        state.tutorialList.data.tutorials_created.push(...tutData);

      if ("data" in state.tutorialData)
        state.tutorialData.data.tutorials_created.push(...tutData);
      else state.tutorialData.push(...tutData);

      return {
        ...state,
        tutorialData: _.cloneDeep(state.tutorialData),
        isLoading: false
      };

    case contentCreatorConstants.UPDATE_TUTORIAL_REQUEST:
      return { ...state, isLoading: true, errors: action.data };
    case contentCreatorConstants.UPDATE_TUTORIAL_SUCCESS:
      if ("data" in state.tutorialData) {
        let i = state.tutorialData.data.tutorials_created.findIndex(
          e => e.id === action.data.data.id
        );
        state.tutorialData.data.tutorials_created.splice(
          i,
          1,
          action.data.data
        );
      } else state.tutorialData.push(action.data);
      return {
        ...state,
        isLoading: false,
        editTutorial: action.data,
        tutorialData: state.tutorialData
      };
    case contentCreatorConstants.UPDATE_TUTORIAL_FAILURE:
      return { ...state, isLoading: false, errors: action.data };
    case contentCreatorConstants.CONTENT_TAB_DATA:
      return {
        ...state,
        editTutorial: { data: action.data },
        isLoading: false
      };
    case contentCreatorConstants.REMOVE_EDIT_DATA:
      return { ...state, editTutorial: {}, isLoading: false };
    case contentCreatorConstants.GET_TUTORIAL_BY_ID_REQUEST:
      return { ...state, isLoading: true };
    case contentCreatorConstants.GET_TUTORIAL_BY_ID_SUCCESS:
      return { ...state, tutorialById: action.data, isLoading: false };
    case contentCreatorConstants.GET_TUTORIAL_BY_ID_FAILURE:
      return {
        ...state,
        tutorialById: {},
        isLoading: false,
        errors: action.data
      };
    case contentCreatorConstants.ADD_MEDIA_SUCCESS:
      return { ...state, medialUrl: action.data };
    case contentCreatorConstants.ADD_MEDIA_FAILURE:
      return { ...state, errors: action.data };
    case contentCreatorConstants.EDIT_MEDIA_SUCCESS:
      return { ...state, mediaUrl: action.data };
    case contentCreatorConstants.EDIT_MEDIA_FAILURE:
      return { ...state, errors: action.data };
    case contentCreatorConstants.GET_TUTORIAL_ANSWER_REQUEST:
      return { ...state, isLoading: true };
    case contentCreatorConstants.GET_TUTORIAL_ANSWER_SUCCESS:
      return { ...state, downloadAnswer: true, isLoading: false };
    case contentCreatorConstants.GET_TUTORIAL_ANSWER_FAILURE:
      return {
        ...state,
        downloadAnswer: false,
        isLoading: false,
        errors: action.data
      };
    default:
      return state;
  }
}
