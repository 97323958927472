import React from "react";
import "./Header.scss";
import profileImg from "../../../public/img/avatars/profileImg.jpeg";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown
} from "reactstrap";
import { Link } from "react-router-dom";

export default class HeaderDropdownAccount extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    const { logoutmethod } = this.props;
    return (
      <ButtonDropdown
        nav
        className=" user-profile-btn-dropdown"
        direction="up"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        aria-haspopup={true}
        aria-expanded={false}
      >
        <span className="visuallyhidden">sidecar</span>
        <DropdownToggle
          left
          className="user-profile-dropdown pr-3"
          aria-label={"Account Management button"}
        >
          <i className="icon-user" />
          {/*<span>{user.first_name.toUpperCase()}&nbsp;{user.last_name.toUpperCase()}</span>*/}
        </DropdownToggle>
        <DropdownMenu left>
          <DropdownItem>
            <Link to="/account-management">Account Management</Link>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
