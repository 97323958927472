import React from "react";
import "./Table_of_Content.scss";
import { tutorialActions } from "../../_actions/LearnerActions/tutorial.actions";
import { connect } from "react-redux";
import { history } from "../../_helpers/history";

class Table_of_Content_Container extends React.Component {
  componentDidMount() {
    let {
      dispatch,
      match: { params: { id } = {} }
    } = this.props;
    dispatch(tutorialActions.getTutorialData(id));
  }

  onNavigate(id, title) {
    history.push({
      pathname: `/tutorial-page/${id}/tutorialViewer`,
      state: title
    });
  }
  render() {
    const {
      tutorialData: { content: { tutorial = [] } = {}, title } = {}
    } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className="border-table">
              <div className="table-content">
                <h4>Table of Content</h4>
                <h5>{title}</h5>
                <div className="display-scroll">
                  {tutorial.map((chap, i) => {
                    return (
                      <ul>
                        <li>
                          <span
                            className="span-link"
                            onClick={() =>
                              this.onNavigate(
                                this.props.tutorialData.id,
                                chap.title
                              )
                            }
                          >
                            <p>{chap.title}</p>
                          </span>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tutorialData: state.tutorial.tutorialData
  };
}

const connectedTableOfContent = connect(
  mapStateToProps,
  null
)(Table_of_Content_Container);
export { connectedTableOfContent as Table_of_Content_Container };
