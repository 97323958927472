import { connect } from "react-redux";
import React, { Component } from "react";
import MainTutorial from "../../components/TutorialLookFeel/mainTutorial";
import { Row, Col, Button } from "reactstrap";
import { tutorialLookFeelActions } from "../../_actions/tutorialLookFeel.actions";
import "./TutorialLookFeel.scss";
import { history } from "../../_helpers/history";
import BackButton from "../../_components/BackButton";

class TutorialLookFeel extends Component {
  state = {
    blankFormErrorMessage: ""
  };

  componentDidMount() {
    if (this.props.lref) this.props.lref(this.formDetail);
  }

  saveTutorialLook = () => {
    const { dispatch } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    let config_id = user?.configuration?.id;

    if (Object.keys(this.formDetail.state.data).length > 0) {
      const obj = {
        configuration_attributes: {
          ...this.formDetail.state.data,
          id: config_id
        }
      };
      dispatch(tutorialLookFeelActions.saveTutorialLookFeel(obj)).then(
        (res, err) => {
          if (res) {
            history.push("/");
          }
        }
      );
    } else {
      this.setState({
        blankFormErrorMessage:
          "You have not changed any setting. You can modify settings or go back."
      });
      return false;
    }
  };

  removeBlankFormErrorMessage = () => {
    this.setState({
      blankFormErrorMessage: ""
    });
  };

  render() {
    const { tab, data, errors, TabsData, user } = this.props;
    const { blankFormErrorMessage } = this.state;
    return (
      <React.Fragment>
        <div className="animated fadeIn">
          <div className="tutorial-page">
            <div className="custom-row bg-white p-4 ">
              <Row>
                <h5 className="normal-font">Tutorial Look & Feel</h5>
              </Row>
              <Row className="border-tb-solid">
                <Col md={12}>
                  <Row>
                    <MainTutorial
                      ref={e => (this.formDetail = e)}
                      data={data}
                      errors={errors}
                      TabsData={TabsData}
                      user={user}
                      tab={tab}
                      removeBlankFormErrorMessage={
                        this.removeBlankFormErrorMessage
                      }
                    />
                  </Row>
                </Col>
              </Row>
              <div class="blank-form-error-message">
                {blankFormErrorMessage}
              </div>
              {!tab && (
                <div className="save-cancel-btn">
                  <BackButton />
                  <Button
                    color="success"
                    className="cancel-btn"
                    onClick={() => {
                      this.saveTutorialLook();
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user
  };
}

const connectedTutorialLookFeel = connect(
  mapStateToProps,
  null
)(TutorialLookFeel);
export { connectedTutorialLookFeel as TutorialLookFeel };
