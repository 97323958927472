import { AttempTutorialConstants, AttempQuizConstants } from "../../_constants";

const initial = {
  attemptId: [],
  attemptInfo: {
    data: {
      certificate_id: ""
    }
  },
  isFinished: false
};

export function attemptAPIData(state = initial, action) {
  switch (action.type) {
    case AttempTutorialConstants.ATTEMPT_TUTORIAL_SUCCESS:
      return { ...state, attemptId: action.data.data.id, isFinished: false };

    case AttempTutorialConstants.ATTEMPT_TUTORIAL_FAILURE:
      return { ...state, attemptId: [] };

    case AttempTutorialConstants.FINISH_TUTORIAL_SUCCESS:
      return { ...state, attemptInfo: action.data, isFinished: true };

    case AttempTutorialConstants.FINISH_TUTORIAL_FAILURE:
      return { ...state, attemptInfo: [] };

    case AttempQuizConstants.ATTEMPT_QUIZ_SUCCESS:
      return { ...state, attemptId: action.data.data.id };

    case AttempQuizConstants.ATTEMPT_QUIZ_FAILURE:
      return { ...state, attemptId: [] };

    case AttempQuizConstants.FINISH_QUIZ_SUCCESS:
      return { ...state, attemptInfo: action.data };

    case AttempQuizConstants.FINISH_QUIZ_FAILURE:
      return { ...state, attemptInfo: [] };

    case AttempTutorialConstants.UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS_SUCCESS:
      return { ...state, attemptInfo: action.data };

    case AttempTutorialConstants.UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS_FAILURE:
      return { ...state, attemptInfo: { data: { certificate_id: null } } };

    default:
      return state;
  }
}
