import React, { Component } from "react";
import "./manageRoles.scss";
import {
  Row,
  Col,
  Collapse,
  Card,
  CardHeader,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  Form,
  FormGroup,
  Tooltip
} from "reactstrap";
import { Checkbox } from "../../_components/InputComponents";
import NoUserImage from "../../../public/img/avatars/no-user.png";
import { rolesActions } from "../../_actions/Administrator/roles.actions";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { Checkbox as CustomeCheckbox } from "../../_components/InputComponents";
import BackButton from "../../_components/BackButton";
const admin_roles = [
  "org_administrator",
  "content_creator",
  "full_content_creator",
  "basic_content_creator",
  "individual_content_creator",
  "learner"
];
const org_roles = [
  "org_administrator",
  "content_creator",
  "full_content_creator",
  "basic_content_creator",
  "individual_content_creator",
  "learner"
];

class ManageRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: 0,
      selectedUserIds: [],
      hoverUser: "",
      userId: "",
      roleName: "",
      selectedRole: "",
      openToolTip: { test: false }
    };

    this.toggleCard = this.toggleCard.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.cancel = this.cancel.bind(this);

    this.onHoverUser = this.onHoverUser.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
    this.saveUsersToRoles = this.saveUsersToRoles.bind(this);
    this.selectAllUsers = this.selectAllUsers.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(e) {
    this.setState({ openToolTip: { [e]: !this.state.openToolTip[e] } });
  }

  onHoverUser(uid, role) {
    this.setState({
      hoverUser: uid,
      roleName: role
    });
  }
  confirmDelete() {
    const { hoverUser, userId, roleName } = this.state;
    const { dispatch } = this.props;
    const data = {
      role: roleName,
      user_ids: [hoverUser]
    };
    dispatch(rolesActions.deleteSelectedUser(data)).then((res, err) => {
      if (res.data)
        this.setState({
          isDelete: false
        });
    });
  }
  cancelDelete() {
    this.setState({
      isDelete: false,
      hoverUser: "",
      userId: ""
    });
  }
  deleteConfirmation(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDelete: true,
      userId: id,
      collapse: 0
    });
  }

  cancel() {
    this.setState({
      selectedUserIds: [],
      collapse: 0
    });
  }
  saveUsersToRoles() {
    const { cards } = this.props;
    let user = JSON.parse(localStorage.getItem("user"));
    const { selectedRole, selectedUserIds } = this.state;
    const { dispatch } = this.props;
    if (selectedUserIds.length === 0 || !selectedRole) {
      dispatch(rolesActions.uservalidation());
    } else {
      const roleData = Object.values(cards.find(data => data[selectedRole]))[0];
      roleData.map((data, i) => {
        selectedUserIds.map((d, i) => {
          if (data.id === d) {
            selectedUserIds.splice(i, 1);
          }
        });
      });
      if (selectedUserIds.length !== 0) {
        let obj = {
          role: selectedRole,
          user_ids: selectedUserIds,
          resource_id:
            selectedRole == "org_administrator"
              ? (user.organization || user.owned_organization).id
              : undefined,
          resource_type:
            selectedRole == "org_administrator" ? "Organization" : undefined
        };
        dispatch(rolesActions.saveRolesToUser(obj));
        this.setState({
          selectedUserIds: [],
          selectedRole: "",
          collapse: 0
        });
      } else {
        dispatch(rolesActions.roleValidation());
        this.setState({
          selectedRole: "",
          collapse: 0
        });
      }
    }
  }

  toggleCard(e, role) {
    let event = e.target.dataset.event;
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event),
      selectedRole: this.state.selectedRole === role ? "" : role
    });
  }
  selectUser(selectedUserId) {
    let { selectedUserIds } = this.state;
    let bool = _.includes(selectedUserIds, selectedUserId);
    if (bool) {
      var index = selectedUserIds.indexOf(selectedUserId);
      index !== -1 && selectedUserIds.splice(index, 1);
    } else selectedUserIds.push(selectedUserId);
    this.setState({
      selectedUserIds
    });
  }
  selectAllUsers(e) {
    const { userData } = this.props;
    let { selectedUserIds } = this.state;
    const { checked } = e.target;
    if (checked) {
      selectedUserIds = [];
      userData.map((l, i) => {
        selectedUserIds.push(l.id);
      });
      this.setState({
        selectedUserIds
      });
    } else {
      this.setState({
        selectedUserIds: []
      });
    }
  }
  render() {
    const { cards, userData = [] } = this.props;
    const {
      hoverUser,
      isDelete,
      collapse,
      selectedUserIds,
      selectedRole,
      openToolTip
    } = this.state;
    const userLogin = localStorage.getItem("user");
    // const userMainRole = userLogin && JSON.parse(userLogin).main_role;
    const userMainRole = localStorage.getItem("defaultRole");
    return (
      <div className="">
        <div className="title-page">
          <h4 className="w-100">Manage User Roles</h4>
          <div className="save-cancel-btn">
            <BackButton />
            <Button
              className="cancel-btn mr-2"
              color="secondary"
              onClick={this.cancel}
            >
              Reset
            </Button>
            <Button
              className="cancel-btn"
              color="success"
              onClick={this.saveUsersToRoles}
            >
              Save
            </Button>
          </div>
        </div>
        <Row>
          <div className=" col-lg-4 col-md-4">
            <Card>
              <div className="title-">
                <div className="assign-role-div">
                  <CardTitle className="font-sm custom-card-title">
                    Assign role to new users
                  </CardTitle>
                  <div className="select-all">
                    <FormGroup check>
                      {userData && userData.length > 0 && (
                        <CustomeCheckbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={this.selectAllUsers}
                            checked={selectedUserIds.length === userData.length}
                          />
                          <p className="pt-1">Select All</p>
                        </CustomeCheckbox>
                      )}
                    </FormGroup>
                  </div>
                  <CardSubtitle className="caption custom-card-sub-title">
                    select user(s) and then select user role
                  </CardSubtitle>
                </div>
                <CardBody>
                  <CardText>
                    <Form className="custom-main-form">
                      {userData &&
                        userData.map((u, i) => {
                          const n = Math.floor(Math.random() * 10);
                          return (
                            <div>
                              <FormGroup check className="custom-form">
                                <Checkbox className="dis-flex">
                                  <div className="checkbox-img">
                                    {u.avatar.url ? (
                                      <img
                                        src={u.avatar.url}
                                        onError={e => {
                                          e.target.src = NoUserImage;
                                        }}
                                        className="checkbox-user-avt"
                                        alt={`Avatar${n}`}
                                      />
                                    ) : (
                                      <img
                                        src={NoUserImage}
                                        className="checkbox-user-avt"
                                        alt={`Avatar${n}`}
                                      />
                                    )}
                                  </div>
                                  <Input
                                    type="checkbox"
                                    onClick={() => this.selectUser(u.id)}
                                    checked={selectedUserIds.includes(u.id)}
                                  />
                                  <span
                                    className={
                                      selectedUserIds.includes(u.id)
                                        ? "active-student check-username"
                                        : "check-username"
                                    }
                                    id={`user-${u.id}-name`}
                                  >
                                    {`${u.first_name} ${u.last_name}`}
                                  </span>
                                  <Tooltip
                                    placement="top"
                                    isOpen={openToolTip[`user-${u.id}-name`]}
                                    target={`user-${u.id}-name`}
                                    toggle={() =>
                                      this.toggleTooltip(`user-${u.id}-name`)
                                    }
                                  >
                                    {u.email}
                                  </Tooltip>
                                </Checkbox>
                              </FormGroup>
                            </div>
                          );
                        })}
                    </Form>
                  </CardText>
                </CardBody>
              </div>
            </Card>
          </div>
          <div className=" col-lg-8 col-md-8 role-scroll">
            {cards &&
              cards.map((c, index) => {
                let role = Object.keys(c).toString();
                const result =
                  userMainRole === "org_administrator"
                    ? org_roles.includes(role)
                    : admin_roles.includes(role);
                if (!result) return null;
                let displayRole =
                  role === "full_content_creator" ||
                  role === "basic_content_creator" ||
                  role === "individual_content_creator"
                    ? "Instructor"
                    : role
                        .split("_")
                        .join(" ")
                        .charAt(0)
                        .toUpperCase() +
                      role
                        .split("_")
                        .join(" ")
                        .slice(1);
                return (
                  <Card className="custome-card manage-custom-card" key={index}>
                    <CardHeader
                      className="header-title"
                      onClick={e => this.toggleCard(e, role)}
                      data-event={index}
                    >
                      <Input
                        className="hadder-accodian"
                        onClick={e => this.toggleCard(e, role)}
                        type="radio"
                        checked={collapse === index}
                        data-event={index}
                        aria-label={"Roles for the New user"}
                      />
                      {collapse !== index ? (
                        <i className="fa fa-chevron-right custom-icon" />
                      ) : (
                        <i className="fa fa-chevron-down" />
                      )}
                      <b>{displayRole}</b>({c[role].length} users)
                    </CardHeader>
                    <Collapse isOpen={collapse === index}>
                      <CardBody>
                        <div className="accordion-body-main-div">
                          {c[role].map((u, i) => {
                            const n = Math.floor(Math.random() * 9);
                            return (
                              <div className="accordion-body-sub-main-div">
                                {u.avatar.url ? (
                                  <img
                                    src={u.avatar.url}
                                    className="accordion-user-avt"
                                    alt={`user-avt${n}`}
                                    onError={e => {
                                      e.target.src = NoUserImage;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={NoUserImage}
                                    alt={`user-avt${n}`}
                                    className="accordion-user-avt"
                                  />
                                )}
                                <p
                                  className={
                                    selectedUserIds.includes(u.id)
                                      ? "active-student"
                                      : ""
                                  }
                                  onMouseOver={() =>
                                    this.onHoverUser(u.id, role)
                                  }
                                >
                                  {u.first_name}&nbsp;{u.last_name}
                                </p>
                                {hoverUser === u.id && (
                                  <i
                                    className="fa fa-trash"
                                    id="delete-tutorial"
                                    onClick={e => {
                                      this.deleteConfirmation(e, u.id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>
                );
              })}
          </div>
        </Row>
        <Modal isOpen={isDelete} className="modal-danger">
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <h4>Are you sure you want to delete this ? </h4>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.confirmDelete}>
              Yes
            </Button>
            <Button color="secondary" onClick={this.cancelDelete}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default ManageRoles;
