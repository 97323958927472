import React from "react";
import { connect } from "react-redux";
import HelpCenterSidebar from "../../../components/Learner/HelpCenter/helpCenterSidebar";
import ManageHelpCenter from "../../../components/Learner/HelpCenter/manageHelpCenter";
import HelpCenterPlatform from "../../../components/Learner/HelpCenter/helpCenterPlatform";
import { Row, Col } from "reactstrap";
import { helpCenterActions } from "../../../_actions/index";
import "./Helpcenter.scss";

class HelpCenterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: { test: true },
      currentMenu: 0
    };
    this.toggleCard = this.toggleCard.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(helpCenterActions.getData());
  }

  toggleCard(e) {
    this.setState({
      collapse: { ...this.state.collapse, [e]: !this.state.collapse[e] }
    });
  }
  toggleMenu(currentMenu) {
    this.setState({
      currentMenu: currentMenu
    });
  }
  render() {
    const { collapse, currentMenu } = this.state;
    const { helpCenterData } = this.props;
    return (
      <div className="animated fadeIn">
        {/*<Row className="custom-row bg-white p-4">*/}
        {/*<Col md={2}>*/}
        {/*<div className="row">*/}
        {/*<HelpCenterSidebar currentMenu={currentMenu}*/}
        {/*toggleMenu={this.toggleMenu}/>*/}
        {/*</div>*/}
        {/*</Col>*/}
        {/*<Col md={10}>*/}
        {/*{currentMenu===0&&<ManageHelpCenter toggleCard={this.toggleCard}*/}
        {/*collapse={collapse}*/}
        {/*helpCenterData={helpCenterData}/>}*/}
        {/*{currentMenu===1&&<HelpCenterPlatform/>}*/}
        {/*</Col>*/}
        {/*</Row>*/}
        <iframe
          src={"https://www.sidecarlearning.com/helpcenter"}
          className={"square-url"}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    helpCenterData: state.helpCenter.helpCenterData
  };
}

const connectedHelpCenterContainer = connect(
  mapStateToProps,
  null
)(HelpCenterContainer);
export { connectedHelpCenterContainer as HelpCenterContainer };
