import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
const style = {
  fontSize: "15px"
};
class SingleSignOnPage extends React.Component {
  render() {
    const { isOpen, toggle } = this.props;
    return (
      <Modal isOpen={isOpen} className="modal-primary" toggle={toggle}>
        <ModalHeader toggle={toggle}>Single Sign On</ModalHeader>
        <ModalBody>
          <p style={style}>
            Contact Sidecar Learning to learn about how to enable single sign on
            for your organization.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default SingleSignOnPage;
