import React, { Component } from "react";
import { connect } from "react-redux";
import Collaborate from "../../components/tutorial_management/Collaborate";
import { userActions } from "../../_actions";
import { tutorialActions } from "../../_actions/LearnerActions/tutorial.actions";
import { CurriculumTutorial } from "../../_reducers/tutorial.reducer";

const mapDispatchToProps = {
  getAll: userActions.getAll,
  addCollaborator: tutorialActions.addCollaboratorToTutorial,
  removeCollaborator: tutorialActions.removeCollaboratorFromTutorial
};

const mapStateToProps = state => ({
  collaboratorUsers: state.users.items,
  tutorial: state.CurriculumTutorial.tutorialById.data,
  isCollaboratorRequestInProgress:
    state.CurriculumTutorial.isCollaboratorRequestInProgress,
  isFetchingCollaboratorsList: state.users.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Collaborate);
