import React from "react";
import Tutorial from "../../../components/Learner/Tutorial-Page/Tutorial";
import { connect } from "react-redux";
import { tutorialActions } from "../../../_actions/LearnerActions/tutorial.actions";
import { setQuizActions } from "../../../_actions/LearnerActions/quizSetAnswers.action";
import { checkAnswerAction } from "../../../_actions/LearnerActions/checkAnswer.action";
import { menuTogglerAction } from "../../../_actions/LearnerActions/menuToggler.action";
import { attemptAPIAction } from "../../../_actions";
import Spinner from "../../../components/spinner/index";
import { history } from "../../../_helpers/history";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import "./TutorialContainer.scss";
import AlternativeModals from "../../../_components/AlternativeModals";
import _ from "lodash";
import { isElectron } from "../../../utils";

class TutorialContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkFeedback: false,
      noAnswer: false,
      isCompleted: false,
      isOpen: false,
      custom_correct_answer_feedback: "Correct",
      custom_wrong_answer_feedback: "Incorrect, try another selection",
      correct_feedback: "",
      wrong_feedback: "",
      gradeFeedback: "",
      isPublic: false,

      isAlternative: false,
      tutorial: {},
      isOpenModal: false,
      prevAnswer: [],
      tutorialLevel: false
    };
    this.handleQuizSelection = this.handleQuizSelection.bind(this);
    this.handleCheckAnswer = this.handleCheckAnswer.bind(this);
    this.handleFinishAttempt = this.handleFinishAttempt.bind(this);
    this.checkQuizAnswer = this.checkQuizAnswer.bind(this);
    this.handleCompleted = this.handleCompleted.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleReview = this.handleReview.bind(this);
    this.handleSummativeQuizAttempt = this.handleSummativeQuizAttempt.bind(
      this
    );
    this.toggle = this.toggle.bind(this);
    this.closeQuiz = this.closeQuiz.bind(this);
    this.isPassingScoreExists = this.isPassingScoreExists.bind(this);

    this.setTutorial = this.setTutorial.bind(this);
    this.close = this.close.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setTutorialLevel = this.setTutorialLevel.bind(this);

    this.updateTutorialAttemptGuestDetails = this.updateTutorialAttemptGuestDetails.bind(
      this
    );
  }
  componentDidMount() {
    let {
      dispatch,
      match: {
        params: { id = "", page = "" } = {},
        location: { pathname = "" } = {}
      } = {},
      tutorialData: { is_public } = {}
    } = this.props;
    let isToggle = false;
    let isAuthorized =
      pathname.split("/").includes("authorized") ||
      pathname.split("/").includes("tutorial");
    dispatch(tutorialActions.clearTutorialData());
    dispatch(menuTogglerAction.toggleMenu(isToggle));
    let self = this;
    let callPublic =
      self.props.location && self.props.location.pathname.includes("public");

    dispatch(tutorialActions.getTutorialData(id, isAuthorized)).then(
      (res, err) => {
        if (!callPublic) {
          let userHeader = JSON.parse(localStorage.getItem("headerJson"));
          if (!userHeader) {
            if (res) {
              const {
                data: {
                  data: { id = "", is_public = true, is_frame_busted = "" } = {}
                } = {}
              } = res;
              if (is_public === true) {
                if (is_frame_busted === true) {
                  this.setState({
                    isPublic: true,
                    isOpenModal: true
                  });
                } else {
                  this.setState({
                    isPublic: false
                  });
                }
              } else {
                this.setState({
                  isPublic: false
                });
                dispatch(
                  tutorialActions.storePrivateRoute(
                    self.props.location.pathname
                  )
                );
                history.push("/");
              }
            }
          } else {
            if (res) {
              const {
                data: {
                  data: { is_public = true, is_frame_busted = "" } = {}
                } = {}
              } = res;
              if (
                is_public === false &&
                is_frame_busted === true &&
                page !== "QuizViewer"
              ) {
                this.setState({
                  isPublic: true,
                  isOpenModal: true
                });
              }
            }
          }
        }
        if (res.data) {
          let t_ID = res.data.data ? res.data.data.id : "";
          if (page === "QuizViewer") {
            dispatch(attemptAPIAction.attemptQuiz(t_ID));
          } else if (page === "tutorialViewer") {
            dispatch(attemptAPIAction.attemptAPI(t_ID));
          } else {
            dispatch(attemptAPIAction.attemptAPI(t_ID));
          }
        }
      }
    );
  }

  setTutorialLevel() {
    this.setState({ tutorialLevel: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.checkAnswerData.length) {
      this.setState({ prevAnswer: nextProps.checkAnswerData[0].text });
    }
  }

  handleQuizSelection(obj) {
    let {
      dispatch,
      match: {
        params: { page = "tutorialViewer" }
      }
    } = this.props;
    if (page !== undefined) {
      dispatch(setQuizActions.setQuizAnswers(obj, page));
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeQuiz() {
    history.push("/");
  }

  handleCheckAnswer(selectedValue, quizzesId, quesId, correct, wrong, q_type) {
    let {
      dispatch,
      match: { params: { id, page } = {} },
      attemptAPIData,
      checkAnswerIds,
      checkAnswerData,
      tutorialData = {},
      tutorialData: { is_public } = {},
      checkAnswerError
    } = this.props;
    const { prevAnswer } = this.state;
    let callApi = _.isEqual(prevAnswer, selectedValue);

    // let type =  page === 'QuizViewer' ? 'quiz' : page === 'tutorialViewer' && 'tutorial';
    let type = page === "QuizViewer" ? "quiz" : "tutorial";

    if (q_type === "short_text" || q_type === "long_text") {
      let textArr = [{ qId: quesId, selectedValues: selectedValue }];
      this.handleQuizSelection(textArr);
    }
    if (selectedValue !== undefined && checkAnswerError === undefined) {
      // added checkAnswerError to stop calling api if limit exhausted
      let ansObj = {
        simple_attempt_id: attemptAPIData,
        quiz_id: quizzesId,
        question_id: quesId,
        text: selectedValue,
        type: type
      };
      let editAnsObj = {
        text: selectedValue,
        type: type
      };
      let tutorialId = is_public ? tutorialData.id : id;
      if (checkAnswerIds.includes(quesId)) {
        let curr = checkAnswerData.find(a => {
          return a.question_id === quesId;
        });
        if (!callApi) {
          dispatch(
            checkAnswerAction.editAnswer(tutorialId, curr.id, editAnsObj)
          ).then(res => {
            if (res.data) {
              page !== "QuizViewer" &&
                this.checkQuizAnswer(res.data.data.id, q_type);
              this.setState({
                noAnswer: false,
                wrong_feedback: wrong,
                correct_feedback: correct
              });
            }
          });
        }
      } else {
        dispatch(checkAnswerAction.checkAnswer(tutorialId, ansObj)).then(
          res => {
            if (res.data) {
              page !== "QuizViewer" &&
                this.checkQuizAnswer(res.data.data.id, q_type);
              this.setState({
                noAnswer: false,
                wrong_feedback: wrong,
                correct_feedback: correct
              });
            }
          }
        );
      }
    } else {
      this.setState({
        noAnswer: true
      });
    }
  }

  handleReview(comment, rating) {
    const {
      dispatch,
      attemptAPIData,
      tutorialData: { id = "" } = {}
    } = this.props;
    const obj = {
      rating: rating,
      comment: comment,
      simple_attempt_id: attemptAPIData
    };
    dispatch(tutorialActions.submitReviewRate(obj, id));
  }

  updateTutorialAttemptGuestDetails(guestData) {
    const {
      dispatch,
      attemptAPIData,
      tutorialData: { id = "" } = {}
    } = this.props;
    dispatch(
      attemptAPIAction.updateTutorialAttemptGuestDetails(
        id,
        attemptAPIData,
        guestData
      )
    );
  }

  checkQuizAnswer(id, qType) {
    let curr = this.props.checkAnswerData.find(a => {
      return a.id === id;
    });
    const { correct_answer = [], text = [] } = curr;
    let finalAnswer =
      correct_answer.every(e => text.includes(e)) &&
      correct_answer.length === text.length;
    this.toggle();
    if (finalAnswer) {
      this.setState({
        checkFeedback: true
      });
    } else {
      this.setState({
        checkFeedback: false
      });
    }
    if (qType === "short_text" || qType === "long_text") {
      this.setState({
        checkFeedback: true
      });
    }
  }

  handleSummativeQuizAttempt() {
    const {
      tutorialData: { id, is_frame_busted },
      dispatch
    } = this.props;
    dispatch(attemptAPIAction.attemptQuiz(id));
    if (is_frame_busted) {
      window.resizeTo(screen.width, screen.height);
      window.opener && window.opener.close();
      history.push(`/tutorial-page/${id}/QuizViewer`);
    } else {
      history.push(`/tutorial-page/${id}/QuizViewer`);
    }
  }

  isPassingScoreExists(res) {
    const {
      data: {
        data: {
          tutorial: {
            settings: {
              type_of_formative_quiz = "",
              formative_quiz_passing_score,
              summative_quiz_passing_score
            } = {}
          },
          grade,
          total_points
        }
      }
    } = res;
    let obtainedScore = (grade * 100) / total_points;
    if (type_of_formative_quiz === "practice") {
      this.setState({
        gradeFeedback: "You have completed this tutorial !"
      });
    } else if (type_of_formative_quiz === "graded") {
      if (formative_quiz_passing_score || summative_quiz_passing_score) {
        if (
          obtainedScore < formative_quiz_passing_score ||
          obtainedScore < summative_quiz_passing_score
        ) {
          this.setState({
            gradeFeedback:
              `You have completed this tutorial. Your score is ${grade} out ${total_points} possible points.` +
              `This did not meet the minimum 50% passing threshold for this tutorial. You may take it again to improve your score.`
          });
        } else if (
          obtainedScore > formative_quiz_passing_score ||
          obtainedScore > summative_quiz_passing_score
        ) {
          this.setState({
            gradeFeedback:
              `You have completed this tutorial. Your score is ${grade} out ${total_points} possible points. ` +
              `Congratulations, your score exceeded the 50% passing threshold for this tutorial.`
          });
        }
      } else if (
        !formative_quiz_passing_score ||
        !summative_quiz_passing_score
      ) {
        this.setState({
          gradeFeedback: `You have completed this tutorial. Your score is ${grade} out ${total_points} of possible points.`
        });
      }
    }
  }
  handleFinishAttempt() {
    let {
      dispatch,
      attemptAPIData,
      match: { params: { page } = {} },
      tutorialData: {
        id = "",
        is_public,
        settings: { type_of_formative_quiz }
      }
    } = this.props;
    if (page === "QuizViewer") {
      dispatch(attemptAPIAction.finishQuizAttempt(id, attemptAPIData)).then(
        res => {
          this.isPassingScoreExists(res);
        }
      );
    } else if (page === "tutorialViewer") {
      dispatch(
        attemptAPIAction.finishAttempt(
          id,
          attemptAPIData,
          type_of_formative_quiz
        )
      ).then(res => {
        this.isPassingScoreExists(res);
        this.setState({
          isCompleted: false
        });
      });
    } else {
      dispatch(
        attemptAPIAction.finishAttempt(
          id,
          attemptAPIData,
          type_of_formative_quiz
        )
      ).then(res => {
        this.isPassingScoreExists(res);
        this.setState({
          isCompleted: false
        });
      });
    }
  }
  handleCompleted() {
    this.setState({ isCompleted: true });
  }
  handlePrevious() {
    this.setState({ isCompleted: false });
  }

  setTutorial() {
    this.setState({
      isAlternative: true,
      tutorial: this.props.tutorialData
    });
  }

  close() {
    this.setState({
      isAlternative: false
    });
  }

  toggleModal() {
    this.setState({ isOpenModal: false });
    history.push("/");
  }

  render() {
    let isIE = false || !!document.documentMode;
    let modalClass = "modal-success",
      modalHeader = "Success",
      btnColor = "success";
    if (isIE) {
      modalClass = "modal-danger";
      modalHeader = "Alert";
      btnColor = "danger";
    }
    const isQuiz = this.props.match?.params?.page
    if (this.state.isPublic && !isQuiz && !isElectron()) {
      return (
        <div>
          <Modal
            isOpen={this.state.isOpenModal}
            toggle={this.toggleModal}
            className={modalClass}
          >
            <ModalHeader>{modalHeader}</ModalHeader>
            <ModalBody>
              <div>
                <p>Welcome to Sidecar Learning.</p>
                {isIE && (
                  <p>
                    Oops. Looks like you are using a non-compatible browser.
                    Please use Chrome for best results.
                  </p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color={btnColor} onClick={this.setTutorial}>
                Continue
              </Button>
            </ModalFooter>
          </Modal>
          {this.state.isAlternative && (
            <AlternativeModals
              tutorial={this.state.tutorial}
              fromLogin={true}
              isAlternative={this.state.isAlternative}
              close={this.close}
              doNotShow={this.props.doNotShow}
              dispatch={this.props.dispatch}
            />
          )}
        </div>
      );
    } else {
      const {
        tutorialData,
        checkAnswerData,
        isFinished,
        dispatch,
        certificateId
      } = this.props;
      let {
        match: { params: { id, page } = {} } = {},
        location: { state } = ""
      } = this.props;
      const {
        checkFeedback,
        noAnswer,
        isCompleted,
        custom_wrong_answer_feedback,
        custom_correct_answer_feedback,
        wrong_feedback,
        correct_feedback,
        isOpen,
        gradeFeedback
      } = this.state;

      if (!Object.keys(tutorialData).length) return <Spinner />;

      const user = JSON.parse(localStorage.getItem("user"))
      const isPublishedByCurrentUser = user?.id === tutorialData?.user_id
      const condition = tutorialData?.is_published || isPublishedByCurrentUser 
    
      if(!condition) {
        return (
          <div className="animated fadeIn font-weight-bold p-4" style={{ display: 'flex', justifyContent: 'center', fontSize: "16px" }}>
            The tutorial is not yet published.
          </div>
        )
      }

      return (
        <div>
          <Tutorial
            tutorialData={tutorialData}
            handleQuizSelection={this.handleQuizSelection}
            handleCheckAnswer={this.handleCheckAnswer}
            handleFinishAttempt={this.handleFinishAttempt}
            checkAnswerData={checkAnswerData}
            id={id}
            page={page}
            state={state}
            checkFeedback={checkFeedback}
            handleCompleted={this.handleCompleted}
            handlePrevious={this.handlePrevious}
            isFinished={isFinished}
            certificateId={certificateId}
            noAnswer={noAnswer}
            isCompleted={isCompleted}
            handleReview={this.handleReview}
            handleSummativeQuizAttempt={this.handleSummativeQuizAttempt}
            dispatch={dispatch}
            gradeFeedback={gradeFeedback}
            setTutorialLevel={this.setTutorialLevel}
            tutorialLevel={this.state.tutorialLevel}
            updateTutorialAttemptGuestDetails={
              this.updateTutorialAttemptGuestDetails
            }
          />
          <div>
            <Modal
              className={checkFeedback ? "modal-success" : "modal-danger"}
              isOpen={isOpen}
              toggle={this.toggle}
            >
              <ModalHeader className="modal-header" toggle={this.toggle}>
                Answer Feedback
              </ModalHeader>
              <ModalBody>
                <div className="visuallyhidden" aria-live="assertive">Go to answer feedback</div>
                <Label className={checkFeedback ? "correct" : "wrong"} aria-live="polite">
                  {checkFeedback
                    ? Boolean(correct_feedback)
                      ? correct_feedback
                      : custom_correct_answer_feedback
                    : Boolean(wrong_feedback)
                    ? wrong_feedback
                    : custom_wrong_answer_feedback}
                </Label>
              </ModalBody>
              <ModalFooter>
                <Button onClick={this.toggle} tabIndex="0">Close</Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    tutorialData: state.tutorial.tutorialData,
    checkAnswerData: state.checkAnswerData.checkAnswerData,
    checkAnswerIds: state.checkAnswerData.checkAnswerIds,
    attemptAPIData: state.attemptAPIData.attemptId,
    certificateId: state.attemptAPIData?.attemptInfo?.data?.certificate_id,
    isFinished: state.attemptAPIData,
    checkTutorial: state.tutorial.checkPublicTutorial,
    checkAnswerError: state.checkAnswerData.errData
  };
}

const connectedTutorial = connect(mapStateToProps, null)(TutorialContainer);
export { connectedTutorial as TutorialContainer };
