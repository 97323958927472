import React from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";

export default class MultipleChoice extends React.Component {
  constructor (props) {
    super(props);
  }

  render() {
    const {
      index,
      renderHTML,
      ques,
      buttonName,
      handleQuizSelection,
      activeOption,
      handleCheckAnswer,
      quizData,
    } = this.props;
    
    return (
      <div id={index} className="quiz-question">
        <p className={"m-0"}>{renderHTML(`Q. ${ques.text}`)}</p>
        {ques.options.map((opt, index) => {
          return (
            <div className="form-multiple-choice" id={index} key={index}>
              <FormGroup check className="radio">
                <Label check>
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id={index}
                    name={`multiple_choice_${ques.id}`}
                    value={opt}
                    onChange={e =>
                      handleQuizSelection(ques.id, ques.type, e)
                    }
                    checked={
                      ques.selectedValue &&
                        activeOption(opt, ques.selectedValue)
                    }
                  />
                  {opt}
                </Label>
              </FormGroup>
            </div>
          );
        })}
        <Button
          className="btn-text"
          aria-label="Submit to answer the quiz question"
          onClick={() =>
            handleCheckAnswer(
              ques.selectedValue,
              quizData.id,
              ques.id,
              ques.correct_answer_feedback,
              ques.wrong_answer_feedback
            )
          }
        >
          {buttonName}
        </Button>
      </div>
    );
  }
}
