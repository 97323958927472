import { rolesConstants } from "../../_constants/index";
const initial = {
  userRoleData: [],
  userData: [],
  userRolesNavData: [],
  isLoadingUserData: false,
  isLoadinguserRoleData: false
};
export function roles(state = initial, action) {
  switch (action.type) {
    case rolesConstants.GETALL_USERS_REQUEST:
      return { ...state, isLoadingUserData: true };
    case rolesConstants.GETALL_USERS_SUCCESS:
      return { ...state, userData: action.data, isLoadingUserData: false };
    case rolesConstants.GETALL_USERS_FAILURE:
      return { ...state, userData: [], isLoadingUserData: false };
    case rolesConstants.GETALL_USERS__ROLES_REQUEST:
      return { ...state, isLoadinguserRoleData: true };
    case rolesConstants.GETALL_USERS_ROLES_SUCCESS:
      return {
        ...state,
        userRoleData: action.data,
        isLoadinguserRoleData: false
      };
    case rolesConstants.GETALL_USERS__ROLES_FAILURE:
      return { ...state, userRoleData: [], isLoadinguserRoleData: false };
    case rolesConstants.GETALL_USERS_ROLES_NAVS_SUCCESS:
      return { ...state, userRolesNavData: action.data };
    case rolesConstants.GETALL_USERS__ROLES__NAVS_FAILURE:
      return { ...state, userRolesNavData: [] };
    case rolesConstants.DELETE_ROLE_USER_SUCCESS:
      state.userRoleData.forEach(
        d =>
          action.data.role in d &&
          d[action.data.role].splice(
            d[action.data.role].findIndex(
              r => r.id === action.data.user_ids[0] && true
            ),
            1
          )
      );
      return { ...state };
    case rolesConstants.DELETE_ROLE_USER_FAILURE:
      return { ...state };
    case rolesConstants.ASSIGN_ROLES_TO_USER_SUCCESS:
      let arr = [];
      let {
        data: { role, user_ids }
      } = action;
      let { userData, userRoleData } = state;
      user_ids.forEach(a => arr.push(_.find(userData, t => t.id === a)));
      Object.values(userRoleData.find(a => a[role]))[0].push(...arr);
      return { ...state };
    case rolesConstants.ASSIGN_ROLES_TO_USER_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
