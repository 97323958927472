import { v4 as uuidv4 } from "uuid";
import { toastConstants } from "../_constants";
import cable from "./cable";

export default function tutorialChannelSubscription({
  email,
  tutorialId,
  dispatch,
  handleChannelResponse,
  that,
}) {
  const uuid = uuidv4();

  const dispatchConnectionStatusMessage = (status) => {
    dispatch({
      type: toastConstants.SHOW_TOAST,
      message: {
        type: "ERROR",
        data: `Server ${status} the connection!`,
      },
    });
  };

  cable.subscriptions.create(
    {
      channel: "TutorialChannel",
      room: `tutorial_${uuid}`,
      tutorial_id: tutorialId,
    },
    {
      received(data) {
        handleChannelResponse(data, tutorialId, this);
        that && that.state._isMounted && that.setState({
          establishingWebSocketConnection: false,
        });
      },

      rejected() {
        console.log("The connection was rejected");
        that && that.state._isMounted && that.setState({
          establishingWebSocketConnection: false,
        });
      },

      disconnected() {
        console.log("The connection was disconnected");
        this.unsubscribe();
        this.consumer.connection.monitor.stop();
        dispatchConnectionStatusMessage("disconnected");
        that && that.state._isMounted && that.setState({
          establishingWebSocketConnection: false,
        });
      },

      closed() {
        console.log("The connection was closed");
        dispatchConnectionStatusMessage("closed");
        that && that.state._isMounted && that.setState({
          establishingWebSocketConnection: false,
        });
      },
    }
  );
}
