import React from "react";
import "./Tutorial.scss";
import { Button, Tooltip } from "reactstrap";
import _ from "lodash";
import TutorialQuiz from "./Tutorial-Quiz";
import TutorialMedia from "./TutorialMedia";
import TutorialEmbed from "./Tutorial-Embed";
import TutorialText from "./Tutorial-Text";
import TutorialImgUrl from "./TutorialImgUrl";
import renderHTML from "react-render-html";
import { history } from "../../../_helpers/history";
import TutorialComplete from "./TutorialComplete";
import { contentCreatorActions } from "../../../_actions";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { isElectron } from '../../../utils'

export default class TutorialLevel extends React.Component {
  constructor(props) {
    super(props);
    this.leftHeader = React.createRef();
    this.state = {
      startIndex: 0,
      pageRecord: {},
      chapterTitle: {},
      chapterDescription: {},
      elements: [],
      long_text: "",
      short_text: "",
      isOpen: false,
      tooltipOpenTable: false,
      tooltipOpenClose: false,
    };
  }

  componentDidMount() {
    const { tutorialPageData } = this.props;
    if (tutorialPageData && !tutorialPageData.is_frame_busted) {
      setTimeout(function() {
        const el = document.getElementById("focus");
        if (el) el.focus();
      }, 6000);
    }
    this.focusLeftHeader();
  }

  focusLeftHeader() {
    this.leftHeader.current.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      page = "",
      tutorialPageData,
      tutorialPageData: { content: { tutorial = [] } = {} } = {},
    } = nextProps;
    let pageData = { "0": [] };
    var apterTitle = { ...this.state.chapterTitle };
    var desc = { ...this.state.chapterDescription };
    let i = 0;
    let propsData =
      page === "QuizViewer"
        ? tutorialPageData &&
          tutorialPageData?.summative_test?.summative_test
        : tutorial;
    propsData &&
      propsData.map((chap) => {
        apterTitle = { ...apterTitle, [i]: chap.title };
        desc = { ...desc, [i]: chap.description };
        pageData = { ...pageData, [i]: [] };
        chap.elements.map((ele) => {
          if (ele.type === "page-break") {
            i = i + 1;
            pageData = { ...pageData, [i]: [] };
            apterTitle = { ...apterTitle, [i]: chap.title };
            this.setState({ chapterTitle: apterTitle });
            desc = { ...desc, [i]: chap.description };
            this.setState({ chapterDescription: desc });
          } else {
            pageData[i.toString()].push(ele);
          }
        });
        i = i + 1;
        this.setState({ chapterTitle: apterTitle });
        this.setState({ chapterDescription: desc });
      });

    if (page !== "QuizViewer") {
      pageData[Object.keys(pageData).length] = [
        { type: "review", index: Object.keys(pageData).length },
      ];
    }
    /* since next prev got freeze in shared tutorial
            else{
                this.setState({startIndex:0});
            } */
    this.setState({
      pageRecord: pageData,
      pageBreakCount: i,
    });

    if (isElectron()) {
      const iframeLinks = document.querySelectorAll(".iframeLink")
      iframeLinks.forEach(link => {
        link.addEventListener('click', e => {
          e.preventDefault();
          eval("require('electron').ipcRenderer.invoke('webview', e.target.href);");
        })
      })
    }
  }

  handleNextChapter = (e) => {
    const { handleFinishAttempt, handlePrevious } = this.props;
    const { page } = this.props;
    let newState = this.state.startIndex;
    const { pageRecord } = this.state;
    newState =
      e.target.id === "prev"
        ? newState
          ? newState - 1
          : newState
        : newState + 1 < Object.keys(pageRecord).length
        ? newState + 1
        : newState;
    this.setState(
      {
        startIndex: newState,
      },
      function() {
        document.getElementsByClassName("page-btn")[0].scrollTop = 0;
        this.state.startIndex + 1 === Object.keys(pageRecord).length
          ? page !== "QuizViewer" && handleFinishAttempt()
          : handlePrevious();
      }
    );
    this.focusLeftHeader();
  }

  componentDidUpdate() {
    const { startIndex, chapterTitle } = this.state;
    const {
      tutorialPageData: { configuration }
    } = this.props;
    const {
      look_and_feel: {
        font_style = "",
        headline_tag = "h2",
        color_scheme = "",
      } = {},
    } = configuration || {}; //set headline_tag default to h5 as it's missing in data for printing chapter heading

    if (headline_tag) {
      let tagItem = document.createElement(headline_tag);
      tagItem.style.fontFamily = font_style;
      tagItem.className = "animated fadeIn";
      tagItem.innerHTML = chapterTitle[startIndex]
        ? chapterTitle[startIndex]
        : "";
      tagItem.style.color = color_scheme;
      let content = document.getElementsByClassName("left-side-content");
      if (content.length) {
        const childNodeIndex = Array(...content[0].childNodes).findIndex(
          value => value.tagName === headline_tag.toLocaleUpperCase() && true
        );
        if (childNodeIndex > -1 && childNodeIndex === 0) {
          content[0].childNodes[childNodeIndex].innerHTML = tagItem.innerHTML;
        } else {
          content[0].prepend(tagItem);
        }
      }
    }
  }

  handleSelection = (quesId, quesType, event) => {
    const {
      target: { value },
    } = event;
    let elements = this.state.elements;
    let obj = { qId: "", selectedValues: [] };

    if (elements.length) {
      let keyContains = false;
      elements.forEach((e) => {
        if (e.qId === quesId) {
          keyContains = true;
        }
      });
      if (keyContains) {
        elements.forEach((e) => {
          if (e.qId === quesId) {
            if (quesType === "single_choice") e.selectedValues[0] = value;
            else if (quesType === "boolean") e.selectedValues[0] = value;
            else {
              let bool = _.includes(e.selectedValues, value);
              if (bool) {
                let index = e.selectedValues.indexOf(value);
                index !== -1 && e.selectedValues.splice(index, 1);
              } else e.selectedValues.push(value);
            }
          }
        });
      } else {
        obj.selectedValues.push(value);
        elements.push({ ...obj, qId: quesId });
      }
    } else {
      obj.selectedValues.push(value);
      elements.push({ ...obj, qId: quesId });
    }
    this.setState({ elements: [...elements] }, () => {
      this.props.handleQuizSelection(elements);
    });
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  onNavigate = (id, title) => {
    const { chapterTitle, pageRecord } = this.state;
    if (Object.keys(pageRecord).length > 1) {
      this.setState({
        startIndex: _.findIndex(
          Object.values(chapterTitle),
          (c) => c === title
        ),
      });
    }
  }

  handleRoute = () => {
    const userLogin = localStorage.getItem("user");
    const {
      tutorialPageData: { id, is_public = true, is_frame_busted = "" },
      state: { page = "", data = {}, tab = "" } = "",
    } = this.props;
    const userRole = localStorage.getItem("defaultRole");
    if (userRole === "learner" || userRole === "org_administrator") {
      history.push("/");
    } else if (userRole === "full_content_creator") {
      if (Boolean(page)) {
        this.props.dispatch(contentCreatorActions.AddTabsData(data));
        history.push({ pathname: `${page}`, state: { data: data, tab: tab } });
      } else history.push({ pathname: "/tutorial-management", state: id });
    } else {
      if (is_public && !is_frame_busted) {
        history.push("/");
      }
    }
  }

  handleWindowClose = () => {
    let userHeader = JSON.parse(localStorage.getItem("headerJson"));
    const {
      tutorialPageData: {
        id = "",
        is_public = true,
        is_frame_busted = "",
      } = {},
    } = this.props;
    const { state: { tab = "", data = {}, page = "" } = {} } = window;
    if (userHeader) {
      const userLogin = localStorage.getItem("user");
      const userRole = userLogin && JSON.parse(userLogin).main_role;
      if (userRole === "full_content_creator") {
        if (Boolean(window.state)) {
          this.props.dispatch(contentCreatorActions.AddTabsData(data));
          history.push({
            pathname: `${page}`,
            state: { data: data, tab: tab.toString() },
          });
        } else history.push({ pathname: "/tutorial-management", state: id });
        // window.location.href = '/tutorial-management';
        window.resizeTo(screen.width, screen.height);
        window.opener.close();
      } else {
        history.push({ pathname: "/tutorial-management", state: id });
        // window.location.href = '/';
        window.resizeTo(screen.width, screen.height);
        window.opener.close();
      }
    } else {
      if (is_public && is_frame_busted) {
        history.push("/");
        window.close();
        window.opener.close();
      }
    }
  }

  closeAttempt = () => {
    const { handleFinishAttempt } = this.props;
    handleFinishAttempt();
  }

  toggleTooltip = (icon) => {
    if (icon === "tableofContents")
      this.setState({
        tooltipOpenTable: !this.state.tooltipOpenTable,
      });
    else if (icon === "close")
      this.setState({
        tooltipOpenClose: !this.state.tooltipOpenClose,
      });
  }

  render() {
    const {
      tutorialPageData,
      tutorialPageData: { configuration } = {},
      tutorialPageData: { settings: { certificate_enabled, opt_out_of_linked_table_of_content: isOptOut } = {} } = {},
      handleCheckAnswer,
      checkAnswerData,
      page,
      isCompleted,
      checkFeedback,
      emptyText,
      handleReview,
      handleSummativeQuizAttempt,
      gradeFeedback,
      certificateId,
      updateTutorialAttemptGuestDetails,
    } = this.props;
    const {
      look_and_feel,
      look_and_feel: { tagline = "", color_scheme = "" } = {},
      logo,
    } = configuration || {};
    const color = color_scheme;

    const {
      startIndex,
      pageRecord,
      chapterTitle,
      chapterDescription,
      isOpen,
    } = this.state;
    const currentPage = startIndex + 1;
    const lastPage = Object.keys(pageRecord).length;
    const left_class =
      (logo && logo.url) || tagline
        ? "page-btn  page-left"
        : "page-btn page-left-2";
    const app = (element, index=0) => {
      switch (element.type) {
        case "quiz":
          return (
            <TutorialQuiz
              key={index}
              handleQuizSelection={this.handleSelection}
              quizData={element.quiz_data}
              pageNo={this.state.startIndex}
              //  handleSelection = { this.handleSelection }
              handleCheckAnswer={handleCheckAnswer}
              checkAnswerData={checkAnswerData}
              page={page}
              checkFeedback={checkFeedback}
              emptyText={emptyText}
            />
          );
          break;
        case "media":
          return <TutorialMedia elements={element} key={index} />;
          break;
        case "embedded_media":
          return (
            <TutorialEmbed embeddedMedia={element.code} element={element} key={index} />
          );
          break;
        case "text":
          return <TutorialText textData={element.text} key={index} />;
          break;
        case "url":
          return <TutorialImgUrl imgUrl={element.url} element={element} key={index} />;
          break;
        case "review":
          return (
            <TutorialComplete
              key={index}
              handleReview={handleReview}
              tutorialPageData={tutorialPageData}
              handleSummativeQuizAttempt={handleSummativeQuizAttempt}
              gradeFeedback={gradeFeedback}
              certificateId={certificateId}
              certificate_enabled={certificate_enabled}
              updateTutorialAttemptGuestDetails={
                updateTutorialAttemptGuestDetails
              }
            />
          );
      }
    };
    return (
      <div className="left-side">
        <div
          className="left-header animated fadeIn d-inline-flex mb-2 w-100"
          style={{ backgroundColor: color }}
        >
          <div className="w-80">
            {/*comment since add button for focus to start screen reader from tutorial page instead of iframe   */}
            {/*<h1 className="border-none" id={'focus'}>{ tutorialPageData && tutorialPageData.title}</h1>*/}
            <button
              id={"focus"}
              autoFocus={true}
              style={{ display: "none" }}
              aria-label={
                "You will use this window to learn how to use the website to the right"
              }
            ></button>
	    <h1
	      className="tutorial-btn border-0 font-weight-bold p-3 "
	      tabIndex="0"
	      ref={this.leftHeader}
	    >
              {tutorialPageData && tutorialPageData.title}
            </h1>
          </div>
          {!isOptOut && 
          (<div className="w-10">
            <div className="outer">
              <div className="middle">
                <div className="inner">
                  <Dropdown
                    isOpen={isOpen}
                    direction="left"
                    toggle={this.toggle}
                    id="tut-chap-btn"
                  >
                    <DropdownToggle
                      aria-label={
                        "To expand the chapter list,select this button and arrow down to move to a chapter of your choice."
                      }
                    >
                      <i
                        className="fa fa-ellipsis-v custom-icons"
                        id="TooltipExample"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.tooltipOpenTable}
                        target="TooltipExample"
                        toggle={() => this.toggleTooltip("tableofContents")}
                      >
                        Table of Contents
                      </Tooltip>
                    </DropdownToggle>
                    <DropdownMenu>
                      {tutorialPageData &&
                        tutorialPageData.content.tutorial.map((chap, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => this.onNavigate(i, chap.title)}
                            >
                              {chap.title}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>)
          }
          <div className="w-10" id="close-btn-area">
            <div className="outer">
              <div className="middle">
                <div className="inner">
                  <div className="custom-close">
                    <Button
                      className="tut-custom-close-btn"
                      onClick={() => {
                        tutorialPageData.is_frame_busted
                          ? this.handleWindowClose()
                          : this.handleRoute();
                      }}
                      aria-label={"To close tutorial select this"}
                    >
                      <i
                        className="fa fa-times custom-icons mt-1"
                        id="closeTooltip"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.tooltipOpenClose}
                        target="closeTooltip"
                        toggle={() => this.toggleTooltip("close")}
                      >
                        Close Tutorial
                      </Tooltip>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={left_class}>
          <div className="left-side-content">
            {
              //removed since tagItem.innerHTML already contains the title with new configuration settings
              // configuration === null &&
              // <h3>{chapterTitle[startIndex] && chapterTitle[startIndex]}</h3>
            }
            {chapterDescription[startIndex] && <p className="p-text animated fadeIn">
                                                   {renderHTML(chapterDescription[startIndex])}
                                               </p>}
            <div className="quiz-content animated fadeIn">
              {
                <div>
                  {pageRecord &&
                    pageRecord[startIndex] &&
                    pageRecord[startIndex].map((element, index) => app(element, index))}
                </div>
              }
            </div>
            <div className="pagination">
              <div className="nextPrev">
                <p>
                  Page {currentPage} of {lastPage}{" "}
                </p>
                { currentPage > 1 && <Button
                  type="submit"
                  className="btn btn-next"
                  id="prev"
                  onClick={this.handleNextChapter}
                  aria-label={
                    `Back arrow to previous tutorial page ${currentPage - 1} out of ${lastPage}`
                  }
                >
                  <i id="prev" className="fa fa-caret-left" />
                </Button>}
                  {currentPage !== lastPage && <Button
                  type="submit"
                  disabled={
                    currentPage === lastPage && true
                  }
                  className="btn btn-prev"
                  id="next"
                  onClick={this.handleNextChapter}
                  aria-label={
                    `Forward arrow to next tutorial page ${currentPage + 1} out of ${lastPage}.`
                  }
                 >
                  <i id="next" className="fa fa-caret-right" />
                </Button>}
              </div>
            </div>
          </div>
          {page === "QuizViewer" &&
            currentPage === lastPage && (
              <div className="btn-submit p-1 text-center">
                <Button
                  type="submit"
                  color="success"
                  onClick={this.closeAttempt}
                >
                  Submit All Answers
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }
}
