import { credentialsConstants, toastConstants } from "../../_constants/index";
import { credentialsService } from "../../_services/index";

export const credentialsActions = {
  getCredentialsData,
  deleteUserData,
  AddUserData,
  EditUserData,
  selectRoles
};

function getCredentialsData() {
  return dispatch => {
    dispatch({ type: credentialsConstants.GETALL_CREDENTIALS_REQUEST });
    credentialsService.getCredentials().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: credentialsConstants.GETALL_CREDENTIALS_SUCCESS, data };
  }
  function failure(error) {
    return { type: credentialsConstants.GETALL_CREDENTIALS_FAILURE, error };
  }
}
function deleteUserData(obj) {
  return dispatch => {
    return credentialsService.deleteUser(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully deleted." }
        });
        return dispatch(success(obj));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };
  function success(data) {
    return { type: credentialsConstants.DELETE_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: credentialsConstants.DELETE_USER_FAILURE, error };
  }
}
function AddUserData(obj) {
  return dispatch => {
    return credentialsService.addUser(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully saved." }
        });
        return dispatch(success({ data, obj }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };
  function success(data) {
    return { type: credentialsConstants.ADD_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: credentialsConstants.ADD_USER_FAILURE, error };
  }
}
function EditUserData(id, obj, activeRole) {
  return dispatch => {
    return credentialsService.editUser(id, obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully updated." }
        });
        return dispatch(success({ data, activeRole }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };
  function success(data) {
    return { type: credentialsConstants.EDIT_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: credentialsConstants.EDIT_USER_FAILURE, error };
  }
}
function selectRoles() {
  return dispatch => {
    return dispatch({
      type: toastConstants.SHOW_TOAST,
      message: { type: "ERROR", data: "Please select at least one role" }
    });
  };
}
