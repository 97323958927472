import React, { Component } from "react";
import Spinner from "../../components/spinner";
import "./adminDashboardSidebar.scss";
import {
  Collapse,
  Card,
  CardHeader,
  CardBody,
  Table,
  ListGroup,
  ListGroupItem,
  Badge
} from "reactstrap";
import { Link } from "react-router-dom";

class AdminDashboardSidebar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      tutorialsData,
      toggleMenu,
      collapse,
      isLoadingTutorialsDashboard
    } = this.props;
    return (
      <div className="row">
        <div className="main-full-sidebar animated fadeIn w-100">
          <div className="tut-analytic-sidebar">
            <Card className="border-0" id="tut-published-scroll">
              <CardHeader>Tutorial Published</CardHeader>
              {isLoadingTutorialsDashboard ? (
                <Spinner />
              ) : (
                <CardBody className="p-0">
                  <Table responsive className="mb-0">
                    <tbody>
                      {tutorialsData.map((t, index) => {
                        return (
                          <tr className="tut-analytics-sidebar-tr-border">
                            <td
                              className="border-0"
                              onClick={e => {
                                e.preventDefault();
                                toggleMenu(`${index}${t.id}details`);
                              }}
                              data-event={index}
                            >
                              <div className="d-flex tutorial-click">
                                {!collapse[`${index}${t.id}details`] ? (
                                  <i className="fa fa-angle-right mr-1" />
                                ) : (
                                  <i className="fa fa-angle-down mr-1" />
                                )}
                                <p className="tutorial-title">{t.title}</p>
                                <Badge color="primary tut-analytics-badge">
                                  {t.tutorials.length}
                                </Badge>
                              </div>
                              <Collapse
                                isOpen={collapse[`${index}${t.id}details`]}
                              >
                                <div>
                                  {t.tutorials.map((c, i) => {
                                    return (
                                      c.is_published && (
                                        <div className="p-2 mt-2 tut-published-data-card">
                                          <p className="mb-0">
                                            <Link
                                              to={`/tutorial-page/${c &&
                                                c.id}/tutorialViewer`}
                                              className="custom-a"
                                            >
                                              {c.title}
                                            </Link>
                                          </p>
                                          <div className="dis-flex  justify-content-between">
                                            <p className="mb-0">June 01</p>
                                            <p className="mb-0">
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                                </div>
                              </Collapse>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              )}
            </Card>
            <Card className="border-0" id="tut-draft-scroll">
              <CardHeader>Tutorial Drafts</CardHeader>
              {isLoadingTutorialsDashboard ? (
                <Spinner />
              ) : (
                <CardBody className="p-0">
                  <Table responsive className="table-text mb-0">
                    <tbody>
                      {tutorialsData.map((t, index) => {
                        return (
                          <tr className="tut-analytics-sidebar-tr-border">
                            <td
                              className="border-top-0"
                              onClick={e => {
                                e.preventDefault();
                                toggleMenu(`${index}${t.id}detail`);
                              }}
                              data-event={index}
                            >
                              <div className="d-flex tutorial-click">
                                {!collapse[`${index}${t.id}detail`] ? (
                                  <i className="fa fa-angle-right mr-1" />
                                ) : (
                                  <i className="fa fa-angle-down mr-1" />
                                )}
                                <p className="tutorial-title">{t.title}</p>
                                <Badge color="primary tut-analytics-badge">
                                  {t.tutorials.length}
                                </Badge>
                              </div>
                              <Collapse
                                isOpen={collapse[`${index}${t.id}detail`]}
                              >
                                <div>
                                  {t.tutorials.map((c, i) => {
                                    return (
                                      !c.is_published && (
                                        <div className="p-2 mt-2 tut-published-data-card">
                                          <p className="mb-0">
                                            <a href="#">{c.title}</a>
                                          </p>
                                          <div className="dis-flex  justify-content-between">
                                            <p className="mb-0">June 01</p>
                                            <p className="mb-0">
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                              <i className="fa fa-star" />
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                                </div>
                              </Collapse>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDashboardSidebar;
