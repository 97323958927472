import React from "react";
import { Row } from "reactstrap";
class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <div className="w-100">
            <a
              href="javascript:void(0)"
              data-cb-type="checkout"
              data-cb-plan-id="classroom"
              className="mt-3 w-25 btn btn-primary"
              style={{ height: "6vh" }}
            >
              Subscribe to Individual Plan
            </a>
          </div>
          <div className="w-100">
            <a
              href="javascript:void(0)"
              data-cb-type="checkout"
              data-cb-plan-id="academic-advanced"
              data-cb-plan-quantity="1"
              className="mt-3 w-25 btn btn-primary"
              style={{ height: "6vh" }}
            >
              Subscribe to Basic Plan
            </a>
          </div>
          <div className="w-100">
            <a
              href="javascript:void(0)"
              data-cb-type="checkout"
              data-cb-plan-id="academic-enterprise"
              data-cb-plan-quantity="1"
              className="mt-3 w-25 btn btn-primary"
              style={{ height: "6vh" }}
            >
              Subscribe to Full Plan
            </a>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

export default Home;
