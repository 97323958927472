import React from "react";
import "../components/Learner/Home-Learner/Home-Learner.scss";
import {
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Table,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { history } from "../_helpers/history";
import { learnerDashboardActions } from "../_actions/LearnerActions/learnerDashboard.action";
import { userActions } from "../_actions/user.actions";
import { connect } from "react-redux";
import { windowObject } from "../_actions/preview.action";
import { isElectron } from "../utils";

let tut_win_test = "",
  web_win_test = "";

class AlternativeModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      viewTutorialId: "",
      url: "",
      isTestPopupOpen: false,
      isOpenSetting: false,
      tutorial: {},
      isFullScreenModal: false,
      blockPopup: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openTestWindow = this.openTestWindow.bind(this);
    this.doNotShow = this.doNotShow.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openSetting = this.openSetting.bind(this);
    this.setTutorial = this.setTutorial.bind(this);
    this.checkFullscreen = this.checkFullscreen.bind(this);
    this.toggleFullScreenModal = this.toggleFullScreenModal.bind(this);
    this.closeFullScreenModal = this.closeFullScreenModal.bind(this);
    this.popupBlocker = this.popupBlocker.bind(this);
    this.showBlockPopup = this.showBlockPopup.bind(this);
    // this.openIframePopup = this.openIframePopup.bind(this);
  }

  componentDidMount() {
    if (this.props.isAlternative === true && this.props.tutorial)
      this.setTutorial(this.props.tutorial);

    if (this.props.isAlternative === true && this.props.fromLogin === true) {
      if (!this.checkFullscreen()) {
        this.openTestWindow();
      } else {
        this.toggleFullScreenModal();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isAlternative === false &&
      nextProps.isAlternative === true &&
      nextProps.tutorial
    )
      this.setTutorial(nextProps.tutorial);

    if (
      this.props.isAlternative === false &&
      nextProps.isAlternative === true &&
      nextProps.fromLogin === true
    ) {
      if (!this.checkFullscreen()) {
        this.openTestWindow();
      } else {
        this.toggleFullScreenModal();
      }
    }
  }

  popupBlocker(popupWindow) {
    if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        try {
          popupWindow.focus();
        } catch (e) {
          return true;
        }
      } else {
        popupWindow.onload = function() {
          return popupWindow.innerHeight > 0 === false;
        };
      }
    } else {
      return true;
    }
    return false;
  }

  showBlockPopup() {
    const { blockPopup } = this.state;
    this.setState({ blockPopup: !blockPopup });
  }

  toggleModal() {
    const { viewTutorialId, url } = this.state;
    tut_win_test && tut_win_test.close();
    web_win_test && web_win_test.close();
    this.closeModal();
    let tut_win = window.open(
      `/tutorial-page/${viewTutorialId}/tutorialViewer`,
      "_blank",
      `width=${screen.width / 4.5},height=${screen.height}`
    );
    tut_win.onload = function() {
      let web_win = window.open(
        url,
        "_blank",
        `width=${(screen.width / 4) * 3},height=${
          screen.height
        },left=${screen.width / 3}`
      );
    };

    // let tut_win=window.open(`/tutorial-page/${viewTutorialId}/tutorialViewer`, '_blank', `width=${(screen.width/4)},height=${screen.height}`);
    // let web_win=window.open(url, '_blank', `width=${(screen.width/4)*3},height=${screen.height},left=${screen.width/4}`);
  }

  closeModal() {
    this.setState({
      isOpen: false,
      isOpenSetting: false
    });
    this.props.close();
  }

  setTutorial(tutorialData) {
    this.setState(
      {
        tutorial: tutorialData
      },
      () => {
        this.openModal();
      }
    );
  }

  checkFullscreen() {
    if (navigator.appVersion.indexOf("Mac") !== -1) {
      if (!window.screenTop && !window.screenY) return true;
      else return false;
    } else {
      if (
        window.innerWidth === screen.width &&
        window.innerHeight === screen.height
      )
        return true;
      else return false;
    }
  }

  toggleFullScreenModal() {
    this.setState({
      isFullScreenModal: true
    });
  }

  closeFullScreenModal() {
    this.setState({
      isFullScreenModal: false
    });
    this.openModal();
  }

  openModal() {
    const { tutorial } = this.state;
    if (tutorial && tutorial.is_frame_busted) {
      if(isElectron()) {
        return history.push(`/tutorial-page/${tutorial.id}/tutorialViewer`);
      }
      if (!this.checkFullscreen()) {
        this.openTestWindow();
      } else {
        this.toggleFullScreenModal();
      }
    } else if (
      tutorial &&
      (tutorial.is_frame_busted === false || tutorial.is_frame_busted === null)
    ) {
      history.push(`/tutorial-page/${tutorial.id}/tutorialViewer`);
    } else {
      if (!this.checkFullscreen()) {
        this.openTestWindow();
      } else {
        this.toggleFullScreenModal();
      }
    }
  }

  doNotShow() {
    const { dispatch } = this.props;
    dispatch(learnerDashboardActions.toggleDoNotShow());
  }

  openTestWindow() {
    const { viewTutorialId, url, tutorial } = this.state;
    const { fromLogin = false, setWindowData } = this.props;

    if (fromLogin === false) {
      let tut_win = window.open(
        `/tutorial-page/${tutorial.id}/tutorialViewer/public`,
        "_blank",
        `top=0,left=0,width=${Math.round(screen.width / 4)},height=${
          screen.height
        }`
      );
      if (this.popupBlocker(tut_win)) {
        this.showBlockPopup();
      } else {
        window.resizeTo(screen.width * 0.75, screen.height);

        window.onresize = function() {
          window.moveTo((screen.width * 25) / 100, 0);
        };
        window.location.href = tutorial.starting_url;
      }
      if (setWindowData) {
        tut_win.state = {
          page: tutorial.id
            ? `/tutorial-management/edit/${tutorial.id}`
            : `create-tutorial`,
          data: tutorial,
          tab: setWindowData
        };
      }
    } else {
      if (fromLogin === true && this.props.tutorial) {
        tut_win_test = window.open(
          `/public-tutorial/${this.props.tutorial.id}`,
          "_blank",
          `width=${screen.width - 200},height=${screen.height - 200}`
        );
      } else {
        tut_win_test = window.open(
          `/`,
          "_blank",
          `width=${screen.width - 200},height=${screen.height -
            200},top=0,left=0`
        );
        // tut_win_test.resizeTo(screen.width-200,screen.height-200);
      }

      this.props.close();
    }

    this.setState({
      isTestPopupOpen: true
    });
  }

  // openIframePopup(){
  //     let obj_win = window.open(this.state.tutorial.starting_url, '_blank', `left=${screen.width*25/100},height=${screen.height},width=${(screen.width*75)/100}`);
  //     obj_win.resizeTo((screen.width * 75) / 100, screen.height);
  //     obj_win.moveTo(screen.width * 25 / 100, 0);
  // }

  openSetting() {
    tut_win_test && tut_win_test.close();
    web_win_test && web_win_test.close();
    this.setState(
      {
        isOpenSetting: true
      },
      () => {
        tut_win_test && tut_win_test.close();
        web_win_test && web_win_test.close();
      }
    );
  }

  render() {
    console.log("tut_win_test render", tut_win_test);
    const {
      isOpen,
      doNotShow,
      isTestPopupOpen,
      isOpenSetting,
      isFullScreenModal,
      blockPopup
    } = this.state;
    let isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    let isFirefox = typeof InstallTrigger !== "undefined";
    let isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );
    let isIE = false || !!document.documentMode;
    let isEdge = !isIE && !!window.StyleMedia;

    return (
      <React.Fragment>
        {/*<button type="button" style={{display: 'none'}} id="btnClick" onClick={this.openIframePopup} ref={el => this.myBtn = el} >Click</button>*/}
        <Modal
          isOpen={isOpen}
          toggle={this.closeModal}
          className="modal-danger modal-setting modal-information"
        >
          <ModalHeader>Important!</ModalHeader>
          <ModalBody>
            <div>
              <p className="modal-question-text">
                Do you see the two windows below?
              </p>
            </div>
            <div className="check-input">
              <Input
                type="checkbox"
                onClick={() => {
                  this.doNotShow();
                }}
              />{" "}
              <p>Do not show this warning again.</p>
            </div>
            <div className="arrow-div">
              <i className="fa fa-arrow-down" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Yes
            </Button>
            <Button color="danger" onClick={this.openSetting}>
              No
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={isOpenSetting}
          toggle={this.closeModal}
          className="modal-primary modal-setting modal-lg"
        >
          <ModalHeader>Pop up Settings</ModalHeader>
          <ModalBody>
            <div className="modal-popup-setting">
              <p>You must turn off a pop-up blocker.</p>
              <div>
                {isChrome && (
                  <img
                    className="img-fluid w-100"
                    src={require("../../public/img/popup.gif")}
                    alt={"chrome"}
                  />
                )}
                {isFirefox && (
                  <img
                    className="img-fluid w-100"
                    src={require("../../public/img/mozilla.gif")}
                    alt={"firefox"}
                  />
                )}
                {isSafari && (
                  <img
                    className="img-fluid w-100"
                    src={require("../../public/img/safari.gif")}
                    alt={"safari"}
                  />
                )}
                {isEdge && (
                  <img
                    className="img-fluid w-100"
                    src={require("../../public/img/edge.gif")}
                    alt={"edge"}
                  />
                )}
              </div>
              <p className="mt-1 mb-0">Did you turn-off pop-up blocker?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  isOpen: false,
                  isOpenSetting: false
                });
                this.openModal();
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={isFullScreenModal}
          toggle={this.closeFullScreenModal}
          className="modal-warning modal-setting"
        >
          <ModalHeader>Exit Full Screen</ModalHeader>
          <ModalBody>
            <div className="modal-popup-setting">
              <p>Please exit full screen to view the tutorial.</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.closeFullScreenModal}>
              I have exited full screen
              <screen></screen>
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={blockPopup} className="modal-warning modal-setting">
          <ModalHeader>Pop-up Setting</ModalHeader>
          <ModalBody>
            <div>
              Pop-ups are blocked for the website. Please allow them to view the
              tutorial.
            </div>
            <div>
              In the address bar, click Pop-ups blocked{" "}
              <img
                src={require("../../public/img/blockIcon.png")}
                alt={"blockIcon"}
                height={14}
                width={16}
              />{" "}
              .
            </div>
            <div>
              Select Always allow pop-ups and redirects from [site] and then
              Done.
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.showBlockPopup}>
              Yes
              <screen></screen>
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    winObj: state.winObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setWindowObject: x => dispatch(windowObject.setWindowObject(x))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlternativeModals);
