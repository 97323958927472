import moment from "moment";
export const messageDataFormat = date => {
  let currentDate = moment();
  let stillUTC = moment.utc(date).toDate();
  let messageDate = moment(stillUTC).local();
  let diff = currentDate.endOf("day").diff(messageDate, "days");
  if (diff === 0) {
    return `Today, ` + moment(date).format("hh:mm a");
  }
  if (diff === 1) {
    return `Yesterday, ` + moment(date).format("hh:mm a");
  }
  if (diff > 1) {
    let stillUTC1 = moment.utc(date).toDate();
    let messageDate = moment(stillUTC1).local();
    let yeardiff = currentDate.endOf("year").diff(messageDate, "year");
    if (yeardiff === 0) return moment(date).format("DD MMM");
    else return moment(date).format("d MMM YYYY");
  }
};
