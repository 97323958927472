import React from "react";
import { Button, Input, Label, ListGroup, ListGroupItem } from "reactstrap";
import { history } from "../../../_helpers/history";
import BackButton from "../../../_components/BackButton";

export default class CancelAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userReason: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }
  handleChange(e) {
    const { userReason } = this.state;
    const { name, value } = e.target;
    userReason[name] = value;
    this.setState({
      userReason
    });
  }
  save(e) {
    e.preventDefault();
    history.push("/");
  }
  render() {
    const { userReason } = this.state;
    return (
      <div className="acc-page animated fadeIn">
        <h3>
          Account Management: <span>Cancel Account</span>
        </h3>
        <div className="col-lg-6 col-md-8 acc-form-wrapper">
          <div>
            <div className="form-data-wrapper">
              <h5>Cancel My SideCar Account</h5>
              <ListGroup>
                <strong>Please select from one of the following reasons</strong>
                <ListGroupItem>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="reason"
                    value="option1"
                    checked={userReason.reason === "option1"}
                    onChange={this.handleChange}
                  />
                  I am no longer associated with the University
                </ListGroupItem>
                <ListGroupItem>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="reason"
                    value="option2"
                    checked={userReason.reason === "option2"}
                    onChange={this.handleChange}
                  />
                  Lorem ipsum dolor sit amet
                </ListGroupItem>
                <ListGroupItem>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="reason"
                    value="option3"
                    checked={userReason.reason === "option3"}
                    onChange={this.handleChange}
                  />
                  I am no longer associated with the University
                </ListGroupItem>
                <ListGroupItem>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="reason"
                    value="option4"
                    checked={userReason.reason === "option4"}
                    onChange={this.handleChange}
                  />
                  Other
                </ListGroupItem>
                {userReason.reason === "option4" && (
                  <ListGroupItem className="no-padding-left-for-textarea">
                    <Input
                      type="textarea"
                      name="text"
                      className="no-resize"
                      placeholder="Enter Other Reason"
                    />
                  </ListGroupItem>
                )}
              </ListGroup>
            </div>
            <div className="acc-from-submition-wrapper d-flex justify-content-end">
              <BackButton />
              <Button
                color="success"
                className="mr-2 cancel-my-account"
                onClick={this.save}
              >
                Cancel My Account
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
