import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import "./Account-Management.scss";
import { history } from "../../../_helpers/history";

export default class ChangeInstitution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      institution: {},
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  handleChange(e) {
    const { institution, errors } = this.state;
    const { name, value } = e.target;
    institution[name] = value;
    errors[name] = "";
    this.setState({
      institution,
      errors
    });
  }

  save(e) {
    e.preventDefault();
    let { institution, errors } = this.state;
    if (!institution.name) errors["name"] = "Institution Name is required.";

    this.setState({
      errors
    });

    if (!errors.name) history.push("/");
  }

  render() {
    let users =
      localStorage.getItem("users") &&
      JSON.parse(localStorage.getItem("users"));
    const { institution, errors } = this.state;
    return (
      <div className="acc-page animated fadeIn">
        <h3>
          Account Management: <span>Change Institution</span>
        </h3>
        <div className="col-md-5 acc-form-wrapper">
          <div>
            <Form>
              <div className="form-data-wrapper">
                <FormGroup>
                  <Label>Current Institution</Label>
                  <div>{users && users[0].institution}</div>
                </FormGroup>
                <FormGroup>
                  <Label>New Institution</Label>
                  <InputGroup>
                    <InputGroupAddon
                      className="custom-input-icon"
                      addonType="prepend"
                    >
                      <i className="fa fa-institution"></i>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      className="left-borderless"
                      name="name"
                      id="name"
                      value={institution.name}
                      onChange={this.handleChange}
                    />
                    {errors.name && (
                      <div className="help-block">{errors.name}</div>
                    )}
                  </InputGroup>
                </FormGroup>
              </div>
              <div className="acc-from-submition-wrapper">
                <Button color="success" onClick={this.save}>
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
