import { checkAnswerConstants } from "../../_constants/index";

const initial = {
  checkAnswerData: [], // to maintain all questions to be answer before it was just for storing one question
  checkAnswerIds: []
};
export function checkAnswer(state = initial, action) {
  switch (action.type) {
    // 'SET_ANS' : setting selected answer for api (to get updated selected value from quiz)
    case "SET_ANS":
      if (state.checkAnswerData.length) {
        let ind = state.checkAnswerData.findIndex(
          c => c.question_id === action.payload[0].qId
        );
        if (ind > 0)
          state.checkAnswerData[ind].text = action.payload[0].selectedValues;
      }
      return { ...state, checkAnswerIds: state.checkAnswerIds };
    case checkAnswerConstants.CHECK_QUIZ_ANSWER_SUCCESS:
      if (!state.checkAnswerIds.includes(action.data.data.question_id)) {
        state.checkAnswerIds.push(action.data.data.question_id);
        state.checkAnswerData.push(action.data.data);
      } else {
        let index = state.checkAnswerData.findIndex(
          c => c.question_id === action.data.data.question_id
        );
        if (index >= 0)
          state.checkAnswerData[index].text = action.data.data.text;
      }
      return { ...state, checkAnswerIds: state.checkAnswerIds };
    case checkAnswerConstants.CHECK_QUIZ_ANSWER_FAILURE:
      return { ...state, checkAnswerData: [], errData: action.error };
    default:
      return state;
  }
}
