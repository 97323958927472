import React, { Component } from "react";
import "./importStudents.scss";
import { Row, Col, Button, Form, FormGroup, Input, Tooltip } from "reactstrap";
import { Checkbox } from "../../_components/InputComponents";
import { Link } from "react-router-dom";
import { getImportFile } from "../../_helpers/importFile";

class ImportLearners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: [],
      fileName: "",
      csvError: "",
      tooltipOpen: false
    };
    this.openDialogue = this.openDialogue.bind(this);
    this.importFile = this.importFile.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  importFile(e) {
    getImportFile(e)
      .then(res => {
        if (typeof res !== "string") {
          this.setState({
            studentData: res.fileResponse,
            fileName: res.fileName,
            csvError: ""
          });
        } else {
          this.setState({
            csvError: res
          });
        }
      })
      .catch(err => {});
  }

  openDialogue() {
    let div = document.getElementById("file-upload");
    div.click();
  }
  render() {
    const { courseData, cancelForm, saveForm, errorData } = this.props;
    const { studentData, csvError, tooltipOpen } = this.state;
    return (
      <div className="stud-page animated fadeIn">
        <Row>
          <div className="col-md-12 import-form-wrapper">
            <div className="col-lg-6 col-md-8 col-12 ">
              <Form>
                <div className="form-data-wrapper stud-form  mt-3">
                  <div className="row">
                    <div className="col-lg-7 col-12 custom-select-stud-wrapper">
                      <p className="stud-selection-imp">
                        Select learners import source
                      </p>
                      <FormGroup>
                        <input
                          id={"file-upload"}
                          type="file"
                          className="get-file-from-local"
                          onChange={this.importFile}
                          ref={ref => (this.fileInput = ref)}
                          aria-label={"Import learner"}
                        />
                        <Input
                          type="text"
                          value={this.state.fileName}
                          aria-label={"Import learner"}
                          id="fileTooltip"
                          onClick={this.openDialogue}
                        />
                        {csvError && (
                          <div className="help-block">{csvError}</div>
                        )}
                        <Tooltip
                          placement="bottom"
                          isOpen={tooltipOpen}
                          target="fileTooltip"
                          toggle={this.toggleTooltip}
                        >
                          Please upload a CSV/XLSX/TXT [tab delinated] file with
                          the following fields, 'email', 'first_name',
                          'last_name'. With proper column headings.
                        </Tooltip>
                        {errorData &&
                          errorData.map((e, i) => {
                            return (
                              e.length > 0 && (
                                <div key={i} className="user-form-custom-alert">
                                  {e.map((l, index) => {
                                    return (
                                      l.length > 0 && (
                                        <li className="mb-0">
                                          Learner {i + 1}: {l}
                                        </li>
                                      )
                                    );
                                  })}
                                </div>
                              )
                            );
                          })}
                      </FormGroup>
                    </div>
                    <div className="col-lg-5 custom-chbx-wrapper">
                      <p className="custom-left-space">Select Course(s)</p>
                      <div className="select-courses-wrap">
                        <Form>
                          {courseData.map((c, i) => {
                            return (
                              <FormGroup>
                                <Checkbox>
                                  <Input type="checkbox" />
                                  {c.title}
                                </Checkbox>
                              </FormGroup>
                            );
                          })}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
              <div className="pull-right">
                <Link to={"/learner-management"}>
                  <Button color="secondary mr-3" onClick={cancelForm}>
                    Cancel
                  </Button>
                </Link>
                <Button
                  color="success"
                  onClick={() => saveForm(studentData, errorData)}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default ImportLearners;
