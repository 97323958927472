import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { Card, CardHeader, CardBody } from "reactstrap";
import IframeLink from "./IframeLink"
import AbbrTagQuill from "./AbbrTagQuill.js"

var icons = Quill.import('ui/icons')

Quill.register('formats/iframe-link', IframeLink)
Quill.register('formats/abbr-tag', AbbrTagQuill)

const container = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["link"],
  ["iframe-link"],
  ["abbr-tag"],
  ["clean"],
]

icons['iframe-link'] = 'IL'
icons['abbr-tag'] = 'AB'

function iframeLinkHandler(value) {
  if (value) {
    var href = prompt('Enter the URL');
    this.quill.format('iframe-link', href);
  } else {
    this.quill.format('iframe-link', false);
  }
}

function abbrTagHandler(value) {
  if (value) {
    var abbreviation = prompt('Enter the abbreviation');
    this.quill.format('abbr-tag', abbreviation);
  } else {
    this.quill.format('abbr-tag', false);
  }
}

const handlers = {
  'iframe-link': iframeLinkHandler,
  'abbr-tag': abbrTagHandler
}

const toolbarOptions = {
  container,
  handlers
}

const modules = {
    toolbar: toolbarOptions
  }

function EditorTest() {
  const [text, setText] = useState('')


  return (
    <Card>
      <CardHeader>
        Quill - modern rich text editor
        <div className="card-actions">
          <a href="https://zenoamaro.github.io/react-quill/" target="_blank">
            docs
          </a>
        </div>
      </CardHeader>
      <CardBody>
        <ReactQuill
          value={text}
          modules={modules}
          onChange={value => setText(value)}
        />
      </CardBody>

        <CardHeader>
            HTML
        </CardHeader>
        <CardBody>

        </CardBody>
    </Card>
  )
}

export default EditorTest;
