import React, { Component } from "react";
import renderHtml from "react-render-html";
import _ from "lodash";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import {
  FormGroup,
  Label,
  Input,
  Collapse,
  CardBody,
  CardHeader,
  Card,
  Row,
  Col,
  Button
} from "reactstrap";
import "./tutorialManagement.scss";
import Spinner from "../../components/spinner";
import { Link } from "react-router-dom";

const ItemsSum = [
  { id: 1, name: "Add Section", category: "summative", link: "addchapter" },
  { id: 2, name: "Add Text", category: "summative", link: "addtext" },
  { id: 3, name: "Add Media", category: "summative", link: "addmedia" },
  // {id:4,name: "Summative Quizes", category: "summative", link: "quiz"},
  { id: 4, name: "Add Questions", category: "summative", link: "quiz" },
  { id: 5, name: "Add Page Break", category: "summative", link: "addpagebreak" }
];

class SummativeQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      tutorialData: {},
      chapterId: "",
      elementIndex: "",
      collapse: { test: false },
      showAlert: false,
      isDelete: false,
      data: {},
      errors: [],
      total: 0
    };

    this.onNext = this.onNext.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.renderTextBlock = this.renderTextBlock.bind(this);
    this.renderMediaBlock = this.renderMediaBlock.bind(this);
    this.renderQuizBlock = this.renderQuizBlock.bind(this);
    this.renderUrlBlock = this.renderUrlBlock.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onDivDrop = this.onDivDrop.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onCollapse = this.onCollapse.bind(this);
    this.renderEmbeddedMedia = this.renderEmbeddedMedia.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onElementClicked = this.onElementClicked.bind(this);
  }

  onNext() {
    this.setState({ index: this.state.index + 1 });
  }

  onCollapse(e) {
    this.setState({ collapse: { [e]: !this.state.collapse[e] }, chapterId: e });
  }

  handleDelete(e, cid, eid, type, qid) {
    this.props.handleDelete(cid, eid, type, qid, "summative");
  }

  handleEdit(name, data, tabid, id, index, category, rest) {
    this.props.toggleModal(
      name,
      id,
      { data: data, tabid: tabid, rest: rest, index: index, type: data.type },
      rest,
      category
    );
  }

  componentDidMount() {
    let {
      data: {
        editTutorial: { data: { summative_test = {} } = {} }
      }
    } = this.props;
    let rec =
      Object.keys(summative_test)[0] === "summative_test"
        ? summative_test.summative_test.length
        : 0;
    this.setState({
      tutorialData: summative_test,
      data: summative_test,
      total: rec ? rec : 0
    });
    let dropNode = document.getElementById("asd");
    dropNode.addEventListener("drop", this.onDivDrop);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let {
      data: {
        editTutorial: { data: { summative_test = {} } = {} }
      },
      errors
    } = nextProps;
    let { chapterId, id, total } = this.state;
    chapterId = chapterId ? chapterId : 0;
    if (
      Object.keys(summative_test).length > 0 &&
      Object.keys(summative_test)[0] === "summative_test"
    ) {
      this.setState({ data: summative_test, tutorialData: summative_test });
      if (total < summative_test.summative_test.length) {
        id === "Add Section" && this.onCollapse(chapterId);
        this.setState({
          total: summative_test.summative_test.length
        });
      }
    }
    this.setState({ errors: errors });
  }

  onDivDrop(e) {
    let id = e.dataTransfer.getData("id");
    let category = e.dataTransfer.getData("category");
    if (id) {
      if (e.target !== null) {
        if (e.target.id) {
          this.setState({
            chapterId: e.target.getAttribute("name"),
            elementIndex: e.target.id,
            id: id
          });
        } else
          this.setState({
            chapterId: e.target.parentElement.getAttribute("name"),
            elementIndex: e.target.parentElement.id,
            id: id
          });

        this.props.toggleModal(
          id,
          this.state.chapterId,
          "",
          this.state.elementIndex,
          category
        );
      }
    }
    const allDiv = document.getElementsByClassName("drag-hover");
    while (allDiv.length)
      allDiv[0].className = allDiv[0].className.replace(/\bdrag-hover\b/g, "");
  }

  onDragStart(ev, id, category) {
    ev.dataTransfer.setData("id", id);
    ev.dataTransfer.setData("category", category);
    this.setState({
      dragStartDiv: { ev, id, category }
    });
  }

  onPrevious() {
    this.setState({ index: this.state.index - 1 });
  }

  renderTextBlock(d, id, i) {
    return (
      <div name={id} id={i}>
        {typeof d.text === "object" ? (
          d.text.ops.map(t => (
            <span name={id} id={i}>
              {t.insert}
            </span>
          ))
        ) : (
          <span name={id} id={i}>
            {renderHtml(d.text)}
          </span>
        )}
        <span className="float-right" name={id} id={i}>
          <i
            className="fa fa-pencil pad-5 pr-lg-4 edit_icon"
            onClick={e =>
              this.handleEdit("Add Text", d, "3", id, i, "summative")
            }
          />
          <i
            className="fa fa-trash"
            onClick={e => this.handleDelete(e, id, i)}
          />
        </span>
      </div>
    );
  }

  renderMediaBlock(d, id, i) {
    // let sourceType = d.source.split('.')[1]
    return (
      <div className="dis-flex chapter-second-section mt-0" name={id} id={i}>
        <div className="full-width" name={id} id={i}>
          <div name={id} id={i}>
            {d.description}
          </div>
          <div className="chapter-section-img-wrapper" name={id} id={i}>
            {/*{(sourceType === 'jpeg' || sourceType === 'png' || sourceType === 'jpg' || d.source.length>1000)*/}
            {/*&&*/}
            <img
              src={d.source}
              name="img"
              onError={e => {
                e.target.src =
                  "http://saveabandonedbabies.org/wp-content/uploads/2015/08/default.png";
              }}
              height={50}
              width={50}
              alt={d.tags[0] ? d.tags[0] : "mediaImage"}
            />
            {/*}*/}
            <span className="float-right" name={id} id={i}>
              <i
                className="fa fa-pencil pad-5 pr-lg-4 edit_icon"
                onClick={() =>
                  this.handleEdit("Add Media", d, "4", id, i, "summative")
                }
              />
              <i
                className="fa fa-trash"
                onClick={e => this.handleDelete(e, id, i)}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderQuizBlock(d, id, i) {
    return (
      <div name={id} id={i}>
        {d.quiz_data.type === "Formative" ? (
          <div className="formative-quiz-block" name={id} id={i}>
            <div className="single-div quiz-header del-area" name={id} id={i}>
              {d.quiz_data.title}
              <span id={i} name={id} className="float-right">
                {" "}
                <i
                  className="fa fa-trash"
                  onClick={e => this.handleDelete(e, id, i)}
                />
              </span>
            </div>
            <div name={id}>
              {d.quiz_data.questions.map((q, k) => {
                return (
                  <div className="single-div" name={id} id={i}>
                    <div name={id} className="mb-1" id={i}>
                      {`Q. ${q.text} `}
                      {q.points && (
                        <span className="pts-bold">{`${q.points} pts`}</span>
                      )}
                      <span className="float-right">
                        {" "}
                        <i
                          className="fa fa-pencil pad-5 pr-lg-4 edit_icon"
                          onClick={() =>
                            this.handleEdit(
                              "Add Questions",
                              q,
                              "6",
                              id,
                              "",
                              "summative",
                              i
                            )
                          }
                        />
                        <i
                          className="fa fa-trash"
                          onClick={e => this.handleDelete(e, id, i, "", k)}
                        />
                      </span>
                    </div>
                    {q.type === "short_text" && (
                      <div name={id} id={i} className="mt-2 pl-3">
                        <Input type="text" />
                      </div>
                    )}
                    {q.type === "long_text" && (
                      <div name={id} id={i} className="mt-2 pl-3">
                        <Input type="text" />
                      </div>
                    )}
                    {q.type === "multiple_choice" && (
                      <div
                        id={i}
                        className="dis-flex quiz-radio-space"
                        name={id}
                      >
                        {q.options &&
                          q.options.map((opt, optk) => {
                            return (
                              <FormGroup check className="options-block">
                                <Label check>
                                  <Input type="checkbox" />
                                  {opt}
                                </Label>
                              </FormGroup>
                            );
                          })}
                      </div>
                    )}
                    {(q.type === "boolean" || q.type === "single_choice") && (
                      <div
                        className="dis-flex quiz-radio-space"
                        name={id}
                        id={i}
                      >
                        {q.options.map((opt, optk) => {
                          return (
                            <FormGroup check className="options-block">
                              <Label check>
                                <Input type="radio" />
                                {opt}
                              </Label>
                            </FormGroup>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  renderUrlBlock(d, id, i) {
    return (
      <div className="dis-flex chapter-second-section mt-0" name={id} id={i}>
        <div className="full-width" name={id} id={i}>
          <div name={id} id={i}>
            {d.display_text}
          </div>
          <div className="chapter-section-img-wrapper" name={id} id={i}>
            <a href={d.url} name="img" target={d.target}>
              {d.url}
            </a>
            <span className="float-right" name={id} id={i}>
              <i
                className="fa fa-pencil pad-5 pr-lg-4 edit_icon"
                onClick={() =>
                  this.handleEdit("Add Media", d, "4", id, i, "summative")
                }
              />
              <i
                className="fa fa-trash"
                onClick={e => this.handleDelete(e, id, i)}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderEmbeddedMedia(d, id, i) {
    return (
      <div className="dis-flex chapter-second-section mt-0" name={id} id={i}>
        <div className="full-width" name={id} id={i}>
          <div className="chapter-section-img-wrapper" name={id} id={i}>
            {renderHtml(d.code)}
            <span className="float-right" name={id} id={i}>
              <i
                className="fa fa-pencil pad-5 pr-lg-4 edit_icon"
                onClick={() =>
                  this.handleEdit("Add Media", d, "4", id, i, "summative")
                }
              />
              <i
                className="fa fa-trash"
                onClick={e => this.handleDelete(e, id, i)}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }

  onElementClicked(id, category) {
    const { tutorialData: { summative_test = [] } = {}, collapse } = this.state;
    let chapterId =
      id === "Add Section"
        ? summative_test.length
        : Object.values(collapse)[0]
        ? Number(Object.keys(collapse)[0])
        : summative_test.length - 1;
    let ellen =
      id !== "Add Section"
        ? Object.values(collapse)[0]
          ? summative_test.length > 0
            ? summative_test[Number(Object.keys(collapse)[0])].elements.length
            : 0
          : summative_test.length > 0
          ? summative_test[chapterId].elements.length
          : 0
        : "";
    let elementIndex = ellen === 0 ? 0 : ellen;
    this.setState({
      chapterId: chapterId,
      elementIndex: elementIndex,
      id: id
    });
    this.props.toggleModal(id, chapterId, "", elementIndex, category);
  }

  onSortEnd({ ...components }) {
    let { data } = this.state;
    const { newIndex, oldIndex, childId } = components;
    if ("childId" in components) {
      data.summative_test[childId].elements = arrayMove(
        data.summative_test[childId].elements,
        oldIndex,
        newIndex
      );
    } else {
      data.summative_test = arrayMove(data.summative_test, oldIndex, newIndex);
    }
    this.setState({ data: _.cloneDeep(data) });
    this.props.handleSubmit(data, "summative");
  }

  render() {
    const {
      tutorialData: { summative_test = [] } = {},
      elementIndex,
      collapse
    } = this.state;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col sm={9}>
            <div id="asd" className="display-edit-tutorial edit-tuts">
              {summative_test.length === 0 && (
                <div className="drop-class">
                  <div className="drop-here">
                    Click or Drag and drop the Add Section button here to get
                    started.
                  </div>
                </div>
              )}
              {// tutorial && tutorial.isLoading ? <Spinner/>
              // :
              summative_test && summative_test.length > 0 && (
                <SortableList
                  items={summative_test}
                  onSortEnd={e =>
                    this.onSortEnd({
                      ...e,
                      id: ""
                    })
                  }
                  onSortEndd={e =>
                    this.onSortEnd({
                      ...e,
                      id: ""
                    })
                  }
                  renderTextBlock={this.renderTextBlock}
                  renderMediaBlock={this.renderMediaBlock}
                  renderUrlBlock={this.renderUrlBlock}
                  renderQuizBlock={this.renderQuizBlock}
                  renderEmbeddedMedia={this.renderEmbeddedMedia}
                  useDragHandle={true}
                  lockToContainerEdges={true}
                  handleEdit={this.handleEdit}
                  handleDelete={this.handleDelete}
                  elementIndex={elementIndex}
                  collapse={collapse}
                  onCollapse={this.onCollapse}
                  toggleModal={this.props.toggleModal}
                />
              )}
            </div>
          </Col>
          <Col sm={3} className="border-all mt-0">
            <Row>
              <div
                className="custom-save-btn-group w-100 mt-0"
                onDragOver={e => this.onDragOver(e)}
                onDrop={e => {
                  this.onDrop(e, "action");
                }}
              >
                {ItemsSum.map((item, key) => {
                  return (
                    <div
                      className={
                        key === 0
                          ? summative_test.length > 0
                            ? "first-btn-sepration my-2 pb-2"
                            : "other-btn-groups"
                          : " other-btn-groups"
                      }
                    >
                      <Link to={""} onClick={e => e.preventDefault()}>
                        <Button
                          type="button"
                          readOnly={true}
                          className=" custom-btn"
                          hidden={
                            !Boolean(
                              summative_test && summative_test.length > 0
                            ) && item.name !== "Add Section"
                          }
                          onDragStart={e =>
                            this.onDragStart(
                              e,
                              `${item.name}`,
                              `${item.category}`
                            )
                          }
                          color="primary"
                          draggable
                          onClick={() =>
                            this.onElementClicked(
                              `${item.name}`,
                              `${item.category}`
                            )
                          }
                        >
                          {item.name}
                        </Button>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const SortableItem = SortableElement(({ ...props }) => {
  return (
    <div name={props.id}>
      <div className="custom-col" name={props.id}>
        <Card name={props.id}>
          <CardHeader name={props.id}>
            <div
              className="chapter-heading-content"
              name={props.id}
              onClick={() => props.onCollapse(props.id)}
            >
              <div name={props.id} className="chapter-heading-content-div">
                {Number(Object.keys(props.collapse)[0]) === props.id &&
                Object.values(props.collapse)[0] ? (
                  <i className="fa fa-chevron-down pr-1" />
                ) : (
                  <i className="fa fa-chevron-right pr-1" />
                )}
                Section: {props.value.title}
                <span className="float-right">
                  <DragHandleNest />
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody className="custom-cardheader" name={props.id}>
            <Collapse
              className="collapse-div"
              isOpen={props.collapse[props.id]}
              name={props.id}
            >
              <div className="chapter-heading-content-para" name={props.id}>
                <p>P1</p>
                <div className="page-delete-icon" />
              </div>
              <div className="chapter-heading-content-2" name={props.id}>
                <div name={props.id}>
                  Chapter :{props.value.title}
                  <span className="float-right">
                    <i
                      className="fa fa-pencil pad-5 pr-lg-4 title-action"
                      onClick={() =>
                        props.handleEdit(
                          "Add Section",
                          props.value,
                          "1",
                          props.id,
                          "",
                          "summative"
                        )
                      }
                    />
                    <i
                      className="fa fa-trash"
                      onClick={e => {
                        props.toggleModal(
                          "Confirmation",
                          props.id,
                          "",
                          "",
                          "summative"
                        );
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="chapter-heading-content-dec mb-3" name={props.id}>
                <div name={props.id}>
                  Description : {props.value.description}
                </div>
              </div>
              {props.value.elements.length === 0 && (
                <div className="drop-class pb-3" name={props.id}>
                  <div className="drop-here second-drop-area" name={props.id}>
                    Click or Drag and drop the buttons here to get started with
                    section content.
                  </div>
                </div>
              )}
              <div>
                <SortableListNest
                  items={props.value.elements}
                  onSortEnd={e => {
                    props.onSortEnd({ ...e, childId: props.id });
                  }}
                  useDragHandle={true}
                  renderTextBlock={props.renderTextBlock}
                  renderMediaBlock={props.renderMediaBlock}
                  renderUrlBlock={props.renderUrlBlock}
                  renderQuizBlock={props.renderQuizBlock}
                  renderEmbeddedMedia={props.renderEmbeddedMedia}
                  id={props.id}
                  handleEdit={props.handleEdit}
                  handleDelete={props.handleDelete}
                />
              </div>
            </Collapse>
          </CardBody>
        </Card>
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({ ...props }) => {
  return (
    <ul>
      {props.items.map((value, index) => (
        <div name={index} id={value.index}>
          <SortableItem
            index={index}
            value={value}
            renderTextBlock={props.renderTextBlock}
            renderMediaBlock={props.renderMediaBlock}
            renderUrlBlock={props.renderUrlBlock}
            renderQuizBlock={props.renderQuizBlock}
            id={index}
            handleEdit={props.handleEdit}
            handleDelete={props.handleDelete}
            elementIndex={props.elementIndex}
            onSortEnd={props.onSortEndd}
            onSortEndd={props.onSortEndd}
            onCollapse={props.onCollapse}
            collapse={props.collapse}
            renderEmbeddedMedia={props.renderEmbeddedMedia}
            toggleModal={props.toggleModal}
          />
        </div>
      ))}
    </ul>
  );
});

const SortableListNest = SortableContainer(({ ...props }) => {
  let p = 1;
  return (
    <div name={props.id}>
      {props.items.map((el, i) => {
        switch (el.type) {
          case "text":
            return (
              <SortableItemNest
                key={`item-${i}`}
                index={i}
                value={
                  <div
                    className="chapter-heading-content-3 mb-3 mt-0"
                    name={props.id}
                    id={i}
                  >
                    {props.renderTextBlock(el, props.id, i)}
                    <br />
                  </div>
                }
              />
            );
            break;
          case "media":
            return (
              <SortableItemNest
                key={`item-${i}`}
                index={i}
                value={
                  <div className="mb-3 mt-0" name={props.id} id={i}>
                    {props.renderMediaBlock(el, props.id, i)}
                  </div>
                }
              />
            );
            break;
          case "url":
            return (
              <SortableItemNest
                key={`item-${i}`}
                index={i}
                value={
                  <div name={props.id} id={i}>
                    {props.renderUrlBlock(el, props.id, i)}
                  </div>
                }
              />
            );
            break;
          case "embedded_media":
            return (
              <SortableItemNest
                key={`item-${i}`}
                index={i}
                value={
                  <div name={props.id} id={i}>
                    {props.renderEmbeddedMedia(el, props.id, i)}
                  </div>
                }
              />
            );
            break;
          case "quiz":
            return (
              <SortableItemNest
                key={`item-${i}`}
                index={i}
                value={
                  <div className="quiz-table mt-0 mb-3" name={props.id} id={i}>
                    {props.renderQuizBlock(el, props.id, i)}
                  </div>
                }
              />
            );
            break;
          case "page-break":
            p = p + 1;
            return (
              <SortableItemNest
                key={`item-${i}`}
                index={i}
                value={
                  <div
                    className="chapter-heading-content-para mb-3 mt-0"
                    name={props.id}
                    id={i}
                  >
                    <label className="w-100">P{p}</label>
                    <i
                      className="fa fa-trash w-0 trash-icon"
                      onClick={e => props.handleDelete(e, props.id, i)}
                    />
                  </div>
                }
              />
            );
            break;
        }
      })}
    </div>
  );
});

const SortableItemNest = SortableElement(({ value }) => {
  return (
    <div id={value.props.id} name={value.props.name}>
      <div className="dragable-icon px-1">
        <DragHandle />
      </div>
      <div>{value}</div>
    </div>
  );
});

const DragHandle = SortableHandle(() => <i className="fa fa-reorder" />);

const DragHandleNest = SortableHandle(() => <i className="fa fa-reorder" />);

export default SummativeQuiz;
