import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Collapse
} from "reactstrap";
import "./manageHelpCenter.scss";
import BackButton from "../../../_components/BackButton";

class ManageHelpCenter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { helpCenterData, toggleCard, collapse } = this.props;
    return (
      <div className="animated fadeIn">
        <div className="title-page dis-flex">
          <h2>
            Help Center: <span className="sub-heading">FAQs</span>
          </h2>
        </div>
        <Row>
          <Col md={12} className="faq-scrollable-area">
            {helpCenterData.map((h, index) => {
              return (
                <Card className="manage-help-card mb-4" key={index}>
                  <CardHeader
                    className="manage-help-header-title"
                    onClick={e => {
                      e.preventDefault();
                      toggleCard(`${index}${h.id}details`);
                    }}
                    data-event={index}
                  >
                    <div className="d-inline-block mt-1">
                      {!collapse[`${index}${h.id}details`] ? (
                        <i className="fa fa-chevron-right" />
                      ) : (
                        <i className="fa fa-chevron-down" />
                      )}
                      <b className="pl-2 manage-help-accordian-title">
                        {h.topicName} : {h.title}
                      </b>
                      &nbsp;
                    </div>
                  </CardHeader>
                  <Collapse isOpen={collapse[`${index}${h.id}details`]}>
                    <CardBody className="help-card-body">
                      {h.Questions.map((q, i) => {
                        return (
                          <div className="manage-help-accordion-body-main-div border-bottom">
                            <p className="font-weight-bold">
                              Q{i + 1}. {q.title}
                            </p>
                            <p>{q.answer}</p>
                          </div>
                        );
                      })}
                    </CardBody>
                  </Collapse>
                </Card>
              );
            })}
          </Col>
          <div className="save-cancel-btn faq-save-btn">
            <BackButton />
          </div>
        </Row>
      </div>
    );
  }
}

export default ManageHelpCenter;
