import React from "react";
import { Button, FormGroup, Input, Label, Row } from "reactstrap";
import "./Quiz.scss";

export default class Quiz extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { quizData } = this.props;
    return (
      <div className="main-quiz">
        <Row>
          <div className="quiz-block text-center col-md-10">
            <Row>
              <div className="sidebar-header">
                <h5>English grammar Level 3 Tutorial Quiz</h5>
              </div>
              <div className="sidebar-quiz">
                {quizData &&
                  quizData.map((quiz, index) => {
                    return (
                      <div>
                        <h6>{quiz.questions}</h6>
                        {quiz.options.map((option, index) => {
                          return (
                            <ul>
                              <li>
                                <FormGroup check className="radio">
                                  <Input
                                    className="form-check-input"
                                    type="radio"
                                    id="radio1"
                                    name="radios"
                                    value={option}
                                  />
                                  <Label
                                    check
                                    className="form-check-label"
                                    htmlFor="radio1"
                                  >
                                    {option}
                                  </Label>
                                </FormGroup>
                              </li>
                            </ul>
                          );
                        })}
                        <Button className="save-btn" color="primary">
                          Save Answer
                        </Button>
                      </div>
                    );
                  })}
                <h6>
                  Q10. What is adjective in the following sentence "The
                  beautiful girl looked at me"?
                </h6>
                <Input
                  type="text"
                  name="text"
                  id="example"
                  className="form-input"
                />
                <div className="sidebar-pagination">
                  <div className="pager">
                    <div className="icon">
                      <p>Page 3 of 3</p>
                      <i className="fa fa-caret-left"></i>
                      <i className="fa fa-caret-right"></i>
                    </div>
                    <div className="btn-submit">
                      <Button color="success">
                        Next
                        <i className="fa fa-angle-right"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
          <div className="col-md-2 col-xs-2 col-sm-2">
            <Row>
              <div className="time-quiz">
                <i class="fa fa-clock-o"></i>
                <p>2:05</p>
              </div>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
