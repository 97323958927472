import React from "react";
import FreeCurriculumCreator from "../../components/AccountManagement/FreeCurriculumCreator";

export default class FreeCurriculumCreatorContainer extends React.Component {
  render() {
    return (
      <div className="animated fadeIn">
        <FreeCurriculumCreator />
      </div>
    );
  }
}
