import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  CardHeader,
  Collapse,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Badge,
  Tooltip,
  Label
} from "reactstrap";
import { Checkbox } from "../../_components/InputComponents";
import "./manageLearner.scss";
import _ from "lodash";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import NoUserImage from "../../../public/img/avatars/no-user.png";
import BackButton from "../../_components/BackButton";

class ManageLearner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: 0,
      collapse: props.collapse,
      selectedStudentIds: [],
      selectedCoursesIds: this.props.selectedCoursesIds.length
        ? this.props.selectedCoursesIds
        : [],
      hoverLearner: "",
      isOpen: false,
      isDelete: false,
      courseId: "",
      openToolTip: { test: false }
    };
    this.assignStudentsToCourse = this.assignStudentsToCourse.bind(this);
    this.toggleCard = this.toggleCard.bind(this);
    this.onHoverLearner = this.onHoverLearner.bind(this);
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.selectAllLearners = this.selectAllLearners.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }
  toggleTooltip(e) {
    this.setState({ openToolTip: { [e]: !this.state.openToolTip[e] } });
  }
  componentDidMount() {
    const { scroll } = this.state;
    let div = document.getElementById("course-div");
    if (div) {
      document.getElementById("course-div").scrollTop = scroll;
    }
  }

  assignStudentsToCourse(selectedStudentId, scrollValue) {
    let { selectedStudentIds } = this.state;
    let bool = _.includes(selectedStudentIds, selectedStudentId);
    if (bool) {
      var index = selectedStudentIds.indexOf(selectedStudentId);
      index !== -1 && selectedStudentIds.splice(index, 1);
    } else selectedStudentIds.push(selectedStudentId);
    this.setState({
      selectedStudentIds,
      scroll: scrollValue
    });
  }

  toggleCard(selectedCoursesId, scrollValue) {
    let { selectedCoursesIds } = this.state;
    let bool = _.includes(selectedCoursesIds, selectedCoursesId);
    if (bool) {
      var index = selectedCoursesIds.indexOf(selectedCoursesId);
      index !== -1 && selectedCoursesIds.splice(index, 1);
    } else selectedCoursesIds.push(selectedCoursesId);
    this.setState({
      selectedCoursesIds,
      scroll: scrollValue,
      collapse: {
        ...this.state.collapse,
        [selectedCoursesId]: !this.state.collapse[selectedCoursesId]
      }
    });
  }

  selectAllLearners(e) {
    const { learnerData } = this.props;
    let { selectedStudentIds } = this.state;

    const { checked } = e.target;
    if (checked) {
      selectedStudentIds = [];
      learnerData.map((l, i) => {
        selectedStudentIds.push(l.id);
      });
      this.setState({
        selectedStudentIds
      });
    } else {
      this.setState({
        selectedStudentIds: []
      });
    }
  }

  onHoverLearner(learnerId) {
    this.setState({
      hoverLearner: learnerId
    });
  }

  deleteConfirmation(e, learnerId, courseId) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDelete: true,
      hoverLearner: learnerId,
      courseId: courseId
    });
  }
  confirmDelete() {
    const { hoverLearner, courseId } = this.state;
    let obj;
    if (hoverLearner) {
      obj = {
        course_ids: courseId,
        learner_ids: hoverLearner
      };
      this.props.deleteLearnerCourse(obj);
    }
  }
  cancelDelete() {
    this.setState({
      isDelete: false,
      hoverLearner: "",
      courseId: ""
    });
  }
  render() {
    const {
      learnerData,
      courseData,
      cancel,
      saveLearnerToCourses,
      status
    } = this.props;
    const {
      collapse,
      selectedStudentIds,
      selectedCoursesIds,
      hoverLearner,
      isDelete,
      openToolTip
    } = this.state;
    return (
      <div className={status.length ? "" : "animated fadeIn"}>
        <div className="row mt-2">
          <div className=" col-lg-4 col-md-5 col-12">
            <Card className="manage-learner-assign-card">
              <div className="p-lg-4">
                <div className="assign-title-header">
                  <h4>Assign Learners to Courses</h4>
                  <div className="select-all">
                    <FormGroup check>
                      {learnerData.length > 0 && (
                        <Checkbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={this.selectAllLearners}
                            checked={
                              selectedStudentIds.length === learnerData.length
                            }
                          />
                          <p className="pt-1">Select All</p>
                        </Checkbox>
                      )}
                    </FormGroup>
                  </div>
                  <p>Select Learner(s) and then Select Course(s)</p>
                </div>
                <Form className="custom-scrollbar">
                  {learnerData.map((l, i) => {
                    return (
                      <FormGroup check className="assign-form">
                        <Checkbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={() => {
                              this.assignStudentsToCourse(
                                l.id,
                                document.getElementById("course-div").scrollTop
                              );
                            }}
                            checked={selectedStudentIds.includes(l.id)}
                          />
                          <div>
                            <img
                              src={NoUserImage}
                              alt="user-avt"
                              className="checkbox-user-avt round-stud-img"
                            />
                          </div>
                          <p
                            className={
                              selectedStudentIds.includes(l.id)
                                ? "active-student p-text"
                                : "p-text"
                            }
                            id={`u-${l.id}`}
                          >
                            {`${l.first_name} ${l.last_name}`}
                          </p>
                          <Tooltip
                            placement="top"
                            isOpen={openToolTip[`u-${l.id}`]}
                            target={`u-${l.id}`}
                            toggle={() => this.toggleTooltip(`u-${l.id}`)}
                          >
                            {l.email}
                          </Tooltip>
                        </Checkbox>
                      </FormGroup>
                    );
                  })}
                </Form>
              </div>
            </Card>
          </div>
          <div className="col-lg-8 col-md-7 col-12">
            <div className="manage-learner-assign-card" id="course-div">
              {courseData.map((c, index) => {
                return (
                  <Card
                    className="manage-learner-card manage-courses-card"
                    key={index}
                  >
                    <CardHeader
                      className="manage-learner-header-title"
                      onClick={e => {
                        e.preventDefault();
                        this.toggleCard(
                          c.id,
                          document.getElementById("course-div").scrollTop
                        );
                      }}
                      data-event={index}
                    >
                      <Checkbox className="dis-flex form-learner-label">
                        <Input
                          type="checkbox"
                          checked={collapse[c.id]}
                          data-event={index}
                          onClick={e => {
                            e.preventDefault();
                            this.toggleCard(c.id);
                          }}
                        />
                        <div className="d-inline-block  ml-3 mt-1">
                          {!collapse[c.id] ? (
                            <i className="fa fa-chevron-right manage-learner-custom-icon" />
                          ) : (
                            <i className="fa fa-chevron-down" />
                          )}
                          <b className="pl-2 manage-learner-accodian-title">
                            {c.title}
                          </b>
                          &nbsp;
                          <Badge color="primary">{c.learners.length}</Badge>
                        </div>
                      </Checkbox>
                    </CardHeader>
                    <Collapse isOpen={collapse[c.id]}>
                      <CardBody>
                        <div className="manage-learner-accordion-body-main-div">
                          {c.learners.map((s, i) => {
                            return (
                              <div className="manage-learner-accordion-body-sub-main-div">
                                <img
                                  src={NoUserImage}
                                  alt="user-avt"
                                  className="accordion-user-avt round-stud-img-2"
                                />
                                <p
                                  className={
                                    selectedStudentIds.includes(s.id)
                                      ? " mb-2 active-student"
                                      : "mb-2"
                                  }
                                  onMouseOver={() => this.onHoverLearner(s.id)}
                                >
                                  {s.first_name}&nbsp;{s.last_name} &nbsp;
                                  {hoverLearner === s.id && (
                                    <i
                                      className="fa fa-trash"
                                      onClick={e => {
                                        this.deleteConfirmation(e, s.id, c.id);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  )}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-right col-12">
            <div className="border-top manage-learner-footer-btn">
              <BackButton />
              {/*<Button className="cancel-btn mr-2" color={'secondary'} onClick={cancel}>Reset</Button>*/}
              <Button
                className="cancel-btn"
                color={"success"}
                onClick={() =>
                  saveLearnerToCourses(
                    selectedStudentIds,
                    selectedCoursesIds,
                    collapse
                  )
                }
              >
                Add Learner to Course
              </Button>
            </div>
          </div>
        </div>
        <Modal isOpen={isDelete} className="modal-danger">
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <h4>Are you sure you want to delete this ? </h4>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.confirmDelete}>
              Yes
            </Button>
            <Button color="secondary" onClick={this.cancelDelete}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ManageLearner;
