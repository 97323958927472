import React, { Component } from "react";
import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import "./helpCenterSidebar.scss";
class HelpCenterSideBar extends Component {
  render() {
    const { currentMenu, toggleMenu } = this.props;
    return (
      <div className="main-full-sidebar animated fadeIn">
        <Card className="help-sidebar-card">
          <CardHeader>Help Center</CardHeader>
          <ListGroup>
            <ListGroupItem
              style={{ cursor: "pointer" }}
              active={currentMenu === 0}
              onClick={() => {
                toggleMenu(0);
              }}
            >
              FAQs
            </ListGroupItem>
            <ListGroupItem
              style={{ cursor: "pointer" }}
              active={currentMenu === 1}
              onClick={() => {
                toggleMenu(1);
              }}
            >
              Learn Sidecar Platform
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  }
}

export default HelpCenterSideBar;
