import { quizConstants } from "../../_constants/index";
import { quizService } from "../../_services/index";

export const quizzesActions = {
  getAllQuizData
};

function getAllQuizData() {
  return dispatch => {
    quizService.getAllQuizzes().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: quizConstants.GETALL_QUIZ_SUCCESS, data };
  }
  function failure(error) {
    return { type: quizConstants.GETALL_QUIZ_FAILURE, error };
  }
}
