import React from "react";
import { Link } from "react-router-dom";
import "./Home-Learner.scss";
import {
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Table,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { Line } from "react-chartjs-2";
import Spinner from "../../spinner/index";
import { history } from "../../../_helpers/history";
import { learnerDashboardActions } from "../../../_actions/LearnerActions/learnerDashboard.action";
import AlternativeModals from "../../../_components/AlternativeModals";
import FirefoxModal from "../../../_components/FirefoxModal";
let tut_win_test = "",
  web_win_test = "";

export default class LearnerHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorial: {},
      isAlternative: false,
      isFirefoxModal: false
    };

    this.setTutorial = this.setTutorial.bind(this);
    this.close = this.close.bind(this);
    this.toggleFirefoxModal = this.toggleFirefoxModal.bind(this);
  }

  componentDidMount() {
    this.props.isToggleMethod(true);
  }

  setTutorial(tutorialData) {
    let isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox && tutorialData.is_frame_busted) {
      this.setState({
        tutorial: tutorialData
      });
      this.toggleFirefoxModal();
    } else {
      this.setState({
        isAlternative: true,
        tutorial: tutorialData
      });
    }
  }

  close() {
    this.setState({
      isAlternative: false
    });
  }

  toggleFirefoxModal() {
    this.setState(
      {
        isFirefoxModal: !this.state.isFirefoxModal
      },
      () => {
        if (this.state.isFirefoxModal === false)
          this.setState({
            isAlternative: true
          });
      }
    );
  }

  render() {
    const { isAlternative, tutorial, isFirefoxModal } = this.state;
    const {
      tutorialData,
      isDashboardLoading,
      dashboardData: {
        tutorials_assigned = [],
        tutorials_incomplete = [],
        tutorials_complete = [],
        quizzes_assigned = [],
        quizzes_complete = [],
        quizzes_incomplete = [],
        suggested_tutorials = [],
        graphs
      } = []
    } = this.props;

    return (
      <div className="main-learner">
        <div className="left">
          <Row>
            <Col xs="12" sm="6" md="6" lg="4" className="bot-space">
              <Card className="text-white bg-info quizzes-card mb-0">
                <CardBody className="text-update first-card">
                  <div className="d-lg-block d-xl-flex d-md-flex custom-summary">
                    <div className="first-text">
                      <h6>{tutorials_assigned.length}</h6>
                      <p className="chart-text">Tutorials Assigned</p>
                    </div>
                    <div className="first-text">
                      <h6>{tutorials_incomplete.length}</h6>
                      <p className="chart-text">Tutorials - In Progress</p>
                    </div>
                    <div className="first-text">
                      <h6>{tutorials_complete.length}</h6>
                      <p className="chart-text">Tutorials Completed</p>
                    </div>
                  </div>
                  <div className="custom-canvas">
                    <div className="chart-wrapper">
                      <Line
                        data={graphs && graphs.tutorials_completed}
                        options={{
                          responsive: true,
                          legend: false,
                          layout: {
                            padding: {
                              right: 15,
                              top: 15
                            }
                          },
                          scales: {
                            yAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false
                                },
                                ticks: {
                                  display: false
                                }
                              }
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false
                                },
                                ticks: {
                                  display: false
                                }
                              }
                            ]
                          },
                          maintainAspectRatio: false
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="bot-space">
                <CardHeader className="card-header-tutorial bg-info">
                  <h6>Assigned Tutorials</h6>
                </CardHeader>
                <CardBody className="card-body-tutorial justify-content-center">
                  {isDashboardLoading ? (
                    <Spinner />
                  ) : tutorials_assigned.length > 0 ? (
                    <Table responsive className="table-text">
                      <tbody>
                        {tutorials_assigned.map(tutorial => (
                          <tr>
                            <td>
                              <a
                                href="#"
                                onClick={() => this.setTutorial(tutorial)}
                              >
                                {/*<i className="fa fa-angle-right"/>*/}
                                {tutorial.title}
                                {/*<Badge color="primary">{tutorials_assigned.length}</Badge>*/}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                      You have no assigned tutorials
                    </div>
                  )}
                </CardBody>
              </Card>
              <Card className="bot-space">
                <CardHeader className="card-header-tutorial bg-info">
                  <h6>Incomplete Tutorials</h6>
                </CardHeader>
                <CardBody className="card-body-tutorial justify-content-center">
                  {isDashboardLoading ? (
                    <Spinner />
                  ) : tutorials_incomplete.length > 0 ? (
                    <Table responsive className="table-text">
                      <tbody>
                        {tutorials_incomplete.map(tutorial => (
                          <tr>
                            <td>
                              <a
                                href="#"
                                onClick={() => this.setTutorial(tutorial)}
                              >
                                {tutorial.title}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                      You have no incomplete tutorials
                    </div>
                  )}
                </CardBody>
              </Card>
              <Card className="bot-space">
                <CardHeader className="card-header-tutorial bg-info">
                  <h6>Completed Tutorials</h6>
                </CardHeader>
                <CardBody className="card-body-tutorial justify-content-center">
                  {isDashboardLoading ? (
                    <Spinner />
                  ) : tutorials_complete.length > 0 ? (
                    <Table responsive className="table-text">
                      <tbody>
                        {tutorials_complete.map(tutorial => (
                          <tr>
                            <td>
                              <a
                                href="#"
                                onClick={() => this.setTutorial(tutorial)}
                              >
                                {tutorial.title}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                      You have no completed tutorials
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4" className="bot-space">
              <Card className="text-white bg-primary quizzes-card mb-0">
                <CardBody className="text-update">
                  <div className="d-xl-flex d-md-flex custom-summary">
                    <div className="width-33 first-text">
                      <h6>{quizzes_incomplete.length}</h6>
                      <p className="chart-text">Quizzes Pending</p>
                    </div>
                    <div className="width-33 first-text">
                      <h6>{quizzes_complete.length}</h6>
                      <p className="chart-text">Quizzes Completed</p>
                    </div>
                  </div>
                  <div className="custom-canvas">
                    <div className="chart-wrapper">
                      <Line
                        data={graphs && graphs.quizzes_completed}
                        options={{
                          responsive: true,
                          legend: false,
                          layout: {
                            padding: {
                              right: 15,
                              top: 15
                            }
                          },
                          scales: {
                            yAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false
                                },
                                ticks: {
                                  display: false
                                }
                              }
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false
                                },
                                ticks: {
                                  display: false
                                }
                              }
                            ]
                          },
                          maintainAspectRatio: true
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="bot-space">
                <CardHeader className="card-header-tutorial bg-primary">
                  <h6>Assigned Quizzes</h6>
                </CardHeader>
                <CardBody className="card-body-tutorial justify-content-center">
                  {isDashboardLoading ? (
                    <Spinner />
                  ) : quizzes_assigned.length > 0 ? (
                    <Table responsive className="table-text">
                      <tbody>
                        {quizzes_assigned.map(tutorial => (
                          <tr>
                            <td>
                              <Link
                                to={`/tutorial-page/${tutorial.id}/authorized/QuizViewer`}
                              >
                                {tutorial.title}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                      You have no assigned quizzes
                    </div>
                  )}
                </CardBody>
              </Card>
              <Card className="bot-space">
                <CardHeader className="card-header-tutorial bg-primary">
                  <h6>Incomplete Quizzes</h6>
                </CardHeader>
                <CardBody className="card-body-tutorial justify-content-center">
                  {isDashboardLoading ? (
                    <Spinner />
                  ) : quizzes_incomplete.length > 0 ? (
                    <Table responsive className="table-text">
                      <tbody>
                        {quizzes_incomplete.map(tutorial => (
                          <tr>
                            <td>
                              <a
                                href="#"
                                onClick={() => this.setTutorial(tutorial)}
                              >
                                {tutorial.title}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                      You have no incomplete quizzes
                    </div>
                  )}
                </CardBody>
              </Card>
              <Card className="bot-space">
                <CardHeader className="card-header-tutorial bg-primary">
                  <h6>Completed Quizzes</h6>
                </CardHeader>
                <CardBody className="card-body-tutorial justify-content-center">
                  {isDashboardLoading ? (
                    <Spinner />
                  ) : quizzes_complete.length > 0 ? (
                    <Table
                      responsive
                      className="table-text justify-content-center"
                    >
                      <tbody>
                        {quizzes_complete.map(tutorial => (
                          <tr>
                            <td>
                              <a
                                href="#"
                                onClick={() => this.setTutorial(tutorial)}
                              >
                                {tutorial.title}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                      You have no completed quizzes
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4" className="bot-space">
              <Card className="text-white bg-warning  quizzes-card mb-0">
                <CardBody className="text-update">
                  <div className="d-xl-flex d-md-flex custom-summary">
                    <div className=" width-33 first-text">
                      <h6>{suggested_tutorials.length}</h6>
                      <p className="chart-text">Suggestions</p>
                    </div>
                  </div>
                  <div className="custom-canvas">
                    <div className="chart-wrapper">
                      <Line
                        data={graphs && graphs.public_tutorials_completed}
                        options={{
                          responsive: true,
                          legend: false,
                          layout: {
                            padding: {
                              right: 15,
                              top: 15
                            },
                            margin: 0
                          },
                          scales: {
                            yAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false
                                },
                                ticks: {
                                  display: false
                                }
                              }
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false
                                },
                                ticks: {
                                  display: false
                                }
                              }
                            ]
                          },
                          maintainAspectRatio: true
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="bot-space">
                <CardHeader className="card-header-tutorial bg-warning">
                  <div className="d-flex justify-content-between">
                    <h6>Suggested Tutorials</h6>
                  </div>
                </CardHeader>
                <CardBody className="card-body-tutorial justify-content-center">
                  {isDashboardLoading ? (
                    <Spinner />
                  ) : suggested_tutorials.length > 0 ? (
                    <Table responsive className="table-text">
                      <tbody>
                        {suggested_tutorials.map(tutorial => (
                          <tr>
                            <td>
                              <a
                                href="#"
                                onClick={() => this.setTutorial(tutorial)}
                              >
                                {tutorial.title}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                      You have no suggested tutorials
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {isAlternative && (
          <AlternativeModals
            tutorial={tutorial}
            isAlternative={isAlternative}
            close={this.close}
            doNotShow={this.props.doNotShow}
            dispatch={this.props.dispatch}
          />
        )}
        {isFirefoxModal && (
          <FirefoxModal
            isFirefoxModal={isFirefoxModal}
            toggleFirefoxModal={this.toggleFirefoxModal}
          />
        )}
      </div>
    );
  }
}
