import axiosI from "../../_helpers/service";
import moment from "moment";
import axios from "axios";

export const attemptService = {
  AttemptTutorial,
  FinishAttemptedTutorial,
  UpdateTutorialAttemptGuestDetails
};
const userLogin = localStorage.getItem("user");
function AttemptTutorial(id) {
  if (userLogin) {
    return axiosI
      .post(`/tutorials/${id}/tutorial_simple_attempts`)
      .then(response => {
        return response;
      });
  } else {
    return axios
      .post(
        `${process.env.BACKEND_URL}/tutorials/${id}/tutorial_simple_attempts`
      )
      .then(response => {
        return response;
      });
  }
}

function UpdateTutorialAttemptGuestDetails(tutorialId, attemptedId, guestData) {
  return axios
    .patch(
      `${process.env.BACKEND_URL}/tutorials/${tutorialId}/tutorial_simple_attempts/${attemptedId}`,
      {
        guest_user_first_name: guestData.guestUserFirstName,
        guest_user_last_name: guestData.guestUserLastName,
        guest_user_email: guestData.guestUserEmail
      }
    )
    .then(response => {
      return response;
    });
}

function FinishAttemptedTutorial(
  tutorialId,
  attemptedId,
  type_of_formative_quiz
) {
  if (userLogin) {
    return axiosI
      .patch(
        `/tutorials/${tutorialId}/tutorial_simple_attempts/${attemptedId}`,
        {
          finished_at: moment()
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          progress: "",
          is_graded: type_of_formative_quiz === "graded" ? true : false
        }
      )
      .then(response => {
        return response;
      });
  } else {
    return axios
      .patch(
        `${process.env.BACKEND_URL}/tutorials/${tutorialId}/tutorial_simple_attempts/${attemptedId}`,
        {
          finished_at: moment()
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          progress: "",
          is_graded: type_of_formative_quiz === "graded" ? true : false
        }
      )
      .then(response => {
        return response;
      });
  }
}
