import { contentCreatorConstants } from "../_constants";

const initial = {
  tab1: {},
  tab2: {},
  tab3: {},
  tab4: {},
  tab5: {}
};
export function TabsData(state = initial, action) {
  switch (action.type) {
    case contentCreatorConstants.ADD_TAB1_DATA:
      return { ...state, tab1: action.data };
    case contentCreatorConstants.ADD_TAB2_DATA:
      return { ...state, tab2: action.data };
    case contentCreatorConstants.ADD_TAB3_DATA:
      return { ...state, tab3: action.data };
    case contentCreatorConstants.ADD_TAB4_DATA:
      return { ...state, tab4: action.data };
    case contentCreatorConstants.ADD_TAB5_DATA:
      return { ...state, tab5: action.data };
    case contentCreatorConstants.REMOVE_TAB_DATA:
      return { tab1: {}, tab2: {}, tab3: {}, tab4: {}, tab5: {} };
    default:
      return state;
  }
}
