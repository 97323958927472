import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { isElectron } from "../../utils";
import "./NativeOrWeb.scss";
import { connect } from "react-redux";
import { tutorialActions } from "../../_actions/LearnerActions/tutorial.actions";
import Spinner from "../spinner";

class NativeOrWeb extends Component {
  componentDidMount() {
    let {
      dispatch,
      match: { params: { id } = {} },
    } = this.props;
    // let { match: { params: { id } } }  = this.props
    // window.location = `sidecar://web/tutorials/${id}`
    dispatch(tutorialActions.getTutorialData(id));
  }

  isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  render() {
    const {
      isLoading = false,
      tutorialData = {},
      match: {
        params: { id },
      },
    } = this.props || {};
    const { settings = {} } = tutorialData || {};
    const { skip_desktop_app_prompt } = settings;

    return isLoading ? (
      <Spinner />
    ) : ( isElectron() || skip_desktop_app_prompt || this.isMobile() ) ? (
      <Redirect to={{ pathname: `/web/tutorials/${id}` }} />
    ) : (
      <div class="native-or-web">
        <h3>Choose your tutorial viewing experience</h3>
        <div class="app-link m-4">
          <a
            href={`/web/tutorials/${id}`}
            class="btn-tut-white btn btn-primary"
          >
            Open in a Browser
          </a>
        </div>

        <div class="m-3">
          <div>
            For the best viewing experience, we recommend downloading the
            Sidecar App.
          </div>
          <div class="app-link">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.sidecarlearning.com/downloads"
              class="btn-tut-white btn btn-primary m-2"
            >
              Download the SidecarApp
            </a>
          </div>
        </div>

        <div class="m-3">
          <div>Already have the App?</div>
          <div class="app-link">
            <a
              href={`sidecar://web/tutorials/${id}`}
              class="btn-tut-white btn btn-primary m-2"
            >
              Open tutorial in Sidecar App
            </a>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.tutorial.isLoading,
    tutorialData: state.tutorial.tutorialData,
  };
}

const native_or_web = connect(mapStateToProps, null)(NativeOrWeb);
export default native_or_web;
