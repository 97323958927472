import { messageConstants } from "../_constants/index";
import { messageService } from "../_services/index";
import { toastConstants } from "../_constants/toast.constants";

export const messageAction = {
  getMessageData,
  deleteMessage,
  getMessageRead,
  markAllMessagesAsRead
};
function getMessageData() {
  return dispatch => {
    dispatch({ type: messageConstants.GET_MESSAGES_REQUEST });
    messageService.getAllMessages().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: messageConstants.GET_MESSAGES_SUCCESS, data };
  }
  function failure(error) {
    return { type: messageConstants.GET_MESSAGES_FAILURE, error };
  }
}

function deleteMessage(messageID) {
  return dispatch => {
    return messageService.deleteMessageService(messageID).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Message Removed successfully" }
        });
        return dispatch(success({ messageID }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: messageConstants.DELETE_MESSAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: messageConstants.DELETE_MESSAGE_FAILURE, error };
  }
}
function getMessageRead(messageID) {
  return dispatch => {
    return messageService.readMessage(messageID).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Marked as Read" }
        });
        return dispatch(success({ data }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: messageConstants.READ_MESSAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: messageConstants.READ_MESSAGE_FAILURE, error };
  }
}

function markAllMessagesAsRead() {
  return dispatch => {
    return messageService.readAllMessage().then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Marked All messages as Read" }
        });
        return dispatch(success({ data }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: messageConstants.READ_ALL_MESSAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: messageConstants.READ_ALL_MESSAGE_FAILURE, error };
  }
}
