import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { roles } from "./Administrator/roles.reducer";
import { credentials } from "./Administrator/credentials.reducer";
import { quiz } from "./Learner/quiz.reducer";
import { tutorial } from "./Learner/tutorial.reducer";
import { CurriculumTutorial } from "./tutorial.reducer";
import { TabsData } from "./tabsData.reducer";

import { courses } from "./Administrator/course.reducer";
import { learner } from "./Administrator/learner.reducer";
import { importTutorials } from "./Administrator/importTutorials.reducer";
import { checkAnswer } from "./Learner/checkAnswer.reducer";
import { menuToggler } from "./Learner/menuToggler.reducer";
import { userConstants } from "../_constants";
import { Tutorialcourses } from "./courses.reducer";
import { routerReducer } from "react-router-redux";
import { helpCenter } from "./helpCenter.reducer";
import { adminDashboard } from "./Administrator/adminDashboard.reducer";
import { toast } from "./toastReducer";
import { learnerDashboard } from "./Learner/learnerDashboard.reducer";

import { message } from "./message.reducer";
import { attemptAPIData } from "./AttemptAPI/attemptAPI.reducer";

const appReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  credentials,
  tutorial,
  quiz,
  CurriculumTutorial,
  roles,
  courses,
  learner,
  importTutorials,
  helpCenter,
  Tutorialcourses,
  checkAnswerData: checkAnswer,
  menuToggler,
  toast,
  adminDashboard,
  learnerDashboard,
  TabsData,
  routing: routerReducer,
  message,
  attemptAPIData
});
const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
