import React, { Component } from "react";
import { connect } from "react-redux";
import CredentialsSideBar from "../../components/CredentialsManagement/credentialsSidebar";
import ManageCredentials from "../../components/CredentialsManagement/manageCredentials";
import UserForm from "../../components/CredentialsManagement/userForm";
import { Col, Row, Button } from "reactstrap";
import Spinner from "../../components/spinner";
import { Link } from "react-router-dom";
import { credentialsActions } from "../../_actions";
import { Authorized } from "../../_components/Authorized";
import { FileActions } from "../../_actions/file_selection.action";
import { history } from "../../_helpers/history";

// let org_id=localStorage.getItem('user')&& JSON.parse(localStorage.getItem('user')).organization!==null?
//     JSON.parse(localStorage.getItem('user')).organization.id
//     :JSON.parse(localStorage.getItem('user')).owned_organization.id;

class Credentials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCredentials: [],
      newUser: [
        {
          first_name: "",
          last_name: "",
          username: "",
          email: "",
          organization_id:
            localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).organization !== null //remove password:''
              ? JSON.parse(localStorage.getItem("user")).organization.id
              : JSON.parse(localStorage.getItem("user")).owned_organization.id
        }
      ],
      errors: [{ first_name: "", last_name: "", username: "", email: "" }], //remove password:''
      activeRole: "",
      selectedRoles: [],
      formData: {},
      names: {},
      isOpen: false,
      selectedUser: ""
    };
    this.selectRole = this.selectRole.bind(this);
    this.saveAddUser = this.saveAddUser.bind(this);
    this.cancelAddForm = this.cancelAddForm.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.roleValidation = this.roleValidation.bind(this);
    this.fileValidation = this.fileValidation.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(credentialsActions.getCredentialsData());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.credentialsData.length > 0) {
      this.setState({
        activeRole: Object.keys(nextProps.credentialsData[0]).toString(),
        selectedCredentials: nextProps.credentialsData[0],
        isOpen: false
      });
    }
  }
  roleValidation(userData, errors, selectedRoles) {
    const { dispatch } = this.props;
    dispatch(credentialsActions.selectRoles());
    this.setState({
      newUser: userData,
      errors: errors,
      selectedRoles: selectedRoles
    });
  }
  selectRole(roleName) {
    const { credentialsData } = this.props;
    let selectedData = credentialsData.find(
      cred => Object.keys(cred).toString() === roleName
    );
    this.setState({
      selectedCredentials: selectedData,
      activeRole: roleName
    });
  }

  saveAddUser(userData, errors, selectedRoles) {
    let obj = { users: userData, roles: selectedRoles };
    const { dispatch } = this.props;
    let f = 1;
    if (selectedRoles.length && userData.length) {
      userData.map(l => {
        if (!l.first_name.length || !l.last_name.length || !l.email.length) {
          f = 0;
        }
      });
      if (f) {
        dispatch(credentialsActions.AddUserData(obj)).then((res, err) => {
          if (res.error) {
            this.setState({
              newUser: userData,
              errors: errors,
              selectedRoles: selectedRoles
            });
          } else {
            this.setState({
              newUser: [
                {
                  first_name: "",
                  last_name: "",
                  username: "",
                  email: "",
                  password: "",
                  organization_id:
                    localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).organization !==
                      null
                      ? JSON.parse(localStorage.getItem("user")).organization.id
                      : JSON.parse(localStorage.getItem("user"))
                          .owned_organization.id
                }
              ],
              errors: [
                {
                  first_name: "",
                  last_name: "",
                  username: "",
                  email: "",
                  password: ""
                }
              ],
              activeRole: "",
              selectedRoles: []
            });
            if (res.data && res.data.data) {
              history.push("/credentials-management");
            }
          }
        });
      } else {
        dispatch(FileActions.showFileContentMsg());
      }
    }
  }

  fileValidation(pageName) {
    const { dispatch } = this.props;
    dispatch(FileActions.showFileMessage(pageName));
  }
  updateUser(userData, activeRole, formData, selectedCredentials) {
    const { dispatch } = this.props;
    dispatch(
      credentialsActions.EditUserData(userData.id, userData, activeRole)
    ).then((res, err) => {
      if (res.error) {
        this.setState({
          isOpen: true,
          formData: { ...formData },
          activeRole: activeRole,
          selectedCredentials: selectedCredentials,
          selectedUser: formData.id,
          names: {
            first_name: formData.first_name,
            last_name: formData.last_name
          }
        });
      }
    });
  }
  deleteUser(id, role) {
    const { dispatch } = this.props;
    const obj = {
      role: role,
      user_ids: [id]
    };
    dispatch(credentialsActions.deleteUserData(obj));
  }
  cancelAddForm() {
    const { newUser, errors } = this.state;
    let objUser = [
      {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        organization_id:
          localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).organization !== null
            ? JSON.parse(localStorage.getItem("user")).organization.id
            : JSON.parse(localStorage.getItem("user")).owned_organization.id
      }
    ];
    let objError = [
      { first_name: "", last_name: "", username: "", email: "", password: "" }
    ];
    this.setState({
      newUser: objUser,
      errors: objError,
      selectedUser: []
    });
  }
  render() {
    const {
      credentialsData,
      isLoadingCredentials,
      errorData,
      editError,
      user: { subscription = {} } = {}
    } = this.props;
    let seats = 0;
    if (subscription && subscription.seats) {
      seats = subscription.seats;
    }
    const {
      selectedCredentials,
      formData,
      names,
      isOpen,
      activeRole,
      selectedUser,
      newUser,
      errors,
      selectedRoles
    } = this.state;
    const userLogin = localStorage.getItem("user");
    let role = Object.keys(selectedCredentials)[0];
    let roleName =
      role &&
      role
        .split("_")
        .join(" ")
        .charAt(0)
        .toUpperCase() +
        role
          .split("_")
          .join(" ")
          .slice(1);
    return (
      <div className="animated fadeIn">
        {isLoadingCredentials ? (
          <Spinner />
        ) : (
          <Row className="custom-row bg-white p-sm-4">
            {this.props.location.pathname !==
              "/credentials-management/adduser" &&
              this.props.location.pathname !==
                "/credentials-management/import-users" && (
                <div className="col-md-3">
                  <div className="row">
                    <CredentialsSideBar
                      credentialsData={credentialsData}
                      activeRole={activeRole}
                      selectRole={this.selectRole}
                      isLoadingCredentials={isLoadingCredentials}
                    />
                  </div>
                </div>
              )}
            {/*{this.props.location.pathname === '/credentials-management/adduser' ? 12 : 10}*/}
            <div className="col-md-9">
              <div className="title-page d-flex justify-content-between align-items-sm-center">
                <h2 className="m-0">
                  User Management
                  {this.props.location.pathname !==
                    "/credentials-management/adduser" &&
                    this.props.location.pathname !==
                      "/credentials-management/import-users" && (
                      <span className="sub-heading">
                        :{" "}
                        {role === "full_content_creator" ||
                        role === "basic_content_creator" ||
                        role === "individual_content_creator"
                          ? "Instructor"
                          : roleName}
                      </span>
                    )}
                </h2>

                {this.props.location.pathname !==
                  "/credentials-management/adduser" &&
                  this.props.location.pathname !==
                    "/credentials-management/import-users" && (
                    <div className="d-flex">
                      <Link to={"/credentials-management/import-users"}>
                        <Button color="primary" className="mr-3">
                          Import Users
                        </Button>
                      </Link>
                      <Link to={"/credentials-management/adduser"}>
                        <Button color="primary">Add User</Button>
                      </Link>
                    </div>
                  )}
              </div>
              <Authorized
                exact
                path="/credentials-management/"
                component={() => (
                  <ManageCredentials
                    selectedCredentials={selectedCredentials}
                    activeRole={activeRole}
                    isOpen={isOpen}
                    formData={formData}
                    names={names}
                    openForm={this.openForm}
                    handleChange={this.handleChange}
                    selectedUser={selectedUser}
                    cancel={this.cancel}
                    credentialsData={this.props.credentialsData}
                    isLoadingCredentials={isLoadingCredentials}
                    deleteUser={this.deleteUser}
                    updateUser={this.updateUser}
                    editError={editError}
                  />
                )}
                allowed={userLogin && ["org_administrator", "licensee"]}
              />
              <Authorized
                exact
                path="/credentials-management/adduser"
                component={() => (
                  <UserForm
                    saveAddUser={this.saveAddUser}
                    newUser={newUser}
                    errors={errors}
                    errorData={errorData}
                    credentialsData={credentialsData}
                    isLoadingCredentials={isLoadingCredentials}
                    selectedRoles={selectedRoles}
                    cancelAddForm={this.cancelAddForm}
                    roleValidation={this.roleValidation}
                    seats={seats}
                    pageFrom={"add-form"}
                  />
                )}
                allowed={userLogin && ["org_administrator", "licensee"]}
              />

              <Authorized
                path="/credentials-management/import-users"
                component={() => (
                  <UserForm
                    saveAddUser={this.saveAddUser}
                    newUser={newUser}
                    errors={errors}
                    errorData={errorData}
                    credentialsData={credentialsData}
                    isLoadingCredentials={isLoadingCredentials}
                    selectedRoles={selectedRoles}
                    cancelAddForm={this.cancelAddForm}
                    roleValidation={this.roleValidation}
                    fileValidation={this.fileValidation}
                    seats={seats}
                    pageFrom={"import"}
                  />
                )}
                allowed={userLogin && ["org_administrator", "licensee"]}
              />
            </div>
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    credentialsData: state.credentials.credentialsData,
    isLoadingCredentials: state.credentials.isLoadingCredentials,
    errorData: state.credentials.errorData,
    editError: state.credentials.editError,
    user: state.authentication.user
  };
}

const connectedCredentials = connect(mapStateToProps, null)(Credentials);
export { connectedCredentials as Credentials };
