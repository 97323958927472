export const learnerDashboardConstants = {
  GET_ASSIGNED_TUTORIALS_SUCCESS: "GET_ASSIGNED_TUTORIALS_SUCCESS",
  GET_ASSIGNED_TUTORIALS_FAILURE: "GET_ASSIGNED_TUTORIALS_FAILURE",
  GET_LEARNER_DASHBOARD_DETAILS_SUCCESS:
    "GET_LEARNER_DASHBOARD_DETAILS_SUCCESS",
  GET_LEARNER_DASHBOARD_DETAILS_FAILURE:
    "GET_LEARNER_DASHBOARD_DETAILS_FAILURE",
  GET_LEARNER_DASHBOARD_DETAILS_REQUEST:
    "GET_LEARNER_DASHBOARD_DETAILS_REQUEST",

  TOGGLE_DO_NOT_SHOW: "TOGGLE_DO_NOT_SHOW"
};
