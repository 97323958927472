import React, { Component } from "react";
import { Button } from "reactstrap";
import { history } from "../_helpers/history";
class BackButton extends Component {
  render() {
    return (
      <Button
        className="cancel-btn mr-2"
        color="primary"
        onClick={() => {
          history.push("/");
        }}
      >
        Back
      </Button>
    );
  }
}
export default BackButton;
