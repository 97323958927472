import { rolesConstants, toastConstants } from "../../_constants/index";
import { roleService } from "../../_services/index";

export const rolesActions = {
  getUserData,
  getUserRolesData,
  getUserRolesNavData,
  deleteSelectedUser,
  uservalidation,
  saveRolesToUser,
  roleValidation
};

function getUserData() {
  return dispatch => {
    roleService.getAllUsers().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: rolesConstants.GETALL_USERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: rolesConstants.GETALL_USERS_FAILURE, error };
  }
}
function getUserRolesData() {
  return dispatch => {
    dispatch({ type: rolesConstants.GETALL_USERS_REQUEST });
    roleService.getAllUsersRoles().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: rolesConstants.GETALL_USERS_ROLES_SUCCESS, data };
  }
  function failure(error) {
    return { type: rolesConstants.GETALL_USERS__ROLES_FAILURE, error };
  }
}
function getUserRolesNavData() {
  return dispatch => {
    dispatch({ type: rolesConstants.GETALL_USERS__ROLES_REQUEST });
    roleService.getRolesNav().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: rolesConstants.GETALL_USERS_ROLES_NAVS_SUCCESS, data };
  }
  function failure(error) {
    return { type: rolesConstants.GETALL_USERS__ROLES__NAVS_FAILURE, error };
  }
}

function deleteSelectedUser(obj) {
  return dispatch => {
    return roleService.deleteSelectedUser(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "User successfully deleted." }
        });
        return dispatch(success(obj));
      },
      error => {
        const message = error?.response?.data?.messages.join(", ") || "Something went wrong."
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: message }
        });
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: rolesConstants.DELETE_ROLE_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: rolesConstants.DELETE_ROLE_USER_FAILURE, error };
  }
}
function uservalidation() {
  return dispatch => {
    dispatch({
      type: toastConstants.SHOW_TOAST,
      message: {
        type: "ERROR",
        data: "Please select at least one user and role type"
      }
    });
  };
}

function roleValidation() {
  return dispatch => {
    dispatch({
      type: toastConstants.SHOW_TOAST,
      message: { type: "ERROR", data: "User(s) already exist in this Role." }
    });
  };
}

function saveRolesToUser(obj) {
  return dispatch => {
    return roleService.saveRoles(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Roles successfully assigned." }
        });
        return dispatch(success(obj));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: rolesConstants.ASSIGN_ROLES_TO_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: rolesConstants.ASSIGN_ROLES_TO_USER_FAILURE, error };
  }
}
