import axiosI from "../../_helpers/service";
import { config } from "../../config";
import axios from "axios";

export const tutorialService = {
  getAllTutorial,
  rateTutorial,
  addCollaborator,
  removeCollaborator
};

const userLogin = localStorage.getItem("user");

function addCollaborator({ tutorialId, userId }) {
  return axiosI
    .patch(`${config.apiUrl}/tutorials/${tutorialId}/collaborators/${userId}`)
    .then(response => response);
}

function removeCollaborator({ tutorialId, userId }) {
  return axiosI
    .delete(`${config.apiUrl}/tutorials/${tutorialId}/collaborators/${userId}`)
    .then(response => response);
}

function getAllTutorial(id, authorized) {
  // if(authorized){
  //     return axiosI.get(`${config.apiUrl}/tutorials/${id}.json`).then(response => {
  //         return response;
  //     });
  // }
  // else{
  //     return axios.get(`${config.apiUrl}/tutorials/${id}.json`).then(response => {
  //         return response;
  //     });
  // }
  return axios.get(`${config.apiUrl}/tutorials/${id}.json`).then(response => {
    return response;
  });
}
function rateTutorial(obj, id) {
  if (userLogin) {
    return axiosI
      .post(`${config.apiUrl}/tutorials/${id}/simple_reviews`, obj)
      .then(response => {
        return response;
      });
  } else {
    return axios
      .post(`${process.env.BACKEND_URL}/tutorials/${id}/simple_reviews`, obj)
      .then(response => {
        return response;
      });
  }
}
