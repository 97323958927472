import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Tooltip,
} from "reactstrap";
import "./createTutorial.scss";

class QuizSetting extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        certificate_enabled: true,
        email_notification: false,
        type_of_formative_quiz: "graded",
        formative_score_required: false,
        completion_required_for_summative_test: false,
        email_notification_question_missed: false,
        email_notification_tutorial_taken: false,
        email_notification_quiz_completed: false,
        summative_score_required: false,
        skip_desktop_app_prompt: false,
      },
      is_quiz_req: false,
      formative_score_required: false,
      summative_score_required: false,
      tries_allowed_per_question: false,
      question_missed: false,
      errors: [],
      openToolTip: { test: false },
    };
  }

  handleChange = (e) => {
    let { data } = this.state;
    let { name, value, id } = e.target;
    if (id === "question_missed") {
      this.setState({ [id]: JSON.parse(value) });
    }
    if (
      name === "formative_score_required" ||
      name === "summative_score_required"
    ) {
      data[name] = JSON.parse(value);
      let defaultValue = value === "true" ? 50 : 0;
      data[
        name === "formative_score_required"
          ? "formative_quiz_passing_score"
          : "summative_test_passing_score"
      ] = defaultValue;
      //this.setState({data});
      this.setState({ [name]: JSON.parse(value), data });
    }

    if (name === "certificate_enabled") {
      if (JSON.parse(value)) {
        data["certificate_enabled"] = 1;
      } else {
        delete data.certificate_enabled;
      }
    }

    if (name === "tries_allowed_per_question") {
      if (JSON.parse(value)) {
        data["no_of_tries_allowed_per_question"] = 1;
      } else {
        delete data.no_of_tries_allowed_per_question;
      }
    }

    if (name === "email_notification_question_missed") {
      data[name] = JSON.parse(value);
      let defaultValue = value === "true" ? 50 : 0;
      data["missed_question_threshold"] = defaultValue;
      this.setState({ data });
      this.setState({ [name]: JSON.parse(value), data });
    } else if (
      name === "formative_quiz_passing_score" ||
      name === "summative_test_passing_score" ||
      name === "missed_question_threshold" ||
      name === "no_of_tries_allowed_per_question"
    ) {
      data[name] = Number(value);
    } else if (name === "is_quiz_public") {
      const val = JSON.parse(value);
      if (val) {
        data["is_quiz_public"] = val;
      } else {
        delete data["is_quiz_public"];
        this.setState({ is_quiz_req: val });
      }
    } else {
      if (value === "true" || value === "false") data[name] = JSON.parse(value);
      else data[name] = value;
      this.setState({ data });
    }
    this.setState({ data });
    if (data && data.hasOwnProperty("tries_allowed_per_question"))
      delete data.tries_allowed_per_question;
  };

  componentDidMount() {
    let {
      data: { editTutorial = {} },
    } = this.props;
    if (Object.keys(editTutorial).length > 0) {
      this.setState({ data: editTutorial.data.settings });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let {
      TabsData,
      data: { editTutorial = {} },
      errors,
    } = nextProps;
    if (Object.keys(TabsData).length > 0)
      this.setState({ errors: errors, data: { ...nextProps.TabsData } });
    else if (Object.keys(editTutorial).length > 0) {
      editTutorial.data.settings["is_wide_iframe"] =
        editTutorial.data.is_wide_iframe;
      this.setState({ errors: errors, data: editTutorial.data.settings });
    }
    this.setState({ errors });
  }

  toggleTooltip = (e) => {
    this.setState({ openToolTip: { [e]: !this.state.openToolTip[e] } });
  };

  render() {
    const { errors, data, openToolTip, is_quiz_req } = this.state;
    return (
      <div className="animated fadeIn" id="input-small">
        <div className="post-wrap">
          <Form
            action=""
            method="post"
            encType="multipart/form-data"
            className="form-horizontal pt-3"
          >
            <FormGroup row>
              <Col md="3">
                <p>
                  Quiz Settings:
                  <i className="icon-question pl-1" id="one" />
                  <Tooltip
                    placement="right"
                    isOpen={openToolTip["one"]}
                    target="one"
                    toggle={() => this.toggleTooltip("one")}
                  >
                    You can select custom settings for both practice questions
                    and final quizzes.
                  </Tooltip>
                </p>
              </Col>
              <Col xs="12" md="9" className="wobble">
                <Row>
                  <Col xs="12" sm="6">
                    <Card>
                      <CardHeader>Practice Questions</CardHeader>
                      <CardBody className="card-body-select">
                        <p className="mt-0">
                          <strong id={"Formative Quiz"}>
                            Select the type of a formative quiz:
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div
                              role={"radiogroup"}
                              aria-labelled={"Formative Quiz"}
                            >
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="formative"
                                  name="type_of_formative_quiz"
                                  value="graded"
                                  onChange={this.handleChange}
                                  checked={
                                    data["type_of_formative_quiz"] === "graded"
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor={"formative"}
                                >
                                  Graded
                                </Label>
                                <i className="icon-question  pl-1" id="two" />
                                <Tooltip
                                  placement="right"
                                  isOpen={openToolTip["two"]}
                                  target="two"
                                  toggle={() => this.toggleTooltip("two")}
                                >
                                  If logged in, students will receive a final
                                  grade reported to their account. If not logged
                                  in, students will just see their final score.
                                </Tooltip>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="formative1"
                                  name="type_of_formative_quiz"
                                  value="practice"
                                  onChange={this.handleChange}
                                  checked={
                                    data["type_of_formative_quiz"] ===
                                    "practice"
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor={"formative1"}
                                >
                                  Ungraded
                                </Label>
                                <i className="icon-question  pl-1" id="three" />
                                <Tooltip
                                  placement="right"
                                  isOpen={openToolTip["three"]}
                                  target="three"
                                  toggle={() => this.toggleTooltip("three")}
                                >
                                  Grades will not be reported to students’
                                  account.
                                </Tooltip>
                              </FormGroup>
                            </div>
                            {errors["type_of_formative_quiz"] && (
                              <div className="help-block">
                                {errors["type_of_formative_quiz"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                        <p>
                          <strong>
                            Passing score requirement?
                            <i className="icon-question  pl-1" id="four" />
                            <Tooltip
                              placement="right"
                              isOpen={openToolTip["four"]}
                              target="four"
                              toggle={() => this.toggleTooltip("four")}
                            >
                              if logged in, students must meet this minimum
                              requirement to receive a passing grade on the
                              tutorial. If not logged in, students will just see
                              their final score.
                            </Tooltip>
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio4"
                                  aria-label={"Passing Score required"}
                                  name="formative_score_required"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={
                                    data["formative_score_required"] === true ||
                                    data["formative_quiz_passing_score"] > 0
                                  }
                                />
                                Yes
                                {data["formative_score_required"] ||
                                data["formative_quiz_passing_score"] > 0 ? (
                                  <span>
                                    <Label check className="form-check-label">
                                      , Passing % threshold
                                    </Label>
                                    <Input
                                      type="number"
                                      id="passingthreshold"
                                      className="small custom-input-small"
                                      aria-label={
                                        "Percentage of Passing Score for Practice Questions"
                                      }
                                      name="formative_quiz_passing_score"
                                      onChange={this.handleChange}
                                      value={
                                        data["formative_quiz_passing_score"] &&
                                        data["formative_quiz_passing_score"]
                                      }
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio5"
                                  aria-label={"Passing Score not required"}
                                  name="formative_score_required"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={
                                    data["formative_score_required"] ===
                                      false ||
                                    !data["formative_quiz_passing_score"]
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="radio5"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["formative_quiz_passing_score"] && (
                              <div className="help-block">
                                {errors["formative_quiz_passing_score"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6" className="mt-3 mt-sm-0">
                    <Card>
                      <CardHeader>Final Quiz</CardHeader>
                      <CardBody className="card-body-select">
                        {/*<FormGroup check className="checkbox">*/}
                        {/*<Input className="form-check-input" type="checkbox" id="checkbox1"*/}
                        {/*name="will_have_summative_quiz" value={true} onChange={this.handleChange}*/}
                        {/*/>*/}
                        {/*<Label check className="form-check-label">Tutorial*/}
                        {/*will have Summative Quiz <Link to="#" className="question-link">*/}
                        {/*<i className="icon-question"/></Link></Label>*/}
                        {/*</FormGroup>*/}
                        <p className="mt-0">
                          <strong>Completion of tutorial required?</strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <FormGroup check className="radio">
                              <Input
                                className="form-check-input"
                                type="radio"
                                id="tut_complete"
                                name="completion_required_for_summative_test"
                                value={true}
                                onChange={this.handleChange}
                                checked={
                                  data[
                                    "completion_required_for_summative_test"
                                  ] === true
                                }
                              />
                              <Label
                                check
                                className="form-check-label"
                                htmlFor={"tut_complete"}
                              >
                                Yes
                              </Label>
                            </FormGroup>
                            <FormGroup check className="radio">
                              <Input
                                className="form-check-input"
                                type="radio"
                                id="tut_complete1"
                                name="completion_required_for_summative_test"
                                value={false}
                                onChange={this.handleChange}
                                checked={
                                  data[
                                    "completion_required_for_summative_test"
                                  ] === false
                                }
                              />
                              <Label
                                check
                                className="form-check-label"
                                htmlFor={"tut_complete1"}
                              >
                                No
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <p>
                          <strong>
                            Passing score requirement?
                            <i className="icon-question  pl-1" id="five" />
                            <Tooltip
                              placement="right"
                              isOpen={openToolTip["five"]}
                              target="five"
                              toggle={() => this.toggleTooltip("five")}
                            >
                              If logged in, students will be required to achieve
                              a minimum score in order to pass the final quiz.
                              If not logged in, students will see just their
                              final score.
                            </Tooltip>
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio4"
                                  aria-label={"Passing Score required"}
                                  name="summative_score_required"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={
                                    data["summative_score_required"] === true ||
                                    data["summative_test_passing_score"] > 0
                                  }
                                />
                                Yes
                                {data["summative_score_required"] ||
                                data["summative_test_passing_score"] > 0 ? (
                                  <span>
                                    <Label
                                      check
                                      className="form-check-label"
                                      htmlFor="radio4"
                                    >
                                      , Passing % Threshold
                                    </Label>
                                    <Input
                                      type="number"
                                      id="passingthreshold"
                                      className="small custom-input-small"
                                      aria-label={
                                        "Percentage of Passing Score for Final Quiz"
                                      }
                                      name="summative_test_passing_score"
                                      onChange={this.handleChange}
                                      value={
                                        data["summative_test_passing_score"] &&
                                        data["summative_test_passing_score"]
                                      }
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio15"
                                  aria-label={"Passing Score not required"}
                                  name="summative_score_required"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={
                                    data["summative_score_required"] ===
                                      false ||
                                    !data["summative_test_passing_score"]
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="radio15"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["summative_test_passing_score"] && (
                              <div className="help-block">
                                {errors["summative_test_passing_score"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                        <p id={"limit"}>
                          <strong>
                            Do you want to limit number of tries per question?
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <FormGroup check className="radio">
                              <Input
                                className="form-check-input"
                                type="radio"
                                id="radio6"
                                aria-label={
                                  "For Limit number of Tries per Question"
                                }
                                name="tries_allowed_per_question"
                                value={true}
                                onChange={this.handleChange}
                                checked={
                                  data["tries_allowed_per_question "] ===
                                    true ||
                                  data["no_of_tries_allowed_per_question"] > 0
                                }
                              />
                              Yes
                              {data["tries_allowed_per_question "] === true ||
                              data["no_of_tries_allowed_per_question"] > 0 ? (
                                <span>
                                  <Label
                                    check
                                    className="form-check-label"
                                    htmlFor={"radio6"}
                                  >
                                    , Maximum tries allowed per question
                                  </Label>
                                  <Input
                                    type="number"
                                    id="tries_allowed"
                                    className="small custom-input-small"
                                    aria-label={
                                      "Maximum tries allowed per question"
                                    }
                                    name="no_of_tries_allowed_per_question"
                                    onChange={this.handleChange}
                                    value={
                                      data[
                                        "no_of_tries_allowed_per_question"
                                      ] &&
                                      data["no_of_tries_allowed_per_question"]
                                    }
                                    max={"10"}
                                    min={"1"}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                            <FormGroup check className="radio">
                              <Input
                                className="form-check-input"
                                type="radio"
                                id="radio12"
                                aria-label={"No Limit of Tries per Question"}
                                name="tries_allowed_per_question"
                                value={false}
                                onChange={this.handleChange}
                                checked={
                                  data["tries_allowed_per_question"] ===
                                    false ||
                                  !data["no_of_tries_allowed_per_question"]
                                }
                              />
                              <Label
                                check
                                className="form-check-label"
                                htmlFor={"radio12"}
                              >
                                No
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <p>
                          <strong>
                            Do you want to detach a final quiz from a tutorial?
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <FormGroup check className="radio">
                              <Input
                                className="form-check-input"
                                type="radio"
                                id="radioPublic"
                                aria-label={"Detach a Final quiz from Tutorial"}
                                name="is_quiz_public"
                                value={true}
                                onChange={this.handleChange}
                                checked={data["is_quiz_public"] === true}
                              />
                              <Label
                                check
                                className="form-check-label mr-1"
                                htmlFor="radioPublic"
                              >
                                Yes
                              </Label>
                            </FormGroup>
                            <FormGroup check className="radio">
                              <Input
                                className="form-check-input"
                                type="radio"
                                id="radioPublic1"
                                aria-label={
                                  "Final quiz not detach from Tutorial"
                                }
                                name="is_quiz_public"
                                value={false}
                                onChange={this.handleChange}
                                checked={
                                  is_quiz_req === false &&
                                  !data["is_quiz_public"]
                                }
                              />
                              <Label
                                check
                                className="form-check-label"
                                htmlFor="radioPublic1"
                              >
                                No
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <p htmlFor="tutorialTitle">
                  Notification Settings:
                  <i className="icon-question  pl-1" id="six" />
                  <Tooltip
                    placement="right"
                    isOpen={openToolTip["six"]}
                    target="six"
                    toggle={() => this.toggleTooltip("six")}
                  >
                    You can customize which notifications you would like to
                    receive via email. Note that this same information will also
                    be available in the dashboard.
                  </Tooltip>
                </p>
              </Col>
              <Col xs="12" md="9">
                <Card>
                  <CardHeader>Notifications</CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="mt-0">
                          <strong>Do you want an email notification?</strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio31"
                                  name="email_notification"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={data["email_notification"] === true}
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="radio31"
                                >
                                  Yes
                                </Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio13"
                                  name="email_notification"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={data["email_notification"] === false}
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="radio13"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["email_notification"] && (
                              <div className="help-block">
                                {errors["email_notification"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="mt-0">
                          <strong>
                            Do you want a notification when the tutorial is
                            taken?
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="notification1"
                                  name="email_notification_tutorial_taken"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={
                                    data[
                                      "email_notification_tutorial_taken"
                                    ] === true
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="notification1"
                                >
                                  Yes
                                </Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="notification2"
                                  name="email_notification_tutorial_taken"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={
                                    data[
                                      "email_notification_tutorial_taken"
                                    ] === false
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="notification2"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["email_notification_tutorial_taken"] && (
                              <div className="help-block">
                                {errors["email_notification_tutorial_taken"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <p>
                          <strong>
                            Do you want a notification when a quiz is completed?
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="quiz1"
                                  name="email_notification_quiz_completed"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={
                                    data[
                                      "email_notification_quiz_completed"
                                    ] === true
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="quiz1"
                                >
                                  Yes
                                </Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="quiz2"
                                  name="email_notification_quiz_completed"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={
                                    data[
                                      "email_notification_quiz_completed"
                                    ] === false
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="quiz2"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["email_notification_quiz_completed"] && (
                              <div className="help-block">
                                {errors["email_notification_quiz_completed"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" md="6">
                        <p>
                          <strong>
                            Do you want a notification when a quiz is missed a
                            lot?
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  aria-label={"Notification for missed quiz"}
                                  id="question_missed"
                                  name="email_notification_question_missed"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={
                                    data[
                                      "email_notification_question_missed"
                                    ] === true
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="question_missed"
                                >
                                  Yes
                                </Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  aria-label={"Notification for missed quiz"}
                                  id="question_missed1"
                                  name="email_notification_question_missed"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={
                                    data[
                                      "email_notification_question_missed"
                                    ] === false
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="question_missed1"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["email_notification_question_missed"] && (
                              <div className="help-block">
                                {errors["email_notification_question_missed"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                        {data["email_notification_question_missed"] ===
                          true && (
                          <Row>
                            <Col id="tut-notify-area">
                              <Row>
                                <label className="pl-3">
                                  {" "}
                                  Missed by what percentage?
                                </label>
                                <Input
                                  type="number"
                                  name="missed_question_threshold"
                                  aria-label={"Percentage for Missed Quiz"}
                                  value={
                                    data["missed_question_threshold"] &&
                                    data["missed_question_threshold"]
                                  }
                                  className="tut-notify-custom-input"
                                  onChange={this.handleChange}
                                />
                                <label>%</label>
                              </Row>
                            </Col>
                          </Row>
                        )}
                        {errors["missed_question_threshold"] && (
                          <div className="help-block">
                            {errors["missed_question_threshold"]}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </FormGroup>
            <FormGroup row id="tutorial_other_settings">
              <Col md="3">
                <p htmlFor="tutorialTitle">
                  Other Settings:
                  <i className="icon-question  pl-1" id="six" />
                </p>
              </Col>
              <Col xs="12" md="9">
                <Card>
                  <CardHeader>Settings</CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="mt-0">
                          <strong>Do you want to enable certificate?</strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio31"
                                  name="certificate_enabled"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={data["certificate_enabled"] === true}
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="radio31"
                                >
                                  Yes
                                </Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="radio13"
                                  name="certificate_enabled"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={
                                    data["certificate_enabled"] === false
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="radio13"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["email_notification"] && (
                              <div className="help-block">
                                {errors["email_notification"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="mt-0">
                          <strong>
                            Do you wish to allow students to take the tutorial
                            only on the browser?
                          </strong>
                        </p>
                        <Row>
                          <Col md="12">
                            <div>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="redirect1"
                                  name="skip_desktop_app_prompt"
                                  value={true}
                                  onChange={this.handleChange}
                                  checked={
                                    data["skip_desktop_app_prompt"] === true
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="redirect1"
                                >
                                  Yes
                                </Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  id="redirect2"
                                  name="skip_desktop_app_prompt"
                                  value={false}
                                  onChange={this.handleChange}
                                  checked={
                                    data["skip_desktop_app_prompt"] === false
                                  }
                                />
                                <Label
                                  check
                                  className="form-check-label"
                                  htmlFor="redirect2"
                                >
                                  No
                                </Label>
                              </FormGroup>
                            </div>
                            {errors["skip_desktop_app_prompt"] && (
                              <div className="help-block">
                                {errors["skip_desktop_app_prompt"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}
export default QuizSetting;
