import { messageConstants } from "../_constants/index";
import _ from "lodash";
const initial = {
  messageData: [],
  isLoadingMessage: false,
  countUnreadMessages: 0
};
export function message(state = initial, action) {
  switch (action.type) {
    case messageConstants.GET_MESSAGES_SUCCESS:
      let unRead = 0;
      let allMessages = action.data.data;
      allMessages &&
        allMessages.map(msg => {
          if (msg.is_read === null || msg.is_read === false) {
            unRead = unRead + 1;
          }
        });
      return {
        ...state,
        messageData: action.data.data,
        isLoadingMessage: false,
        countUnreadMessages: unRead
      };
    case messageConstants.GET_MESSAGES_FAILURE:
      return { ...state, messageData: [], isLoadingTutorials: false };
    case messageConstants.GET_MESSAGES_REQUEST:
      return { ...state, isLoadingMessage: true };
    case messageConstants.DELETE_MESSAGE_SUCCESS:
      let removeMsg = state.messageData;
      let index = removeMsg.findIndex(d => d.id === action.data.messageID);
      removeMsg.splice(index, 1);
      return { ...state, messageData: removeMsg };
    case messageConstants.DELETE_MESSAGE_FAILURE:
      return { ...state };
    case messageConstants.READ_MESSAGE_SUCCESS:
      let ind = state.messageData.findIndex(
        m => m.id === action.data.data.data.id
      );
      state.messageData.splice(ind, 1, action.data.data.data);
      let newUnRead = 0;
      state.messageData &&
        state.messageData.map(msg => {
          if (msg.is_read === null || msg.is_read === false) {
            newUnRead = newUnRead + 1;
          }
        });
      return { ...state, countUnreadMessages: newUnRead };
    case messageConstants.READ_ALL_MESSAGE_SUCCESS:
      state.messageData &&
        state.messageData.map(msg => {
          msg.is_read = true;
        });
      return { ...state, countUnreadMessages: newUnRead };
    case messageConstants.READ_MESSAGE_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
