import { authHeader } from "../_helpers";
import { history } from "../_helpers";
import axios from "axios";
import { config } from "../config";
import axiosI from "../_helpers/service";

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  changeName,
  changePassword,
  resetPassword,
  confirmChangePassword,
  getTutorialByID
};

function login(email, password) {
  return axios
    .post(`${config.apiUrl}/auth/sign_in`, {
      email: email,
      password: password
    })
    .then(response => {
      const headerJson = {
        Uid: response.headers["uid"],
        Client: response.headers["client"],
        "Token-Type": response.headers["token-type"],
        "Access-Token": response.headers["access-token"]
      };
      localStorage.setItem("headerJson", JSON.stringify(headerJson));
      // localStorage.setItem('user', JSON.stringify(response.data.data));
      localStorage.setItem("defaultRole", response.data.data.main_role);
      return response.data.data;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("headerJson");
  localStorage.removeItem("defaultRole");
  localStorage.removeItem("first-time");
  history.push("/login");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return axios({
    url: `${config.apiUrl}/users`,
    headers: { ...authHeader(), "Content-Type": "application/json" }
  }).then(response => response.data);
}

function getById(uid, client, token) {
  return axiosI
    .get(
      `/auth/validate_token?uid=${uid}&client=${client}&access-token=${token}`
    )
    .then(response => {
      return response;
    });
}

function register(user) {
  return axios.post(`${config.apiUrl}/auth`, user).then(response => {
    return response;
  });
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function changeName(obj) {
  return axiosI.patch("/auth", obj).then(response => {
    return response;
  });
}
function changePassword(obj) {
  return axiosI.put("/auth/password", obj).then(response => {
    return response;
  });
}
function resetPassword(obj) {
  return axios.post(`${config.apiUrl}/auth/password`, obj).then(response => {
    return response;
  });
}
function confirmChangePassword(headers, obj) {
  const requestOptions = {
    url: `${config.apiUrl}/auth/password`,
    method: "PUT",
    headers: headers,
    data: obj
  };
  return axios(requestOptions).then(response => {
    return response;
  });
}
function getTutorialByID(id) {
  return axios
    .get(`${config.apiUrl}/tutorials/562`, {
      headers: JSON.parse(localStorage.getItem("headerJson"))
    })
    .then(res => {
      if (res.data) {
        return res.data;
      }
    })
    .catch(err => {});
}
