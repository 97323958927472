import { credentialsConstants } from "../../_constants/index";
const initial = {
  credentialsData: [],
  isLoadingCredentials: false,
  errorData: [],
  editError: {}
};
const admin_roles = [
  "org_administrator",
  "content_creator",
  "full_content_creator",
  "basic_content_creator",
  "individual_content_creator",
  "learner"
];
const org_roles = [
  "org_administrator",
  "content_creator",
  "full_content_creator",
  "basic_content_creator",
  "individual_content_creator",
  "learner"
];
const userLogin = localStorage.getItem("user");
// const userMainRole = userLogin && JSON.parse(userLogin).main_role;
const userMainRole = localStorage.getItem("defaultRole");

export function credentials(state = initial, action) {
  switch (action.type) {
    case credentialsConstants.GETALL_CREDENTIALS_SUCCESS:
      let credData = [];
      action.data.data.map((c, i) => {
        let role = Object.keys(c).toString();
        const result =
          userMainRole === "org_administrator"
            ? org_roles.includes(role)
            : admin_roles.includes(role);
        if (!result) return null;
        credData.push(c);
      });
      return {
        ...state,
        credentialsData: credData,
        isLoadingCredentials: false,
        errorData: [],
        editError: {}
      };
    case credentialsConstants.GETALL_CREDENTIALS_FAILURE:
      return {
        ...state,
        credentialsData: [],
        isLoadingCredentials: false,
        errorData: [],
        editError: {}
      };
    case credentialsConstants.GETALL_CREDENTIALS_REQUEST:
      return { ...state, isLoadingCredentials: true };
    case credentialsConstants.DELETE_USER_SUCCESS:
      state.credentialsData.forEach(
        d =>
          action.data.role in d &&
          d[action.data.role].splice(
            d[action.data.role].findIndex(
              r => r.id === action.data.user_ids[0] && true
            ),
            1
          )
      );
      return { ...state };
    case credentialsConstants.DELETE_USER_FAILURE:
      return { ...state };
    case credentialsConstants.ADD_USER_SUCCESS:
      action.data.obj.roles.forEach((i, e) => {
        Object.values(
          state.credentialsData[
            _.findIndex(
              state.credentialsData,
              a => action.data.obj.roles[e]["name"] in a
            )
          ]
        )[0].push(...action.data.data.data);
      });
      return { ...state, errorData: [] };
    case credentialsConstants.ADD_USER_FAILURE:
      return { ...state, errorData: action.error };
    case credentialsConstants.EDIT_USER_SUCCESS:
      Object.values(
        state.credentialsData[
          _.findIndex(state.credentialsData, a => action.data.activeRole in a)
        ]
      )[0][
        _.findIndex(
          Object.values(
            state.credentialsData[
              _.findIndex(
                state.credentialsData,
                a => action.data.activeRole in a
              )
            ]
          )[0],
          i => i.id === action.data.data.data.id
        )
      ] = action.data.data.data;
      return { ...state, editError: {} };
    case credentialsConstants.EDIT_USER_FAILURE:
      return { ...state, editError: action.error.errors };
    default:
      return state;
  }
}
