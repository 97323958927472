import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

import { store, history } from "./_helpers";
import { App } from "./App";

import "flag-icon-css/css/flag-icon.min.css";
import "font-awesome/css/font-awesome.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "../scss/style.scss";
import "../scss/core/_dropdown-menu-right.scss";

import { configureFakeBackend } from "./_helpers";
configureFakeBackend();
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
