import React from "react";
import CertificateForm from "./CertificateForm";

const CertificateDownload = ({
  userLogin,
  certificateId,
  updateTutorialAttemptGuestDetails,
}) => {
  return (
    <div>
      {userLogin &&
        "Your certificate will be emailed to you. Although you can download it from the link below."}
      <br />
      {userLogin && certificateLink(userLogin, certificateId)}
      {!userLogin && (
        <CertificateForm
          updateTutorialAttemptGuestDetails={updateTutorialAttemptGuestDetails}
        />
      )}
    </div>
  );
};

const certificateLink = (userLogin, certificateId) => {
  const certificateDownloadLink = `${process.env.BACKEND_URL}/certificates/show/${certificateId}.pdf`;
  return (
    userLogin && (
      <a
        href={certificateDownloadLink}
        target="_blank"
        rel="noopener noreferrer"
        j
      >
        Download Certificate
      </a>
    )
  );
};

export default CertificateDownload;
