import { importTutorialsConstants } from "../../_constants/index";
const initial = {
  userData: [],
  courseData: [],
  isLoadingCourseImport: false,
  isLoadingUserImport: false
};
export function importTutorials(state = initial, action) {
  switch (action.type) {
    case importTutorialsConstants.GET_TUTORIALS_COURSE_REQUEST:
      return { ...state, isLoadingCourseImport: true };
    case importTutorialsConstants.GET_TUTORIALS_COURSE_SUCCESS:
      return {
        ...state,
        courseData: action.data.data.filter(c => {
          return [
            "full_content_creator",
            "basic_content_creator",
            "individual_content_creator"
          ].includes(c.user.main_role);
        }),
        isLoadingCourseImport: false
      };
    case importTutorialsConstants.GET_TUTORIALS_COURSE_FAILURE:
      return { ...state, courseData: [] };
    case importTutorialsConstants.GET_TUTORIALS_ROLES_REQUEST:
      return { ...state, isLoadingUserImport: true };
    case importTutorialsConstants.GET_TUTORIALS_ROLES_SUCCESS:
      let userRoles = {};
      userRoles =
        action.data.length &&
        Object.values(
          action.data.find(a => {
            return (
              a["full_content_creator"] ||
              a["basic_content_creator"] ||
              a["individual_content_creator"]
            );
          })
        )[0];
      return { ...state, userData: userRoles, isLoadingUserImport: false };
    case importTutorialsConstants.GET_TUTORIALS_ROLES_FAILURE:
      return { ...state, userData: [] };
    default:
      return state;
  }
}
