export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",
  DEFAULT_ROLE: "USER_DEFAULT_ROLE",

  CHANGE_NAME_REQUEST: "CHANGE_NAME_REQUEST",
  CHANGE_NAME_SUCCESS: "CHANGE_NAME_SUCCESS",
  CHANGE_NAME_FAILURE: "CHANGE_NAME_FAILURE",

  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  RESET_USER_PASSWORD_SUCCESS: "RESET_USER_PASSWORD_SUCCESS",
  RESET_USER_PASSWORD_FAILURE: "RESET_USER_PASSWORD_FAILURE",

  TOGGLE_POP_UP_REQUEST: "TOGGLE_POP_UP_REQUEST"
};
