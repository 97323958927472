import { contentCreatorConstants, toastConstants } from "../_constants";
import { contentCreatorService } from "../_services";

export const contentCreatorActions = {
  getTutorialsData,
  getTutorialData,
  getCoursesData,
  copyTutorialData,
  deleteTutorialData,
  addTutorialData,
  addTabData,
  removeTabsData,
  updateTutorialData,
  shareTutorialWithLearnersAndCourses,
  AddTabsData,
  RemoveEditData,
  getTutorialsList,
  getTutorialDataById,
  downloadTutorialAnswer,
  addMedia,
  editMedia,
};

function getTutorialsData() {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.GET_ALL_TUTORIALS_REQUEST });
    contentCreatorService.getTutorials().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.GET_ALL_TUTORIALS_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.GET_ALL_TUTORIALS_FAILURE, error };
  }
}

function getTutorialData(id) {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.GET_TUTORIAL_REQUEST });
    return contentCreatorService.getTutorial(id).then(
      (data) => {
        return dispatch(success(data));
      },
      (error) => {
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.GET_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.GET_TUTORIAL_FAILURE, error };
  }
}

function getTutorialDataById(id) {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.GET_TUTORIAL_BY_ID_REQUEST });
    return contentCreatorService.getTutorial(id).then(
      (data) => {
        return dispatch(success(data));
      },
      (error) => {
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.GET_TUTORIAL_BY_ID_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.GET_TUTORIAL_BY_ID_FAILURE, error };
  }
}

function getCoursesData() {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.GET_COURSES_REQUEST });
    contentCreatorService.getCourses().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.GET_COURSES_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.GET_COURSES_FAILURE, error };
  }
}

function copyTutorialData(obj) {
  return (dispatch) => {
    return contentCreatorService.copyTutorial(obj).then(
      (data) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully Copied." },
        });
        return dispatch(success(data));
      },
      (error) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." },
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.COPY_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.COPY_TUTORIAL_FAILURE, error };
  }
}

function deleteTutorialData(id) {
  return (dispatch) => {
    return contentCreatorService.deleteTutorial(id).then(
      (data) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully deleted." },
        });
        return dispatch(success(id));
      },
      (error) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." },
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(id) {
    return { type: contentCreatorConstants.DELETE_TUTORIAL_SUCCESS, id };
  }
  function failure(error) {
    return { type: contentCreatorConstants.DELETE_TUTORIAL_FAILURE, error };
  }
}

function addTutorialData(tutorial) {
  return (dispatch) => {
    return contentCreatorService.addTutorial(tutorial).then(
      (data) => {
        if (data.data.courses.length > 0)
          contentCreatorService.assignedTutorialsToCourses({
            tutorial_ids: [data.data.id],
            course_ids: data.data.courses[0].id,
          });
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully saved." },
        });
        return dispatch(success(data));
      },
      (error) => {
        let keys = Object.keys(error.response.data.errors);
        let val = error.response.data.errors[keys];
        let errMsg = keys[0] + " " + val;
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: errMsg || "Something went wrong." },
        });
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.CREATE_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.CREATE_TUTORIAL_FAILURE, error };
  }
}

function addTabData(action, data) {
  return (dispatch) => {
    if (action === "1")
      dispatch({ type: contentCreatorConstants.ADD_TAB1_DATA, data });
    if (action === "2")
      dispatch({ type: contentCreatorConstants.ADD_TAB2_DATA, data });
    if (action === "3")
      dispatch({ type: contentCreatorConstants.ADD_TAB3_DATA, data });
    if (action === "4")
      dispatch({ type: contentCreatorConstants.ADD_TAB4_DATA, data });
    if (action === "5")
      dispatch({ type: contentCreatorConstants.ADD_TAB5_DATA, data });
  };
}

function removeTabsData() {
  return {
    type: contentCreatorConstants.REMOVE_TAB_DATA,
  };
}

function AddTabsData(data) {
  return {
    type: contentCreatorConstants.CONTENT_TAB_DATA,
    data,
  };
}
function RemoveEditData() {
  return {
    type: contentCreatorConstants.REMOVE_EDIT_DATA,
  };
}

function updateTutorialData(obj) {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.UPDATE_TUTORIAL_REQUEST });
    return contentCreatorService.updateTutorial(obj).then(
      (data) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully Updated." },
        });
        return dispatch(success(data));
      },
      (error) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." },
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.UPDATE_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.UPDATE_TUTORIAL_FAILURE, error };
  }
}

function shareTutorialWithLearnersAndCourses(tutorialId, tutorialData) {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.SHARE_TUTORIAL_REQUEST });
    return contentCreatorService.shareTutorial(tutorialId, tutorialData).then(
      (data) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Tutorial shared successfully." },
        });
        return dispatch(success(data));
      },
      (error) => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." },
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.SHARE_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.SHARE_TUTORIAL_FAILURE, error };
  }
}

function getTutorialsList() {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.GET_TUTORIAL_LIST_REQUEST });
    return contentCreatorService.getTutorialList().then(
      (data) => {
        return dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.GET_TUTORIAL_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.GET_TUTORIAL_LIST_REQUEST, error };
  }
}

function downloadTutorialAnswer(tutorialId) {
  return (dispatch) => {
    dispatch({ type: contentCreatorConstants.GET_TUTORIAL_ANSWER_REQUEST });
    return contentCreatorService.getTutorialAnswer(tutorialId).then(
      (data) => {
        return dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.GET_TUTORIAL_ANSWER_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentCreatorConstants.GET_TUTORIAL_ANSWER_FAILURE, error };
  }
}

function addMedia(data) {
  return (dispatch) => {
    return contentCreatorService.mediaService(data).then(
      (data) => {
        return dispatch(success(data.data));
      },
      (error) => {
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.ADD_MEDIA_SUCCESS, data };
  }

  function failure(error) {
    const errorMessage =
      error?.response?.data?.errors?.full_messages.join(",") ||
      "Something went wrong!";
    return {
      type: toastConstants.SHOW_TOAST,
      message: {
        type: "ERROR",
        data: errorMessage,
      },
    };
  }
}

function editMedia(id, data) {
  return (dispatch) => {
    return contentCreatorService
      .editMediaService(id, data)
      .then((data) => {
        return dispatch(success(data.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function success(data) {
    return { type: contentCreatorConstants.EDIT_MEDIA_SUCCESS, data };
  }

  function failure(error) {
    const errorMessage =
      error?.response?.data?.errors?.full_messages.join(",") ||
      "Something went wrong!";
    return {
      type: toastConstants.SHOW_TOAST,
      message: {
        type: "ERROR",
        data: errorMessage,
      },
    };
  }
}
