export default {
  items: [
    {
      name: "Dashboard",
      url: "/",
      icon: "icon-speedometer"
    },
    {
      name: "Create Tutorial",
      url: "/create-tutorial",
      icon: "icon-note",
      allowed: [
        "full_content_creator",
        "basic_content_creator",
        "individual_content_creator"
      ]
    },
    {
      name: "Tutorial Management",
      url: "/tutorial-management",
      icon: "fa fa-code",
      allowed: [
        "administrator",
        "full_content_creator",
        "basic_content_creator",
        "individual_content_creator",
        "learner_role"
      ]
    },
    {
      name: "User Management",
      url: "/credentials-management",
      icon: "icon-key",
      allowed: ["org_administrator", "licensee"]
    },
    {
      name: "Role Management",
      url: "/role-management",
      icon: "icon-user",
      allowed: ["org_administrator", "licensee"]
    },
    {
      name: "Course Management",
      url: "/course-management",
      icon: "icon-list",
      allowed: ["full_content_creator"]
    },
    {
      name: "Learner Management",
      url: "/learner-management",
      icon: "icon-user",
      allowed: ["org_administrator", "full_content_creator", "licensee"]
    },
    {
      name: "Tutorial Look & Feel",
      url: "/tutorial-look-feel",
      icon: "fa fa-eye",
      allowed: ["org_administrator", "full_content_creator", "licensee"]
    },
    {
      name: "Import Tutorials",
      url: "/import-tutorials",
      icon: "fa fa-book",
      allowed: ["org_administrator", "licensee", "full_content_creator"]
    },
    {
      name: "System Wide Configuration",
      url: "/system-wide-configuration",
      icon: "icon-pencil",
      allowed: ["administrator", "learner_role"]
    },
    {
      name: "Help Center",
      url: "/help-center",
      icon: "fa fa-question-circle",
      allowed: [
        "learner",
        "org_administrator",
        "basic_content_creator",
        "full_content_creator",
        "individual_content_creator",
        "learner_role",
        "licensee"
      ]
    },
    {
      name: "Logout",
      url: "/login",
      icon: "fa fa-sign-out",
      onClick: "onClick"
    }
  ],
  content_creator_items: [
    {
      name: "Dashboard",
      url: "/",
      icon: "icon-speedometer"
    },
    {
      name: "Create Tutorial",
      url: "/create-tutorial",
      icon: "icon-note",
      allowed: ["full_content_creator"]
    },
    {
      name: "Tutorial Management",
      url: "/tutorial-management",
      icon: "fa fa-code",
      allowed: ["administrator", "full_content_creator", "learner_role"]
    },
    {
      name: "Course Management",
      url: "/course-management",
      icon: "icon-list",
      allowed: ["full_content_creator"]
    },
    {
      name: "Learner Management",
      url: "/learner-management",
      icon: "icon-user",
      allowed: ["org_administrator", "full_content_creator"]
    },
    {
      name: "Role Management",
      url: "/role-management",
      icon: "icon-user",
      allowed: ["org_administrator"]
    },
    {
      name: "User Management",
      url: "/credentials-management",
      icon: "icon-key",
      allowed: ["org_administrator"]
    },

    {
      name: "Tutorial Look & Feel",
      url: "/tutorial-look-feel",
      icon: "fa fa-eye",
      allowed: ["org_administrator", "full_content_creator"]
    },
    {
      name: "Import Tutorials",
      url: "/import-tutorials",
      icon: "fa fa-book",
      allowed: ["org_administrator", "full_content_creator"]
    },
    {
      name: "System Wide Configuration",
      url: "/system-wide-configuration",
      icon: "icon-pencil",
      allowed: ["administrator", "learner_role", "licensee"]
    },
    {
      name: "Help Center",
      url: "/help-center",
      icon: "fa fa-question-circle",
      allowed: [
        "learner",
        "org_administrator",
        "full_content_creator",
        "learner_role"
      ]
    },
    {
      name: "Logout",
      url: "/login",
      icon: "fa fa-sign-out",
      onClick: "onClick"
    }
  ]
};
