import { helpCenterConstants } from "../_constants/index";
import { helpCenterService } from "../_services/index";

export const helpCenterActions = {
  getData
};

function getData() {
  return dispatch => {
    helpCenterService.getHelpCenter().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: helpCenterConstants.HELP_CENTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: helpCenterConstants.HELP_CENTER_FAILURE, error };
  }
}
