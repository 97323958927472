export const quizService = {
  getAllQuizzes
};

function getAllQuizzes() {
  let data = [
    {
      id: 7,
      title: "Sample Quiz Questions",
      type: "Formative",
      questions: "Q8. Select a noun from the following option:",
      options: ["option1", "option2", "option3", "option4"]
    },
    {
      id: 8,
      title: "Sample Quiz Questions",
      type: "Formative",
      questions: "Q8. Select a noun from the following option:",
      options: ["option1", "option2", "option3", "option4"]
    }
  ];
  return new Promise((res, rej) => {
    res(data);
  });
}
