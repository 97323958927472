import { helpCenterConstants } from "../_constants/index";
const initial = {
  helpCenterData: []
};
export function helpCenter(state = initial, action) {
  switch (action.type) {
    case helpCenterConstants.HELP_CENTER_SUCCESS:
      return { ...state, helpCenterData: action.data };
    case helpCenterConstants.HELP_CENTER_FAILURE:
      return { ...state, helpCenterData: [] };
    default:
      return state;
  }
}
