import React from "react";

export default class TutorialImgUrl extends React.Component {
  render() {
    const { imgUrl, element } = this.props;
    return (
      <div className="animated fadeIn">
        <img
          src={imgUrl}
          alt={element.tags && element.tags[0] && element.tags[0]}
        />
      </div>
    );
  }
}
