import React, { Component } from "react";
import {
  Label,
  Input,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  ModalFooter,
} from "reactstrap";

import "./tutorialManagement.scss";
import ShareTutorial from "./ShareTutorial";
import { history } from "../../_helpers";
import renderHtml from "react-render-html";
import SpinnerLoader from "../../components/spinner";
import BackButton from "../../_components/BackButton";
import AlternativeModals from "../../_components/AlternativeModals";
import CollaborateContainer from "../../containers/tutotrial_management/collaborateContainer";
import { tutorialActions } from "../../_actions/LearnerActions/tutorial.actions";
import tutorialChannelSubscription from "../../_helpers/tutorialChannelSubscription";

class DisplayTutorial extends Component {
  state = {
    index: 0,
    tutorialIndex: 0,
    modal: false,
    activeButton: "",
    copyTutorialName: "",
    exportTutorialName: "",
    tutorialId: "",
    collapse: { test: false },
    tutorial: {},
    isAlternative: false,
    isDownload: false,
    tut_win_test: "",
    establishingWebSocketConnection: false,
  };

  componentDidMount() {
    let { tutorialData } = this.props;
    if (Object.keys(tutorialData).length > 0) {
      this.setState({
        copyTutorialName: `Copy of [${tutorialData && tutorialData.title}]`,
        exportTutorialName: `Export of[${tutorialData && tutorialData.title}]`,
        tutorialId: tutorialData.id,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { tutorialData, isDownload } = nextProps;
    if (Object.keys(tutorialData).length > 0)
      this.setState({
        copyTutorialName: `Copy of [${tutorialData && tutorialData.title}]`,
        exportTutorialName: `Export of[${tutorialData && tutorialData.title}]`,
        tutorialId: tutorialData.id,
      });
    if (isDownload !== this.props.isDownload) {
      this.setState({ isDownload: isDownload });
    }
  }

  onCollapse = (e) => {
    this.setState({ collapse: { [e]: !this.state.collapse[e] }, chapterId: e });
  };

  onEdit = (e) => {
    this.setState({
      establishingWebSocketConnection: true,
    });

    const {
      user: { email },
      dispatch,
    } = this.props;
    const tutorialId = e.target.id;

    const handleChannelResponse = (data, tutorialId, that) => {
      const { is_editing: isEditing } = data;

      if (isEditing) {
        console.log("Someone else is editing");
        this.setState({
          establishingWebSocketConnection: false,
        });

        this.props.dispatch(
          tutorialActions.someoneIsEditingTutorial({
            editingUserEmail: data.user.email,
            currentUserEmail: email,
          })
        );
      } else {
        this.props.dispatch(tutorialActions.storeChannelInRedux(that));
        history.push({
          pathname: `/tutorial-management/edit/${tutorialId}`,
          state: this.props.index,
        });
      }
    };

    tutorialChannelSubscription({
      email,
      tutorialId,
      dispatch,
      handleChannelResponse,
      that: this,
    });
  };

  EditButton = ({ id }) => {
    const { establishingWebSocketConnection } = this.state;
    return establishingWebSocketConnection ? (
      <Button color="success">
        <div className="loader sm" />
      </Button>
    ) : (
      <Button onClick={this.onEdit} id={id} color="success">
        <i className="fa fa-pencil" id={id} /> Edit
      </Button>
    );
  };

  deleteConfirm = () => {
    return (
      <div>
        <div>
          <h4>Are you sure you want to delete this tutorial?.</h4>
        </div>
        <div className="float-right">
          <Button
            className="mt-2 mr-2"
            color="danger"
            onClick={(e) => {
              this.props.deleteTutorial(this.state.tutorialId);
              this.toggleModal(e);
            }}
          >
            Yes
          </Button>
          <Button className="mt-2 mr-2" onClick={this.toggleModal}>
            No
          </Button>
        </div>
      </div>
    );
  };

  handleChange = (e) => {
    let { value, name } = e.target;
    this.setState({ [name]: value });
  };

  downloadJson = (Obj) => {
    let { exportTutorialName } = this.state;
    let data =
      "text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify({ tutorials: [Obj] }));
    return (
      <div>
        <div>Do you want to download the tutorial Json file?</div>
        <div>
          <Button className="mt-2 mr-2" color="primary">
            <a
              href={"data:" + data}
              download={`${exportTutorialName}.json`}
              onClick={(e) => this.toggleModal(e)}
              style={{ color: "#000" }}
            >
              Download
            </a>
          </Button>
          <Button className="mt-2 mr-2" onClick={this.toggleModal}>
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  copyBlock = () => {
    let { copyTutorialName, tutorialId } = this.state;
    return (
      <div>
        <div>
          <Input
            type="text"
            value={copyTutorialName}
            name="copyTutorialName"
            onChange={this.handleChange}
          />
        </div>
        <div className="float-right">
          <Button
            className="mt-2 mr-2"
            color="primary"
            onClick={(e) => {
              this.props.copyTutorial({
                id: tutorialId,
                title: copyTutorialName,
              });
              this.toggleModal(e);
            }}
          >
            Copy
          </Button>
          <Button className="mt-2 mr-2" onClick={this.toggleModal}>
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  ExportBlock = () => {
    let { exportTutorialName } = this.state;
    return (
      <div>
        <div>
          <Input
            type="text"
            value={exportTutorialName}
            name="exportTutorialName"
            onChange={this.handleChange}
          />
        </div>
        <div className="float-right">
          <Button
            className="mt-2 mr-2"
            color="primary"
            id="Download"
            onClick={(e) => {
              this.toggleModal(e);
              this.setState({ activeButton: "Download" }, () => {
                this.setState({ modal: true });
              });
            }}
          >
            Export
          </Button>
          <Button className="mt-2 mr-2" onClick={this.toggleModal}>
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  toggleModal = (e) => {
    if (e.target) {
      if (Boolean(e.target.id)) {
        this.setState({ activeButton: e.target.id }, () => {
          this.setState({ modal: !this.state.modal });
        });
      } else this.setState({ modal: !this.state.modal });
    } else this.setState({ modal: !this.state.modal });
  };

  onNext = () => {
    this.setState({ index: this.state.index + 1 });
  };

  onPrevious = () => {
    this.setState({ index: this.state.index - 1 });
  };

  renderTextBlock = (data) => {
    return (
      <div>
        {typeof data.text === "object" ? (
          data.text.ops.map((t) => <span>{t.insert}</span>)
        ) : (
          <span>{renderHtml(data.text)}</span>
        )}
      </div>
    );
  };

  renderMediaBlock = (data) => {
    //let sourceType = data.source.split('.')[1]
    return (
      <div className="dis-flex chapter-second-section mt-0">
        <div className="full-width">
          <div>{data.description}</div>
          <div className="chapter-section-img-wrapper">
            {/*{(sourceType === 'jpeg' || sourceType === 'png' || sourceType === 'jpg' || data.source.length>1000)*/}
            {/*&&*/}
            <img
              src={data.source}
              name="img"
              onError={(e) => {
                e.target.src =
                  "http://saveabandonedbabies.org/wp-content/uploads/2015/08/default.png";
              }}
              height={50}
              width={50}
              alt={data.tags[0] ? data.tags[0] : "mediaImage"}
            />
            {/*}*/}
          </div>
        </div>
      </div>
    );
  };

  renderQuizBlock = (data) => {
    return (
      <div>
        {data.quiz_data.type === "Formative" ? (
          <div className="formative-quiz-block">
            <div className="single-div quiz-header">{data.quiz_data.title}</div>
            <div>
              {data.quiz_data.questions.map((q, k) => {
                return (
                  <div className="single-div" key={k}>
                    <div className="dis-flex" id="questions-ans-area">
                      <div className="Q-tag">
                        <label>Q.</label>
                      </div>
                      <div className="Q-area">{`${q.text} `}</div>
                      <div className="pts-bold point-area">{`${q.points} pts`}</div>
                    </div>
                    {q.type === "short_text" && (
                      <div className="pl-3">
                        <Input className="mt-2 mb-2" type="text" />
                      </div>
                    )}
                    {q.type === "long_text" && (
                      <div className="pl-3">
                        <Input className="mt-2 mb-2" type="text" />
                      </div>
                    )}
                    {q.type === "multiple_choice" && (
                      <div className="dis-flex quiz-radio-space">
                        {q.options.map((opt, optk) => {
                          return (
                            <FormGroup check className="options-block" key={optk}>
                              <Label check>
                                <Input type="checkbox" />
                                {opt}
                              </Label>
                            </FormGroup>
                          );
                        })}
                      </div>
                    )}
                    {(q.type === "boolean" || q.type === "single_choice") && (
                      <div className="dis-flex quiz-radio-space">
                        {q.options.map((opt, optk) => {
                          return (
                            <FormGroup check className="options-block" key={optk}>
                              <Label check>
                                <Input type="radio" />
                                {opt}
                              </Label>
                            </FormGroup>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  renderUrlBlock = (data) => {
    return (
      <div className="dis-flex chapter-second-section mt-0">
        <div className="full-width">
          <div>{data.display_text}</div>
          <div className="chapter-section-img-wrapper">
            <a href={data.url} name="img" target={data.target}>
              {data.url}
            </a>
          </div>
        </div>
      </div>
    );
  };

  renderEmbeddedMedia = (data) => {
    return (
      <div className="dis-flex chapter-second-section mt-0">
        <div className="full-width">
          <div className="chapter-section-img-wrapper">
            {renderHtml(data.code)}
          </div>
        </div>
      </div>
    );
  };

  setTutorial = (tutorialData) => {
    this.setState({
      isAlternative: true,
      tutorial: tutorialData,
    });
  };

  close = () => {
    this.setState({
      isAlternative: false,
    });
  };

  set_window_object = (win_obj) => {
    debugger;
    this.setState({
      tut_win_test: win_obj,
    });
  };

  get_window_object = () => {
    return this.state.tut_win_test;
  };

  doesUserOwnTutorial = ({ userId, tutorialUserId }) => {
    return userId === tutorialUserId ? true : false;
  };

  render() {
    const {
      tutorialData: {
        content: { tutorial = [] } = {},
        summative_test = {},
        id,
        user_id: tutorialUserId,
        title,
        roles = [],
      } = {},
      courseData,
      setLoader,
      onShare,
      tutorialList,
      user,
    } = this.props;
    const {
      modal,
      activeButton,
      index,
      tutorialId,
      collapse,
      isAlternative,
      isDownload,
    } = this.state;
    const summativeLength =
      summative_test &&
      (Object.keys(summative_test)[0] === "summative_test"
        ? summative_test.summative_test.length
        : 0);
    let p = 1;
    return (
      <div className="display-tutorial">
        {setLoader ? (
          <SpinnerLoader />
        ) : (
          <div>
            {activeButton !== "" && (
              <Modal
                isOpen={modal}
                toggle={this.toggleModal}
                className={
                  activeButton === "Delete" ? "modal-danger" : "modal-primary"
                }
              >
                <ModalHeader className="modal-header" toggle={this.toggleModal}>
                  {activeButton === "Share" &&
                    "Share this tutorial with students."}
                  {activeButton === "Copy" && "Copy this existing tutorial."}
                  {activeButton === "Delete" && "Confirmation"}
                  {activeButton === "Export" && "Export Tutorial"}
                  {activeButton === "Download" &&
                    "Download Exported Tutorial File"}
                  {activeButton === "Collaborate" &&
                    "Add Collaborators to Tutorial"}
                </ModalHeader>
                <ModalBody>
                  {activeButton === "Share" && (
                    <ShareTutorial
                      courses={courseData && courseData}
                      tutorialId={tutorialId}
                      toggleModal={this.toggleModal}
                      onShare={onShare}
                      tutorialData={this.props.tutorialData}
                    />
                  )}
                  {activeButton === "Copy" && this.copyBlock()}
                  {activeButton === "Delete" && this.deleteConfirm()}
                  {activeButton === "Export" && this.ExportBlock()}
                  {activeButton === "Download" &&
                    this.downloadJson(
                      Object.assign({}, this.props.tutorialData, {
                        url: undefined,
                        user_id: undefined,
                      })
                    )}
                  {activeButton === "Collaborate" && <CollaborateContainer />}
                </ModalBody>
              </Modal>
            )}
            <div id="container">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <div className="row">
                      <div className="tutorial-actions col-md-12 col-12 float-right">
                        <this.EditButton id={id} />
                        <Button
                          color={"danger"}
                          onClick={this.toggleModal}
                          id="Delete"
                        >
                          <i className="fa fa-trash" /> Delete{" "}
                        </Button>
                        <Button
                          color={"primary"}
                          onClick={this.toggleModal}
                          id="Copy"
                        >
                          <i className="fa fa-copy" /> Copy
                        </Button>
                        <Button
                          color={"primary"}
                          className="btn-tut-white"
                          onClick={this.toggleModal}
                          id="Share"
                        >
                          <i className="fa fa-share" /> Share
                        </Button>
                        <Button
                          color={"primary"}
                          className="btn-tut-white"
                          onClick={this.toggleModal}
                          id="Export"
                        >
                          <i className="fa fa-share" /> Export
                        </Button>
                        {tutorial.length > 0 && (
                          <Button
                            color={"success"}
                            onClick={() =>
                              this.setTutorial(this.props.tutorialData)
                            }
                          >
                            <i className="fa fa-eye" /> Preview Tutorial
                          </Button>
                        )}
                        {tutorial.length > 0 &&
                          this.doesUserOwnTutorial({
                            userId: user.id,
                            tutorialUserId,
                          }) && (
                            <Button
                              color={"success"}
                              onClick={this.toggleModal}
                              id="Collaborate"
                            >
                              <i className="fa fa-users" /> Collaborate
                            </Button>
                          )}
                        {summativeLength > 0 && (
                          <Button
                            color={"success"}
                            onClick={() =>
                              history.push(
                                `/tutorial-page/${id}/authorized/QuizViewer`
                              )
                            }
                          >
                            <i className="fa icon-note" /> Preview Final Quiz
                          </Button>
                        )}
                        {(summativeLength > 0 || tutorial.length > 0) && (
                          <Button
                            color={"success"}
                            onClick={() =>
                              this.props.downloadTutorialAnswer(
                                this.props.tutorialData
                              )
                            }
                          >
                            <i className="fa fa-cloud-download" /> Download
                            Tutorial Results
                          </Button>
                        )}
                      </div>
                      <div>
                        <div className="manage-tutorial-display">
                          <h3>
                            Manage Tutorial:<span>{title}</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Object.keys(tutorial).length > 0 && (
                <div className="tutorial-collapse-content mt-2">
                  {tutorial.map((tutorial, i) => {
                    return (
                      <Card key={i}>
                        <CardHeader style={{ cursor: "pointer" }}>
                          <div onClick={() => this.onCollapse(i)}>
                            {Number(Object.keys(collapse)[0]) === i &&
                            Object.values(collapse)[0] ? (
                              <i className="fa fa-chevron-down pr-1" />
                            ) : (
                              <i className="fa fa-chevron-right pr-1" />
                            )}
                            Chapter: {tutorial.title}
                          </div>
                        </CardHeader>
                        <Collapse className="collapse-div" isOpen={collapse[i]}>
                          <CardBody className="custom-cardheader">
                            <div className="custom-col">
                              <div className="tutorial-detail-disp">
                                <div className="chapter-heading-content">
                                  <div>Chapter: {tutorial.title}</div>
                                </div>
                                <div className="chapter-heading-content-para">
                                  <p>P1</p>
                                </div>
                                <div className="chapter-heading-content-2">
                                  <div>Chapter: {tutorial.title}</div>
                                </div>
                                <div className="chapter-heading-content-dec">
                                  <div>
                                    Description:{" "}
                                    {tutorial.description &&
                                      tutorial.description}
                                  </div>
                                </div>
                                <div>
                                  {tutorial.elements.length > 0 &&
                                    tutorial.elements.map((el, i) => {
                                      // eslint-disable-next-line default-case
                                      switch (el.type) {
                                        case "text":
                                          return (
                                            <div
                                            key={i}
                                            className="chapter-heading-content-3">
                                              {this.renderTextBlock(el)}
                                            </div>
                                          );
                                          break;
                                        case "media":
                                          return (
                                            <div key={i}>
                                              {this.renderMediaBlock(el)}
                                            </div>
                                          );
                                          break;
                                        case "url":
                                          return (
                                            <div key={i}>{this.renderUrlBlock(el)}</div>
                                          );
                                          break;
                                        case "embedded_media":
                                          return (
                                            <div key={i}>
                                              {this.renderEmbeddedMedia(el)}
                                            </div>
                                          );
                                          break;
                                        case "quiz":
                                          return (
                                            <div className="quiz-table" key={i}>
                                              {this.renderQuizBlock(el)}
                                            </div>
                                          );
                                          break;
                                        case "page-break":
                                          p = p + 1;
                                          return (
                                            <div className="chapter-heading-content-para mb-3 mt-0" key={i}>
                                              <div className="w-100">
                                                <label>P{p}</label>
                                              </div>
                                            </div>
                                          );
                                          break;
                                      }
                                    })}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Collapse>
                      </Card>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="text-right display-footer-btn mr-2">
              {index === 0 && <BackButton />}
            </div>
          </div>
        )}
        {isAlternative && (
          <AlternativeModals
            tutorial={this.state.tutorial}
            isAlternative={isAlternative}
            close={this.close}
            doNotShow={this.props.doNotShow}
            dispatch={this.props.dispatch}
            setWindowObject={this.set_window_object}
            getWindowObject={this.get_window_object}
          />
        )}
        <Modal isOpen={isDownload} className="modal-warning modal-setting">
          <ModalHeader>Download Answer</ModalHeader>
          <ModalBody>
            <div>Unable to download answers for this tutorial.</div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.props.showDownload}>
              Okay<screen> </screen>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default DisplayTutorial;
