import axiosI from "../../_helpers/service";

export const courseService = {
  getTutorials,
  getCourses,
  addCourses,
  assignedTutorialsToCourses,
  editCourses,
  deleteCourse,
  copyCourse,
  deleteCourseTutorial
};
function getTutorials() {
  return axiosI.get("/tutorials/list.json").then(response => {
    return response;
  });
}
function getCourses() {
  return axiosI.get("/courses").then(response => {
    return response;
  });
}
function addCourses(courses) {
  return axiosI.post("/courses", courses).then(response => {
    return response;
  });
}
function assignedTutorialsToCourses(obj) {
  return axiosI.post("/tutorials/share_with_courses", obj).then(response => {
    return response;
  });
}
function editCourses(obj, id) {
  return axiosI.patch(`/courses/${id}`, obj).then(response => {
    return response;
  });
}
function deleteCourse(id) {
  return axiosI.delete(`/courses/${id}`).then(response => {
    return response;
  });
}
function copyCourse(id, obj) {
  return axiosI.post(`/courses/${id}/copy`, obj).then(response => {
    return response;
  });
}
function deleteCourseTutorial(tid, obj) {
  return axiosI.post(`/tutorials/${tid}/revoke`, obj).then(response => {
    return response;
  });
}
