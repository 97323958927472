import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
class FirefoxModal extends React.Component {
  render() {
    const { isFirefoxModal, toggleFirefoxModal } = this.props;
    return (
      <Modal isOpen={isFirefoxModal} className="modal-primary modal-lg">
        <ModalHeader>Tutorial Viewer</ModalHeader>
        <ModalBody>
          <p>
            To complete this tutorial, you will be working with two windows side
            by side. Make sure you align your windows correctly.
          </p>
          <img
            src={require("../../public/img/firefoxIssue.gif")}
            alt={"firefoxImage"}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleFirefoxModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default FirefoxModal;
