import React from "react";
import { Label } from "reactstrap";
import "./Checkbox.scss";

export const Checkbox = props => (
  <Label
    className={`${props.className ? props.className : ""} container`}
    onClick={props.onClick}
  >
    {props.children}
    <span className="checkmark"></span>
  </Label>
);

export default Checkbox;
