import React from "react";
import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
export default class AccountManagementPanel extends React.Component {
  render() {
    let activePath = window.location.pathname.split("/");
    let activeRole = JSON.parse(localStorage.getItem("user"));
    return (
      <div className="animated fadeIn">
        <Card className="acc-mgmt-card">
          <CardHeader>Account Management</CardHeader>
          <ListGroup>
            <ListGroupItem
              href="#"
              active={
                activePath[activePath.length - 1].toLowerCase() ===
                "account-management"
              }
            >
              <Link
                style={{
                  color:
                    activePath[activePath.length - 1].toLowerCase() ===
                      "account-management" && "white"
                }}
                to={"/account-management"}
              >
                Change Password
              </Link>
            </ListGroupItem>
            <ListGroupItem
              href="#"
              active={
                activePath[activePath.length - 1].toLowerCase() ===
                "change-profile"
              }
            >
              <Link
                style={{
                  color:
                    activePath[activePath.length - 1].toLowerCase() ===
                      "change-profile" && "white"
                }}
                to={"/account-management/change-profile"}
              >
                Change Profile
              </Link>
            </ListGroupItem>
            {/*{activeRole.main_role!=='full_content_creator'&&<ListGroupItem href="#" active={activePath[activePath.length-1].toLowerCase()==='change-institution'}>*/}
            {/*<Link style={{"color":activePath[activePath.length-1].toLowerCase()==='change-institution'&&'white'}} to={'/account-management/change-Institution'}>Change Institution</Link>*/}
            {/*</ListGroupItem>}*/}
            <ListGroupItem
              href="#"
              active={
                activePath[activePath.length - 1].toLowerCase() ===
                "change-account"
              }
            >
              <Link
                style={{
                  color:
                    activePath[activePath.length - 1].toLowerCase() ===
                      "change-account" && "white"
                }}
                to={"/account-management/change-Account"}
              >
                Cancel Account
              </Link>
            </ListGroupItem>
            <ListGroupItem
              href="#"
              active={
                activePath[activePath.length - 1].toLowerCase() ===
                "change-email"
              }
            >
              <Link
                style={{
                  color:
                    activePath[activePath.length - 1].toLowerCase() ===
                      "change-email" && "white"
                }}
                to={"/account-management/change-Email"}
              >
                Change Email
              </Link>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  }
}
