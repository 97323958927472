import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SingleSignOnPage from "./SingleSignOnPage";
import {
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import { userActions } from "../_actions";
import "./LoginPage.scss";
import AlternativeModals from "../_components/AlternativeModals";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitted: false,
      isOpen: false,
      isAlternative: false,
      tutorial: {},
      isSingleSignOn: false,
      isEmail_popUp: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { state = "" }
    } = nextProps;
    if (state !== undefined) {
      this.setState({
        isEmail_popUp: state.show_popUp && state.show_popUp
      });
    }
    if (nextProps.loggingInData && nextProps.loggingInData.tutorial) {
      // this.setState({
      //     isAlternative : true,
      //     tutorial:nextProps.loggingInData.tutorial
      // })
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    let self = this;
    if (email && password) {
      dispatch(userActions.login(email, password)).then((res, err) => {
        if (res) {
          localStorage.setItem("user", JSON.stringify(res.user));
          self.props.history.push(self.props.privatePath);
        }
      });
    }
  };

  setTutorial = () => {
    this.setState({
      isAlternative: true
    });
  };

  close = () => {
    this.setState({
      isAlternative: false
    });
  };

  toggleModal = () => {
    this.props.dispatch(userActions.logout());
    this.setState({ isOpen: false });
  };

  toggleEmailModal = () => {
    this.setState({ isEmail_popUp: false });
  };

  toggleSignOn = () => {
    this.setState({
      isSingleSignOn: !this.state.isSingleSignOn
    });
  };

  set_window_object = win_obj => {
    this.setState({
      tut_win_test: win_obj
    });
  };

  returnWindow = () => {
    return this.state.tut_win_test;
  };

  render() {
    const { loggingInData } = this.props;
    const {
      email,
      password,
      submitted,
      isAlternative,
      tutorial = {},
      isOpen,
      isSingleSignOn,
      isEmail_popUp
    } = this.state;
    let isFirefox = typeof InstallTrigger !== "undefined";
    let isIE = false || !!document.documentMode;
    let modalClass = "modal-success",
      modalHeader = "Success",
      btnColor = "success";
    if (isFirefox) {
      // modalClass = 'modal-warning';
      // modalHeader = 'Alert';
      // btnColor = 'warning';
    } else if (isIE) {
      modalClass = "modal-danger";
      modalHeader = "Alert";
      btnColor = "danger";
    }

    return (
      <div className="animated fadeIn margin-minus-left">
        <div className="middlebx">
          <Row className="justify-content-center center-width">
            <Col md="6" sm="6" xs="12" lg="6">
              <CardGroup className="mb-4">
                <Card className="p-4">
                  <CardBody className="login-page-card">
                    <img
                      className="login-page-logo"
                      src={require("../../public/img/SidecarLearning-Final-01.jpg")}
                      alt={"Logo of Sidecar Learning"}
                    />
                    <p className="text-muted text-center">
                      Sign In to your Sidecar account
                    </p>
                    <form name="form" onSubmit={this.handleSubmit}>
                      <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-user"></i>
                          </span>
                        </div>
                        <Input
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          aria-label={"Textbox for User Email"}
                        />
                        {submitted && !email && (
                          <div className="help-block">Email is required</div>
                        )}
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                        </div>
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={this.handleChange}
                          aria-label={"Textbox for User Password"}
                        />
                        {submitted && !password && (
                          <div className="help-block">Password is required</div>
                        )}
                      </InputGroup>
                      <Col xs="12" className="text-center mb-4">
                        <Button color="primary" className="px-4">
                          Login
                        </Button>
                        {loggingInData && loggingInData.loggingIn && (
                          <img
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            alt={"Login in Please wait"}
                          />
                        )}
                      </Col>
                      {/*<Row>*/}
                      {/*{this.props.loggingInData.user&&<p onClick={this.setTutorial} style={{"color":"#20a8d8","cursor":"pointer"}}>Test Tutorial</p>}*/}
                      {/*</Row>*/}
                      <Row>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          className="m-0 sign-on-text"
                        >
                          {/*<Link to="" onClick={this.toggleSignOn}>Single Sign-on</Link>*/}
                          <p
                            className="sign-on-link"
                            onClick={this.toggleSignOn}
                          >
                            Single Sign-on
                          </p>
                        </Col>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          className="forgot-password-text"
                        >
                          <Link to="/reset-password">Forgot password?</Link>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <Modal
            isOpen={isOpen}
            toggle={this.toggleModal}
            className={modalClass}
          >
            <ModalHeader>{modalHeader}</ModalHeader>
            <ModalBody>
              <div>
                <p>Welcome to Sidecar Learning.</p>
                {/*{*/}
                {/*    isFirefox && <p>Oops. Looks like you are using a non-optimal browser. Please use Chrome for best results.</p>*/}
                {/*}*/}
                {isIE && (
                  <p>
                    Oops. Looks like you are using a non-compatible browser.
                    Please use Chrome for best results.
                  </p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color={btnColor} onClick={this.setTutorial}>
                Continue
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={isEmail_popUp}
            toggle={this.toggleEmailModal}
            className={modalClass}
          >
            <ModalHeader toggle={this.toggleEmailModal}>
              {modalHeader}
            </ModalHeader>
            <ModalBody>
              <div>
                <p>
                  You have successfully registered, please confirm your email
                  now to login.
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color={btnColor} onClick={this.toggleEmailModal}>
                Okay
              </Button>
            </ModalFooter>
          </Modal>
          {isAlternative && (
            <AlternativeModals
              isAlternative={isAlternative}
              close={this.close}
              doNotShow={this.props.doNotShow}
              dispatch={this.props.dispatch}
              setWindowObject1={this.set_window_object}
              getWindow={this.returnWindow}
              fromLogin={true}
            />
          )}
        </div>
        <SingleSignOnPage isOpen={isSingleSignOn} toggle={this.toggleSignOn} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggingInData: state.authentication,
    privatePath: state.tutorial.privatePath
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
