import React from "react";
import renderHTML from "react-render-html";

export default class TutorialEmbed extends React.Component {
  render() {
    const { embeddedMedia, element } = this.props;
    let code = embeddedMedia;

    if (embeddedMedia.includes("<iframe")) {
      code =
        embeddedMedia.slice(
          0,
          embeddedMedia.indexOf("<iframe") + "<iframe".length
        ) +
        ` title="${element.tags[0]}" ` +
        embeddedMedia.slice(
          embeddedMedia.indexOf("<iframe") + "<iframe".length
        );
    }
    if (embeddedMedia.includes("<a")) {
      code =
        code.slice(0, code.indexOf("<a") + "<a".length) +
        ` title="${element.tags[0]}" ` +
        code.slice(code.indexOf("<a") + "<a".length);
    }
    if (embeddedMedia.includes("<img")) {
      code =
        code.slice(0, code.indexOf("<img") + "<img".length) +
        ` alt="${element.tags[0]}" ` +
        code.slice(code.indexOf("<img") + "<img".length);
    }
    return <div className="animated fadeIn">{renderHTML(code)}</div>;
  }
}
