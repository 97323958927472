import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Badge, Nav, NavItem, NavLink as RsNavLink } from "reactstrap";
import classNames from "classnames";
import nav from "./_nav";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SidebarFooter from "./../SidebarFooter";
import SidebarForm from "./../SidebarForm";
import SidebarHeader from "./../SidebarHeader";
import SidebarMinimizer from "./../SidebarMinimizer";
import { Router, Route } from "react-router-dom";
import { history } from "../../_helpers";
import { userActions } from "../../_actions";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarHidden: document.body.classList.value
    };
    this.setRole = this.setRole.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
    this.hideMobile = this.hideMobile.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { sidebarHidden } = nextProps;
    if (nextProps.sidebarHidden !== this.props.sidebarHidden) {
      this.setState({ sidebarHidden: sidebarHidden });
    }
  }

  setRole(role) {
    this.props.setUserRole(role);
  }

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle("open");
  }

  activeRoute(routeName, props) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    return props.location.pathname.indexOf(routeName) > -1
      ? "nav-item nav-dropdown open"
      : "nav-item nav-dropdown";
  }

  handleLogout() {
    this.props.setLogout();
  }

  hideMobile(e) {
    if (e.onClick) this.handleLogout();

    if (document.body.classList.contains("sidebar-mobile-show")) {
      document.body.classList.toggle("sidebar-mobile-show");
    }
  }

  // todo Sidebar nav secondLevel
  // secondLevelActive(routeName) {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }

  render() {
    //const userLogin = localStorage.getItem('user');
    // const userRole=userLogin && JSON.parse(userLogin).roles[0]['name'];
    const { sidebarHidden } = this.state;
    const { user } = this.props;
    const userRole = localStorage.getItem("defaultRole");
    // const userRole=userLogin && JSON.parse(userLogin).main_role;
    // badge addon to NavItem
    const badge = badge => {
      if (badge) {
        const classes = classNames(badge.class);
        return (
          <Badge className={classes} color={badge.variant}>
            {badge.text}
          </Badge>
        );
      }
    };

    // simple wrapper for nav-title item
    const wrapper = item => {
      return item.wrapper && item.wrapper.element
        ? React.createElement(
            item.wrapper.element,
            item.wrapper.attributes,
            item.name
          )
        : item.name;
    };

    // nav list section title
    const title = (title, key) => {
      const classes = classNames("nav-title", title.class);
      return (
        <li key={key} className={classes}>
          {wrapper(title)}{" "}
        </li>
      );
    };

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames("divider", divider.class);
      return <li key={key} className={classes}></li>;
    };

    // nav label with nav link
    const navLabel = (item, key) => {
      const classes = {
        item: classNames("hidden-cn", item.class),
        link: classNames("nav-label", item.class ? item.class : ""),
        icon: classNames(
          !item.icon ? "fa fa-circle" : item.icon,
          item.label.variant ? `text-${item.label.variant}` : "",
          item.label.class ? item.label.class : ""
        )
      };
      return navLink(item, key, classes);
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames(
          "nav-link",
          item.variant ? `nav-link-${item.variant}` : ""
        ),
        icon: classNames(item.icon)
      };
      return navLink(item, key, classes);
    };

    // nav link
    const navLink = (item, key, classes) => {
      const url = item.url ? item.url : "";
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink
              exact
              href={url}
              className={classes.link}
              active
              ariaCurrent={"true"}
            >
              <i className={classes.icon} />
              {item.name}
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            <NavLink
              exact
              to={url}
              className={classes.link}
              style={{
                fontWeight:
                  (item.name === "Create Tutorial" ||
                    item.name === "Tutorial Management") &&
                  "bold"
              }}
              ariaCurrent={"true"}
              onClick={() => {
                this.hideMobile(item);
              }}
            >
              <i
                className={classes.icon}
                style={{
                  fontWeight:
                    (item.name === "Create Tutorial" ||
                      item.name === "Tutorial Management") &&
                    900,
                  color:
                    (item.name === "Create Tutorial" ||
                      item.name === "Tutorial Management") &&
                    "#29363d"
                }}
              ></i>
              {item.name}
              {badge(item.badge)}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => {
      return (
        <li key={key} className={this.activeRoute(item.url, this.props)}>
          <a
            className="nav-link nav-dropdown-toggle"
            href="#"
            onClick={this.handleClick}
          >
            <i className={item.icon}></i>
            {item.name}
          </a>
          <ul className="nav-dropdown-items">{navList(item.children)}</ul>
        </li>
      );
    };

    // nav type
    const navType = (item, idx) =>
      item.title
        ? title(item, idx)
        : item.divider
        ? divider(item, idx)
        : item.label
        ? navLabel(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navItem(item, idx);

    // nav list
    const navList = items => {
      return items.map((item, index) => {
        return navType(item, index);
      });
    };

    const isExternal = url => {
      const link = url ? url.substring(0, 4) : "";
      return link === "http";
    };

    // sidebar-nav root
    return (
      <div
        className="sidebar"
        aria-hidden={sidebarHidden.includes("sidebar-hidden")}
      >
        <SidebarHeader
          setRole={this.setRole}
          actionMethods={userActions.logout}
        />
        <nav className="sidebar-nav">
          <Router history={history}>
            <Nav>
              {userRole === "full_content_creator"
                ? navList(
                    nav.content_creator_items.filter(e =>
                      e.allowed
                        ? e.allowed.some(
                            e =>
                              this.props.users.DefaultRole &&
                              this.props.users.DefaultRole === e
                          )
                        : true
                    )
                  )
                : navList(
                    nav.items.filter(e =>
                      e.allowed
                        ? e.allowed.some(
                            e =>
                              this.props.users.DefaultRole &&
                              this.props.users.DefaultRole === e
                          )
                        : true
                    )
                  )}
            </Nav>
          </Router>
        </nav>
        <div className="sidebar-logo">
          <img
            src={require("../../../public/img/SidecarLearning-Final-01.jpg")}
            alt={"Sidecar Logo"}
          />
        </div>
        <SidebarFooter />
        <SidebarMinimizer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.authentication
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setUserRole: userActions.setDefaultRole,
      setLogout: userActions.logout
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
