import React from "react";
import "./StarRatingComponent.scss"

export default class StarRatingComponent extends React.Component {
  handleChange = (e) => {
    console.log('From child component', e.target.value)
    this.props.onRatingChange(e)
  }

  render() {
    const html = [];
    for(let i = 1; i <= 5; i++) {
      html.push(
        <React.Fragment key={i}>
          <input value={ `${i}` } id={ `star${i}` } type="radio" name="rating" className="visuallyhidden" onChange={this.props.onRatingChange}></input>
          <label htmlFor={ `star${i}` }>
            <span className="visuallyhidden">{ ` ${i} Star` }</span>
            <svg viewBox="0 0 512 512"><path d="M512 198.525l-176.89-25.704-79.11-160.291-79.108 160.291-176.892 25.704 128 124.769-30.216 176.176 158.216-83.179 158.216 83.179-30.217-176.176 128.001-124.769z"></path></svg>
          </label>
        </React.Fragment>
      )
    }
    return(
      <div id="star_rating">
        <fieldset>
          {html}
      </fieldset>
    </div>
    )
  }
}