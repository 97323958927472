import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";
import StarRatingComponent from "../../StarRatingComponent";
import "./Tutorial-Complete.scss";
import { history } from "../../../_helpers/history";
import CertificateDownload from "./CertificateDownload";

export default class TutorialComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      rating: 0,
    };
  }

  onRatingChange = (e) => {
    this.setState({ rating: e.target.value });
  };
  handleComment = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  onSubmitComment = () => {
    const {
      handleReview,
      tutorialPageData: { summative_test = {} },
    } = this.props;
    const { comment, rating } = this.state;
    handleReview(comment, rating);
    if (summative_test === null) {
      history.push("/");
    }
  };
  onSummativeQuiz = () => {
    const { handleSummativeQuizAttempt } = this.props;
    handleSummativeQuizAttempt();
  };

  render() {
    const { comment, rating } = this.state;
    const userLogin = localStorage.getItem("user");
    const {
      tutorialPageData: { title = "" },
      tutorialPageData: { id, summative_test = {} },
      gradeFeedback,
      tutorialPageData: { settings = {} },
      certificateId,
      certificate_enabled,
      updateTutorialAttemptGuestDetails,
    } = this.props;
    const isFinalQuiz = settings.hasOwnProperty("is_quiz_public");
    const doesQuizExist = summative_test?.summative_test?.length > 0 ||
              summative_test?.summative_quiz?.length > 0
    return (
      <Row>
        <div className="col-md-1"></div>
        <div className="col-12 col-md-10">
          <div className="card-1">
            {gradeFeedback && <h3>{gradeFeedback}</h3>}
            {certificate_enabled && (
              <>
              <CertificateDownload
                userLogin={userLogin}
                certificateId={certificateId}
                updateTutorialAttemptGuestDetails={
                  updateTutorialAttemptGuestDetails
                }
              />
              <hr />
              </>
            )}
            { doesQuizExist && !isFinalQuiz && (
                <div>
                  <h4>You can now take final quiz for this Tutorial</h4>
                  <a
                    className="custom-link"
                    onClick={this.onSummativeQuiz}
                    href="#"
                  >
                    {title}
                  </a>
                </div>
              )}
            <p>Please rate the tutorial!</p>
            <StarRatingComponent onRatingChange={this.onRatingChange}/>
            <h6>
              <Label>Add Comment</Label>
            </h6>
            <Input
              type="textarea"
              className="inputText"
              name="comment"
              onChange={this.handleComment}
              aria-label="Feedback for tutorial"
            />

            <Button
              type="submit"
              value={comment}
              color="primary"
              onClick={this.onSubmitComment}
              aria-label="Submit your feedback"
            >
              Submit
            </Button>
          </div>
        </div>
        <div className="col-md-1 text-center"></div>
      </Row>
    );
  }
}
