import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import "./Account-Management.scss";
import { history } from "../../../_helpers/history";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { userActions } from "../../../_actions";
import BackButton from "../../../_components/BackButton";

export default class ChangeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: {},
      errors: {},
      isOpen: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.confirmChangeEmail = this.confirmChangeEmail.bind(this);
    this.cancelChangeEmail = this.cancelChangeEmail.bind(this);
  }
  handleChange(e) {
    const { userEmail, errors } = this.state;
    const { name, value } = e.target;
    userEmail[name] = value;
    errors[name] = "";
    this.setState({
      userEmail,
      errors
    });
  }
  save(e) {
    e.preventDefault();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let { userEmail, errors } = this.state;

    if (!userEmail.email) errors["email"] = "New Email is required.";
    else if (!reg.test(userEmail.email)) errors["email"] = "Email is invalid.";

    if (!userEmail.confirmEmail)
      errors["confirmEmail"] = "Confirm Email is required.";
    else if (!reg.test(userEmail.confirmEmail))
      errors["confirmEmail"] = "Confirm Email is invalid.";
    else if (userEmail.email !== userEmail.confirmEmail)
      errors["confirmEmail"] = "New Email and confirm Email not matched.";

    this.setState({
      errors
    });

    if (!errors.email && !errors.confirmEmail) {
      this.setState({
        isOpen: true
      });
    }
  }
  confirmChangeEmail() {
    const { dispatch } = this.props;
    const { userEmail } = this.state;
    dispatch(userActions.updateUser(userEmail)).then((res, err) => {
      history.push("/");
    });
  }
  cancelChangeEmail() {
    this.setState({
      isOpen: false
    });
  }
  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    const { userEmail, errors, isOpen } = this.state;
    const { user: loginUser } = this.props;
    return (
      <div className="acc-page animated fadeIn">
        <h3>
          Account Management: <span>Change Email</span>
        </h3>
        <div className="col-lg-6 col-md-8 acc-form-wrapper">
          <div>
            <Form>
              <div className="form-data-wrapper">
                <FormGroup>
                  <Label>Current Email</Label>
                  <div>{loginUser.email}</div>
                </FormGroup>
                <FormGroup>
                  <Label>New Email</Label>
                  <InputGroup>
                    <InputGroupAddon
                      className="custom-input-icon"
                      addonType="prepend"
                    >
                      <i className="fa fa-envelope"></i>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      name="email"
                      className="left-borderless"
                      id="email"
                      placeholder="Email"
                      value={userEmail.email}
                      onChange={this.handleChange}
                    />
                    {errors.email && (
                      <div className="help-block">{errors.email}</div>
                    )}
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label>Confirm New Email</Label>
                  <InputGroup>
                    <InputGroupAddon
                      className="custom-input-icon"
                      addonType="prepend"
                    >
                      <i className="fa fa-envelope"></i>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      name="confirmEmail"
                      className="left-borderless"
                      id="confirmEmail"
                      placeholder="Email"
                      value={userEmail.confirmEmail}
                      onChange={this.handleChange}
                    />
                    {errors.confirmEmail && (
                      <div className="help-block">{errors.confirmEmail}</div>
                    )}
                  </InputGroup>
                </FormGroup>
              </div>
            </Form>
            <div className="acc-from-submition-wrapper">
              <BackButton />
              <Button color="success" className="mr-2" onClick={this.save}>
                Save
              </Button>
            </div>
          </div>
        </div>
        <Modal isOpen={isOpen} className="modal-primary">
          <ModalHeader>Email Confirmation</ModalHeader>
          <ModalBody>
            <p>
              This will not change the email of the user immediately. It will
              change the UID though. A confirmation email will be sent to the
              new email. Once the user has confirmed the email it will be
              updated.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.confirmChangeEmail}>
              Okay
            </Button>
            <Button color="secondary" onClick={this.cancelChangeEmail}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
