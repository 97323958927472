import React from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "./Account-Management.scss";
import { history } from "../../../_helpers/history";
import { userActions } from "../../../_actions";
import BackButton from "../../../_components/BackButton";

export default class ChangeName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }
  handleChange(e) {
    const { user } = this.state;
    const { name, value } = e.target;

    if (name === "avatar") {
      if (e.target.files && e.target.files[0]) {
        var FR = new FileReader();
        FR.addEventListener("load", function(e) {
          user["avatar"] = e.target.result;
        });
        FR.readAsDataURL(e.target.files[0]);
      }
    } else {
      user[name] = value;
    }

    this.setState({
      user
    });
  }

  save(e) {
    e.preventDefault();
    let { user } = this.state;
    let obj = {};

    if (user.first_name && user.first_name.length > 0)
      obj["first_name"] = user.first_name;
    if (user.last_name && user.last_name.length > 0)
      obj["last_name"] = user.last_name;
    if (user.avatar && user.avatar.length > 0) obj["avatar"] = user.avatar;

    const { dispatch } = this.props;

    if (Object.keys(obj).length > 0) dispatch(userActions.updateUser(obj));
    else history.push("/");
  }

  render() {
    let userData = JSON.parse(localStorage.getItem("user"));
    const { user, errors } = this.state;
    const { user: loginUser, isLoadingChangeName } = this.props;
    return (
      <div className="acc-page animated fadeIn">
        <h3>
          Account Management: <span>Change Profile</span>
        </h3>
        <div className="col-md-5 acc-form-wrapper">
          <div>
            <Form>
              <div className="form-data-wrapper">
                <FormGroup>
                  <Label>Current Name</Label>
                  <div>
                    {loginUser.first_name.charAt(0).toUpperCase()}
                    {loginUser.first_name.substr(1).toLowerCase()}&nbsp;
                    {loginUser.last_name.charAt(0).toUpperCase()}
                    {loginUser.last_name.substr(1).toLowerCase()}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>New Name</Label>
                  <div className="dis-flex">
                    <div className="mr-3">
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="First Name"
                        value={user.first_name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Last Name"
                        value={user.last_name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>Photo</Label>
                  <div>
                    <Input
                      type="file"
                      placeholder="avatar"
                      name={"avatar"}
                      className="account-photo-upload"
                      onChange={this.handleChange}
                    />
                  </div>
                </FormGroup>
              </div>
            </Form>
            <div className="acc-from-submition-wrapper">
              <BackButton />
              <Button color="success" className="mr-2" onClick={this.save}>
                Save
              </Button>
              {isLoadingChangeName && (
                <img
                  alt={"loadImage"}
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
