import React, { Component } from "react";
import "./tutorialManagement.scss";
import {
  Col,
  Button,
  Card,
  CardFooter,
  CardBody,
  Form,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from "classnames";

class TutorialManagement extends Component {
  render() {
    const {
      currentActiveTab,
      toggleSub,
      stateData,
      tutorial,
      addState,
      onPreview
    } = this.props;
    return (
      <div>
        <TabContent activeTab={stateData.activeTab}>
          <TabPane tabId="1">
            <Nav tabs className="innertabs" id="tut-manage-nav">
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": stateData.activeSubTab === "1"
                  })}
                  onClick={e => {
                    toggleSub("1", "link");
                  }}
                >
                  Tutorial Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": stateData.activeSubTab === "2"
                  })}
                  onClick={e => {
                    toggleSub("2", "link");
                  }}
                >
                  Tutorial Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": stateData.activeSubTab === "3"
                  })}
                  onClick={e => {
                    toggleSub("3", "link");
                  }}
                >
                  Tutorial Content
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": stateData.activeSubTab === "4"
                  })}
                  onClick={e => {
                    toggleSub("4", "link");
                  }}
                >
                  Final Quiz
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": stateData.activeSubTab === "5"
                  })}
                  onClick={e => {
                    toggleSub("5", "link");
                  }}
                >
                  Tutorial Look & Feel
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={stateData.activeSubTab}>
              <TabPane tabId={stateData.activeSubTab}>
                <Card>
                  <CardBody className="custom-card ml-0">
                    <Form
                      action=""
                      method="post"
                      encType="multipart/form-data"
                      className="form-horizontal"
                    >
                      <FormGroup className="custom-form pb-0">
                        <Col
                          md={12}
                          xs={12}
                          sm={12}
                          className="display-edit-tutorial custom-tutorial-view"
                        >
                          {currentActiveTab(tutorial)}
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Button
                      type="submit"
                      className="btn btn-primary float-right ml-3 btn-next"
                      color="success"
                      disabled={stateData.activeSubTab > 5}
                      onClick={e =>
                        toggleSub(
                          (Number(stateData.activeSubTab) + 1).toString(),
                          e.target.innerText
                        )
                      }
                    >
                      {stateData.activeSubTab === "5"
                        ? !addState
                          ? "Update"
                          : "Save"
                        : "Next"}
                    </Button>
                    {["3", "4"].includes(stateData.activeSubTab) && (
                      <Button
                        type="submit"
                        className="btn btn-primary float-right ml-3"
                        onClick={() =>
                          onPreview(
                            stateData.activeSubTab === "3"
                              ? "tutorialViewer"
                              : "QuizViewer",
                            stateData.activeSubTab,
                            "SaveAs"
                          )
                        }
                        color="primary"
                      >
                        Preview
                      </Button>
                    )}
                    {["3", "4"].includes(stateData.activeSubTab) && (
                      <Button
                        type="submit"
                        className="btn btn-primary ml-3 float-right"
                        onClick={e =>
                          toggleSub(
                            (Number(stateData.activeSubTab) + 1).toString(),
                            "SaveAs"
                          )
                        }
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className="btn btn-primary float-right"
                      onClick={e =>
                        toggleSub(
                          (Number(stateData.activeSubTab) - 1).toString(),
                          e.target.innerText
                        )
                      }
                    >
                      Previous
                    </Button>
                  </CardFooter>
                </Card>
              </TabPane>
            </TabContent>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
export default TutorialManagement;
