import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./manageHelpCenter.scss";
import BackButton from "../../../_components/BackButton";

class HelpCenterPlatform extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="title-page dis-flex">
          <h2>
            Help Center:{" "}
            <span className="sub-heading">Learn Sidecar Platform</span>
          </h2>
        </div>
        <Row>
          <Col md={12}>
            <div className="faq-scrollable-area">
              <p className="font-weight-bold">
                Consectetur adipiscing elit, sed do eiusmod tempor
              </p>
              <p>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                  aspernatur at atque cum deleniti doloribus ea eius, eveniet ex
                  id laborum, magnam magni natus optio porro tenetur unde vitae
                  voluptatem.
                </span>
                <span>
                  Animi, aspernatur aut blanditiis, commodi consectetur deleniti
                  dignissimos est illum laborum numquam officiis placeat quaerat
                  repellat reprehenderit, sapiente sit ullam vitae voluptatum!
                  At beatae consequuntur cumque eveniet maxime, quos voluptatem.
                </span>
                <span>
                  Aperiam assumenda atque distinctio, dolore ea eligendi, enim,
                  error et nihil obcaecati pariatur tempore totam veniam. A
                  delectus dolorum hic laborum minus nisi temporibus. Cupiditate
                  doloribus nemo non odio vitae.
                </span>
              </p>
              <a href="#">Launch Tutorial ></a>
            </div>
            <div className="save-cancel-btn faq-save-btn">
              <BackButton />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default HelpCenterPlatform;
