import { tutorialConstants } from "../../_constants/index";
import { tutorialService } from "../../_services/index";
import { toastConstants } from "../../_constants/toast.constants";

export const tutorialActions = {
  getTutorialData,
  clearTutorialData,
  submitReviewRate,
  storePrivateRoute,
  addCollaboratorToTutorial,
  removeCollaboratorFromTutorial,
  storeChannelInRedux,
  someoneIsEditingTutorial
};

function someoneIsEditingTutorial({ editingUserEmail, currentUserEmail }) {
  let message;
  if (editingUserEmail === currentUserEmail)
    message = "You are editing the tutorial in another window.";
  else
    message = `${editingUserEmail} from your organization is editing the tutorial at the moment!`;
  return dispatch => {
    dispatch({
      type: toastConstants.SHOW_TOAST,
      message: {
        type: "ERROR",
        data: message,
        autoClose: 7000
      }
    });
  };
}

function storeChannelInRedux(channel) {
  return dispatch => {
    dispatch({
      type: tutorialConstants.STORE_CHANNEL_IN_REDUX,
      channel
    });
  };
}

function removeCollaboratorFromTutorial({ tutorialId, userId }) {
  return dispatch => {
    dispatch({
      type: tutorialConstants.REMOVE_COLLABORATOR_FROM_TUTORIAL_REQUEST
    });
    tutorialService.removeCollaborator({ tutorialId, userId }).then(
      data => {
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: {
            type: "ERROR",
            data: "Something went wrong. Couldn't change the role"
          }
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return {
      type: tutorialConstants.REMOVE_COLLABORATOR_FROM_TUTORIAL_SUCCESS,
      data
    };
  }

  function failure(error) {
    return {
      type: tutorialConstants.REMOVE_COLLABORATOR_FROM_TUTORIAL_FAILURE,
      error
    };
  }
}

function addCollaboratorToTutorial({ tutorialId, userId }) {
  return dispatch => {
    dispatch({
      type: tutorialConstants.ADD_COLLABORATOR_TO_TUTORIAL_REQUEST
    });
    tutorialService.addCollaborator({ tutorialId, userId }).then(
      data => {
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: {
            type: "ERROR",
            data: "Something went wrong. Couldn't change the role"
          }
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return {
      type: tutorialConstants.ADD_COLLABORATOR_TO_TUTORIAL_SUCCESS,
      data
    };
  }

  function failure(error) {
    return {
      type: tutorialConstants.ADD_COLLABORATOR_TO_TUTORIAL_FAILURE,
      error
    };
  }
}

function getTutorialData(id, authorized) {
  return dispatch => {
    return tutorialService.getAllTutorial(id, authorized).then(
      data => {
        return dispatch(success(data));
      },
      error => {
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: tutorialConstants.GETALL_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: tutorialConstants.GETALL_TUTORIAL_FAILURE, error };
  }
}

function clearTutorialData() {
  return {
    type: tutorialConstants.CLEAR_TUTORIAL
  };
}

function submitReviewRate(obj, id) {
  return dispatch => {
    tutorialService.rateTutorial(obj, id).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Review Saved !" }
        });
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: {
            type: "ERROR",
            data:
              "You didn't leave a review, if you don't wish to review the tutorial, select the 'close tutorial'."
          }
        });
      }
    );
  };
}

function storePrivateRoute(path) {
  return dispatch => {
    return dispatch({ type: "STORE_PRIVATE_PATH", data: path });
  };
}
