import React, { Component } from "react";
import { Router, Route, NavLink, Link } from "react-router-dom";
import HeaderDropdown from "../../components/Header/HeaderDropdown";
import HeaderDropdownAccount from "../../components/Header/HeaderDropdownAccount";
import NoUserImage from "../../../public/img/avatars/no-user.png";
import "./SidebarHeader.scss";
import {
  Badge,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { userActions } from "../../_actions";
import { messageAction } from "./../../_actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { history } from "../../_helpers/history";

class SidebarHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {
      dropdownOpen: { test: false }
    };
    this.switchToRole = this.switchToRole.bind(this);
  }
  componentDidMount() {
    this.props.getMessages();
  }
  toggle(name) {
    this.setState(prevState => ({
      dropdownOpen: {
        ...this.state.dropdownOpen,
        [name]: !this.state.dropdownOpen[name]
      }
    }));
  }
  handleLogout() {
    this.props.setLogout();
  }

  switchToRole(role) {
    this.props.changeRole(role);
  }

  render() {
    let user =
      JSON.parse(localStorage.getItem("user")) &&
      JSON.parse(localStorage.getItem("user"));
    const { user: loginUser = {}, unReadMessage } = this.props;
    const {
      avatar: { url = "" } = {},
      first_name = "",
      last_name = ""
    } = loginUser;
    return (
      <div className="sidebar-header">
        {loginUser && loginUser.avatar && loginUser.avatar.url ? (
          <img
            src={url}
            className="img-avatar"
            alt="Avatar"
            onError={e => {
              e.target.src = NoUserImage;
            }}
          />
        ) : (
          <img src={NoUserImage} className="img-avatar" alt="Avatar" />
        )}
        <div>
          <strong>
            {loginUser && first_name.toUpperCase()}&nbsp;
            {last_name.toUpperCase()}
          </strong>
        </div>
        <div className="text-muted">
          {user.roles.map((r, i) => {
            return (
              <small key={i}>
                {localStorage.getItem("defaultRole") === r.name ? (
                  <b>
                    {r.name === "full_content_creator" ||
                    r.name === "basic_content_creator" ||
                    r.name === "individual_content_creator"
                      ? "Instructor"
                      : r.name
                          .split("_")
                          .join(" ")
                          .charAt(0)
                          .toUpperCase() +
                        r.name
                          .split("_")
                          .join(" ")
                          .slice(1)}
                  </b>
                ) : r.name === "full_content_creator" ||
                  r.name === "basic_content_creator" ||
                  r.name === "individual_content_creator" ? (
                  "Instructor"
                ) : (
                  r.name
                    .split("_")
                    .join(" ")
                    .charAt(0)
                    .toUpperCase() +
                  r.name
                    .split("_")
                    .join(" ")
                    .slice(1)
                )}
                {user.roles.length > 1 && user.roles.length !== i + 1 && ` & `}
              </small>
            );
          })}
        </div>
        <div
          className="btn-group"
          role="group"
          aria-label="Button group with nested dropdown"
        >
          <HeaderDropdownAccount logoutmethod={this.props.actionMethods} />

          <ButtonDropdown
            nav
            className=" user-profile-btn-dropdown"
            direction="up"
            isOpen={this.state.dropdownOpen["msg"]}
            toggle={() => this.toggle("msg")}
          >
            <button
              type="button"
              className="btn btn-link button-bell-badge"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <DropdownToggle left className="user-profile-dropdown">
                <i className="icon-bell">
                  <Badge className="badge-notification" pill color="danger">
                    {unReadMessage}
                  </Badge>
                </i>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem>
                  <Link to="/notification">Messages</Link>
                </DropdownItem>
              </DropdownMenu>
            </button>
          </ButtonDropdown>
          <ButtonDropdown
            nav
            className=" user-profile-btn-dropdown"
            direction="up"
            isOpen={this.state.dropdownOpen["logout"]}
            toggle={() => this.toggle("logout")}
          >
            <DropdownToggle
              left
              className="user-profile-dropdown"
              aria-label={"Role Management"}
            >
              <i className="fa fa-cog" aria-hidden="true" />
            </DropdownToggle>
            {user.roles.length > 1 ? (
              <DropdownMenu left>
                {user.roles.map((r, i) => {
                  return (
                    <DropdownItem
                      key={i}
                      onClick={() => {
                        this.switchToRole(r.name);
                      }}
                    >
                      {r.name === "full_content_creator" ||
                      r.name === "basic_content_creator" ||
                      r.name === "individual_content_creator"
                        ? "Instructor"
                        : r.name
                            .split("_")
                            .join(" ")
                            .charAt(0)
                            .toUpperCase() +
                          r.name
                            .split("_")
                            .join(" ")
                            .slice(1)}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            ) : (
              <DropdownMenu left>
                {
                  <DropdownItem
                    onClick={this.handleLogout}
                    tag="a"
                    href="/login"
                  >
                    Logout
                  </DropdownItem>
                }
              </DropdownMenu>
            )}
          </ButtonDropdown>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    messageData: state.message.messageData,
    unReadMessage: state.message.countUnreadMessages
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLogout: userActions.logout,
      changeRole: userActions.changeRole,
      getMessages: messageAction.getMessageData
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
