import React from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";

function RadioInput(props) {
  const { opt, index, ques, handleQuizSelection } = props;

  return (
    <div className="form-single-choice mb-2" id={index} key={index}>
      <FormGroup check className="radio">
        <Label check aria-labelledby={index}>
          <Input
            className="form-check-input"
            type="radio"
            id={index}
            name={`single_choice_${ques.id}`}
            value={opt}
            onChange={e =>
              handleQuizSelection(ques.id, ques.type, e)
            }
            checked={
              ques.selectedValue &&
              ques.selectedValue[0] === opt
            }
          />
          {opt}
        </Label>
      </FormGroup>
    </div>
  )
}

export default function SingleChoiceQuestion(props) {
  const {
    index,
    renderHTML,
    ques,
    buttonName,
    handleQuizSelection,
    handleCheckAnswer,
    quizData
  } = props;

  const radioInputs = ques.options.map((opt, index) =>
    <RadioInput
      ques={ques}
      opt={opt}
      index={index}
      handleQuizSelection={handleQuizSelection}
    />
  )
  
  return (
    <div id={index} className=" form-radio quiz-question">
      <fieldset>
        <legend>{renderHTML(`Q. ${ques.text}`)}</legend>

        {radioInputs}

        <Button
          className="btn-text mt-4"
          aria-label="Submit to answer the quiz question"
          onClick={() =>
            handleCheckAnswer(
              ques.selectedValue,
              quizData.id,
              ques.id,
              ques.correct_answer_feedback,
              ques.wrong_answer_feedback
            )
          }
        >
          {buttonName}
        </Button>
      </fieldset>
    </div>
  )
}
