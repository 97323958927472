import { toastConstants } from "../_constants/toast.constants";

export const FileActions = {
  showFileMessage,
  showFileContentMsg
};

function showFileMessage(pageName) {
  return dispatch => {
    if (pageName === "user")
      dispatch({
        type: toastConstants.SHOW_TOAST,
        message: {
          type: "ERROR",
          data: "Please select a file and atleast a role "
        }
      });

    if (pageName === "learner")
      dispatch({
        type: toastConstants.SHOW_TOAST,
        message: {
          type: "ERROR",
          data: "Please select a file and atleast a course "
        }
      });

    if (pageName === "learner-course")
      dispatch({
        type: toastConstants.SHOW_TOAST,
        message: {
          type: "ERROR",
          data: "Please select a learner and atleast a course "
        }
      });
    if (pageName === "course-management")
      dispatch({
        type: toastConstants.SHOW_TOAST,
        message: {
          type: "ERROR",
          data: "Please select a Tutorial and atleast a course "
        }
      });
  };
}
function showFileContentMsg() {
  return dispatch => {
    dispatch({
      type: toastConstants.SHOW_TOAST,
      message: {
        type: "ERROR",
        data: "Please check for the Missing fields in selected file."
      }
    });
  };
}
