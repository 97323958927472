import React from "react";
import { Router, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../_helpers";
import { alertActions, userActions } from "../_actions";
import { PublicRoute, Authorized } from "../_components";
import { HomePage } from "../HomePage";
import { LoginPage } from "../LoginPage";
import { RegisterPage } from "../RegisterPage";
import { ThankyouPage } from "../ThankYouPage";
import { ResetPasswordPage, ConfirmPasswordPage } from "../ResetPasswordPage";
import { CreateTutorial } from "../containers/CreateTutorial";
import { Roles } from "../containers/RoleManagement";
import { AdminDashboard } from "../containers/AdminDashboard";
import { Credentials } from "../containers/CredentialsManagement";
import { Course } from "../containers/CourseManagement";
import { LearnerManagementContainer } from "../containers/LearnerManagement";
import { TutorialLookFeel } from "../containers/TutorialLookFeel";
import { ImportTutorialContainer } from "../containers/ImportTutorials/importTutorials";

import {
  TutorialManagement,
  EditTutorialPage
} from "../containers/tutotrial_management";

import Header from "../components/Header/Header";
import Notification from "../components/Header/notification";

import authorize from "./authorize";
import Sidebar from "../components/Sidebar/";
import { DashboardLearner } from "../containers/Learner/DashboardLearner/DashboardLearner";
import { HelpCenterContainer } from "../containers/Learner/HelpCenter/index";
import { QuizContainer } from "../containers/Learner/QuizContainer/QuizContainer";
import { TutorialContainer } from "../containers/Learner/Tutorial/TutorialContainer";
import { AccountManagementContainer } from "../containers/AccountManagement/AccountManagementContainer";
import MyProfileContainer from "../containers/AccountManagement/MyProfileContainer";
import FreeCurriculumCreatorContainer from "../containers/AccountManagement/FreeCurriculumCreatorContainer";
import ToastNotify from "../_components/toastNotify";
import { Table_of_Content_Container } from "../containers/Table_Of_Content/Table_of_Content_Container";
import { SideCarHome } from "../containers/Home";
import PublicTutorial from "../components/Learner/Tutorial-Page/publicTutorial";
import LegalPage from "../components/HomePage/LegalPage";
import "./App.scss";
import EditorTest from "../components/editorTest";
import NativeOrWeb from "../components/NativeOrWeb/NativeOrWeb";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarHidden: ""
    };
    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(alertActions.clear());
    });
    this.setPageAndSendToGA = this.setPageAndSendToGA.bind(this);
    this.handleSidebarState = this.handleSidebarState.bind(this);
  }
  componentDidMount() {
    // window.ga('create', 'UA-114414918-1', 'auto');
    if (window.ga) {
      let location = this.props.location;
      let url = location.pathname;
      this.setPageAndSendToGA(url);

      if (location.search.length === 10) {
        let url = location.pathname + location.search;
        this.setPageAndSendToGA(url);
      }
    }
    const { dispatch } = this.props;
    let uid = localStorage.getItem("headerJson")
      ? JSON.parse(localStorage.getItem("headerJson")).Uid
      : "";
    let client = localStorage.getItem("headerJson")
      ? JSON.parse(localStorage.getItem("headerJson")).Client
      : "";
    let token = localStorage.getItem("headerJson")
      ? JSON.parse(localStorage.getItem("headerJson"))["Access-Token"]
      : "";
    dispatch(userActions.getById(uid, client, token));
  }

  setPageAndSendToGA(url) {
    window.ga("set", "page", url);
    window.ga("send", "pageview");
  }

  handleSidebarState() {
    this.setState({ sidebarHidden: document.body.classList.value });
  }

  render() {
    const { alert, user } = this.props;
    const userLogin = localStorage.getItem("user");
    const defaultRole = localStorage.getItem("defaultRole");
    const userRole = defaultRole && defaultRole;
    let dashboardName = "";
    let homeComponent;
    switch (userRole) {
      case "learner":
        homeComponent = DashboardLearner;
        dashboardName = "Learner Dashboard";
        break;
      case "org_administrator":
        homeComponent = AdminDashboard;
        dashboardName = "Admin Dashboard";
        break;
      case "full_content_creator":
        homeComponent = AdminDashboard;
        dashboardName = "Content Creator Dashboard";
        break;
      case "basic_content_creator":
        homeComponent = AdminDashboard;
        dashboardName = "Content Creator Dashboard";
        break;
      case "individual_content_creator":
        homeComponent = AdminDashboard;
        dashboardName = "Content Creator Dashboard";
        break;
      case "licensee":
        homeComponent = AdminDashboard;
        dashboardName = "Licensee Dashboard";
        break;
      default:
        homeComponent = HomePage;
        dashboardName = "Home Page";
        break;
    }
    let visibleSideBar =
      this.props.location && this.props.location.pathname.split("/")[3];
    return (
      <div className="mainApp">
        <ToastNotify />
        <div className="app">
          <Header
            isUserLogin={userLogin ? true : false}
            actionMethods={userActions.logout}
            handleSidebarState={this.handleSidebarState}
          />
          <div className="app-body">
            {userLogin !== null && (
              <Sidebar
                {...this.props}
                sidebarHidden={this.state.sidebarHidden}
              />
            )}
            <main className="main">
              <div className="app flex-row align-items-center">
                <div className="container-fluid">
                  {alert.message && (
                    <div className={`alert ${alert.type}`}>
                      <p>{alert.message}</p>
                    </div>
                  )}
                  <Router history={history}>
                    <div className="main-wrap-login">
                      <h1 className={"header-name"}>{dashboardName}</h1>
                      <PublicRoute exact path="/home" component={SideCarHome} />
                      <PublicRoute
                        exact
                        path="/agreement"
                        component={LegalPage}
                      />
                      <PublicRoute exact path="/login" component={LoginPage} />
                      <PublicRoute
                        exact
                        path="/register"
                        component={RegisterPage}
                      />
                      <PublicRoute
                        exact
                        path="/thank-you"
                        component={ThankyouPage}
                      />
                      <PublicRoute
                        exact
                        path="/reset-password"
                        component={ResetPasswordPage}
                      />
                      <PublicRoute
                        exact
                        path="/editorTest"
                        component={EditorTest}
                      />
                      <Route
                        exact
                        path="/confirm-password"
                        component={ConfirmPasswordPage}
                      />
                      <Authorized
                        exact
                        path="/create-tutorial"
                        component={CreateTutorial}
                        allowed={
                          userLogin && [
                            "full_content_creator",
                            "basic_content_creator",
                            "individual_content_creator"
                          ]
                        }
                      />
                      <Authorized
                        exact
                        path="/role-management"
                        component={Roles}
                        allowed={userLogin && ["org_administrator", "licensee"]}
                      />
                      <Authorized
                        path="/credentials-management"
                        component={Credentials}
                        allowed={userLogin && ["org_administrator", "licensee"]}
                      />
                      <Authorized
                        path="/course-management"
                        component={Course}
                        allowed={userLogin && ["full_content_creator"]}
                      />
                      <Authorized
                        path="/learner-management"
                        component={LearnerManagementContainer}
                        allowed={
                          userLogin && [
                            "org_administrator",
                            "full_content_creator",
                            "licensee"
                          ]
                        }
                      />
                      <Authorized
                        exact
                        path="/tutorial-look-feel"
                        component={TutorialLookFeel}
                        allowed={
                          userLogin && [
                            "org_administrator",
                            "full_content_creator",
                            "licensee"
                          ]
                        }
                      />
                      <Authorized
                        exact
                        path="/import-tutorials"
                        component={ImportTutorialContainer}
                        allowed={
                          userLogin && [
                            "org_administrator",
                            "licensee",
                            "full_content_creator"
                          ]
                        }
                      />
                      <Authorized
                        exact
                        path="/"
                        component={homeComponent}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Authorized
                        exact
                        path="/tutorial-table-of-content/:id/:page"
                        component={Table_of_Content_Container}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />

                      <Authorized
                        exact
                        path="/tutorial-page/:id/authorized/:page"
                        component={TutorialContainer}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Route
                        exact
                        path="/tutorial-page/:id/:page"
                        component={TutorialContainer}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Route
                        exact
                        path="/public-tutorial/:id"
                        component={PublicTutorial}
                      />
                      <Route
                        exact
                        path="/tutorial-page/:id/:page/public"
                        component={TutorialContainer}
                      />

                      <Authorized
                        exact
                        path="/help-center"
                        component={HelpCenterContainer}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Authorized
                        exact
                        path="/tutorial-management"
                        component={TutorialManagement}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Authorized
                        path="/tutorial-management/edit/:id"
                        component={EditTutorialPage}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Authorized
                        path="/account-management"
                        component={AccountManagementContainer}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Authorized
                        exact
                        path="/my-profile"
                        component={MyProfileContainer}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Authorized
                        exact
                        path="/free-curriculum-creator"
                        component={FreeCurriculumCreatorContainer}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Authorized
                        exact
                        path="/notification"
                        component={Notification}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Route
                        exact
                        path="/web/tutorials/:id"
                        component={TutorialContainer}
                      />
                      <Authorized
                        exact
                        path="/tutorial/:id"
                        component={TutorialContainer}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                      <Route
                        exact
                        path="/tutorials/:id"
                        component={NativeOrWeb}
                        allowed={userLogin && authorize.roles.map(e => e.name)}
                      />
                    </div>
                  </Router>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    alert,
    authentication: { user = {}, DefaultRole = "" }
  } = state;
  return {
    alert,
    state
  };
}

const connectedApp = withRouter(connect(mapStateToProps)(App));
export { connectedApp as App };
