import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
  Nav,
  Tooltip,
  NavItem,
  NavbarToggler,
  NavbarBrand,
  NavLink
} from "reactstrap";
import { userActions } from "../../_actions";
import HeaderDropdown from "./HeaderDropdown";
import HeaderDropdownAccount from "./HeaderDropdownAccount";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./Header.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
    this.setRole = this.setRole.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.sidebarToggle = this.sidebarToggle.bind(this); //add as not found this in function
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      routing: { location },
      handleSidebarState
    } = nextProps;
    const routeName = ((location && location.pathname) || "").split("/")[4];
    if (
      routeName === "QuizViewer" ||
      (location && location.pathname && location.pathname === "/")
    ) {
      document.body.classList.toggle("sidebar-hidden", false);
      handleSidebarState();
    } else if (
      (location && location.pathname && location.pathname === "/login") ||
      (location &&
        location.pathname &&
        location.pathname == "/reset-password") ||
      (location && location.pathname && location.pathname == "/register") ||
      location === null
    ) {
      document.body.classList.toggle("sidebar-hidden", false);
      handleSidebarState();
    } else {
      document.body.classList.toggle("sidebar-hidden", true);
      handleSidebarState();
    }
  }
  sidebarToggle(e) {
    e.preventDefault();
    const { handleSidebarState } = this.props;
    document.body.classList.toggle("sidebar-hidden");
    handleSidebarState();
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  }
  setRole(role) {
    this.props.setUserRole(role);
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  render() {
    const userLogin = localStorage.getItem("user");
    const data_login = <Nav className="d-md-down-none" navbar></Nav>;
    const dropdown = (
      <Nav className="ml-auto" navbar>
        {/*<HeaderDropdown notif setRole={this.setRole}/>*/}
        {/*<HeaderDropdownAccount logoutmethod={this.props.actionMethods}/>*/}
      </Nav>
    );
    return (
      <header className="app-header navbar border-navbar">
        {this.props.isUserLogin && (
          <NavbarToggler
            className="d-lg-none"
            onClick={this.mobileSidebarToggle}
            aria-label={"Home Button"}
          >
            {/*<span className="navbar-toggler-icon visuallyhidden">sidecar</span>*/}
          </NavbarToggler>
        )}

        <NavbarBrand href="/">
          <span className="visuallyhidden">sidecar</span>
        </NavbarBrand>
        {this.props.isUserLogin && (
          <NavbarToggler
            className="d-md-down-none"
            id="tooltipToggle"
            onClick={this.sidebarToggle}
          >
            <span className="navbar-toggler-icon">
              <span className="visuallyhidden">
                <img alt={"sdg"} src={"."} />
                Sidecar Toggler
              </span>
            </span>
            <Tooltip
              placement="bottom"
              isOpen={this.state.tooltipOpen}
              target="tooltipToggle"
              toggle={this.toggleTooltip}
            >
              Open/Close Navigation
            </Tooltip>
          </NavbarToggler>
        )}

        {this.props.isUserLogin && (
          <div className="custom-breadcrumb">
            <Breadcrumb />
          </div>
        )}
        {userLogin != null && data_login}
        {this.props.isUserLogin && dropdown}
      </header>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { setUserRole: userActions.setDefaultRole },
    dispatch
  );
};

function mapStateToProps(state) {
  return {
    menuToggler: state.menuToggler,
    routing: state.routing
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
