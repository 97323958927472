import React, { Component } from "react";
import { Collapse, Button } from "reactstrap";

import "./tutorialManagement.scss";

class ChapterSideBar extends Component {
  constructor() {
    super();
    this.state = {
      collapseTutorial: { test: false },
      collapseCourses: { test: false }
    };

    this.onCollapseTutorial = this.onCollapseTutorial.bind(this);
    this.onCollapseCourses = this.onCollapseCourses.bind(this);
  }

  createTutorialTitle(tutorial) {
    const resourceIds = tutorial.roles.map(role => role.resource_id);
    const isShared = resourceIds.includes(
      this.props.user && this.props.user.id
    );
    return (
      <div>
        {isShared && <i className="fa fa-users"></i>}
        Tutorial: {tutorial.title}
        {isShared && " (Shared)"}
      </div>
    );
  }

  onCollapseTutorial(e) {
    this.setState({
      collapseTutorial: {
        ...this.state.collapseTutorial,
        [e]: !this.state.collapseTutorial[e]
      }
    });
  }

  onCollapseCourses(e) {
    this.setState({
      collapseCourses: {
        ...this.state.collapseCourses,
        [e]: !this.state.collapseCourses[e]
      }
    });
  }

  render() {
    const {
      setIndex,
      tutorialData,
      isSearch,
      searchText,
      openSearch,
      clearSearch,
      search,
      searchResult
    } = this.props;
    const { collapseCourses } = this.state;
    return (
      <div className="custom-border manage-scroll">
        <div className="p-2 title-manage-tutorial bg-dark-theme-blue dis-flex">
          <div className="tut-sidebar-main-head w-100">
            Manage Tutorials
            {!isSearch && (
              <i
                className="fa fa-search float-right mt-1"
                onClick={openSearch}
              />
            )}
          </div>
        </div>
        {isSearch && (
          <div className="tutorial-search-box animated fadeIn">
            <input
              type="textbox"
              autoFocus={true}
              value={searchText}
              placeholder="Search Text"
              onChange={search}
            />
            <i className="fa fa-close" onClick={clearSearch} />
          </div>
        )}
        {tutorialData && (
          <div className="tutorial-list-wrapper">
            {tutorialData &&
              tutorialData.map((d, key) => {
                return (
                  <Collapse
                    isOpen={true}
                    className="tutorial-list-scroll"
                    id={key}
                    key={key}
                  >
                    <div
                      className="p-2 custom-border dis-flex tut-manage-list"
                      id={key}
                      onClick={() => {
                        setIndex(d.id);
                      }}
                    >
                      {/*{collapseCourses[d.id] ? <div><i className="fa fa-chevron-down pr-1" onClick={()=>this.onCollapseCourses(d.id)}/></div>*/}
                      {/*: <div><i className="fa fa-chevron-right pr-1" onClick={()=>this.onCollapseCourses(d.id)} /></div>*/}
                      {/*}*/}
                      <div>{this.createTutorialTitle(d)}</div>
                    </div>
                  </Collapse>
                );
              })}
            {tutorialData.length === 0 && (
              <p className="ml-2">{searchResult}</p>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default ChapterSideBar;
