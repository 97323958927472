import {
  userConstants,
  accountConstants,
  tutorialLookFeelConstants
} from "../_constants";
let user =
  localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? {
      DefaultRole: localStorage.getItem("defaultRole"),
      loggedIn: true,
      user,
      errorData: [],
      tutorial: {}
    }
  : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user
      };

    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        DefaultRole: action.user.main_role
      };

    case userConstants.DEFAULT_ROLE:
      return {
        ...state,
        DefaultRole: action.payload
      };

    case userConstants.LOGIN_FAILURE:
      return {};

    case userConstants.GET_USER_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.data.data.data));
      return { ...state, user: action.data.data.data };

    case userConstants.GET_USER_FAILURE:
      return { ...state };

    case userConstants.CHANGE_NAME_REQUEST:
      return { ...state, isLoadingChangeName: true };

    case userConstants.CHANGE_NAME_SUCCESS:
      return {
        ...state,
        user: action.data.data.data,
        isLoadingChangeName: false
      };

    case userConstants.CHANGE_NAME_FAILURE:
      return { ...state };

    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return { ...state, errorData: [] };

    case userConstants.CHANGE_PASSWORD_FAILURE:
      return { ...state, errorData: action.error.errors.full_messages };

    case tutorialLookFeelConstants.SAVE_TUTORIAL_LOOK_FEEL_SUCCESS:
      const user = action.data.data.data;
      localStorage.setItem("user", JSON.stringify(user));
      return { ...state, user };

    case tutorialLookFeelConstants.SAVE_TUTORIAL_LOOK_FEEL_FAILURE:
      return { ...state };

    case userConstants.LOGOUT:
      return {};

    case "GET_LOGIN_TUTORIAL_SUCCESS":
      return { ...state, tutorial: action.data };

    case "GET_LOGIN_TUTORIAL_FAILURE":
      return { ...state, tutorial: {} };

    default:
      return { ...state };
  }
}
