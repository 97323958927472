import { connect } from "react-redux";
import React, { Component } from "react";
import { userActions, contentCreatorActions } from "../../_actions";
import { EditTutorialPage } from "../tutotrial_management/editTutorialPage";
class CreateTutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      activeSubTab: "1",
      showChapter: false,
      tutorialDetail: {},
      enableTabs: false
    };
  }
  onEnableTabs = () => {
    this.setState({ enableTabs: !this.state.enableTabs });
  }

  componentDidMount() {
    this.props.dispatch(contentCreatorActions.getCoursesData());
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        tutorialDetail: tab
      });
    }
  }

  toggleSub = (tab, data) => {
    if (this.state.activeSubTab !== tab) {
      this.setState({
        activeSubTab: tab,
        tutorialDetail: data
      });
    }
  }
  render() {
    const {
      CurriculumTutorial: { errors = [] } = {},
      location: { state }
    } = this.props;
    return (
      <EditTutorialPage
        createTutorial={true}
        toggleSub={this.toggleSub}
        stateData={this.state}
        toggle={this.toggle}
        onSubmit={this.onSubmit}
        tutorialDetail={this.state.tutorialDetail}
        courses={this.props.Tutorialcourses}
        errors={errors && errors}
        onEnableTabs={this.onEnableTabs}
        currentActiveTab={this.currentActiveTab}
        addState={true}
        edit={true}
        state={state}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    users,
    authentication: { user },
    Tutorialcourses,
    CurriculumTutorial
  } = state;
  return {
    user,
    users,
    Tutorialcourses,
    CurriculumTutorial
  };
}

const connectedCreateTutorial = connect(mapStateToProps)(CreateTutorial);
export { connectedCreateTutorial as CreateTutorial };
