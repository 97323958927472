export const AttempTutorialConstants = {
  UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS:
    "UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS",
  UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS_SUCCESS:
    "UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS_SUCCESS",
  UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS_FAILURE:
    "UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS_FAILURE",
  ATTEMPT_TUTORIAL: "ATTEMPT_TUTORIAL",
  ATTEMPT_TUTORIAL_SUCCESS: "ATTEMPT_TUTORIAL_SUCCESS",
  ATTEMPT_TUTORIAL_FAILURE: "ATTEMPT_TUTORIAL_FAILURE",
  FINISH_TUTORIAL: "FINISH_TUTORIAL",
  FINISH_TUTORIAL_SUCCESS: "FINISH_TUTORIAL_SUCCESS",
  FINISH_TUTORIAL_FAILURE: "FINISH_TUTORIAL_FAILURE"
};
