import axiosI from "../../_helpers/service";
export const credentialsService = {
  getCredentials,
  deleteUser,
  addUser,
  editUser
};

function getCredentials() {
  return axiosI.get("/roles").then(response => {
    return response;
  });
}
function deleteUser(obj) {
  return axiosI.post(`/roles/remove`, obj).then(response => {
    return response;
  });
}
function addUser(obj) {
  return axiosI.post(`/users`, obj).then(response => {
    return response;
  });
}
function editUser(id, obj) {
  return axiosI.patch(`/users/${id}`, obj).then(response => {
    return response;
  });
}
