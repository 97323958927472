import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./ResetPassword.scss";

import {
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup
} from "reactstrap";

import { userActions } from "../_actions";
class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      submitted: false,
      errors: {}
    };
  }

  handleChange = e => {
    this.setState({ submitted: false });
    const { errors } = this.state;
    const { name, value } = e.target;
    errors[name] = "";
    this.setState({
      email: value,
      errors
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, errors } = this.state;
    const { dispatch } = this.props;

    if (!email) errors["email"] = " Email is required.";
    else {
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/confirm-password";
      dispatch(userActions.resetPassword({ email, redirect_url: url })).then(
        this.setState({ email: "", submitted: false })
      );
    }
  };

  render() {
    const { email, errors, submitted } = this.state;
    return (
      <div className="">
        <div className="forgot-page">
          <Row className="justify-content-center">
            <div className="col-md-12">
              <CardGroup className="mb-4">
                <Card className="p-sm-4">
                  <CardBody>
                    <h1>Forgot Your Password ?</h1>
                    <p className="text-muted">
                      Please enter your tpo receive the reset password link.
                    </p>
                    <form name="form" onSubmit={this.handleSubmit}>
                      <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-user"></i>
                          </span>
                        </div>
                        <Input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={email}
                          autoFocus
                          onChange={this.handleChange}
                        />
                        {errors.email && (
                          <div className="help-block">{errors.email}</div>
                        )}
                      </InputGroup>
                      <Col xs="12" className="text-center mb-4">
                        <Button
                          color="primary"
                          className="px-4"
                          disabled={submitted}
                        >
                          Send Password Reset Link
                        </Button>
                      </Col>
                      <Row>
                        <Col xs="12" className="text-center">
                          <Link to="/login">Login</Link>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}

const connectedResetPasswordPage = connect(mapStateToProps)(ResetPasswordPage);
export { connectedResetPasswordPage as ResetPasswordPage };
