import axiosI from "../../_helpers/service";

export const learnerDashboardService = {
  getAssignedTutorials,
  getLearnerDashboard
};
function getAssignedTutorials() {
  return axiosI.get("/tutorials.json").then(response => {
    return response;
  });
}

function getLearnerDashboard() {
  return axiosI.get("dashboard/learner").then(response => {
    return response;
  });
}
