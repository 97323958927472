export const messageConstants = {
  GET_MESSAGES_REQUEST: "GET_MESSAGES_REQUEST",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  GET_MESSAGES_FAILURE: "GET_MESSAGES_FAILURE",
  DELETE_MESSAGE_SUCCESS: "DELETE_MESSAGE_SUCCESS",
  DELETE_MESSAGE_FAILURE: "DELETE_MESSAGE_FAILURE",
  READ_MESSAGE_SUCCESS: "READ_MESSAGE_SUCCESS",
  READ_MESSAGE_FAILURE: "READ_MESSAGE_FAILURE",
  READ_ALL_MESSAGE_REQUEST: "READ_ALL_MESSAGE_REQUEST",
  READ_ALL_MESSAGE_SUCCESS: "READ_ALL_MESSAGE_SUCCESS",
  READ_ALL_MESSAGE_FAILURE: "READ_ALL_MESSAGE_FAILURE"
};
