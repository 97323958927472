import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup
} from "reactstrap";
import { getURLParameters } from "../_helpers/getQueryString";
import { userActions } from "../_actions";
import "./ConfirmPasswordPage.scss";

class ConfirmPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      obj: {},
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { obj, errors } = this.state;
    const { name, value } = e.target;
    obj[name] = value;
    errors[name] = "";
    this.setState({
      obj,
      errors
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let { obj, errors } = this.state;

    if (!obj.password) errors["password"] = "New Password is required.";

    if (!obj.password_confirmation)
      errors["password_confirmation"] = "Confirm Password is required.";
    else if (obj.password !== obj.password_confirmation)
      errors["password_confirmation"] =
        "New Password and confirm Password not matched.";

    this.setState({
      errors
    });

    if (!errors.password && !errors.password_confirmation) {
      let output = getURLParameters(window.location.href);
      let headers = {
        "content-type": "application/json",
        "access-token": output["access-token"],
        "token-type": "Bearer",
        client: output["client"],
        uid: output.uid.replace("%40", "@")
      };
      const { dispatch } = this.props;
      dispatch(userActions.confirmChangePassword(headers, obj));
    }
  }
  render() {
    const { obj, errors } = this.state;
    return (
      <div className="animated fadeIn margin-minus-left-confirm-password">
        <div className="middlebx-confirm">
          <Row>
            <Col md="12" sm="12" xs="12" lg="12">
              <CardGroup className="mb-4">
                <Card className="p-sm-4">
                  <CardBody>
                    <h1 className="text-center">Reset your password</h1>
                    <p className="text-muted text-center">
                      Enter new password here.
                    </p>
                    <form name="form">
                      <InputGroup className="mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                        </div>
                        <Input
                          type="password"
                          placeholder="New Password"
                          name="password"
                          value={obj.password}
                          onChange={this.handleChange}
                        />
                        {errors.password && (
                          <div className="help-block">{errors.password}</div>
                        )}
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                        </div>
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          name="password_confirmation"
                          value={obj.password_confirmation}
                          onChange={this.handleChange}
                        />
                        {errors.password_confirmation && (
                          <div className="help-block">
                            {errors.password_confirmation}
                          </div>
                        )}
                      </InputGroup>
                      <Col xs="12" className="text-center mb-4">
                        <Button
                          color="primary"
                          className="px-4"
                          onClick={this.handleSubmit}
                        >
                          Save
                        </Button>
                      </Col>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const connectedConfirmPasswordPage = connect(null)(ConfirmPasswordPage);
export { connectedConfirmPasswordPage as ConfirmPasswordPage };
