import React, { Component } from "react";
import "./Tutorial.scss";
import TutorialLevel from "./TutorialLevel";
import TutorialUrlPage from "./TutorialUrlPage";
import { isElectron } from "../../../utils";

class Tutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      panels: [330, 990]
    };
  }

  componentDidMount() {
    document.addEventListener("mousemove", this.resizePanel);
    document.addEventListener("mouseup", this.stopResize);
    document.addEventListener("mouseleave", this.stopResize);

    this.setState({
      panels: [
        (document.body.offsetWidth * 25) / 100,
        (document.body.offsetWidth * 75) / 100
      ]
    });
  }

  startResize = (event, index) => {
    this.setState({
      isDragging: true,
      currentPanel: index,
      initialPos: event.clientX
    });
  }

  stopResize = (e) => {
    if (this.state.isDragging) {
      const leftDiv = document.getElementById("left");
      const rightDiv = document.getElementById("right");
      let leftDivWidth, rightDivWidth;
      if (leftDiv) {
        leftDivWidth = Number(leftDiv.style.width.replace("px", ""));
      }
      if (rightDiv) {
        rightDivWidth = Number(rightDiv.style.width.replace("px", ""));
      }
      this.setState(({ panels }) => ({
        isDragging: false,
        panels: {
          ...panels,
          1: rightDivWidth,
          0: leftDivWidth
        },
        delta: 0
      }));
    }
  }

  resizePanel = (event) => {
    if (this.state.isDragging) {
      const delta = event.clientX - this.state.initialPos;
      const leftDiv = document.getElementById("left");
      const rightDiv = document.getElementById("right");
      if (leftDiv) {
        leftDiv.style.width = `${this.state.panels[0] + delta}px`;
      }
      if (rightDiv) {
        rightDiv.style.width = `${this.state.panels[1] - delta}px`;
      }
    }
  }

  render() {
    const {
      tutorialData,
      tutorialData: { configuration = {} } = {},
      handleQuizSelection,
      handleCheckAnswer,
      checkAnswerData,
      page,
      handleFinishAttempt,
      state,
      checkFeedback,
      handleCompleted,
      handlePrevious,
      isCompleted,
      isFinished,
      noAnswer,
      id,
      handleReview,
      handleSummativeQuizAttempt,
      dispatch,
      gradeFeedback,
      setTutorialLevel,
      tutorialLevel,
      certificateId,
      updateTutorialAttemptGuestDetails
    } = this.props;
    configuration.look_and_feel = configuration.look_and_feel || {}
    const {
      look_and_feel: {
        logo_url = "",
        tagline = "",
        tagline_position = ""
      } = {},
      logo: { url = "" } = {}
    } = configuration || {};
    const tag_pos =
      tutorialData.configuration &&
      tutorialData.configuration.look_and_feel &&
      tagline_position;
    const condition =
      !tutorialData.is_frame_busted && page !== "QuizViewer"
        ? tutorialLevel
        : true;
    return (
      <div className=" tutorial-pane mt-3" ref={el => (this.container = el)}>
        <div
          className={page === "QuizViewer" ? "col-lg-8" : "custom-left"}
          onMouseUp={e => this.stopResize(e)}
          id={"left"}
          style={{ width: this.state.panels[0] }}
        >
          {(url || tagline) && (
            <div className="custom-border custom-line w-100">
              <div
                className={
                  tag_pos === "right_to_logo" ? "w-100 d-flex" : "w-100"
                }
              >
                <img
                  src={url}
                  onError={e => {
                    e.target.style.display = "none";
                  }}
                  className="img-mb-10"
                  alt="logo"
                />
                <p className={tag_pos === "right_to_logo" && "p-t-20"}>
                  {tagline}
                </p>
              </div>
            </div>
          )}
          <div className="tutorial animated fadeIn">
            {condition && (
              <div>
                <TutorialLevel
                  handleQuizSelection={handleQuizSelection}
                  methodComplete={handleCompleted}
                  methodPrev={this.handlePrevious}
                  tutorialPageData={tutorialData}
                  handleCheckAnswer={handleCheckAnswer}
                  checkAnswerData={checkAnswerData}
                  page={page}
                  isCompleted={isCompleted}
                  state={state}
                  handleFinishAttempt={handleFinishAttempt}
                  checkFeedback={checkFeedback}
                  handlePrevious={handlePrevious}
                  handleCompleted={handleCompleted}
                  emptyText={noAnswer}
                  isFinished={isFinished.isFinished}
                  handleReview={handleReview}
                  handleSummativeQuizAttempt={handleSummativeQuizAttempt}
                  dispatch={dispatch}
                  gradeFeedback={gradeFeedback}
                  certificateId={certificateId}
                  updateTutorialAttemptGuestDetails={
                    updateTutorialAttemptGuestDetails
                  }
                />
                <div
                  className="slide-btn-container"
                  onMouseDown={e => this.startResize(e, 1)}
                  onMouseLeave={e => this.stopResize(e)}
                >
                  <div
                    className="slide-btn resizer"
                    id="btnResize"
                    key={"resizer_" + 1}
                    ref={el => (this.handler = el)}
                    // style={this.state.currentPanel === 1 ? {left: this.state.delta} : {}}
                  >
                    ----
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {page !== "QuizViewer" &&
        ( !tutorialData.is_frame_busted || isElectron() ) && (
          <div
            className="custom-right"
            style={{ width: this.state.panels[1] }}
            id="right"
          >
            <TutorialUrlPage
              class="handle"
              urlData={tutorialData.starting_url}
              id={id}
              setTutorialLevel={setTutorialLevel}
              wideFrame={tutorialData.settings.is_wide_iframe}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Tutorial;
