import unicodeRegex from "./unicodeRegex";

export const ValidateName = (name) => {
  name = name && name.trim();
  let err = "";
  if (name.length < 1) {
    err = "Enter Name";
  } else if (name.length > 20) {
    err = "Name must have 20 characters or less.";
  }
  return err;
};

export const validateEmail = (email) => {
  email = email && email.trim();
  if (email === "") return "Must Enter Email.";
  else if (!unicodeRegex.email.test(String(email).toLowerCase()))
    return "Email Format Invalid";
  else return "";
};

export const validateUsername = (username) => {
  let pattern = /^[a-z0-9\p{L}]*/u;
  if (username === "") return "Username is required";
  else if (username.length < 2)
    return "Username should be minimum of 2 characters";
  else if (!pattern.test(username)) return "Only numbers and alphabets allowed";
  else return "";
};

export const validatePassword = (password) => {
  let pattern = /^(?=.*[a-zA-Z0-9!@#$%^&*\p{L}\p{N}]).{8,15}$/u;
  if (password === "") return "Password is required";
  else if (!pattern.test(password)) return "Only numbers and alphabets allowed";
  else if (password.length < 8)
    return "Password should be minimum of 8 characters";
  else return "";
};

export const validateFirstName = (name) => {
  name = name && name.trim();
  let pattern = /^[\p{L}\p{N}]*/iu;
  if (name === "") return "First name is required";
  else if (name.length < 1)
    return "First name should be minimum of 3 characters";
  else if (!pattern.test(name)) return "Invalid First name";
  else return "";
};

export const validateLastName = (name) => {
  name = name && name.trim();
  let pattern = /^[\p{L}\p{N}]*/iu;
  if (name === "") return "Last name is required";
  else if (name.length < 1)
    return "Last name should be minimum of 3 characters";
  else if (!pattern.test(name)) return "Invalid Last name";
  else return "";
};

export const validateTutorialName = (name) => {
  name = name && name.trim();
  if (name) {
    if (name.length < 5) return "Title should be of minimum 5 characters.";
  } else {
    return "Title is required, can't be blank.";
  }
};
export const validateChapterTitle = (name) => {
  name = name && name.trim();
  if (name) {
    if (name.length < 5) return "Title should be of minimum 5 characters.";
  } else {
    return "Title is required, can't be blank.";
  }
};

export const validateDescription = (name) => {
  name = name && name.trim();
  if (name) {
    if (name.length < 3)
      return "Description should be of minimum 3 characters.";
  }
};
export const validateText = (text) => {
  text = text && text.trim();
  if (!text) {
    return "Text is required, can't be blank.";
  }
};
export const validateQuestion = (text) => {
  text = text && text.trim();
  if (!text) {
    return "question is required, can't be blank.";
  }
};

export const validateQuizField = (text, type) => {
  text = text && text.trim();
  if (!text) {
    return `${type} is required, can't be blank.`;
  }
};

export const validateStartingTutorial = (url) => {
  url = url && url.trim();
  //let urlExp=/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
  let urlExp = /^((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))?$/g;
  if (url) {
    if (!urlExp.test(url)) return "starting url is not a valid Url.";
  } else return "starting url is required, can't be blank.";
};

export const validateMediaURL = (url) => {
  url = url && url.trim();
  // let urlExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
  let urlExp = /^((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))?$/g;
  // let urlExp = /^(https?):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i;
  if (url) {
    if (!urlExp.test(url)) {
      return "url is not a valid Url.";
    }
  }
};

export const validateTutorialUrlSlug = (url) => {
  url = url && url.trim();
  let urlExp = /[^a-z0-9\p{L}-]/gu;
  if (url) {
    if (urlExp.test(url)) {
      return "should include alphanumeric characters and hyphen only.";
    }
  }
};

export default {
  ValidateName,
  validateEmail,
  validateUsername,
  validatePassword,
  validateFirstName,
  validateLastName,
  validateTutorialName,
  validateChapterTitle,
  validateDescription,
  validateText,
  validateQuestion,
  validateQuizField,
  validateStartingTutorial,
  validateMediaURL,
  validateTutorialUrlSlug,
};
