import React from "react";
import "./Tutorial.scss";
import { isElectron } from "../../../utils";

export default class TutorialUrlPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newWidth: {}
    };
  }

  componentDidMount() {
    this.props.setTutorialLevel();
    const { wideFrame } = this.props;

    if (wideFrame === true) {
      this.setState({
        newWidth: { maxWidth: "1140px" }
      });
    }
  }

  render() {
    const { urlData } = this.props;

    return (
      <div className="main-page-url" onMouseUp={this.props.onMouseUp} style={{
        height: '100%'
      }}>
        {isElectron() ? (
          <webview
            id="mainFrame"
            name="mainFrame"
            src={urlData}
            style={{ ...this.state.newWidth, width: '100%', height: '100%' }}
          />
        ) : (
          <iframe
            id="mainFrame"
            title="tutorialIframe"
            name="mainFrame"
            style={this.state.newWidth}
            src={urlData}
            onLoad={this.onIframeLoad}
            sandbox="allow-same-origin allow-scripts allow-forms allow-popups
                                    allow-popups-to-escape-sandbox"
            onMouseUp={this.props.onMouseUp}
          />
        )}
      </div>
    );
  }
}
