import axiosI from "../../_helpers/service";
import axios from "axios";

export const checkAnswerService = {
  checkQuizAnswer,
  editQuizAnswer
};
const userLogin = localStorage.getItem("user");
function checkQuizAnswer(tutorialId, ansObj) {
  if (userLogin) {
    return axiosI
      .post(`/tutorials/${tutorialId}/simple_answers`, ansObj)
      .then(response => {
        return response;
      });
  } else {
    return axios
      .post(
        `${process.env.BACKEND_URL}/tutorials/${tutorialId}/simple_answers`,
        ansObj
      )
      .then(response => {
        return response;
      });
  }
}
function editQuizAnswer(tutorialId, answerId, editAnsObj) {
  if (userLogin) {
    return axiosI
      .patch(`/tutorials/${tutorialId}/simple_answers/${answerId}`, editAnsObj)
      .then(response => {
        return response;
      });
  } else {
    return axios
      .patch(
        `${process.env.BACKEND_URL}/tutorials/${tutorialId}/simple_answers/${answerId}`,
        editAnsObj
      )
      .then(response => {
        return response;
      });
  }
}
