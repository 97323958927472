import React, { Component } from "react";
import { connect } from "react-redux";
import { messageAction } from "./../../_actions/index";
import { messageDataFormat } from "../../_helpers/dateFormat";
import Spinner from "../spinner/index";
import "./notification.scss";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isDelete: false,
      messageID: ""
    };
    this.markRead = this.markRead.bind(this);
    this.toggle = this.toggle.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
    this.markAllRead = this.markAllRead.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(messageAction.getMessageData());
  }
  confirmDelete() {
    const { messageID } = this.state;
    const { dispatch } = this.props;
    dispatch(messageAction.deleteMessage(messageID)).then(() => {
      this.setState({
        isOpen: false
      });
    });
  }

  cancelDelete() {
    this.setState({
      isOpen: false
    });
  }

  deleteConfirmation(e, messageID) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isOpen: true,
      messageID: messageID
    });
  }

  markRead(mId, is_read) {
    const { dispatch } = this.props;
    if (is_read === false || is_read === null) {
      dispatch(messageAction.getMessageRead(mId));
    }
  }
  markAllRead() {
    const { dispatch } = this.props;
    dispatch(messageAction.markAllMessagesAsRead());
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  dropNotif() {
    const { messageData, isLoadingMessage } = this.props;
    let cnt = 0;
    messageData.map(msg => {
      (msg.is_read === false || msg.is_read === null) && cnt++;
    });
    const { is_read, isOpen } = this.state;
    return (
      <div className="animated fadeIn">
        <div className="w-100 d-flex">
          <Button
            disabled={cnt === 0}
            className="float-right ml-auto m-3"
            color="primary"
            onClick={this.markAllRead}
          >
            Mark All as Read
          </Button>
        </div>
        <div className="email-app mb-4 message-scrollable-area">
          <main className="inbox">
            <ul className="messages">
              {isLoadingMessage ? (
                <Spinner />
              ) : (
                messageData.map((m, i) => {
                  return (
                    <li
                      className={
                        m.is_read === false || m.is_read === null
                          ? "message unread"
                          : "mark-read"
                      }
                      onClick={() => this.markRead(m.id, m.is_read)}
                    >
                      <a href="#" className="a-msg">
                        {/*<div className="actions">*/}
                        {/*<span className="action"><i className="fa fa-square-o"></i></span>*/}
                        {/*<span className="action"><i className="fa fa-star-o"></i></span>*/}
                        {/*</div>*/}
                        <div className="header  d-flex justify-content-between">
                          <span>Sidecar Learning</span>
                          <span>
                            <span className="fa fa-paper-clip"></span>
                            {messageDataFormat(m.created_at)}
                          </span>
                        </div>
                        <div>
                          <div className="d-flex justify-content-between">
                            <span>{m.title}</span>
                            <span className="bg-icon">
                              <i
                                className="fa fa-trash"
                                onClick={e => {
                                  this.deleteConfirmation(e, m.id);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="description">{m.body}</div>
                      </a>
                    </li>
                  );
                })
              )}
            </ul>
          </main>
        </div>
        <div>
          <Modal className="modal-danger" isOpen={isOpen} toggle={this.toggle}>
            <ModalHeader className="modal-header" toggle={this.toggle}>
              Confirmation
            </ModalHeader>
            <ModalBody>
              <h4> Are you sure you want to delete this ?</h4>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={this.confirmDelete}>
                Yes
              </Button>
              <Button color="secondary" onClick={this.cancelDelete}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
  render() {
    return this.dropNotif();
  }
}

const mapStateToProps = state => {
  return {
    messageData: state.message.messageData,
    isLoadingMessage: state.message.isLoadingMessage
  };
};
export default connect(mapStateToProps, null)(Notification);
