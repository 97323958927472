import { tutorialLookFeelConstants, toastConstants } from "../_constants";
import { tutorialLookFeelService } from "../_services";

export const tutorialLookFeelActions = {
  saveTutorialLookFeel
};

function saveTutorialLookFeel(obj) {
  return dispatch => {
    return tutorialLookFeelService.saveTutorialLookFeel(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully Saved." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };
  function success(data) {
    return {
      type: tutorialLookFeelConstants.SAVE_TUTORIAL_LOOK_FEEL_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: tutorialLookFeelConstants.SAVE_TUTORIAL_LOOK_FEEL_FAILURE,
      error
    };
  }
}
