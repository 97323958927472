import React from "react";
import "./Tutorial.scss";
import renderHTML from "react-render-html";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import LongTextQuestion from "./LongTextQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import ShortTextQuestion from "./ShortTextQuestion";
import BooleanQuestion from "./BooleanQuestion";

export default class TutorialQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ansArray: [],
      long_text: [],
      short_text: [],
      correct_answer_feedback: "Correct",
      wrong_answer_feedback: "Incorrect, try another selection",
      no_answer_feedback: "Please answer the question.",
      isTutorialType: false
    };
    this.activeOption = this.activeOption.bind(this);
    this.handleTextValue = this.handleTextValue.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { page } = nextProps;
    if (page === "tutorialViewer") {
      this.setState({
        isTutorialType: true
      });
    }
  }

  activeOption(opt, selectedValues) {
    for (let i = 0; i < selectedValues.length; ++i) {
      if (opt === selectedValues[i]) {
        return true;
      }
    }
  }

  handleTextValue(e) {
    const { long_text, short_text } = this.state;
    const { value, name } = e.target;
    if (name.includes("long_text")) {
      long_text[0] = value;
      this.setState({
        long_text
      });
    } else if (name.includes("short_text")) {
      short_text[0] = value;
      this.setState({
        short_text
      });
    }
  }

  render() {
    const {
      quizData,
      handleQuizSelection,
      handleCheckAnswer,
      page,
    } = this.props;

    const questionTypeToComponents = {
      multiple_choice: MultipleChoiceQuestion,
      long_text: LongTextQuestion,
      single_choice: SingleChoiceQuestion,
      short_text: ShortTextQuestion,
      boolean: BooleanQuestion
    }

    const { long_text, short_text } = this.state;
    const buttonName = page === "QuizViewer" ? "Save Answer" : "Submit Answer";

    return (
      <div className="animated fadeIn quiz-class">
        {quizData && quizData.title && <h5 tabIndex="0">{quizData.title}</h5>}
        {quizData && quizData.questions.map((ques, index) => {
          const QuestionComponent = questionTypeToComponents[ques.type]

          return (
            <QuestionComponent
              key={index}
              index={index}
              renderHTML={renderHTML}
              ques={ques}
              buttonName={buttonName}
              handleQuizSelection={handleQuizSelection}
              handleTextValue={this.handleTextValue}
              activeOption={this.activeOption}
              handleCheckAnswer={handleCheckAnswer}
              quizData={quizData}
              long_text={long_text}
              short_text={short_text}
            />
          );
        })}
      </div>
    );
  }
}
