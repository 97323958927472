import React from "react";
import MyProfile from "../../components/AccountManagement/MyProfile";

export default class MyProfileContainer extends React.Component {
  render() {
    return (
      <div className="animated fadeIn">
        <MyProfile />
      </div>
    );
  }
}
