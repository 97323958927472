import React, { Component } from "react";
import { Line } from "react-chartjs-2";

const line = {
  labels: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su", "Mo"],
  datasets: [
    {
      label: "My first dataset",
      type: "line",
      fill: true,
      fillColor: "rgba(151,187,205,0.2)",
      lineTension: 0.2,
      borderColor: "#20a8d8",
      borderCapStyle: "butt",
      borderDash: [],
      borderWidth: 1,
      strokeColor: "rgba(220,180,0,1)",
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      // pointBorderColor: '#c8eaf4',
      // pointBorderWidth: 1,
      pointHoverRadius: 4,
      pointHoverBackgroundColor: "white",
      pointHoverBorderColor: "#20a8d8",
      pointHoverBorderWidth: 1,
      pointRadius: 0,
      pointHitRadius: 10,
      data: [50, 30, 80, 81, 60, 89, 67, 20]
    },
    {
      label: "My second dataset",
      fill: true,
      fillColor: "rgba(151,187,205,0.2)",
      lineTension: 0.2,
      borderColor: "#79c447",
      borderCapStyle: "butt",
      borderDash: [],
      borderWidth: 1,
      strokeColor: "rgba(151,187,205,1)",
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      // pointBackgroundColor: '#fff',
      // pointBorderWidth: 1,
      pointHoverRadius: 4,
      pointHoverBackgroundColor: "white",
      pointHoverBorderColor: "#79c447",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      data: [100, 10, 40, 90, 20, 55, 40, 90]
    }
  ]
};

class StudentChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h4>Students Traffic for the 6 Tutorials </h4>
        <div className="chart-wrapper">
          <Line
            data={line}
            options={{
              legend: false,
              responsive: true,
              tooltips: {
                enabled: true,
                mode: "x",
                intersect: false
              },
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      display: true
                    }
                  }
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      display: false
                    }
                  }
                ]
              },
              maintainAspectRatio: false
            }}
          />
        </div>
      </div>
    );
  }
}

export default StudentChart;
