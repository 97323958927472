import { quizSetAnswersConstants } from "../../_constants/Learner/quizSetAnswers.constants";

export const setQuizActions = {
  setQuizAnswers
};
function setQuizAnswers(obj, page) {
  if (page === "tutorialViewer") {
    return dispatch => {
      dispatch({
        type: quizSetAnswersConstants.SETALL_QUIZ_SELECTION,
        payload: obj
      });
      dispatch({ type: "SET_ANS", payload: obj }); // for everytime value get selected,stored in reducer
    };
  } else if (page === "QuizViewer") {
    return dispatch => {
      dispatch({
        type: quizSetAnswersConstants.SET_SUMMATIVE_QUIZ_SELECTION,
        payload: obj
      });
      dispatch({ type: "SET_ANS", payload: obj });
    };
  }
}
