import { learnerConstants, toastConstants } from "../../_constants/index";
import { learnerService } from "../../_services/index";

export const learnerActions = {
  getLearnerData,
  getCourseData,
  assignLearnerToCourses,
  addLearnerData,
  removeLearnerFromCourse
};

function getLearnerData() {
  return dispatch => {
    dispatch({ type: learnerConstants.LEARNER_REQUEST });
    learnerService.getLearner().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: learnerConstants.GET_LEARNER_SUCCESS, data };
  }
  function failure(error) {
    return { type: learnerConstants.GET_LEARNER_FAILURE, error };
  }
}
function getCourseData() {
  return dispatch => {
    dispatch({ type: learnerConstants.COURSE_REQUEST });
    learnerService.getCourse().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: learnerConstants.GET_COURSE_SUCCESS, data };
  }
  function failure(error) {
    return { type: learnerConstants.GET_COURSE_FAILURE, error };
  }
}
function assignLearnerToCourses(obj) {
  return dispatch => {
    if (!obj.course_ids.length || !obj.learner_ids.length) {
      dispatch({
        type: toastConstants.SHOW_TOAST,
        message: { type: "ERROR", data: "Something went wrong." }
      });
    } else
      return learnerService.assignLearnerToCourse(obj).then(
        data => {
          obj.status = data;
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "SUCCESS", data: "Assigned Successfully." }
          });
          return dispatch(success(obj));
        },
        error => {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "ERROR", data: "Something went wrong." }
          });
          dispatch(failure(error.toString()));
        }
      );
  };

  function success(data) {
    return { type: learnerConstants.ASSIGN_LEARNER_TO_COURSE_SUCCESS, data };
  }
  function failure(error) {
    return { type: learnerConstants.ASSIGN_LEARNER_TO_COURSE_FAILURE, error };
  }
}
function addLearnerData(obj) {
  return dispatch => {
    return learnerService.addLearnersService(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Added Successfully." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: learnerConstants.ADD_LEARNER_SUCCESS, data };
  }
  function failure(error) {
    return { type: learnerConstants.ADD_LEARNER_FAILURE, error };
  }
}
function removeLearnerFromCourse(obj) {
  return dispatch => {
    return learnerService.deleteLearnerCourseService(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Removed successfully" }
        });
        return dispatch(success({ obj }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: learnerConstants.DELETE_LEARNER_COURSES_SUCCESS, data };
  }
  function failure(error) {
    return { type: learnerConstants.DELETE_LEARNER_COURSES_FAILURE, error };
  }
}
