import React, { Component } from "react";
import classnames from "classnames";
import Spinner from "../spinner/index";

import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import "./tutorialManagement.scss";
import Validations from "../../_helpers/Validation";

class AddMedia extends Component {
  constructor() {
    super();
    this.state = {
      media: {
        type: "media",
        imageFileError: undefined,
        tags: [],
        description: "",
        source: "",
        image_in_viewer: 80,
        index: 0,
        id: "",
        length: 100,
        width: 100,
        constraint_proportion: false,
      },
      embedded_media: { type: "embedded_media", code: "", tags: [], index: 0 },
      url: {
        type: "url",
        tags: [],
        display_text: "",
        url: "",
        target: "_blank",
        index: 0,
      },
      errorstab1: [],
      errorstab2: [],
      errorstab3: [],
      files: "",
      filesToBeSent: [],
      filesPreview: [],
      printcount: 10,
      activeTab: "media",
      previewImage: "",
      constrainAlert: false,
      imageSizeHeader: "",
      imageSizeBody: "",
      mediaProcess: false,
      mediaModel: false,
    };
  }

  componentDidMount() {
    let { editData, mediaActiveTab } = this.props;
    let { url, embedded_media, media } = this.state;
    if ("data" in editData) {
      if (editData.data.type === "media")
        this.setState({
          media: editData.data,
          url: { ...url, index: editData.index + 1 },
          embedded_media: { ...embedded_media, index: editData.index - 1 },
        });
      if (editData.data.type === "url")
        this.setState({
          url: editData.data,
          media: { ...media, index: editData.index - 1 },
          embedded_media: { ...embedded_media, index: editData.index + 1 },
        });
      if (editData.data.type === "embedded_media")
        this.setState({
          embedded_media: editData.data,
          media: { ...media, index: editData.index - 1 },
          url: { ...url, index: editData.index + 1 },
        });
      this.setState({ activeTab: mediaActiveTab });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { mediaProcess } = this.state;
    const { mediaProcess: mediaLoading } = nextProps;
    if (mediaLoading !== mediaProcess) {
      this.setState({ mediaProcess: mediaLoading, mediaModel: mediaLoading });
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleMediaModel = () => {
    const { mediaModel } = this.state;
    this.setState({ mediaModel: !mediaModel });
  };

  validate = () => {
    let {
      media,
      embedded_media,
      url,
      errorstab1,
      errorstab2,
      errorstab3,
      activeTab,
    } = this.state;
    errorstab1 = [];
    errorstab2 = [];
    errorstab3 = [];
    let f = 0,
      ur = 0,
      em = 0;

    media.tags.map((m, i) => {
      if (m.length < 3) {
        f = 1;
      }
    });
    url.tags.map((u, i) => {
      if (u.length < 3) {
        ur = 1;
      }
    });
    embedded_media.tags.map((emb, i) => {
      if (emb.length < 3) {
        em = 1;
      }
    });
    if (f) errorstab1["tags"] = `Minimum 3 characters of Title required`;
    else errorstab1["tags"] = "";
    if (ur) errorstab2["tags"] = `Minimum 3 characters of Tags required`;
    else errorstab2["tags"] = "";
    if (em) errorstab3["tags"] = `Minimum 3 characters of Tags required`;
    else errorstab3["tags"] = "";

    if (activeTab === "media") {
      if (!Boolean(media.source))
        errorstab1[
          "source"
        ] = `source of the media is required, can't be blank`;
    }
    if (activeTab === "url") {
      if (!Boolean(url.url))
        errorstab2["url"] = `Url media is required, can't be blank`;
      if (
        Number(media.image_in_viewer) > 100 ||
        !(Number(media.image_in_viewer) > 0)
      )
        errorstab1["image_in_viewer"] = `value must be between 0 to 100`;
    }
    if (activeTab === "embedded_media") {
      if (!Boolean(embedded_media.code))
        errorstab3[
          "code"
        ] = `Embedded media source is required, can't be blank`;
    }
    this.setState({ errorstab1, errorstab2, errorstab3 });

    if (activeTab === "media") {
      if (errorstab1.tags.length === 0) {
        return 0;
      }
    }
    if (activeTab === "url") {
      if (errorstab2.tags.length === 0) {
        return 0;
      }
    }
    if (activeTab === "embedded_media") {
      if (errorstab3.tags.length === 0) {
        return 0;
      }
    }
  };

  onSave = () => {
    let err = this.validate();
    let { media, url, embedded_media, activeTab, errorstab2 } = this.state;
    if (err === 0) {
      if (media.source) {
        this.onMediaSave().then((res) => {
          if (res) {
            media.id = res.id;
            this.setState({ media }, () => {
              this.props.handleSubmit(media, "media");
            });
          }
          this.setState({ mediaProcess: false });
        });
      }
      if (url.url) {
        let err = Validations.validateMediaURL(url.url);
        if (!Boolean(err)) {
          this.props.handleSubmit(url, "url");
        } else {
          errorstab2["url"] = "url is not valid.";
          this.setState({ errorstab2 });
        }
      }
      if (embedded_media.code) {
        !errorstab2["url"] &&
          this.props.handleSubmit(embedded_media, "embedded_media");
      }
    }
  };

  handleChange = (e, type) => {
    let { media, url, embedded_media } = this.state;
    let { name, value } = e.target;
    if (type === "media") {
      if (name === "image_in_viewer") {
        media[name] = Number(value);
        this.setState({ constrainAlert: false });
      }
      if (name === "tags")
        media[name] = value.includes(",") ? value.split(",") : value.split();
      else media[name] = value;

      this.setState({ media });
    }
    if (type === "url") {
      if (name === "url") {
        let urls = "";
        let exp = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
        if (e.target.value[e.target.value.length - 1] !== " ") {
          let previewImage = "";
          urls = e.target.value.match(exp);
          if (urls) {
            if (
              ["jpeg", "png", "jpg"].includes(
                urls[0].split(".")[urls[0].split(".").length - 1]
              )
            )
              previewImage = urls;
            else {
              previewImage = `https://besticon-demo.herokuapp.com/icon?url=${urls}&size=80..120..200`;
            }
            this.setState({ previewImage });
          } else {
            this.setState({ previewImage: "" });
          }
        }
        url[name] = value;
      } else if (name === "tags")
        url[name] = value.includes(",") ? value.split(",") : value.split();
      else url[name] = value;
      this.setState({ url });
    }
    if (type === "embedded_media") {
      if (name === "tags")
        embedded_media[name] = value.includes(",")
          ? value.split(",")
          : value.split();
      else embedded_media[name] = value;
      this.setState({ embedded_media });
    }
    if (type === "media_constraint") {
      const { media } = this.state;
      if (name === "constraint_proportion") {
        if (!JSON.parse(value)) {
          media.width = 100;
          media.length = 100;
        }
        media.constraint_proportion = JSON.parse(value);
        this.setState({ media });
      } else if (name === "length" || name === "width") {
        media[name] = Number(value);
        this.setState({ media });
        this.setState({ constrainAlert: false });
      }
    }
  };

  dragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  validateFileInput = (file) => {
    let errors = [];
    const allowedTypes = ["image/png", "image/gif", "image/jpg", "image/jpeg"];

    if (file.size > 2 * 1000 * 1000) {
      errors.push("Image can not exceed 2 MB in size!");
    }

    if (!allowedTypes.includes(file.type)) {
      errors.push(
        `Only images are allowed.
         Allowed extensions: jpg, jpeg, png, gif`
      );
    }

    if (errors.length) return { isFileValid: false, errors: errors.join(" ") };
    return { isFileValid: true };
  };

  onDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          const file = ev.dataTransfer.items[i].getAsFile();
          this.uploadFile(file);
        }
      }
    }
  };

  fileReader = () => {
    const { media } = this.state;
    const reader = new FileReader();
    reader.onload = (e2) => {
      media.source = e2.target.result;
      media.imageFileError = null;
      this.setState({ media });
    };
    return reader;
  };

  uploadFile = (file) => {
    const { media } = this.state;
    const { isFileValid, errors: inputFileErrors } = this.validateFileInput(
      file
    );

    if (isFileValid) {
      this.fileReader().readAsDataURL(file);
    } else {
      media.imageFileError = inputFileErrors;
      this.setState({ media });
    }
  };

  onUpload = (e) => {
    const file = e.target.files[0];
    this.uploadFile(file);
  };

  onDDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onMediaSave = () => {
    return new Promise((resolve, reject) => {
      try {
        const { media } = this.state;
        if (
          media.length > 100 ||
          media.length <= 0 ||
          media.width > 100 ||
          media.width <= 0
        ) {
          this.setState({
            constrainAlert: true,
            imageSizeHeader: "Constraint Proportion",
            imageSizeBody:
              "Constraint Proportion Height and width should be in range of 1-100.",
          });
        } else if (media.image_in_viewer > 100 || media.image_in_viewer <= 0) {
          this.setState({
            constrainAlert: true,
            imageSizeHeader: "Image Width in Viewer",
            imageSizeBody: "Image width in viewer should be in range of 1-100.",
          });
        } else {
          const obj = {
            file: media.source,
            length: media.length,
            width: media.width,
            constraint_proportion: media.constraint_proportion,
          };
          this.props.saveMedia(obj).then((res) => {
            return resolve(res);
          });
        }
      } catch (err) {
        console.log(err);
        return reject(err);
      }
    });
  };

  setConstraintAlert = () => {
    const { constrainAlert } = this.state;
    this.setState({ constrainAlert: !constrainAlert });
  };

  render() {
    let {
      media,
      url,
      embedded_media,
      errorstab1,
      errorstab2,
      errorstab3,
      activeTab,
      previewImage,
      constrainAlert,
      imageSizeHeader,
      imageSizeBody,
      mediaProcess,
      mediaModel,
    } = this.state;
    const btnName = activeTab === "media" ? "Add Media" : "Save";
    const mediaWidth = media.width > 100 ? 100 : media.width;
    const mediaHeight = media.length > 100 ? 100 : media.length;
    return (
      <div>
        <div>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs className="tab-bottom-line">
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": activeTab === "media",
                  })}
                  onClick={() => {
                    this.toggle("media");
                  }}
                >
                  Add Image/GIF
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": activeTab === "url",
                  })}
                  onClick={() => {
                    this.toggle("url");
                  }}
                >
                  URL
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    "tut-manage-nav-active": activeTab === "embedded_media",
                  })}
                  onClick={() => {
                    this.toggle("embedded_media");
                  }}
                >
                  Embedded Media
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="media" className="mt-2">
                <Row>
                  <Col md={6} className="pad-5" onDrop={this.onDrop}>
                    <div className="add-media-table-box" id="drop-zone">
                      <label className="block-content" id="drag">
                        Drag and drop (Max size: 2 MB)
                      </label>
                      <div className="image-file-error">
                        {media.imageFileError}
                      </div>
                      <div onDrop={this.onDrop} onDragOver={this.dragOver}>
                        <div
                          className="drag-drop-file"
                          id="drop-zone-display"
                          onClick={() => {
                            document.getElementById("file-upload").click();
                          }}
                        >
                          {Boolean(media.source) ? (
                            <img
                              style={{
                                width: media.constraint_proportion
                                  ? `${mediaWidth}%`
                                  : "100%",
                                height: `${mediaHeight}%`,
                              }}
                              src={media.source}
                            />
                          ) : (
                            "Click here to upload or drag a file to this Drop Zone ..."
                          )}

                          {/*{Boolean(media.source)?<img className={"drag-drop-img"} src={media.source} alt={'mediaSource'}/>:'Click here to upload or drag a file to this Drop Zone ...'}*/}
                        </div>
                        <Input
                          type="file"
                          id="file-upload"
                          onChange={(e) => this.onUpload(e)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className="pad-5">
                    <div className="add-media-table-box">
                      <label className="block-content" id="imp-media">
                        Title
                      </label>
                      <Input
                        className="mb-0 mt-2"
                        type="text"
                        name="tags"
                        placeholder="alt tags"
                        onChange={(e) => this.handleChange(e, "media")}
                        value={
                          typeof media.tags === "object"
                            ? media.tags.join(",")
                            : media.tags
                        }
                      />
                      {errorstab1["tags"] && (
                        <div className="help-block">{errorstab1["tags"]}</div>
                      )}
                      <Label className="mb-0 mt-2">Description:</Label>
                      <Input
                        type="text"
                        name="description"
                        onChange={(e) => this.handleChange(e, "media")}
                        value={media.description}
                        accept="image/*"
                      />
                      <Label className="mb-0 mt-2 w-100">
                        Image Width in Viewer:
                      </Label>
                      <span className="dis-flex per-field">
                        <Input
                          type="number"
                          name="image_in_viewer"
                          min="1"
                          max="100"
                          value={media.image_in_viewer}
                          onChange={(e) => this.handleChange(e, "media")}
                        />{" "}
                        <Label className="mb-0 mt-2 ml-2">%</Label>
                      </span>
                      {errorstab1["image_in_viewer"] && (
                        <div className="help-block">
                          {errorstab1["image_in_viewer"]}
                        </div>
                      )}
                      <div className="set-constraint">
                        <Label className="mb-0 mt-2">
                          Set Constraint proportion :
                        </Label>
                        <div>
                          <FormGroup check className="radio">
                            <Input
                              className="form-check-input"
                              type="radio"
                              id="radio1"
                              name="constraint_proportion"
                              value={true}
                              onChange={(e) =>
                                this.handleChange(e, "media_constraint")
                              }
                              checked={media.constraint_proportion === true}
                            />
                            <Label check className="form-check-label mr-1">
                              Yes
                            </Label>
                          </FormGroup>
                          <FormGroup check className="radio">
                            <Input
                              className="form-check-input"
                              type="radio"
                              id="radio1"
                              name="constraint_proportion"
                              value={false}
                              onChange={(e) =>
                                this.handleChange(e, "media_constraint")
                              }
                              checked={media.constraint_proportion === false}
                            />
                            <Label check className="form-check-label">
                              No
                            </Label>
                          </FormGroup>
                          {media.constraint_proportion ? (
                            <span className="dis-flex align-items-center mt-2">
                              <Label check className="form-check-label mr-2">
                                Width:
                              </Label>
                              <Input
                                type="number"
                                id="constrain_length"
                                // className="small custom-input-small"
                                name="width"
                                onChange={(e) =>
                                  this.handleChange(e, "media_constraint")
                                }
                                value={
                                  media.constraint_proportion
                                    ? media.width
                                    : 100
                                }
                                max={"100"}
                                min={"70"}
                              />
                              <span className="mr-2 ml-2">%</span>
                              <Label check className="form-check-label mr-2">
                                Height:
                              </Label>
                              <Input
                                type="number"
                                id="constrain_width"
                                // className="small custom-input-small"
                                name="length"
                                onChange={(e) =>
                                  this.handleChange(e, "media_constraint")
                                }
                                value={
                                  media.constraint_proportion
                                    ? media.length
                                    : 100
                                }
                                max={"100"}
                                min={"80"}
                              />
                              <span className="ml-2">%</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="url">
                <Row>
                  <Col md={6}>
                    <div className="drag-drop-file mt-2" id="drop-zone-display">
                      {Boolean(previewImage) ? (
                        <img
                          className="drag-drop-img img-width"
                          src={previewImage}
                          alt={"Preview url"}
                        />
                      ) : (
                        "Preview image of url ..."
                      )}
                    </div>
                    <div className="i-text">
                      <i>
                        The size of the tutorial is controlled by the webpage
                        hosting the tutorial. If you need to change the size of
                        the player, then you must use the embed media option.
                      </i>
                    </div>
                  </Col>
                  <Col md={6} className="pad-5">
                    <div className="add-media-table-box mt-2">
                      <label className="block-content" id="insert-url">
                        InsertUrl Tags
                      </label>
                      <Input
                        type="text"
                        name="tags"
                        placeholder="alt tags"
                        onChange={(e) => this.handleChange(e, "url")}
                        value={
                          typeof url.tags === "object"
                            ? url.tags.join(",")
                            : url.tags
                        }
                      />
                      {errorstab2["tags"] && (
                        <div className="help-block">{errorstab2["tags"]}</div>
                      )}
                      <Label className="mb-1 mt-2">URL:</Label>
                      <Input
                        type="text"
                        name="url"
                        onChange={(e) => this.handleChange(e, "url")}
                        value={url.url}
                      />
                      {errorstab2["url"] && (
                        <div className="help-block">{errorstab2["url"]}</div>
                      )}
                      <Label className="mb-1 mt-2">Display Text:</Label>
                      <Input
                        type="text"
                        name="display_text"
                        onChange={(e) => this.handleChange(e, "url")}
                        value={url.display_text}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="embedded_media">
                <Col md={12} className="pad-5">
                  <div className="add-media-table-box mt-2">
                    <label className="block-content mb-2" id="embed-media">
                      Embed Media Tags
                    </label>
                    <Input
                      className="mb-2"
                      type="text"
                      name="tags"
                      placeholder="alt tags"
                      onChange={(e) => this.handleChange(e, "embedded_media")}
                      value={
                        typeof embedded_media.tags === "object"
                          ? embedded_media.tags.join(",")
                          : embedded_media.tags
                      }
                    />
                    {errorstab3["tags"] && (
                      <div className="help-block">{errorstab3["tags"]}</div>
                    )}
                    <textarea
                      className="w-100 custom-text-area"
                      rows={8}
                      name="code"
                      placeholder="Paste code to embed."
                      value={embedded_media.code}
                      onChange={(e) => this.handleChange(e, "embedded_media")}
                    ></textarea>
                    {errorstab3["code"] && (
                      <div className="help-block">{errorstab3["code"]}</div>
                    )}
                  </div>
                </Col>
              </TabPane>
            </TabContent>
          </Col>
          <div className="mediaProcess">
            <div className="save-btn-tutorial mb-2 mt-2 pr-2 text-right w-100">
              <Button
                color="primary"
                className="btn btn-primary custom-save-btn w-25"
                onClick={this.onSave}
                disabled={mediaProcess}
              >
                {btnName}
              </Button>
              {mediaProcess && activeTab === "media" && (
                <div className="media-loader">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal isOpen={constrainAlert} className={"modal-warning"}>
          <ModalHeader toggle={this.setConstraintAlert}>
            {imageSizeHeader}
          </ModalHeader>
          <ModalBody>
            <p>{imageSizeBody}</p>
          </ModalBody>
          <ModalFooter>
            <Button color={"warning"} onClick={this.setConstraintAlert}>
              Okay
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default AddMedia;
