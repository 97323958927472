import axiosI from "../../_helpers/service";
import moment from "moment";
import axios from "axios";

export const attemptQuizService = {
  AttemptQuiz,
  FinishAttemptedQuiz
};
const userLogin = localStorage.getItem("user");
function AttemptQuiz(id) {
  if (userLogin) {
    return axiosI.post(`/tutorials/${id}/quiz_simple_attempts`).then(response => {
      return response;
    });
  } else {
    return axios.post(
      `${process.env.BACKEND_URL}/tutorials/${id}/quiz_simple_attempts`
    ).then(
      response => { return response; }
    );
  }
}

function FinishAttemptedQuiz(quizId, attemptedId) {
  if (userLogin) {
    return axiosI
      .patch(`/tutorials/${quizId}/quiz_simple_attempts/${attemptedId}`, {
        finished_at: moment()
          .utc()
          .format("YYYY-MM-DDTHH:mm:SS"),
        progress: ""
      })
      .then(response => {
        return response;
      });
  } else {
    return axios
      .patch(`${process.env.BACKEND_URL}/tutorials/${quizId}/quiz_simple_attempts/${attemptedId}`, {
        finished_at: moment()
          .utc()
          .format("YYYY-MM-DDTHH:mm:SS"),
        progress: ""
      })
      .then(response => {
        return response;
      });
  }
}
