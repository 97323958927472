import axios from "axios";
import { config } from "../config";
import { store } from "../_helpers/store";
import { userActions } from "../_actions";
const axiosI = axios.create({
  baseURL: `${config.apiUrl}`
});

axiosI.interceptors.request.use(
  config => {
    let user = JSON.parse(localStorage.getItem("user"));
    let headerJson = JSON.parse(localStorage.getItem("headerJson"));
    headerJson.Authorization = "Bearer fake-jwt-token"; //TODO while we remove fake backend we need to remove Authorization header
    if (user && headerJson) {
      config.headers = headerJson;
    } else {
      return {};
    }
    return config;
  },
  err => Promise.reject(err)
);

axiosI.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;
    if (error.response.status === 401 || error.code === "ECONNABORTED") {
      store.dispatch(userActions.logout());
    }
    return Promise.reject(error);
  }
);

export default axiosI;
