import axiosI from "../_helpers/service";

export const tutorialLookFeelService = {
  saveTutorialLookFeel
};
function saveTutorialLookFeel(obj) {
  return axiosI.patch("/auth", obj).then(response => {
    return response;
  });
}
