import React, { Component } from "react";
import "./learnerForm.scss";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup
} from "reactstrap";
import Validation from "../../_helpers/Validation";
import { Link } from "react-router-dom";

class LearnerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: props.studentData,
      errMsg: props.errMsg,
      errorData: props.errors
    };
  }

  generateStudentForm = index => {
    const { studentData, errMsg } = this.state;
    let obj = { id: index + 1, email: "", first_name: "", last_name: "" };
    errMsg.push({ email: "", first_name: "", last_name: "" });
    studentData.push(obj);
    this.setState({
      studentData,
      errMsg
    });
  };

  removeLearnerForm = index => {
    const { studentData } = this.state;
    studentData.splice(index, 1);
    this.setState({
      studentData
    });
  };

  handleChange = (e, i) => {
    let orgId =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).organization !== null
        ? JSON.parse(localStorage.getItem("user")).organization.id
        : JSON.parse(localStorage.getItem("user")).owned_organization.id;
    const { name, value } = e.target;
    const { studentData, errMsg } = this.state;
    studentData[i][name] = value;
    studentData[i].organization_id = orgId;
    errMsg[i][name] = "";
    this.setState({
      studentData,
      errMsg
    });
  };

  checkValidation(e, i) {
    const { name, value } = e.target;
    const { errMsg } = this.state;
    let error;
    switch (name) {
      case "email":
        error = Validation.validateEmail(value);
        errMsg[i].email = error;
        this.setState({ errMsg });
        break;

      case "first_name":
        error = Validation.ValidateName(value);
        errMsg[i].first_name = error;
        this.setState({ errMsg });
        break;

      case "last_name":
        error = Validation.ValidateName(value);
        errMsg[i].last_name = error;
        this.setState({ errMsg });
        break;

      default:
        break;
    }
  }

  save = e => {
    e.preventDefault();
    const { studentData, errMsg } = this.state;
    studentData.map((stud, i) => {
      if (!stud.email) {
        errMsg[i].email = "Email is required";
      }
      if (!stud.first_name) {
        errMsg[i].first_name = "First Name is required";
      }
      if (!stud.last_name) {
        errMsg[i].last_name = "Last Name is required";
      }
    });
    this.setState({
      errMsg
    });
    if (
      !errMsg.find(e => e.email) &&
      !errMsg.find(e => e.first_name) &&
      !errMsg.find(e => e.last_name)
    ) {
      this.props.saveForm(studentData, errMsg);
    }
  };

  render() {
    const { cancelForm, errorData } = this.props;
    const { studentData, errMsg } = this.state;
    return (
      <div className="stud-page animated fadeIn">
        <Row>
          <Col md={12}></Col>
        </Row>
        <Row>
          <div className="col-md-6 stud-form-wrapper mt-2">
            <div>
              <Form>
                <div className="form-data-wrapper stud-form learner-form-scroll">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4>Add New Learner</h4>
                    </div>
                    <div
                      className="text-right add-learner-btn"
                      onClick={() => {
                        this.generateStudentForm(studentData.length);
                      }}
                    >
                      <i className="fa fa-plus-circle" /> Add More
                    </div>
                  </div>
                  {studentData &&
                    studentData.map((s, i) => {
                      return (
                        <div>
                          <FormGroup>
                            <div>
                              <Row>
                                <div className="col-md-6 col-12">
                                  <InputGroup className="custom-inputs">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="icon-user"></i>
                                      </span>
                                    </div>
                                    <Input
                                      placeholder="First Name"
                                      name={"first_name"}
                                      value={s.first_name}
                                      onChange={e => this.handleChange(e, i)}
                                      onBlur={e => this.checkValidation(e, i)}
                                      aria-label={"Enter First name of user"}
                                    />
                                    {errMsg && errMsg[i].first_name && (
                                      <div className="help-block">
                                        {errMsg[i].first_name}
                                      </div>
                                    )}
                                  </InputGroup>
                                </div>
                                <div className="col-md-6 col-12 custome-space">
                                  <InputGroup className="custom-inputs">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="icon-user"></i>
                                      </span>
                                    </div>
                                    <Input
                                      placeholder="Last Name"
                                      name={"last_name"}
                                      value={s.last_name}
                                      onChange={e => this.handleChange(e, i)}
                                      onBlur={e => this.checkValidation(e, i)}
                                      aria-label={"Enter Last   name of user"}
                                    />
                                    {errMsg && errMsg[i].last_name && (
                                      <div className="help-block">
                                        {errMsg[i].last_name}
                                      </div>
                                    )}
                                  </InputGroup>
                                </div>
                              </Row>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="col-md-12">
                              <Row>
                                <InputGroup className="custom-inputs custom-i">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text"> @</span>
                                  </div>
                                  <Input
                                    placeholder="Email"
                                    name={"email"}
                                    value={s.email}
                                    onChange={e => this.handleChange(e, i)}
                                    className="no-border-left"
                                    onBlur={e => this.checkValidation(e, i)}
                                    aria-label={"Enter Email id of user"}
                                  />
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    onClick={() => this.removeLearnerForm(i)}
                                  />
                                  {errMsg && errMsg[i].email && (
                                    <div className="help-block">
                                      {errMsg[i].email}
                                    </div>
                                  )}
                                  {errorData[i] &&
                                    errorData[i].length !== 0 && (
                                      <div className="help-block">
                                        {errorData[i]}
                                      </div>
                                    )}
                                </InputGroup>
                              </Row>
                            </div>
                          </FormGroup>
                          <hr />
                        </div>
                      );
                    })}
                </div>
                <div className="pull-right">
                  <Link to={"/learner-management"}>
                    <Button color="secondary mr-3" onClick={cancelForm}>
                      Cancel
                    </Button>
                  </Link>
                  <Button color="success" onClick={this.save}>
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default LearnerForm;
