import React, { Component } from "react";
import "./courseForm.scss";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";

class EditCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editCourse: {},
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.editCourse) {
      this.setState({
        editCourse: nextProps.editCourse
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { editCourse, errors } = this.state;
    errors[name] = "";
    editCourse[name] = value;
    this.setState({
      editCourse,
      errors
    });
  }

  handleValidation(e) {
    let { errors, editCourse } = this.state;
    const { name } = e.target;
    if (!editCourse[name]) errors[name] = `${name} is required`;
    this.setState({
      errors
    });
  }
  cancelModal() {
    this.setState({
      errors: {}
    });
    this.props.cancelModal();
  }
  handleClick() {
    let { errors, editCourse } = this.state;
    if (!editCourse.title) errors = "Course title is required";
    this.setState({
      errors
    });
    if (!errors.title) this.props.saveEditCourse(editCourse);
  }

  render() {
    const { isOpen, cancelModal, saveEditCourse } = this.props;
    const { editCourse, errors } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={cancelModal}>
        <ModalHeader toggle={cancelModal}>
          <div>
            <h4>Edit Course</h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="">
            <FormGroup>
              <div className="">
                <Label className="label-title" for="title">
                  Course Title
                </Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={editCourse.title}
                  onChange={this.handleChange}
                  onBlur={this.handleValidation}
                />
                {errors.title && (
                  <div className="help-block">Course Title is required</div>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label className="label-title" for="description">
                Course Description:
                <small>(optional)</small>
              </Label>
              <Input
                rows="2"
                cols="50"
                type="textarea"
                name="description"
                id="description"
                value={editCourse.description}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="pull-right">
            <Button color="secondary mr-3" onClick={this.cancelModal}>
              Cancel
            </Button>
            <Button color="success" onClick={this.handleClick}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditCourse;
