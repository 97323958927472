import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  CardHeader,
  Collapse,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Badge
} from "reactstrap";
import "./manageCourse.scss";
import { Checkbox as CustomeCheckbox } from "../../_components/InputComponents";
import Spinner from "../../components/spinner";
import EditCourse from "./editCourse";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import BackButton from "../../_components/BackButton";
import { Link } from "react-router-dom";

class ManageCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse,
      selectedTutorialIds: props.selectedTutorialIds,
      selectedCourseIds: props.selectedCourseIds,
      isOpen: false,
      editCourse: {},
      isDelete: false,
      deleteCourseId: "",
      isCopy: false,
      copyCourse: {},
      copyCourseName: "",
      errorCourseName: "",
      hoverTutorial: "",
      courseId: "",
      scroll: 0
    };
    this.assignTutorialsToCourse = this.assignTutorialsToCourse.bind(this);
    this.cancelManageCourse = this.cancelManageCourse.bind(this);
    this.toggleCard = this.toggleCard.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.copyConfirmation = this.copyConfirmation.bind(this);
    this.confirmCopy = this.confirmCopy.bind(this);
    this.cancelCopy = this.cancelCopy.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.onHoverTutorial = this.onHoverTutorial.bind(this);
    this.selectAllTutorials = this.selectAllTutorials.bind(this);
  }

  componentDidMount() {
    const { scroll } = this.props;
    let scrollDiv = document.getElementById("scroll");
    if (scrollDiv) scrollDiv.scrollTop = scroll;
  }

  selectAllTutorials(e) {
    const { tutorialData } = this.props;
    let { selectedTutorialIds } = this.state;
    const { checked } = e.target;
    if (checked) {
      selectedTutorialIds = [];
      tutorialData.map((l, i) => {
        selectedTutorialIds.push(l.id);
      });
      this.setState({
        selectedTutorialIds
      });
    } else {
      this.setState({
        selectedTutorialIds: []
      });
    }
  }

  toggleCard(e, cid) {
    let { selectedCourseIds } = this.state;
    let bool = _.includes(selectedCourseIds, cid);
    if (bool) {
      var index = selectedCourseIds.indexOf(cid);
      index !== -1 && selectedCourseIds.splice(index, 1);
    } else selectedCourseIds.push(cid);
    this.setState({
      collapse: { ...this.state.collapse, [e]: !this.state.collapse[e] },
      selectedCourseIds
    });
  }

  assignTutorialsToCourse(selectedId) {
    let { selectedTutorialIds } = this.state;
    let bool = _.includes(selectedTutorialIds, selectedId);
    if (bool) {
      var index = selectedTutorialIds.indexOf(selectedId);
      index !== -1 && selectedTutorialIds.splice(index, 1);
    } else selectedTutorialIds.push(selectedId);
    this.setState({
      selectedTutorialIds
    });
  }

  cancelManageCourse() {
    this.setState({
      collapse: { test: true },
      selectedTutorialIds: [],
      selectedCourseIds: []
    });
  }
  toggleModal(event, course) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isOpen: true,
      editCourse: { ...course }
    });
  }
  cancelModal() {
    this.setState({
      isOpen: false
    });
  }
  confirmDelete() {
    const { hoverTutorial, deleteCourseId, courseId } = this.state;
    if (deleteCourseId) this.props.deleteCourse(deleteCourseId);
    if (hoverTutorial) this.props.deleteCourseTutorial(hoverTutorial, courseId);
  }

  cancelDelete() {
    this.setState({
      isDelete: false,
      hoverTutorial: "",
      deleteCourseId: ""
    });
  }

  deleteConfirmation(e, id, cid) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.id === "delete-course")
      this.setState({
        isDelete: true,
        deleteCourseId: id
      });
    if (e.target.id === "delete-tutorial")
      this.setState({
        isDelete: true,
        hoverTutorial: id,
        courseId: cid
      });
  }

  handleChange(e) {
    this.setState({
      errorCourseName: "",
      copyCourseName: e.target.value
    });
  }

  handleValidation() {
    let { copyCourseName } = this.state;
    if (!copyCourseName)
      this.setState({
        errorCourseName: "Course Title is required"
      });
  }

  confirmCopy() {
    const { copyCourseName, copyCourse } = this.state;
    if (copyCourseName) {
      this.props.copyCourse(copyCourse.id, copyCourseName);
    } else {
      this.setState({
        errorCourseName: "Course Title is required"
      });
    }
  }

  cancelCopy() {
    this.setState({
      isCopy: false,
      copyCourseName: "",
      errorCourseName: "",
      showCopyModal: false
    });
  }

  copyConfirmation(e, c) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isCopy: true,
      copyCourse: c,
      copyCourseName: "Copy of " + c.title
    });
  }

  onHoverTutorial(tid) {
    this.setState({
      hoverTutorial: tid
    });
  }

  render() {
    const {
      tutorialData,
      isLoadingTutorials,
      courseData,
      isLoadingCourses,
      saveManageCourse,
      saveEditCourse,
      errorData,
      pathname
    } = this.props;
    const {
      isOpen,
      editCourse,
      isDelete,
      isCopy,
      copyCourseName,
      copyCourse,
      errorCourseName,
      collapse,
      selectedTutorialIds,
      selectedCourseIds,
      hoverTutorial
    } = this.state;
    return (
      <div className="animated fadeIn">
        <Row>
          <div className="col-md-12">
            <div className="course-heading mb-3">
              <h4 className="mb-2 d-inline-block">
                Manage Courses
                <small>
                  {pathname === "/course-management/addCourses" &&
                    ` : Add New Course`}
                </small>
              </h4>
              <div className="course-heading-link">
                {pathname !== "/course-management/addCourses" && (
                  <Link to="/course-management/addCourses">
                    <Button color="primary" className="d-inline">
                      Create New Course
                    </Button>
                  </Link>
                )}
                <div className="manage-course w-100">
                  <BackButton />
                  {/*<Button color={'secondary'} className="mr-2" onClick={this.cancelManageCourse}>Reset</Button>*/}
                  <Button
                    color={"success"}
                    onClick={() =>
                      saveManageCourse(
                        selectedTutorialIds,
                        selectedCourseIds,
                        collapse,
                        document.getElementById("scroll").scrollTop
                      )
                    }
                  >
                    Assign Tutorial(s)
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <Card className="manage-assign-card">
              <div className="p-lg-4">
                <div className="assign-title-header">
                  <h4>Assign Tutorials to Course</h4>
                  <div className="select-all">
                    <FormGroup check>
                      {tutorialData.length > 0 && (
                        <CustomeCheckbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={this.selectAllTutorials}
                            checked={
                              selectedTutorialIds.length === tutorialData.length
                            }
                          />
                          <p className="pt-1">Select All</p>
                        </CustomeCheckbox>
                      )}
                    </FormGroup>
                  </div>
                  <p>Select Tutorial(s) and then select Course(s)</p>
                </div>
                <Form className="custom-manage-course-form">
                  {tutorialData.map((t, i) => {
                    return (
                      <FormGroup
                        check
                        className="assign-form"
                        key={`${i}${t.id}`}
                      >
                        <CustomeCheckbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={() => {
                              this.assignTutorialsToCourse(t.id);
                            }}
                            checked={selectedTutorialIds.includes(t.id)}
                          />{" "}
                          <p
                            className={
                              selectedTutorialIds.includes(t.id)
                                ? "active-student mb-0"
                                : "mb-0"
                            }
                          >
                            {t.title}
                          </p>
                        </CustomeCheckbox>
                      </FormGroup>
                    );
                  })}
                </Form>
              </div>
            </Card>
          </div>
          <div className="col-md-9 col-12">
            <div className="custom-manage-course" id="scroll">
              {courseData.map((c, index) => {
                return (
                  <Card className="manage-courses-card" key={`${index}${c.id}`}>
                    <CardHeader
                      className="manage-courses-header-title"
                      data-event={index}
                    >
                      <CustomeCheckbox className="form-custom-label">
                        <Input
                          className="manage-courses-header-accodian"
                          type="checkbox"
                          checked={collapse[`${index}${c.id}details`]}
                          onClick={e => {
                            this.toggleCard(`${index}${c.id}details`, c.id);
                          }}
                          data-event={index}
                        />
                        <div style={{ width: "100%" }}>
                          <div className="d-inline-block ml-3 mt-1">
                            {!collapse[`${index}${c.id}details`] ? (
                              <i className="fa fa-chevron-right manage-courses-custom-icon" />
                            ) : (
                              <i className="fa fa-chevron-down" />
                            )}
                            <b className="pl-2 manage-courses-accodian-title">
                              {c.title}
                            </b>
                            &nbsp;
                            <Badge color="primary">{c.tutorials.length}</Badge>
                          </div>
                          {collapse[`${index}${c.id}details`] && (
                            <div className=" d-flex pt-sm-0 pt-3 d-sm-inline-block ml-sm-3 mt-sm-1 float-sm-right animated fadeIn">
                              <Button
                                className="mr-2 "
                                color="primary"
                                onClick={e => this.toggleModal(e, c)}
                              >
                                <i className=" pr-1 fa fa-pencil" />
                                Edit
                              </Button>
                              <Button
                                className="mr-2"
                                color="danger"
                                id="delete-course"
                                onClick={e => {
                                  this.deleteConfirmation(e, c.id);
                                }}
                              >
                                <i className=" pr-1 fa fa-trash" />
                                Delete
                              </Button>
                              <Button
                                className="mr-2"
                                color="warning"
                                onClick={e => {
                                  this.copyConfirmation(e, c);
                                }}
                              >
                                <i className="pr-1 fa fa-copy" />
                                Copy
                              </Button>
                            </div>
                          )}
                        </div>
                      </CustomeCheckbox>
                    </CardHeader>
                    <Collapse isOpen={collapse[`${index}${c.id}details`]}>
                      <CardBody>
                        <div className="manage-courses-accordion-body-main-div">
                          {c.tutorials.map((chap, i) => {
                            return (
                              <div
                                key={`${i}${chap.title}`}
                                className="manage-courses-accordion-body-sub-main-div"
                              >
                                <li
                                  className={
                                    selectedTutorialIds.includes(chap.id)
                                      ? "active-student mb-2"
                                      : "mb-2"
                                  }
                                  onMouseOver={() =>
                                    this.onHoverTutorial(chap.id)
                                  }
                                >
                                  {chap.title}
                                </li>
                                {hoverTutorial === chap.id && (
                                  <i
                                    className="fa fa-trash"
                                    id="delete-tutorial"
                                    onClick={e => {
                                      this.deleteConfirmation(e, chap.id, c.id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>
                );
              })}
            </div>
          </div>
        </Row>

        <EditCourse
          isOpen={isOpen}
          toggleModal={this.toggleModal}
          cancelModal={this.cancelModal}
          editCourse={editCourse}
          saveEditCourse={saveEditCourse}
        />
        <Modal isOpen={isDelete} className="modal-danger">
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <h4>Are you sure you want to delete this ? </h4>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.confirmDelete}>
              Yes
            </Button>
            <Button color="secondary" onClick={this.cancelDelete}>
              No
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={isCopy}
          toggle={this.cancelCopy}
          className="modal-primary"
        >
          <ModalHeader toggle={this.cancelCopy}>Copy Course</ModalHeader>
          <ModalBody>
            <div>
              <FormGroup>
                <div className="">
                  <Label className="label-title" for="title">
                    Course Title
                  </Label>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    value={copyCourseName}
                    onChange={this.handleChange}
                    onBlur={this.handleValidation}
                  />
                  {errorCourseName && (
                    <div className="help-block">Course Title is required</div>
                  )}
                  {errorData && errorData.length !== 0 && (
                    <div className="help-block">{errorData.title}</div>
                  )}
                </div>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.confirmCopy}>
              Copy
            </Button>
            <Button color="secondary" onClick={this.cancelCopy}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ManageCourse;
