import axiosI from "../../_helpers/service";
export const adminDashboardService = {
  getTutorials,
  getDashboardData,
  getCCUsersData,
  getCCDashboardData
};
function getTutorials() {
  return axiosI.get("/courses").then(response => {
    return response;
  });
}

function getDashboardData() {
  return axiosI.get("/dashboard/content_creator").then(response => {
    return response;
  });
}

function getCCUsersData() {
  return axiosI.get("/roles").then(response => {
    return response;
  });
}

function getCCDashboardData(userId) {
  return axiosI.get(`/dashboard/org_administrator/${userId}`).then(response => {
    return response;
  });
}
