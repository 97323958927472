import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";

import { userActions } from "../_actions";

class ThankyouPage extends React.Component {
  constructor(props) {
    super(props);
    // reset login status

    this.state = {
      email: "wilayats@gmail.com",
      password: "12345",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(userActions.logout());
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(userActions.login(email, password));
    }
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, submitted } = this.state;
    return (
      <div className="margin-minus-left">
        <div className="middlebx">
          <Row className="justify-content-center center-width">
            <Col md="8">
              <CardGroup className="mb-4">
                <Card className="p-4 transparent">
                  <CardBody className="text-center">
                    <div className="vertical-center">
                      <h1 className="mb-4">Thankyou for Signing up.</h1>
                      <p>
                        We have send an email to verification to your email
                        address.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}

const connectedThankyouPage = connect(mapStateToProps)(ThankyouPage);
export { connectedThankyouPage as ThankyouPage };
