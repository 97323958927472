import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import ManageLearner from "../../components/LearnerManagement/manageLearner";
import LearnerForm from "../../components/LearnerManagement/learnerForm";
import ImportLearners from "../../components/LearnerManagement/importLearners";
import authorize from "../../App/authorize";
import { Link } from "react-router-dom";
import "../../components/LearnerManagement/manageLearner.scss";
import { learnerActions } from "../../_actions/index";
import { Authorized } from "../../_components";
import { history } from "../../_helpers/history";
import Spinner from "../../components/spinner/index";
import { FileActions } from "../../_actions/file_selection.action";

class LearnerManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: { test: true },
      selectedStudentIds: [],
      selectedCoursesIds: [],
      saveStudents: "",
      studentData: [{ email: "", first_name: "", last_name: "" }],
      errMsg: [{ email: "", first_name: "", last_name: "" }],
      status: ""
    };

    this.openStudentForm = this.openStudentForm.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.saveStudentCourses = this.saveStudentCourses.bind(this);
    this.openImportStudentForm = this.openImportStudentForm.bind(this);
    this.saveLearnerToCourses = this.saveLearnerToCourses.bind(this);
    this.deleteLearnerCourse = this.deleteLearnerCourse.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(learnerActions.getLearnerData());
    dispatch(learnerActions.getCourseData());
  }

  openStudentForm() {
    this.setState({
      isAddNewStudents: true
    });
  }
  openImportStudentForm() {
    this.setState({
      isImportStudents: true
    });
  }
  cancelForm() {
    this.setState({
      isAddNewStudents: false,
      isImportStudents: false
    });
    history.push("/learner-management");
    this.cancel();
  }

  saveForm(learnerObj, errObj) {
    let obj = {
      learners: learnerObj
    };
    let f = 1;
    const { dispatch } = this.props;
    if (learnerObj.length > 0) {
      learnerObj.map(l => {
        if (!l.first_name.length || !l.last_name.length || !l.email.length) {
          f = 0;
        }
      });
      if (f) {
        dispatch(learnerActions.addLearnerData(obj)).then((res, err) => {
          if (res.data && res.data.data) {
            this.setState({
              studentData: [{ email: "", first_name: "", last_name: "" }],
              errMsg: [{ email: "", first_name: "", last_name: "" }]
            });
            history.push("/learner-management");
          }
        });
      } else {
        dispatch(FileActions.showFileContentMsg());
      }
    } else {
      dispatch(FileActions.showFileMessage("learner"));
    }
  }

  saveStudentCourses() {
    const { saveStudents } = this.state;
    if (saveStudents) {
      this.setState({
        saveStudents: "",
        selectedStudentIds: []
      });
      this.cancel();
    } else {
      this.setState({
        saveStudents: "deleteIcon"
      });
    }
  }

  cancel() {
    this.setState({
      selectedStudentIds: [],
      selectedStudentId: 0,
      saveStudents: "",
      selectedCoursesIds: [],
      collapse: { test: true },
      status: ""
    });
  }

  saveLearnerToCourses(selectedStudentIds, selectedCoursesIds, collapse) {
    const { dispatch } = this.props;
    this.setState({
      collapse: collapse,
      selectedCoursesIds: selectedCoursesIds
    });
    let objData = {
      course_ids: selectedCoursesIds,
      learner_ids: selectedStudentIds
    };
    if (selectedCoursesIds.length !== 0 && selectedStudentIds.length !== 0) {
      dispatch(learnerActions.assignLearnerToCourses(objData)).then(
        (res, err) => {
          if (res.data.status.status) {
            this.setState({
              selectedStudentIds: [],
              status: res.data.status.status
            });
          }
        }
      );
    } else {
      dispatch(FileActions.showFileMessage("learner-course"));
    }
  }

  deleteLearnerCourse(obj) {
    const { dispatch } = this.props;
    dispatch(learnerActions.removeLearnerFromCourse(obj));
  }
  render() {
    const {
      collapse,
      selectedCoursesIds,
      saveStudents,
      studentData,
      errMsg,
      status
    } = this.state;
    const {
      learnerData,
      courseData,
      errorData,
      isLoadingCourse,
      isLoadingLearner
    } = this.props;
    const userLogin = localStorage.getItem("user");
    return (
      <div className="animated fadeIn">
        {isLoadingCourse || isLoadingLearner ? (
          <Spinner />
        ) : (
          <Row className="custom-row bg-white pt-4">
            <Col md={12}>
              <div className="learner-heading">
                <div className="d-flex flex-column mb-2 align-items-baseline">
                  <h4 className="mb-2 d-inline-block">
                    <b>Manage Learners</b>
                    {window.location.pathname ===
                      "/learner-management/import-students" &&
                      " : Import Learners"}
                    {window.location.pathname ===
                      "/learner-management/addLearner-Form" &&
                      " : Add Learner(s)"}
                  </h4>

                  {window.location.pathname !==
                    "/learner-management/import-students" &&
                    window.location.pathname !==
                      "/learner-management/addLearner-Form" && (
                      <div className="btn-add-import">
                        <Link to={"/learner-management/addLearner-Form"}>
                          <Button
                            color="primary"
                            className="d-inline pull-right"
                          >
                            Enroll Learner(s)
                          </Button>
                        </Link>
                        <Link to={"/learner-management/import-students"}>
                          <Button
                            color="primary"
                            className="d-inline pull-right  mr-3"
                          >
                            Import Learners
                          </Button>
                        </Link>
                      </div>
                    )}
                </div>
              </div>
              <Authorized
                exact
                path="/learner-management"
                allowed={userLogin && authorize.roles.map(e => e.name)}
                component={() => (
                  <ManageLearner
                    isLoadingCourse={isLoadingCourse}
                    isLoadingLearner={isLoadingLearner}
                    courseData={courseData}
                    learnerData={learnerData}
                    collapse={collapse}
                    status={status}
                    selectedCoursesIds={selectedCoursesIds}
                    saveStudentCourses={this.saveStudentCourses}
                    saveStudents={saveStudents}
                    cancel={this.cancel}
                    deleteLearnerCourse={this.deleteLearnerCourse}
                    saveLearnerToCourses={this.saveLearnerToCourses}
                  />
                )}
              />
              <Authorized
                exact
                path="/learner-management/import-students"
                allowed={userLogin && authorize.roles.map(e => e.name)}
                component={() => (
                  <ImportLearners
                    courseData={courseData}
                    cancelForm={this.cancelForm}
                    saveForm={this.saveForm}
                    errorData={errorData}
                  />
                )}
              />
              <Authorized
                exact
                path="/learner-management/addLearner-Form"
                allowed={userLogin && authorize.roles.map(e => e.name)}
                component={() => (
                  <LearnerForm
                    cancelForm={this.cancelForm}
                    saveForm={this.saveForm}
                    studentData={studentData}
                    errMsg={errMsg}
                    errorData={errorData}
                  />
                )}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    learnerData: state.learner.learnerData,
    courseData: state.learner.courseData,
    status: state.learner.status,
    errorData: state.learner.errorData,
    isLoadingCourse: state.learner.isLoadingCourse,
    isLoadingLearner: state.learner.isLoadingLearner
  };
}

const connectedLearner = connect(
  mapStateToProps,
  null
)(LearnerManagementContainer);
export { connectedLearner as LearnerManagementContainer };
