import { previewConstants } from "../_constants";

export const windowObject = {
  setWindowObject
};

function setWindowObject(obj) {
  return dispatch => {
    return dispatch({
      type: previewConstants.SET_WINDOW_OBJECT,
      obj: obj
    });
  };
}
