export const helpCenterService = {
  getHelpCenter
};

function getHelpCenter() {
  let data = [
    {
      id: 1,
      topicName: "Topic 1",
      title: "Lorem  Ipsum",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 2,
      topicName: "Topic 2",
      title: "Quis Nostrud Exercitation",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 3,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 4,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 5,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 6,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 7,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 8,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 9,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    },
    {
      id: 10,
      topicName: "Topic 3",
      title: "Euismod Tempor Incididunt",
      Questions: [
        {
          id: 1,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 2,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        },
        {
          id: 3,
          title: "Consectetur adipiscing elit, sed do eiusmod tempor?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at atque cum deleniti doloribus ea eius, eveniet ex id laborum, magnam magni natus optio porro tenetur unde vitae voluptatem.Animi, aspernatur aut blanditiis, commodi consectetur deleniti dignissimos est illum laborum numquam officiis placeat quaerat repellat reprehenderit, sapiente sit ullam vitae voluptatum! At beatae consequuntur cumque eveniet maxime, quos voluptatem.Aperiam assumenda atque distinctio, dolore ea eligendi, enim, error et nihil obcaecati pariatur tempore totam veniam. A delectus dolorum hic laborum minus nisi temporibus. Cupiditate doloribus nemo non odio vitae."
        }
      ]
    }
  ];
  return new Promise((res, rej) => {
    res(data);
  });
}
