import React, { Component } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import moment from "moment";

import Validations from "../../_helpers/Validation";
import "./tutorialManagement.scss";

class Quiz extends Component {
  constructor() {
    super();
    this.state = {
      questionBlock: {
        type: "quiz",
        index: 0,
        quiz_data: {
          id: Number(moment().format("x")),
          title: "",
          type: "Formative",
          questions: [
            {
              type: "short_text",
              index: 0,
              id: Number(moment().format("x")),
              points: 0,
              text: "",
              correct_answers: [],
              correct_answer_feedback: "Correct",
              wrong_answer_feedback: "Incorrect, try another selection"
            }
          ]
        }
      },
      type: "formative",
      errors: [],
      edit: false,
      showPoint: true
    };

    this.generateQuestionBlock = this.generateQuestionBlock.bind(this);
    this.deleteQuestionBlock = this.deleteQuestionBlock.bind(this);
    this.questionTypeChange = this.questionTypeChange.bind(this);
    this.handleAddOptions = this.handleAddOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    let { editData, type } = this.props;
    let { quiz_data } = this.state.questionBlock;
    if ("data" in editData) {
      this.setState(
        {
          questionBlock: {
            ...this.state.questionBlock,
            quiz_data: {
              ...quiz_data,
              title: editData.rest,
              questions: [editData.data]
            }
          },
          type: type,
          edit: true,
          showPoint: ["boolean", "multiple_choice", "single_choice"].includes(
            editData.type
          )
            ? false
            : true
        },
        () => {
          console.log("state - ", this.state);
        }
      );
    } else this.setState({ type, edit: false });
  }

  generateQuestionBlock() {
    let { questionBlock } = this.state;
    let question = {
      id: Number(moment().format("x")),
      index: questionBlock.quiz_data.questions.length,
      type: "short_text",
      points: 0,
      text: "",
      correct_answers: [],
      correct_answer_feedback: "Correct",
      wrong_answer_feedback: "Incorrect, try another selection"
    };
    questionBlock.quiz_data.questions.push(question);
    this.setState({ questionBlock });
  }

  validate() {
    let {
      questionBlock: { quiz_data: { questions = [] } = {} },
      errors
    } = this.state;
    errors = [];
    questions.forEach((e, i) => {
      if (["boolean", "multiple_choice", "single_choice"].includes(e.type)) {
        if (e.options.length === 0)
          errors[i] = { ...errors[i], options: "options are required" };
        if (e.correct_answers.length > 0) {
          e.correct_answers.map((opt, p) => {
            if (!Boolean(opt))
              errors[i] = { ...errors[i], answers: "Select correct answer" };
          });
        }
      }
      //remove since in short and long question Answer is optional
      // else {
      //     if (!Boolean(e.correct_answers[0]))
      //         errors[i] = {...errors[i], 'answers': 'correct answer is required'};
      // }
      if (Boolean(Validations.validateQuizField(e.text, "question")))
        errors[i] = {
          ...errors[i],
          text: Validations.validateQuizField(e.text, "question")
        };
      if (
        Boolean(
          Validations.validateQuizField(
            e.correct_answer_feedback,
            "correct answer feedback"
          )
        )
      )
        errors[i] = {
          ...errors[i],
          correct_answer_feedback: Validations.validateQuizField(
            e.correct_answer_feedback,
            "correct answer feedback"
          )
        };
      if (
        Boolean(
          Validations.validateQuizField(
            e.wrong_answer_feedback,
            "wrong_answer_feedback"
          )
        )
      )
        errors[i] = {
          ...errors[i],
          wrong_answer_feedback: Validations.validateQuizField(
            e.wrong_answer_feedback,
            "wrong_answer_feedback"
          )
        };
    });
    this.setState({ errors });
    return errors.length;
  }

  onSave() {
    let err = this.validate();
    if (err === 0)
      this.props.handleSubmit(this.state.questionBlock, this.state.type);
  }

  handleChange(e) {
    let { questionBlock } = this.state;
    let { value, name, id } = e.target;
    if (name === "title") {
      questionBlock.quiz_data.title = value;
    }
    if (name === "type") {
      if (value === "boolean") {
        this.setState({ showPoint: false });
        questionBlock.quiz_data.questions[id]["points"] = 1;
        questionBlock.quiz_data.questions[id]["options"] = ["true", "false"];
        questionBlock.quiz_data.questions[id].correct_answers = [""];
      }
      if (value === "multiple_choice") {
        this.setState({ showPoint: false });
        questionBlock.quiz_data.questions[id]["points"] = 1;
        questionBlock.quiz_data.questions[id]["options"] = ["", ""];
        questionBlock.quiz_data.questions[id].correct_answers = [""];
      }
      if (value === "single_choice") {
        this.setState({ showPoint: false });
        questionBlock.quiz_data.questions[id]["points"] = 1;
        questionBlock.quiz_data.questions[id]["options"] = ["", ""];
        questionBlock.quiz_data.questions[id].correct_answers = [""];
      }
      if (value === "long_text") {
        questionBlock.quiz_data.questions[id]["points"] = 0;
        this.setState({ showPoint: true });
      }
      if (value === "short_text") {
        questionBlock.quiz_data.questions[id]["points"] = 0;
        this.setState({ showPoint: true });
      }
    }
    if (name === "option") {
      let qid = id.split(",")[0];
      let opid = id.split(",")[1];
      questionBlock.quiz_data.questions[qid].options[opid] = value;
    } else if (name === "correct_answers") {
      let qid = id.split(",")[0];
      let ansid = id.split(",")[1];
      questionBlock.quiz_data.questions[qid].correct_answers[ansid] = value;
    } else if (name === "points") {
      questionBlock.quiz_data.questions[id][name] = Number(value);
    } else {
      questionBlock.quiz_data.questions[id][name] = value;
    }
    this.setState({ questionBlock });
  }

  deleteQuestionBlock(key, option, e) {
    let id = e.target.id;
    let { questionBlock } = this.state;
    if (option === "option")
      questionBlock.quiz_data.questions[key]["options"].splice(id, 1);
    else if (option === "answer")
      questionBlock.quiz_data.questions[key]["correct_answers"].splice(id, 1);
    else questionBlock.quiz_data.questions.splice(id, 1);
    this.setState({ questionBlock });
  }

  questionTypeChange(e) {
    let { questionBlock } = this.state;
    let { name, value, id } = e.target;
    questionBlock.quiz_data.questions[id][name] = value;
    this.setState({ questionBlock });
  }

  handleAddOptions(e, name) {
    let { id } = e.target;
    let { questionBlock } = this.state;
    if (name === "option")
      questionBlock.quiz_data.questions[id].options.push("");
    else questionBlock.quiz_data.questions[id].correct_answers.push("");
    this.setState({ questionBlock });
  }

  render() {
    const { questionBlock, type, errors, edit, showPoint } = this.state;
    return (
      <div className="mb-10 modal-content-div">
        <div className="form-main mt-3">
          <div className="quiz-title">{`${type}`}</div>
          <div className="question-type">
            {/*{type ==='Formative Quizes' && <FormGroup>*/}
            {/*<Label htmlFor="tutorialTitle">Quiz Title:(optional)</Label>*/}
            {/*<Input type="text" name="title" value={questionBlock.quiz_data.title} onChange={this.handleChange}/>*/}
            {/*</FormGroup>}*/}
          </div>
          <div className="question-type">
            {questionBlock.quiz_data.questions.map((e, i) => {
              return (
                <FormGroup className="question-wrapper">
                  <div className="field-area">
                    <fieldset>
                      <legend>Question Type</legend>
                      <Input
                        type="select"
                        className="select-one"
                        name="type"
                        id={i}
                        onChange={this.handleChange}
                        value={e.type}
                      >
                        <option value="0">Please select</option>
                        <option value="long_text">Long Text</option>
                        <option value="boolean">True/False</option>
                        <option value="multiple_choice">Multiple Choice</option>
                        <option value="short_text">Short Text</option>
                        <option value="single_choice">Single Choice</option>
                      </Input>
                      <Input
                        type="select"
                        className={
                          showPoint
                            ? "select_number_pts non-select"
                            : "select_number_pts"
                        }
                        name="points"
                        id={i}
                        onChange={this.handleChange}
                        value={e.points}
                        disabled={showPoint}
                      >
                        {/*<option value={0} selected={(e.type==='short_text'||e.type==='long_text')}>0</option>*/}
                        {/*<option value={1} selected={(e.type==='boolean'||e.type==='multiple_choice'||e.type==='single_choice')}>1</option>*/}
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </Input>
                      <p className="small_text"> points</p>
                    </fieldset>
                  </div>
                  <div className="field-area">
                    <fieldset>
                      <legend>Question</legend>
                      <div className="dis-flex custom-full-width">
                        <input
                          type="text"
                          id={i}
                          name="text"
                          className="form-control"
                          value={e.text}
                          onChange={this.handleChange}
                          placeholder="question"
                        />
                        {i > 0 && (
                          <i
                            className="fa fa-trash custom-icon-trash"
                            id={i}
                            onClick={e =>
                              this.deleteQuestionBlock("", "question", e)
                            }
                          />
                        )}
                      </div>
                    </fieldset>
                  </div>
                  {errors[i] && errors[i]["text"] && (
                    <div className="help-block error-first-question">
                      {errors[i]["text"]}
                    </div>
                  )}
                  <div className="answer-block">
                    {e.type ? (
                      e.type === "short_text" || e.type === "long_text" ? (
                        <div className="field-area">
                          <fieldset>
                            <legend>Answer</legend>
                            <div>
                              <div>
                                <Input
                                  className="mt-2"
                                  type="text"
                                  id={`${i},0`}
                                  name="correct_answers"
                                  value={e.correct_answers[0]}
                                  placeholder="Answer"
                                  onChange={this.handleChange}
                                />
                              </div>
                              {errors[i] && errors[i]["answers"] && (
                                <div className="help-block">
                                  {errors[i]["answers"]}
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </div>
                      ) : (
                        <div className="field-area">
                          <fieldset>
                            <legend>Options</legend>
                            <div>
                              {e.options.map((ans, key) => {
                                return (
                                  <FormGroup
                                    check
                                    className="form-check-inline input-box-inline mt-2 pr-0 mb-2 pl-0"
                                  >
                                    <Input
                                      type="text"
                                      placeholder={ans}
                                      value={ans}
                                      name="option"
                                      onChange={this.handleChange}
                                      className="mr-2"
                                      id={`${i},${key}`}
                                    />
                                    <i
                                      className={
                                        !Boolean(
                                          [
                                            "multiple_choice",
                                            "single_choice"
                                          ].includes(e.type) &&
                                            key !== 0 && key !== 1
                                        )
                                          ? "fa fa-trash hide-icon"
                                          : "fa fa-trash"
                                      }
                                      id={key}
                                      onClick={e =>
                                        this.deleteQuestionBlock(i, "option", e)
                                      }
                                    />
                                  </FormGroup>
                                );
                              })}
                              {["multiple_choice", "single_choice"].includes(
                                e.type
                              ) && (
                                <i
                                  className="fa fa-plus-circle"
                                  id={i}
                                  onClick={e =>
                                    this.handleAddOptions(e, "option")
                                  }
                                >
                                  <span className="p-1">Add Options</span>
                                </i>
                              )}
                            </div>
                          </fieldset>
                          {errors[i] && errors[i]["options"] && (
                            <div className="help-block">
                              {errors[i]["options"]}
                            </div>
                          )}
                          <fieldset>
                            <legend>Answer</legend>
                            <div className="answer-add-area">
                              {e.correct_answers.map((ans, key) => {
                                return (
                                  <div className="drop-down">
                                    <Input
                                      type="select"
                                      className="answer-add-selection"
                                      name="correct_answers"
                                      id={`${i},${key}`}
                                      onChange={this.handleChange}
                                      value={ans}
                                    >
                                      <option></option>
                                      {e.options.map((opt, key) => {
                                        return (
                                          <option
                                            id={`${i},${key}`}
                                            value={opt}
                                          >
                                            {opt}
                                          </option>
                                        );
                                      })}
                                    </Input>
                                    <i
                                      className={
                                        !Boolean(
                                          ["multiple_choice"].includes(
                                            e.type
                                          ) && key !== 0
                                        )
                                          ? "fa fa-trash hide-icon"
                                          : "fa fa-trash"
                                      }
                                      id={key}
                                      onClick={e =>
                                        this.deleteQuestionBlock(i, "answer", e)
                                      }
                                    />
                                  </div>
                                );
                              })}
                              {["multiple_choice"].includes(e.type) && (
                                <i
                                  className="fa fa-plus-circle add-ans-icon"
                                  id={i}
                                  onClick={e =>
                                    this.handleAddOptions(e, "answer")
                                  }
                                >
                                  Add Answers
                                </i>
                              )}
                            </div>
                          </fieldset>
                          {errors[i] && errors[i]["answers"] && (
                            <div className="help-block">
                              {errors[i]["answers"]}
                            </div>
                          )}
                        </div>
                      )
                    ) : null}
                    <div className="field-area">
                      <fieldset disabled={showPoint}>
                        <legend>Feedback{showPoint}</legend>
                        <Input
                          type="text"
                          id={i}
                          name="correct_answer_feedback"
                          value={e.correct_answer_feedback}
                          onChange={this.handleChange}
                          className={showPoint ? "mb-2 non-select" : "mb-2"}
                          placeholder="Correct answer feedback"
                        />
                        {errors[i] && errors[i]["correct_answer_feedback"] && (
                          <div className="help-block">
                            {errors[i]["correct_answer_feedback"]}
                          </div>
                        )}
                        <Input
                          type="text"
                          id={i}
                          name="wrong_answer_feedback"
                          value={e.wrong_answer_feedback}
                          onChange={this.handleChange}
                          placeholder="Wrong answer Feedback"
                          className={showPoint ? "non-select" : " "}
                        />
                        {errors[i] && errors[i]["wrong_answer_feedback"] && (
                          <div className="help-block">
                            {errors[i]["wrong_answer_feedback"]}
                          </div>
                        )}
                      </fieldset>
                    </div>
                  </div>
                </FormGroup>
              );
            })}
            {!Boolean(edit) && (
              <span>
                <i
                  className="fa fa-plus-circle"
                  onClick={this.generateQuestionBlock}
                />
                Add Question
              </span>
            )}
          </div>
          <div className="save-btn-tutorial mb-3 mt-2 pr-2 text-right w-100">
            <Button
              type="button"
              color="primary"
              className="btn btn-primary custom-save-btn w-25"
              onClick={this.onSave}
            >
              {" "}
              Save{" "}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Quiz;
