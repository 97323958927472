import axiosI from "../../_helpers/service";
export const roleService = {
  getAllUsers,
  getAllUsersRoles,
  getRolesNav,
  deleteSelectedUser,
  saveRoles
};

function getAllUsers() {
  return axiosI.get(`/users`).then(response => {
    return new Promise((res, rej) => {
      res(response.data);
    });
  });
}
function getAllUsersRoles() {
  return axiosI.get(`/roles`).then(response => {
    return new Promise((res, rej) => {
      res(response.data);
    });
  });
}
function getRolesNav() {
  let userRolesNavs = [
    {
      roleName: "Administrator",
      navs: [
        { navId: "1", navItem: "Role Management" },
        { navId: "2", navItem: "Course Management" },
        { navId: "3", navItem: "Credential Management" },
        { navId: "4", navItem: "Tutorial Management" },
        { navId: "5", navItem: "Learner Management" }
      ]
    },
    {
      roleName: "Curriculum Creator",
      navs: [
        { navId: "1", navItem: "Create Tutorial" },
        { navId: "2", navItem: "Publish Tutorial" },
        { navId: "3", navItem: "System Wide Configuration" },
        { navId: "4", navItem: "Account Management" },
        { navId: "5", navItem: "Course Management" }
      ]
    },
    {
      roleName: "Learner",
      navs: [
        { navId: "1", navItem: "Complete Tutorial" },
        { navId: "2", navItem: "Publish Tutorial" },
        { navId: "3", navItem: "Take Summative Quize" },
        { navId: "4", navItem: "Account Management" },
        { navId: "5", navItem: "Add Tutorial via Email" }
      ]
    },
    {
      roleName: "Free Curriculum Creator ",
      navs: [
        { navId: "1", navItem: "Create Tutorial" },
        { navId: "2", navItem: "Publish Tutorial" },
        { navId: "3", navItem: "System Wide Configuration" },
        { navId: "4", navItem: "Account Management" },
        { navId: "5", navItem: "Course Management" }
      ]
    }
  ];
  return new Promise((res, rej) => {
    res(userRolesNavs);
  });
}

function deleteSelectedUser(obj) {
  return axiosI.post(`/roles/remove`, obj);
}
function saveRoles(obj) {
  return axiosI.post(`/roles/assign`, obj).then(response => {
    return new Promise((res, rej) => {
      res(response.data);
    });
  });
}
