import { checkAnswerConstants } from "../../_constants/Learner/checkAnswer.constants";
import { checkAnswerService } from "../../_services";
import { toastConstants } from "../../_constants/index";

export const checkAnswerAction = {
  checkAnswer,
  editAnswer
};
function checkAnswer(tutorialId, ansObj) {
  return dispatch =>
    checkAnswerService.checkQuizAnswer(tutorialId, ansObj).then(
      data => {
        if (ansObj.type === "quiz") {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "SUCCESS", data: "Answer saved." }
          });
        }
        return dispatch(success(data));
      },
      error => {
        if (ansObj.type === "quiz") {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "ERROR", data: "Something went wrong." }
          });
        } else {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "ERROR", data: "Something went wrong." }
          });
        }
        return dispatch(failure(error.toString()));
      }
    );
  function success(data) {
    return { type: checkAnswerConstants.CHECK_QUIZ_ANSWER_SUCCESS, data };
  }
  function failure(error) {
    return { type: checkAnswerConstants.CHECK_QUIZ_ANSWER_FAILURE, error };
  }
}
function editAnswer(tutorialId, answerId, editAnsObj) {
  return dispatch =>
    checkAnswerService.editQuizAnswer(tutorialId, answerId, editAnsObj).then(
      data => {
        if (editAnsObj.type === "quiz") {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "SUCCESS", data: "Answer saved." }
          });
        }
        return dispatch(success(data));
      },
      error => {
        let errData = error.response && error.response.data.errors.messages[0];
        if (editAnsObj.type === "quiz") {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "ERROR", data: errData }
          });
        } else {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "ERROR", data: "Something went wrong." }
          });
        }
        return dispatch(failure(errData));
      }
    );
  function success(data) {
    return { type: checkAnswerConstants.CHECK_QUIZ_ANSWER_SUCCESS, data };
  }
  function failure(error) {
    return { type: checkAnswerConstants.CHECK_QUIZ_ANSWER_FAILURE, error };
  }
}
