import React from "react";
import { FormGroup, Input, Label, Button } from "reactstrap";

export default class BooleanQuestion extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      index,
      renderHTML,
      ques,
      handleQuizSelection,
      activeOption,
      handleCheckAnswer,
      quizData,
      buttonName
    } = this.props;

    return(
      <div className="form-bool" id={index}>
        <fieldset>
          <legend>{renderHTML(`Q. ${ques.text}`)}</legend>
          {ques.options.map((opt, index) => {
            return (
              <div className="form-boolean" id={index} key={index}>
                <FormGroup check className="radio check-box">
                  <Label check aria-labelledby={index}>
                    <Input
                      className="form-check-input"
                      type="radio"
                      id={index}
                      name={`boolean_${ques.id}`}
                      value={opt}
                      onChange={e =>
                        handleQuizSelection(ques.id, ques.type, e)
                      }
                      checked={
                        ques.selectedValue &&
                        activeOption(opt, ques.selectedValue)
                      }
                    />
                    {opt}
                  </Label>
                </FormGroup>
              </div>
            );
          })}
          <Button
            className="btn-text"
            aria-label="Submit to answer the quiz question"
            onClick={() =>
              handleCheckAnswer(
                ques.selectedValue,
                quizData.id,
                ques.id,
                ques.correct_answer_feedback,
                ques.wrong_answer_feedback
              )
            }
          >
            {buttonName}
          </Button>
        </fieldset>
      </div>
    )
  }
}
