import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import "./Account-Management.scss";
import { history } from "../../../_helpers/history";
import { userActions } from "../../../_actions";
import BackButton from "../../../_components/BackButton";

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPassword: {},
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }
  handleChange(e) {
    const { userPassword, errors } = this.state;
    const { name, value } = e.target;
    userPassword[name] = value;
    errors[name] = "";
    this.setState({
      userPassword,
      errors
    });
  }
  save(e) {
    e.preventDefault();
    let { userPassword, errors } = this.state;
    if (!userPassword.current_password)
      errors["current_password"] = "Current Password is required.";

    if (!userPassword.password)
      errors["password"] = "New Password is required.";

    if (!userPassword.password_confirmation)
      errors["password_confirmation"] = "Confirm Password is required.";
    else if (userPassword.password !== userPassword.password_confirmation)
      errors["password_confirmation"] =
        "New Password and confirm Password not matched.";

    this.setState({
      errors
    });

    if (
      !errors.current_password &&
      !errors.password &&
      !errors.password_confirmation
    ) {
      const { dispatch } = this.props;
      dispatch(userActions.changePassword(userPassword)).then((res, err) => {
        if (!res.error) {
          history.push("/");
        }
      });
    }
  }
  render() {
    const { userPassword, errors } = this.state;
    const { errorData = [] } = this.props;
    return (
      <div className="acc-page animated fadeIn">
        <h3>
          Account Management: <span>Change Password</span>
        </h3>
        <div className="col-lg-5 col-md-8 acc-form-wrapper">
          <div>
            {errorData.length > 0 &&
              errorData.map((e, i) => {
                return (
                  <div key={i} className="user-form-custom-alert">
                    {<li className="mb-0">{e}</li>}
                  </div>
                );
              })}
            <Form>
              <div className="form-data-wrapper">
                <FormGroup>
                  <Label>Enter Current Password</Label>
                  <InputGroup>
                    <InputGroupAddon
                      className="custom-input-icon"
                      addonType="prepend"
                    >
                      <i className="fa fa-lock"></i>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      className="left-borderless"
                      name="current_password"
                      id="current_password"
                      value={userPassword.current_password}
                      onChange={this.handleChange}
                    />
                    {errors.current_password && (
                      <div className="help-block">
                        {errors.current_password}
                      </div>
                    )}
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label>New Password</Label>
                  <InputGroup>
                    <InputGroupAddon
                      className="custom-input-icon"
                      addonType="prepend"
                    >
                      <i className="fa fa-lock"></i>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      className="left-borderless"
                      name="password"
                      id="password"
                      value={userPassword.password}
                      onChange={this.handleChange}
                    />
                    {errors.password && (
                      <div className="help-block">{errors.password}</div>
                    )}
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label>Confirm New Password</Label>
                  <InputGroup>
                    <InputGroupAddon
                      className="custom-input-icon"
                      addonType="prepend"
                    >
                      <i className="fa fa-lock"></i>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      className="left-borderless"
                      name="password_confirmation"
                      id="password_confirmation"
                      value={userPassword.password_confirmation}
                      onChange={this.handleChange}
                    />
                    {errors.password_confirmation && (
                      <div className="help-block">
                        {errors.password_confirmation}
                      </div>
                    )}
                  </InputGroup>
                </FormGroup>
              </div>
            </Form>
            <div className="acc-from-submition-wrapper">
              <BackButton />
              <Button color="success" className="mr-2" onClick={this.save}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
