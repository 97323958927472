import axiosI from "../_helpers/service";

export const contentCreatorService = {
  getTutorials,
  getTutorial,
  getCourses,
  copyTutorial,
  deleteTutorial,
  addTutorial,
  updateTutorial,
  shareTutorial,
  assignedTutorialsToCourses,
  getTutorialList,
  getTutorialAnswer,
  mediaService,
  editMediaService,
};

function getTutorialList() {
  return axiosI.get("/tutorials/list.json").then((response) => {
    return response;
  });
}

function getTutorials() {
  return axiosI.get("/tutorials.json").then((response) => {
    return response;
  });
}

function getTutorial(id) {
  return axiosI.get(`/tutorials/${id}.json`).then((response) => {
    return response;
  });
}

function getCourses() {
  return axiosI.get(`/courses`).then((response) => {
    return response;
  });
}

function copyTutorial(data) {
  return axiosI
    .post(`/tutorials/${data.id}/copy`, { title: data.title })
    .then((response) => {
      return response;
    });
}

function deleteTutorial(id) {
  return axiosI.delete(`/tutorials/${id}.json`).then((response) => {
    return response;
  });
}

function addTutorial(tutorial) {
  return axiosI.post(`/tutorials.json`, tutorial).then((response) => {
    return response;
  });
}

function updateTutorial(tutorial) {
  return axiosI
    .patch(`/tutorials/${tutorial.id}.json`, tutorial)
    .then((response) => {
      return response;
    });
}

function shareTutorial(tutorialId, shareData) {
  return axiosI
    .post(`/tutorials/${tutorialId}/share`, shareData)
    .then((response) => {
      return response;
    });
}

function assignedTutorialsToCourses(obj) {
  return axiosI.post("/tutorials/share_with_courses", obj).then((response) => {
    return response;
  });
}

function getTutorialAnswer(tutorialId) {
  return axiosI
    .get(`/simple_answers/download/tutorial/${tutorialId}`)
    .then((response) => {
      return response;
    });
}

function mediaService(obj) {
  return axiosI
    .post("/media", obj)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

function editMediaService(id, data) {
  return axiosI
    .patch(`/media/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}
