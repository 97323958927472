export const contentCreatorConstants = {
  GET_ALL_TUTORIALS_SUCCESS: "GET_ALL_TUTORIALS_SUCCESS",
  GET_ALL_TUTORIALS_FAILURE: "GET_ALL_TUTORIALS_FAILURE",
  GET_ALL_TUTORIALS_REQUEST: "GET_ALL_TUTORIALS_REQUEST",
  GET_TUTORIAL: "GET_TUTORIAL",
  GET_TUTORIAL_REQUEST: "GET_TUTORIAL_REQUEST",
  GET_TUTORIAL_SUCCESS: "GET_TUTORIAL_SUCCESS",
  GET_TUTORIAL_FAILURE: "GET_TUTORIAL_FAILURE",
  GET_COURSES_REQUEST: "GET_COURSES_REQUEST",
  GET_COURSES_SUCCESS: "GET_COURSES_SUCCESS",
  GET_COURSES_FAILURE: "GET_COURSES_FAILURE",
  COPY_TUTORIAL_SUCCESS: "COPY_TUTORIAL_SUCCESS",
  COPY_TUTORIAL_FAILURE: "COPY_TUTORIAL_FAILURE",
  DELETE_TUTORIAL_SUCCESS: "DELETE_TUTORIAL_SUCCESS",
  DELETE_TUTORIAL_FAILURE: "DELETE_TUTORIAL_FAILURE",
  CREATE_TUTORIAL_SUCCESS: "CREATE_TUTORIAL_SUCCESS",
  CREATE_TUTORIAL_FAILURE: "CREATE_TUTORIAL_FAILURE",

  ADD_TAB1_DATA: "ADD_TAB1_DATA",
  ADD_TAB2_DATA: "ADD_TAB2_DATA",
  ADD_TAB3_DATA: "ADD_TAB3_DATA",
  ADD_TAB4_DATA: "ADD_TAB4_DATA",
  ADD_TAB5_DATA: "ADD_TAB5_DATA",
  ADD_MEDIA_SUCCESS: "ADD_MEDIA_SUCCESS",
  ADD_MEDIA_FAILURE: "ADD_MEDIA_FAILURE",
  EDIT_MEDIA_SUCCESS: "EDIT_MEDIA_SUCCESS",
  EDIT_MEDIA_FAILURE: "EDIT_MEDIA_FAILURE",

  REMOVE_TAB_DATA: "REMOVE_TAB_DATA",

  UPDATE_TUTORIAL_REQUEST: "UPDATE_TUTORIAL_REQUEST",
  UPDATE_TUTORIAL_SUCCESS: "UPDATE_TUTORIAL_SUCCESS",
  UPDATE_TUTORIAL_FAILURE: "UPDATE_TUTORIAL_FAILURE",

  SHARE_TUTORIAL_REQUEST: "SHARE_TUTORIAL_REQUEST",
  SHARE_TUTORIAL_SUCCESS: "SHARE_TUTORIAL_SUCCESS",
  SHARE_TUTORIAL_FAILURE: "SHARE_TUTORIAL_FAILURE",

  CONTENT_TAB_DATA: "CONTENT_TAB_DATA",

  REMOVE_EDIT_DATA: "REMOVE_EDIT_DATA",

  GET_TUTORIAL_LIST_REQUEST: "GET_TUTORIAL_LIST_REQUEST",
  GET_TUTORIAL_LIST_SUCCESS: "GET_TUTORIAL_LIST_SUCCESS",
  GET_TUTORIAL_LIST_FAILURE: "GET_TUTORIAL_LIST_FAILURE",

  GET_TUTORIAL_BY_ID_REQUEST: "GET_TUTORIAL_BY_ID_REQUEST",
  GET_TUTORIAL_BY_ID_SUCCESS: "GET_TUTORIAL_BY_ID_SUCCESS",
  GET_TUTORIAL_BY_ID_FAILURE: "GET_TUTORIAL_BY_ID_FAILURE",

  GET_TUTORIAL_ANSWER_REQUEST: "GET_TUTORIAL_BY_ID_REQUEST",
  GET_TUTORIAL_ANSWER_SUCCESS: "GET_TUTORIAL_BY_ID_SUCCESS",
  GET_TUTORIAL_ANSWER_FAILURE: "GET_TUTORIAL_BY_ID_FAILURE"
};
