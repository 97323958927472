import React, { Component } from "react";
import "./courseForm.scss";
import { Row, Col, Button, Form, FormGroup, Input, Label } from "reactstrap";

class CourseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCourse: this.props.courses,
      errors: this.props.errors
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleValidation = this.handleValidation.bind(this);

    this.generateCourseForm = this.generateCourseForm.bind(this);
    this.deleteCourseForm = this.deleteCourseForm.bind(this);
  }
  handleChange(e, i) {
    const { name, value } = e.target;
    const { newCourse, errors } = this.state;
    errors[i][name] = "";
    newCourse[i][name] = value;
    this.setState({
      newCourse,
      errors
    });
  }
  handleValidation(e, i) {
    let { errors, newCourse } = this.state;
    const { name, value } = e.target;
    if (!newCourse[i][name]) errors[i][name] = `${name} is required`;
    this.setState({
      errors
    });
  }
  handleClick() {
    let { errors, newCourse } = this.state;
    newCourse.map((c, i) => {
      if (!c.title) {
        errors[i].title = "title is required";
      }
    });
    this.setState({
      errors
    });
    if (!errors.find(e => e.title)) {
      this.props.saveCourses(newCourse, this.state.errors);
    }
  }
  generateCourseForm(index) {
    const { newCourse, errors } = this.state;
    let obj = {
      title: "",
      description: "",
      user_id: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).id
        : ""
    };
    let err = { title: "" };
    newCourse.push(obj);
    errors.push(err);
    this.setState({
      newCourse,
      errors
    });
  }
  deleteCourseForm(index) {
    const { newCourse, errors } = this.state;
    newCourse.splice(index, 1);
    errors.splice(index, 1);
    this.setState({
      newCourse,
      errors
    });
  }
  render() {
    const { errorData, courses, errors, cancelForm } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col md={5}>
            <Form>
              <div className="course-form">
                <div className="d-flex">
                  <Col md={8} className="pl-0">
                    <h4>Add New Course</h4>
                  </Col>
                  <Col md={4} className="text-right">
                    <div
                      className="custom-i"
                      onClick={() => {
                        this.generateCourseForm(courses.length);
                      }}
                    >
                      <i
                        className="fa fa-plus-circle"
                        style={{ cursor: "pointer" }}
                      />
                      Add More
                    </div>
                  </Col>
                </div>
                {this.state.newCourse.map((c, i) => {
                  return (
                    <div className="course-detail-group" key={i}>
                      <FormGroup>
                        <div className="custome-course">
                          <Label className="label-title" for="title">
                            Course Title
                          </Label>
                          <i
                            className="fa fa-trash"
                            onClick={() => {
                              this.deleteCourseForm(i);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            value={c.title}
                            onChange={e => {
                              this.handleChange(e, i);
                            }}
                            onBlur={e => {
                              this.handleValidation(e, i);
                            }}
                          />

                          {errors[i].title && (
                            <div className="help-block">
                              Course Title is required
                            </div>
                          )}
                          {errorData[i] && errorData[i].length !== 0 && (
                            <div className="help-block">{errorData[i]}</div>
                          )}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="label-title" for="description">
                          Course Description:
                          <small>(optional)</small>
                        </Label>
                        <Input
                          rows="2"
                          cols="50"
                          type="textarea"
                          name="description"
                          id="description"
                          value={c.description}
                          onChange={e => {
                            this.handleChange(e, i);
                          }}
                        />
                      </FormGroup>
                    </div>
                  );
                })}
              </div>
            </Form>
            <div className="pull-right">
              <Button color="secondary mr-3" onClick={cancelForm}>
                Cancel
              </Button>
              <Button color="success" onClick={this.handleClick}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CourseForm;
