export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));
  let headerJson = JSON.parse(localStorage.getItem("headerJson"));
  headerJson.Authorization = "Bearer fake-jwt-token"; //TODO while we remove fake backend we need to remove Authorization header
  if (user && headerJson) {
    return headerJson;
  } else {
    return {};
  }
}
