import React from "react";
import { Input, Button } from "reactstrap";

export default class ShortTextQuestion extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      index,
      renderHTML,
      ques,
      handleTextValue,
      handleCheckAnswer,
      quizData,
      buttonName,
      short_text
    } = this.props;

    return(
      <div className="quiz-content" id={index}>
        <p>{renderHTML(`Q. ${ques.text}`)}</p>
        <Input
          type="text"
          id="Text1"
          className="inputText"
          name={`short_text_${ques.id}`}
          onChange={handleTextValue}
        />
        <Button
          className="btn-text"
          aria-label="Submit to answer the quiz question"
          onClick={() => {
            // sent ques_type remove feedback for short_text
            //   handleCheckAnswer(short_text ,quizData.id , ques.id ,ques.correct_answer_feedback , ques.wrong_answer_feedback, ques.type)
            handleCheckAnswer(
              short_text,
              quizData.id,
              ques.id,
              "Thank you for your answer.",
              "Thank you for your answer.",
              ques.type
            );
          }}
        >
          {buttonName}
        </Button>
      </div>
    )
  }
}
