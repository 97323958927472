import axiosI from "../../_helpers/service";

export const importTutorialsService = {
  getCurriculumCreators,
  getCourse,
  importTutorials,
  shareTutorialsToCourses,
  shareTutorialsToUsers
};

function getCurriculumCreators() {
  return axiosI.get(`/roles`).then(response => {
    return new Promise((res, rej) => {
      res(response.data);
    });
  });
}
function getCourse() {
  return axiosI.get("/courses").then(response => {
    return response;
  });
}
function importTutorials(tutorials) {
  return axiosI.post("/tutorials/import", tutorials).then(response => {
    return response;
  });
}
function shareTutorialsToCourses(obj) {
  return axiosI.post("/tutorials/share_with_courses", obj).then(response => {
    return response;
  });
}
function shareTutorialsToUsers(id, obj) {
  return axiosI.post(`/tutorials/${id}/share`, obj).then(response => {
    return response;
  });
}
