import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { history } from "../../_helpers";
import Validations from "../../_helpers/Validation";
import AddChapter from "../../components/tutorial_management/addChapter";
import AddMedia from "../../components/tutorial_management/addMedia";
import AddText from "../../components/tutorial_management/addText";
import EditFirstPage from "../../components/tutorial_management/editFirstPage";
import Quiz from "../../components/tutorial_management/Quiz";
import "../../components/tutorial_management/tutorialManagement.scss";
import Tutorial from "../../components/tutorial_management/tutorialManagement";
import SummativeQuiz from "../../components/tutorial_management/summativeQuiz";
import { TutorialLookFeel } from "../../containers/TutorialLookFeel";
import TutorialDetail from "../../components/CreateTutorial/tutorialDetail";
import QuizSetting from "../../components/CreateTutorial/quizSetting";
import Spinner from "../../components/spinner";
import { contentCreatorActions } from "../../_actions";
import AlternativeModals from "../../_components/AlternativeModals";
import FirefoxModal from "../../_components/FirefoxModal";
import { tutorialActions } from "../../_actions/LearnerActions/tutorial.actions";
import tutorialChannelSubscription from "../../_helpers/tutorialChannelSubscription";
import cable from "../../_helpers/cable";

class EditTutorialPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      activeSubTab: "1",
      showTab: "0",
      tab: "0",
      edit: false,
      modal: false,
      CurriculumTutorial: {},
      activeButton: "",
      chapterId: "",
      editData: {},
      elIndex: 0,
      SummativeQuiz: [],
      formDetail: {},
      createTutorial: {},
      callAPI: true,
      errors: [],
      mode: "add",
      mediaActiveTab: "",
      tutorialId: 0,
      PreviewState: "",
      SaveMode: "Add",
      step: "",
      tutorial: {},
      isAlternative: false,
      isFirefoxModal: false,
      setWindowData: 0,
      tutorialIndex: 0,
      mediaUrl: "",
      mediaProcess: false,
      _isMounted: false,
    };

    if (!props.createTutorial) {
      if (!props.channel) {
        const index = window.location.pathname.split("/")[3];
        const handleChannelResponse = (data, tutorialId, that) => {
          const { is_editing: isEditing } = data;

          const {
            user: { email },
          } = this.props;

          if (isEditing) {
            this.props.dispatch(
              tutorialActions.someoneIsEditingTutorial({
                editingUserEmail: data.user.email,
                currentUserEmail: email,
              })
            );
          } else {
            this.props.dispatch(tutorialActions.storeChannelInRedux(that));
            history.push({
              pathname: `/tutorial-management/edit/${tutorialId}`,
              state: this.props.index,
            });
          }
        };

        tutorialChannelSubscription({
          dispatch: this.props.dispatch,
          tutorialId: index,
          handleChannelResponse,
        });

        history.push({
          pathname: "/tutorial-management",
          state: {
            index,
          },
        });
      } else {
        this.channel = this.props.channel;
      }
    }
  }

  componentDidMount() {
    this.setState({ _isMounted: true });
    let index = window.location.pathname.split("/")[3];
    let { state } = this.props;
    let stateObj = state
      ? state
      : this.props.location
      ? this.props.location.state
      : "";
    if (index) {
      this.setState({
        activeSubTab: "3",
        SaveMode: "Update",
        tutorialIndex: index,
      });
      this.props.dispatch(contentCreatorActions.getTutorialData(index));
    }
    if (Boolean(stateObj)) {
      this.setState({
        activeSubTab: stateObj.tab,
        SaveMode: "Update",
        tab: stateObj.tab,
        step: "Next",
      });
    }
    this.props.dispatch(contentCreatorActions.getCoursesData());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let {
      createTutorial,
      activeSubTab,
      tab,
      callAPI,
      PreviewState,
      SaveMode,
      step,
    } = this.state;
    let {
      CurriculumTutorial: { editTutorial },
    } = this.props;

    console.log("console, nextProps", nextProps);

    let index = window.location.pathname.split("/")[3];
    let tabs = ["3", "4", "5"].includes(tab)
      ? tab
      : (activeSubTab - 1).toString();

    console.log("console, tabs", tabs);

    if (callAPI) {
      if (tabs === "1") {
        this.setState({
          createTutorial: { ...createTutorial, ...nextProps.TabsData.tab1 },
        });
      }
      if (tabs === "2") {
        this.setState({
          createTutorial: {
            // ...createTutorial,
            ...nextProps.TabsData.tab1,
            settings: {
              ...nextProps.TabsData.tab1.settings,
              ...nextProps.TabsData.tab2,
            },
          },
        });
      }
      if (tabs === "3") {
        let obj = {
          // ...createTutorial,
          ...nextProps.TabsData.tab1,
          settings: {
            ...nextProps.TabsData.tab1.settings,
            ...nextProps.TabsData.tab2,
          },
          content:
            Object.keys(nextProps.TabsData.tab3).length > 0
              ? { ...nextProps.TabsData.tab3 }
              : { tutorial: [] },
          summative_test: { summative_test: [] },
        };
        let editedData3 = {
          ...editTutorial.data,
          ...nextProps.TabsData.tab1,
          ...nextProps.TabsData.tab2,
        };

        if (editedData3?.skip_desktop_app_prompt !== "undefined") {
          editedData3.settings.skip_desktop_app_prompt =
            editedData3?.skip_desktop_app_prompt;
        }

        this.setState({ createTutorial: obj, callAPI: false }, () => {
          let action = index
            ? `updateTutorialData`
            : SaveMode === "Update"
            ? `updateTutorialData`
            : `addTutorialData`;
          let data =
            SaveMode === "Update" ? editedData3 : this.state.createTutorial;
          if (["Save", "SaveAs"].includes(step)) {
            this.props
              .dispatch(contentCreatorActions[`${action}`](data))
              .then((res, err) => {
                if (res) {
                  this.setState({
                    createTutorial: res.data.data,
                    tutorialId: res.data.data.id,
                  });
                  this.props.dispatch(
                    contentCreatorActions.AddTabsData(res.data.data)
                  );
                  if (Boolean(PreviewState)) {
                    if (res.data.data.is_frame_busted) {
                      // this.setTutorial(res.data.data);
                      this.setState({
                        isToggle: true,
                        setWindowData: 3,
                      });
                    } else
                      history.push({
                        pathname: `/tutorial-page/${this.state.tutorialId}/authorized/${PreviewState}`,
                        state: {
                          page: index
                            ? `/tutorial-management/edit/${res.data.data.id}`
                            : `/create-tutorial`,
                          data: res.data.data,
                          tab: "3",
                        },
                      });
                    this.setState({ PreviewState: "" });
                  }
                  this.setState({ SaveMode: "Update" });
                }
              });
          }
        });
      }
      if (tabs === "4") {
        let obj = {
          // ...createTutorial,
          ...nextProps.TabsData.tab1,
          settings: {
            ...nextProps.TabsData.tab1.settings,
            ...nextProps.TabsData.tab2,
          },
          content:
            Object.keys(nextProps.TabsData.tab3).length > 0
              ? { ...nextProps.TabsData.tab3 }
              : { tutorial: [] },
          summative_test:
            Object.keys(nextProps.TabsData.tab4).length > 0
              ? nextProps.TabsData.tab4
              : { summative_test: [] },
        };
        let editedData4 = {
          ...editTutorial.data,
          ...nextProps.TabsData.tab1,
          ...nextProps.TabsData.tab2,
          content:
            Object.keys(nextProps.TabsData.tab3).length > 0
              ? { ...nextProps.TabsData.tab3 }
              : { tutorial: [] },
        };
        this.setState({ createTutorial: obj, callAPI: false }, () => {
          let action = index
            ? `updateTutorialData`
            : SaveMode === "Update"
            ? `updateTutorialData`
            : `addTutorialData`;
          //used editTutorial.data instead of editedData4 ,since it had tutorial:[] while previewing from Final Quiz
          let data =
            SaveMode === "Update"
              ? editTutorial.data
              : this.state.createTutorial;
          if (["Save", "SaveAs"].includes(step)) {
            this.props
              .dispatch(contentCreatorActions[`${action}`](data))
              .then((res, err) => {
                if (res) {
                  this.setState({
                    createTutorial: res.data.data,
                    tutorialId: res.data.data.id,
                  });
                  this.props.dispatch(
                    contentCreatorActions.AddTabsData(res.data.data)
                  );
                  if (Boolean(PreviewState)) {
                    if (res.data.data.is_frame_busted) {
                      // this.setTutorial(res.data.data)
                      this.setState({
                        isToggle: true,
                        setWindowData: 4,
                      });
                    } else
                      history.push({
                        pathname: `/tutorial-page/${this.state.tutorialId}/authorized/${PreviewState}`,
                        state: {
                          page: index
                            ? `/tutorial-management/edit/${res.data.data.id}`
                            : `/create-tutorial`,
                          data: res.data.data,
                          tab: "4",
                        },
                      });
                    this.setState({ PreviewState: "", SaveMode: "Update" });
                  }
                  this.setState({ SaveMode: "Update" });
                }
              });
          }
        });
      }
      if (tabs === "5") {
        let action = index
          ? `updateTutorialData`
          : SaveMode === "Update"
          ? `updateTutorialData`
          : `addTutorialData`;
        let obj =
          Object.keys(nextProps.TabsData.tab5).length > 0
            ? {
                ...createTutorial,
                configuration_attributes: { ...nextProps.TabsData.tab5 },
              }
            : createTutorial;
        let obj1 = {
          ...editTutorial.data,
          ...nextProps.TabsData.tab1,
          settings: {
            ...nextProps.TabsData.tab1.settings,
            ...nextProps.TabsData.tab2,
          },
          content: { ...nextProps.TabsData.tab3 },
          summative_test:
            Object.keys(nextProps.TabsData.tab4).length > 0
              ? nextProps.TabsData.tab4
              : { summative_test: [] },
          ...nextProps.TabsData.tab5,
        };
        let data = SaveMode === "Update" ? obj1 : obj;
        this.setState(
          {
            createTutorial: data,
            callAPI: false,
          },
          () => {
            this.props
              .dispatch(contentCreatorActions[`${action}`](data))
              .then((res, err) => {
                history.push({
                  pathname: "/tutorial-management",
                  state: res ? res.data.data.id : 0,
                });
              });
          }
        );
      }
    }
  }

  onPreview = (view, tab, e) => {
    this.setState({ PreviewState: view });
    this.toggleSub((Number(tab) + 1).toString(), e);
    //history.push(`/tutorial-page/${this.state.tutorialId}/authorized/${view}`);
  };

  deleteConfirm = () => {
    let { chapterId, mode } = this.state;
    return (
      <div>
        <div>
          <h4>Are you sure you want to delete this chapter?.</h4>
        </div>
        <div className="float-right">
          <Button
            className="mt-2 mr-2"
            color="danger"
            onClick={(e) => {
              this.handleDelete(chapterId, "", "chapter", "", mode);
              this.toggleModal();
            }}
          >
            Yes
          </Button>
          <Button className="mt-2 mr-2" onClick={this.toggleModal}>
            No
          </Button>
        </div>
      </div>
    );
  };

  handleDelete = (cid, eid, type, qid, category) => {
    let { CurriculumTutorial } = this.props;
    if (category === "tutorial") {
      if (type === "chapter") {
        CurriculumTutorial.editTutorial.data.content?.tutorial.splice(cid, 1);
      } else if (qid || qid === 0)
        CurriculumTutorial.editTutorial.data.content?.tutorial[cid]?.elements[
          eid
        ].quiz_data.questions.splice(qid, 1);
      else
        CurriculumTutorial.editTutorial.data.content?.tutorial[
          cid
        ]?.elements.splice(eid, 1);
    } else {
      if (type === "chapter")
        CurriculumTutorial.editTutorial.data.summative_test?.summative_test.splice(
          cid,
          1
        );
      else if (qid || qid === 0)
        CurriculumTutorial.editTutorial.data.summative_test?.summative_test[
          cid
        ]?.elements[eid].quiz_data.questions.splice(qid, 1);
      else
        CurriculumTutorial.editTutorial.data.summative_test?.summative_test[
          cid
        ]?.elements.splice(eid, 1);
    }

    this.setState({ CurriculumTutorial });
  };

  onValidate = (tab, data, type) => {
    let errorsArr = [];
    if (tab === "2") {
      let {
        starting_url = "",
        title = "",
        is_frame_busted = "",
        url = "",
      } = data;
      let titleErr = Validations.validateTutorialName(title);
      let urlErr = Validations.validateStartingTutorial(starting_url);
      let customUrlErr = Validations.validateTutorialUrlSlug(url);
      if (urlErr) errorsArr["starting_url"] = urlErr;
      if (titleErr) errorsArr["title"] = titleErr;
      if (customUrlErr) errorsArr["url"] = customUrlErr;
      if (is_frame_busted === "" || is_frame_busted === null)
        errorsArr["is_frame_busted"] = "This field is required";
      // if (is_wide_iframe === '' || is_wide_iframe === null)
      //     errorsArr['is_wide_iframe'] = 'This field is required';

      this.setState({ errors: errorsArr });
      return Object.keys(errorsArr).length;
    }
    if (tab === "3") {
      if (!("email_notification" in data)) {
        errorsArr["email_notification"] = "Select Email Notification Setting.";
      }
      if (!("type_of_formative_quiz" in data)) {
        errorsArr["type_of_formative_quiz"] = "Select Formative Quiz type.";
      }
      if (!("email_notification_tutorial_taken" in data)) {
        errorsArr["email_notification_tutorial_taken"] =
          "Select tutorial taken Notification Setting.";
      }
      if (!("email_notification_quiz_completed" in data)) {
        errorsArr["email_notification_quiz_completed"] =
          "Select quiz completed Notification Setting.";
      }
      if (!("email_notification_question_missed" in data)) {
        errorsArr["email_notification_question_missed"] =
          "Select question missed Notification Setting.";
      }
      if ("formative_quiz_passing_score" in data) {
        if (
          Number(data.formative_quiz_passing_score) > 100 ||
          !(Number(data.formative_quiz_passing_score) >= 0)
        ) {
          errorsArr[
            "formative_quiz_passing_score"
          ] = `value must be between 0 to 100`;
        }
      }
      if ("summative_test_passing_score" in data) {
        if (
          Number(data.summative_test_passing_score) > 100 ||
          !(Number(data.summative_test_passing_score) >= 0)
        ) {
          errorsArr[
            "summative_test_passing_score"
          ] = `value must be between 0 to 100`;
        }
      }
      if ("missed_question_threshold" in data) {
        if (
          Number(data.missed_question_threshold) > 100 ||
          !(Number(data.missed_question_threshold) >= 0)
        ) {
          errorsArr[
            "missed_question_threshold"
          ] = `value must be between 0 to 100`;
        }
      }
      this.setState({ errors: errorsArr });
      return Object.keys(errorsArr).length;
    }
    if (tab === "4") {
      if (type === "chapter") {
        let { title = "", description } = data;
        let titleErr = Validations.validateChapterTitle(title);
        let descErr = Validations.validateDescription(description);
        if (titleErr) errorsArr["chapterTitle"] = titleErr;
        if (descErr) errorsArr["description"] = descErr;
      }
      if (type === "text") {
        let { text = "" } = data;
        let textErr = Validations.validateText(text);
        if (textErr) errorsArr["text"] = textErr;
      }
      this.setState({ errors: errorsArr });
      return Object.keys(errorsArr).length;
    }
    if (tab === "5") return Object.keys(errorsArr).length;
    if (tab === "6") return Object.keys(errorsArr).length;
  };

  toggleModal = (id, chapterId, data, index, category) => {
    if (id === "Add Page Break") {
      this.setState({
        modal: false,
        chapterId: chapterId,
        editData: {},
        elIndex: index,
        mode: category,
      });
      this.handleSubmit(
        { type: "page-break", index: Number(index) },
        "addPageBreak",
        index,
        chapterId
      );
    }
    if (id && id !== "Add Page Break")
      this.setState({
        modal: true,
        chapterId: chapterId,
        edit: false,
        activeButton: id,
        editData: {},
        elIndex: index,
        mode: category,
      });
    if (data) {
      this.setState({
        modal: true,
        edit: true,
        editData: data,
        activeButton: id,
        chapterId: chapterId,
        elIndex: index,
        mode: category,
        mediaActiveTab: data.type,
      });
    }
    if (!id && !chapterId)
      this.setState({
        modal: false,
        mediaProcess: false,
      });
  };

  handleSubmit = (data, source, pbindex, cid) => {
    let { chapterId, edit, elIndex, mode } = this.state;
    let { CurriculumTutorial } = this.props;
    let { editTutorial } = CurriculumTutorial;
    if (mode === "tutorial") {
      if (source === "tutorial") editTutorial = data;
      if (source === "summative")
        editTutorial.data.summative_test.summative_test = data;
      if (source === "addPageBreak") {
        if (pbindex)
          editTutorial.data.content?.tutorial[cid]?.elements.splice(
            pbindex,
            0,
            data
          );
        else
          editTutorial.data.content?.tutorial[cid]?.elements.splice(
            elIndex,
            0,
            data
          );
      }
      if (source === "addChapter") {
        let err = this.onValidate("4", data, "chapter");
        if (err === 0) {
          if (edit) {
            editTutorial.data.content.tutorial.splice(chapterId, 1, {
              ...data,
              elements:
                editTutorial.data.content?.tutorial[chapterId]?.elements,
            });
          } else {
            if (editTutorial.data) {
              if (editTutorial.data.content)
                editTutorial.data.content?.tutorial.splice(chapterId, 0, {
                  ...data,
                  elements: [],
                });
              else
                editTutorial.data["content"] = {
                  tutorial: [{ ...data, elements: [] }],
                };
            } else {
              let obj = { content: { tutorial: [{ ...data, elements: [] }] } };
              editTutorial["data"] = obj;
            }
          }
          this.toggleModal();
        }
      }
      if (source === "addText") {
        let err = this.onValidate("4", data, "text");
        if (err === 0) {
          if (edit) {
            editTutorial.data.content?.tutorial[chapterId]?.elements.splice(
              data.index,
              1,
              data
            );
          } else {
            editTutorial.data.content?.tutorial[chapterId]?.elements.splice(
              elIndex,
              0,
              {
                ...data,
                index: Number(elIndex),
              }
            );
          }
          this.toggleModal();
        }
      }
      if (["media", "url", "embedded_media"].includes(source)) {
        let err = this.onValidate("4", data, source);
        if (err === 0) {
          if (edit) {
            // window.location.reload();
            editTutorial.data.content?.tutorial[chapterId]?.elements.forEach(
              (obj, arrIndex) => {
                if (obj.type === "media" && data.id === obj.id) {
                  editTutorial.data.content?.tutorial[
                    chapterId
                  ]?.elements.splice(arrIndex, 1, data);
                }
              }
            );
          } else {
            editTutorial.data.content?.tutorial[chapterId]?.elements.splice(
              elIndex,
              0,
              {
                ...data,
                index: Number(elIndex),
              }
            );
          }
          this.toggleModal();
        }
      }
      if (source === "Add Questions") {
        let err = this.onValidate("4", data, source);
        if (err === 0) {
          if (edit) {
            let pos = editTutorial.data.content?.tutorial[chapterId]?.elements[
              elIndex
            ].quiz_data.questions.findIndex(
              (c, i) => c.id === data.quiz_data.questions[0].id
            );
            editTutorial.data.content?.tutorial[chapterId]?.elements[
              elIndex
            ].quiz_data.questions.splice(pos, 1, data.quiz_data.questions[0]);
          } else {
            editTutorial.data.content?.tutorial[chapterId]?.elements.splice(
              elIndex,
              0,
              {
                ...data,
                index: Number(elIndex),
              }
            );
          }
          this.toggleModal();
        }
      }
    } else if (mode === "summative") {
      if (source === "addPageBreak") {
        if (pbindex)
          editTutorial.data.summative_test?.summative_test[
            cid
          ]?.elements.splice(pbindex, 0, data);
        else
          editTutorial.data.summative_test?.summative_test[
            cid
          ]?.elements.splice(elIndex, 0, data);
      }
      if (source === "addChapter") {
        let err = this.onValidate("4", data, "chapter");
        if (err === 0) {
          if (edit) {
            editTutorial.data.summative_test?.summative_test.splice(
              chapterId,
              1,
              {
                ...data,
                elements:
                  editTutorial.data.summative_test?.summative_test[chapterId]
                    ?.elements,
              }
            );
          } else {
            if (editTutorial.data) {
              if (editTutorial.data.summative_test)
                editTutorial.data.summative_test?.summative_test.splice(
                  chapterId,
                  0,
                  {
                    ...data,
                    elements: [],
                  }
                );
              else
                editTutorial.data["summative_test"] = {
                  summative_test: [{ ...data, elements: [] }],
                };
            } else {
              let summative_test = {
                summative_test: [{ ...data, elements: [] }],
              };
              editTutorial["data"] = { summative_test };
            }
          }
          this.toggleModal();
        }
      }
      if (source === "addText") {
        let err = this.onValidate("4", data, "text");
        if (err === 0) {
          if (edit) {
            editTutorial.data.summative_test?.summative_test[
              chapterId
            ].elements.splice(data.index, 1, data);
          } else {
            editTutorial.data.summative_test?.summative_test[
              chapterId
            ].elements.splice(elIndex, 0, {
              ...data,
              index: Number(elIndex),
            });
          }
          this.toggleModal();
        }
      }
      if (
        source === "media" ||
        source === "url" ||
        source === "embedded_media"
      ) {
        let err = this.onValidate("4", data, source);
        if (err === 0) {
          if (edit) {
            //window.location.reload(); Removed since image change but data gone on refresh
            editTutorial.data.summative_test?.summative_test[
              chapterId
            ].elements.splice(data.index, 1, data);
          } else {
            editTutorial.data.summative_test?.summative_test[
              chapterId
            ].elements.splice(elIndex, 0, data);
          }
          this.toggleModal();
        }
      }
      if (source === "Add Questions") {
        let err = this.onValidate("4", data, source);
        if (err === 0) {
          if (edit) {
            let pos = editTutorial.data.summative_test?.summative_test[
              chapterId
            ].elements[elIndex].quiz_data.questions.findIndex(
              (c, i) => c.id === data.quiz_data.questions[0].id
            );
            editTutorial.data.summative_test?.summative_test[
              chapterId
            ]?.elements[elIndex].quiz_data.questions.splice(
              pos,
              1,
              data.quiz_data.questions[0]
            );
          } else {
            editTutorial.data.summative_test?.summative_test[
              chapterId
            ]?.elements.splice(elIndex, 0, data);
          }
          this.toggleModal();
        }
      }
    }
    this.setState({ CurriculumTutorial });
  };

  toggleSub = (tab, e, link) => {
    let { activeSubTab } = this.state;
    let {
      CurriculumTutorial: { editTutorial = {} },
    } = this.props;
    let data = this.formDetail.state.data;
    let err = ["Previous"].includes(e)
      ? 0
      : this.onValidate(
          e === "link" ? (Number(activeSubTab) + 1).toString() : tab,
          data
        );
    if (err === 0) {
      if (["Next", "Previous", "link"].includes(e)) {
        if (activeSubTab <= 1 && e === "Previous") history.push("/");
        if (activeSubTab !== tab) {
          this.setState({ activeSubTab: tab, callAPI: true, step: e });
          this.setState(
            { formDetail: { ...this.state.formDetail, data } },
            () => {
              this.props.dispatch(
                contentCreatorActions.addTabData(activeSubTab, data)
              );
            }
          );
        }
      } else if (e === "Save" || e === "SaveAs") {
        this.setState({ tab: activeSubTab, callAPI: true, step: e }, () => {
          this.props.dispatch(
            contentCreatorActions.addTabData(activeSubTab, data)
          );
        });
      } else if (e === "Update") {
        let obj = {
          ...editTutorial.data,
          configuration_attributes:
            editTutorial.data.configuration.configurable_type === "Tutorial"
              ? { ...data, id: editTutorial.data.configuration.id }
              : data,
        };
        this.props
          .dispatch(
            contentCreatorActions.updateTutorialData(
              Object.keys(data).length > 0 ? obj : editTutorial.data
            )
          )
          .then((res, err) => {
            history.push({
              pathname: "/tutorial-management",
              state: res ? res.data.data.id : 0,
            });
          });
      }
    }
  };

  currentActiveTab = (data) => {
    let {
      Tutorialcourses,
      CurriculumTutorial,
      TabsData,
      dispatch,
    } = this.props;
    let { errors, SaveMode } = this.state;
    if (this.state.activeSubTab === "1") {
      return (
        <TutorialDetail
          ref={(e) => (this.formDetail = e)}
          courses={Tutorialcourses}
          data={CurriculumTutorial}
          errors={errors}
          TabsData={TabsData.tab1}
          SaveMode={SaveMode}
          dispatch={dispatch}
        />
      );
    }
    if (this.state.activeSubTab === "2") {
      return (
        <QuizSetting
          ref={(e) => (this.formDetail = e)}
          data={CurriculumTutorial}
          errors={errors}
          TabsData={TabsData.tab2}
          SaveMode={SaveMode}
        />
      );
    }
    if (this.state.activeSubTab === "3") {
      return (
        <EditFirstPage
          data={data}
          toggleModal={this.toggleModal}
          setTab={this.setTab}
          handleDelete={this.handleDelete}
          edit={this.props.edit}
          ref={(e) => (this.formDetail = e)}
          TabsData={TabsData.tab3}
          onCollapse={this.onCollapse}
          handleSubmit={this.handleSubmit}
          collapse={this.collapse}
        />
      );
    }
    if (this.state.activeSubTab === "4") {
      return (
        <SummativeQuiz
          data={data}
          toggleModal={this.toggleModal}
          setTab={this.setTab}
          handleDelete={this.handleDelete}
          handleSubmit={this.handleSubmit}
          ref={(e) => (this.formDetail = e)}
          TabsData={TabsData.tab4}
        />
      );
    }
    if (this.state.activeSubTab === "5") {
      return (
        <TutorialLookFeel
          tab="tutorial"
          lref={(e) => (this.formDetail = e)}
          data={CurriculumTutorial}
          errors={errors}
          TabsData={TabsData.tab5}
        />
      );
    }
  };

  setTab = (tab) => {
    this.setState({ showTab: tab });
  };

  onDragOver = (ev) => {
    ev.preventDefault();
  };

  removeEffect = (ev) => {
    ev.preventDefault();
    ev.target.style.borderBottom = "none";
  };

  componentWillUnmount() {
    this.setState({ _isMounted: false });
    this.props.dispatch(contentCreatorActions.removeTabsData());
    this.props.dispatch(contentCreatorActions.RemoveEditData());
    // if (this.props.channel) this.props.channel.unsubscribe();
    this.channel && this.channel.unsubscribe() && cable.disconnect();
  }

  onDDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  setTutorial = (tutorialData) => {
    let isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox && tutorialData.is_frame_busted) {
      this.setState({
        tutorial: tutorialData,
        isToggle: false,
      });
      this.toggleFirefoxModal();
    } else {
      this.setState({
        isAlternative: true,
        tutorial: tutorialData,
        isToggle: false,
      });
    }
  };

  toggleContinue = (tutorial) => {
    this.setState({
      isToggle: !this.state.isToggle,
    });
  };

  toggleFirefoxModal = () => {
    this.setState(
      {
        isFirefoxModal: !this.state.isFirefoxModal,
      },
      () => {
        if (this.state.isFirefoxModal === false)
          this.setState({
            isAlternative: true,
          });
      }
    );
  };

  close = () => {
    this.setState({
      isAlternative: false,
    });
  };

  saveMedia = (obj) => {
    this.setState({ mediaProcess: true });
    return new Promise((resolve, reject) => {
      const { tutorialIndex, edit, editData } = this.state;
      const { dispatch } = this.props;
      try {
        if (edit) {
          const tutId = Number(tutorialIndex);
          const id = Number(editData.data.id);
          if (!obj.file.includes("data:image")) {
            delete obj.file;
          }
          obj.tutorial_id = tutId;
          dispatch(contentCreatorActions.editMedia(id, obj)).then((res) => {
            if (res) {
              return resolve(res.data);
            }
          });
        } else {
          dispatch(contentCreatorActions.addMedia(obj)).then((res) => {
            if (res) {
              return resolve(res.data);
            }
          });
        }
      } catch (err) {
        return reject(err);
      }
    });
  };

  render() {
    const {
      CurriculumTutorial,
      addState,
      media: { file: { url = "" } = {} } = {},
    } = this.props;
    const { editTutorial } = CurriculumTutorial;
    const {
      activeButton,
      editData,
      modal,
      elIndex,
      errors,
      mediaActiveTab,
      setWindowData,
      mediaProcess,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {addState && (
          <Col md={12} className="pt-3">
            <h4 className="ml-1 w-100 header-custom-border mb-0 pb-2">
              Create Tutorial
            </h4>
          </Col>
        )}
        {CurriculumTutorial.isLoading ? (
          <Spinner />
        ) : (
          <Col md={12}>
            {modal && (
              <Modal
                isOpen={true}
                fade={true}
                className="element-modal modal-lg chap-edit-pop"
              >
                <ModalHeader toggle={this.toggleModal}>
                  {activeButton}
                </ModalHeader>
                <ModalBody>
                  <div>
                    {(activeButton === "Add Chapter" ||
                      activeButton === "Add Section") && (
                      <AddChapter
                        handleSubmit={this.handleSubmit}
                        activeButton={activeButton}
                        toggleModal={this.toggleModal}
                        editData={editData}
                        errors={errors}
                      />
                    )}
                    {activeButton === "Add Text" && (
                      <AddText
                        handleSubmit={this.handleSubmit}
                        toggleModal={this.toggleModal}
                        editData={editData}
                        index={elIndex}
                        errors={errors}
                      />
                    )}
                    {activeButton === "Add Media" && (
                      <AddMedia
                        handleSubmit={this.handleSubmit}
                        mediaActiveTab={mediaActiveTab}
                        saveMedia={this.saveMedia}
                        toggleModal={this.toggleModal}
                        editData={editData}
                        errors={errors}
                        mediaUrl={url}
                        mediaProcess={mediaProcess}
                      />
                    )}
                    {(activeButton === "Add Questions" ||
                      activeButton === "Add Questions") && (
                      <Quiz
                        handleSubmit={this.handleSubmit}
                        toggleModal={this.toggleModal}
                        editData={editData}
                        type={activeButton}
                        errors={errors}
                      />
                    )}
                    {activeButton === "Confirmation" && this.deleteConfirm()}
                  </div>
                </ModalBody>
              </Modal>
            )}
            {!addState && (
              <div>
                <Row>
                  <div className="w-100 py-2 d-flex edit-tut-border-bottom">
                    <div>
                      <span className="edit-tut-header">
                        Manage Tutorial :{" "}
                      </span>
                      <span className="edit-tut-changable-content">
                        {editTutorial.data && editTutorial.data.title}
                      </span>
                    </div>
                  </div>
                </Row>
              </div>
            )}
            <Row className="base-row">
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <div
                        className="create-tutorial-info"
                        id="tutorial"
                        onDragOver={(e) => this.onDragOver(e)}
                      >
                        <Tutorial
                          setTab={this.setTab}
                          currentActiveTab={this.currentActiveTab}
                          toggleSub={this.toggleSub}
                          toggle={this.toggle}
                          stateData={this.state}
                          tutorial={CurriculumTutorial}
                          addState={addState}
                          onPreview={this.onPreview}
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {this.state.isAlternative && (
              <AlternativeModals
                tutorial={this.state.tutorial}
                // fromLogin={true}
                isAlternative={this.state.isAlternative}
                close={this.close}
                doNotShow={this.props.doNotShow}
                dispatch={this.props.dispatch}
                setWindowData={setWindowData}
              />
            )}
            {this.state.isFirefoxModal && (
              <FirefoxModal
                isFirefoxModal={this.state.isFirefoxModal}
                toggleFirefoxModal={this.toggleFirefoxModal}
              />
            )}
            <hr className="custom-hr" />
          </Col>
        )}
        <Modal
          isOpen={this.state.isToggle}
          toggle={() => this.toggleContinue(this.state.createTutorial)}
          className="modal-success"
        >
          <ModalHeader
            toggle={() => this.toggleContinue(this.state.createTutorial)}
          >
            Preview Tutorial
          </ModalHeader>
          <ModalBody>
            <p>Do you want to continue ? </p>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.setTutorial(this.state.createTutorial)}
              color="success"
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    users,
    authentication: { user },
    CurriculumTutorial,
    TabsData,
    Tutorialcourses,
    tutorial: { channel },
  } = state;
  return {
    user,
    users,
    CurriculumTutorial,
    TabsData,
    Tutorialcourses,
    doNotShow: state.learnerDashboard.doNotShow,
    media: state.CurriculumTutorial.medialUrl,
    channel,
  };
}

const connectedEditTutorialPage = connect(mapStateToProps)(EditTutorialPage);
export { connectedEditTutorialPage as EditTutorialPage };
