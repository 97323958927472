// import Inline from 'quill/blots/inline';
import { Quill } from 'react-quill';

const Inline = Quill.import('blots/inline')

class AbbrTagQuill extends Inline {
  static blotName = 'abbr-tag';
  static tagName = 'abbr';

  static create(value) {
    const node = super.create(value);
    node.setAttribute('title', this.sanitize(value));
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('title');
  }

  static sanitize(value) {
    return value;
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) {
      super.format(name, value);
    } else {
      this.domNode.setAttribute('title', this.constructor.sanitize(value));
    }
  }
}

export { AbbrTagQuill as default };
