import React from "react";
import Quiz from "../../../components/Learner/Quiz-Page/Quiz";
import { connect } from "react-redux";
import { quizzesActions } from "../../../_actions";

class QuizContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizData: []
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(quizzesActions.getAllQuizData());
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.quizData) {
      this.setState({
        quizData: nextProps.quizData
      });
    }
  }
  render() {
    const { quizData } = this.state;
    return (
      <div className="animated fadeIn">
        <Quiz quizData={quizData} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    quizData: state.quiz.quizzesData
  };
}

const connectedQuiz = connect(mapStateToProps, null)(QuizContainer);
export { connectedQuiz as QuizContainer };
