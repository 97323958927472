import React from "react";
import {
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import AlternativeModals from "../../../_components/AlternativeModals";
import { tutorialActions } from "../../../_actions/LearnerActions/tutorial.actions";
import Spinner from "../../../components/spinner/index";

import { connect } from "react-redux";
import FirefoxModal from "../../../_components/FirefoxModal";
import "./publicTutorial.scss";
class PublicTutorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isAlternative: false,
      tutorial: {},
      isFirefoxModal: false
    };

    this.setTutorial = this.setTutorial.bind(this);
    this.close = this.close.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleFirefoxModal = this.toggleFirefoxModal.bind(this);
  }

  componentDidMount() {
    let {
      dispatch,
      match: {
        params: { id, page },
        location: { pathname = "" } = ({} = {})
      }
    } = this.props;
    this.props.dispatch(tutorialActions.getTutorialData(id));
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tutorialData) {
      this.setState({
        tutorial: nextProps.tutorialData
      });
    }
  }

  setTutorial() {
    let isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox && this.state.tutorial.is_frame_busted) {
      this.setState({
        tutorial: this.state.tutorial
      });
      this.toggleFirefoxModal();
    } else {
      this.setState({
        isAlternative: true,
        tutorial: this.state.tutorial
      });
    }
  }

  close() {
    this.setState({
      isAlternative: false
    });
  }

  toggleModal() {
    this.props.dispatch(userActions.logout());
    this.setState({ isOpen: false });
  }

  toggleFirefoxModal() {
    this.setState(
      {
        isFirefoxModal: !this.state.isFirefoxModal
      },
      () => {
        if (this.state.isFirefoxModal === false)
          this.setState({
            isAlternative: true
          });
      }
    );
  }

  render() {
    const { isAlternative, tutorial = {}, isFirefoxModal } = this.state;

    if (!Object.keys(tutorial).length) return <Spinner />;

    return (
      <div className="animated fadeIn">
        <div className="main-wrap-tutorial">
          <div className="alter-tut-heading">
            <p>{`Start the ${tutorial.title} tutorial now!`}</p>
          </div>
          <div className="sub-main-wrap">
            <p href="#" title="Click here" onClick={this.setTutorial} tabIndex="0">
              {tutorial.title}
            </p>
          </div>
          <p>{tutorial.description}</p>
        </div>
        {isAlternative && (
          <AlternativeModals
            tutorial={tutorial}
            fromLogin={false}
            isAlternative={isAlternative}
            close={this.close}
          />
        )}
        {isFirefoxModal && (
          <FirefoxModal
            isFirefoxModal={isFirefoxModal}
            toggleFirefoxModal={this.toggleFirefoxModal}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tutorialData: state.tutorial.tutorialData
  };
}

export default connect(mapStateToProps, null)(PublicTutorial);
