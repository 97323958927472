import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./Tutorial.scss";
export default class TutorialMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  toggleWithKey = e => {
    if (e.which === 13) {
      this.setState({
        isOpen: !this.state.isOpen
      })
    }
  }
  render() {
    const { elements } = this.props;
    return (
      <div className="media-block animated fadeIn">
        <div className="width-img m-auto">
          <div className="text-center">
            {
              <img
                tabIndex="0"
                className="img-fluid custom-img tutorial-img-hover"
                src={elements.source}
                alt={
                  elements.tags[0]
                    ? elements.tags[0] && elements.tags[0]
                    : "Media alt text"
                }
                width={
                  elements.image_in_viewer
                    ? `${elements.image_in_viewer}%`
                    : `80%`
                }
                name="mediaImg"
                onError={e => {
                  e.target.src =
                    "http://www.eltis.org/sites/default/files/default_images/photo_default_4.png";
                }}
                onClick={this.toggle}
                onKeyPress={this.toggleWithKey}
                aria-label="Press enter to expand/toggle"
              />
            }
          </div>
        </div>
        <Modal
          className="img-modal-dialogue"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody className="img-modal-body">
            <img
              className="img-fluid custom-img"
              src={elements.source}
              alt={"mediaImage"}
              name="mediaImg"
              onError={e => {
                e.target.src =
                  "http://www.eltis.org/sites/default/files/default_images/photo_default_4.png";
              }}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
