import { contentCreatorConstants } from "../_constants";

const initial = {
  courseData: [],
  isLoading: false
};
export function Tutorialcourses(state = initial, action) {
  switch (action.type) {
    case contentCreatorConstants.GET_COURSES_REQUEST:
      return { ...state, isLoading: true };
    case contentCreatorConstants.GET_COURSES_SUCCESS:
      return { ...state, courseData: action.data, isLoading: false };
    case contentCreatorConstants.GET_COURSES_FAILURE:
      return { ...state, courseData: [], isLoading: false };
    default:
      return state;
  }
}
