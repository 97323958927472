import { learnerConstants } from "../../_constants/index";
import _ from "lodash";
const initial = {
  learnerData: [],
  courseData: [],
  status: "",
  errorData: [],
  isLoadingCourse: false,
  isLoadingLearner: false
};
export function learner(state = initial, action) {
  switch (action.type) {
    case learnerConstants.GET_LEARNER_SUCCESS:
      return { ...state, learnerData: action.data, isLoadingLearner: false };
    case learnerConstants.GET_LEARNER_FAILURE:
      return { ...state, learnerData: [], isLoadingLearner: false };
    case learnerConstants.LEARNER_REQUEST:
      return { ...state, isLoadingLearner: true };
    case learnerConstants.GET_COURSE_SUCCESS:
      return { ...state, courseData: action.data, isLoadingCourse: false };
    case learnerConstants.GET_COURSE_FAILURE:
      return { ...state, courseData: [], isLoadingCourse: false };
    case learnerConstants.COURSE_REQUEST:
      return { ...state, isLoadingCourse: true };
    case learnerConstants.ASSIGN_LEARNER_TO_COURSE_SUCCESS:
      let arrayData = [];
      let {
        data: { learner_ids, course_ids }
      } = action;
      let { learnerData, courseData } = state;
      learner_ids.forEach(l =>
        arrayData.push(_.find(learnerData, t => t.id === l))
      );
      course_ids.forEach(course => {
        let index = _.findIndex(courseData, d => d.id === course);
        courseData[index].learners = _.uniqBy(
          courseData[index].learners.concat(arrayData),
          "id"
        );
      });
      return { ...state, status: action.data.status.status };
    case learnerConstants.ASSIGN_LEARNER_TO_COURSE_FAILURE:
      return { ...state };
    case learnerConstants.ADD_LEARNER_SUCCESS:
      let data = state.learnerData;
      action.data.data.map((dt, i) => {
        data.push(dt);
      });
      return { ...state, learnerData: data, errorData: [] };
    case learnerConstants.ADD_LEARNER_FAILURE:
      return { ...state, errorData: action.error };
    case learnerConstants.DELETE_LEARNER_COURSES_SUCCESS:
      state.courseData.find(
        course =>
          course.id === action.data.obj.course_ids &&
          course.learners.splice(
            course.learners.findIndex(
              f => f.id === action.data.obj.learner_ids
            ),
            1
          )
      );
      return { ...state };
    case learnerConstants.DELETE_LEARNER_COURSES_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
