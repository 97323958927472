import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardGroup,
  CardBody,
  CardFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { userActions } from "../_actions";
import { getURLParameters } from "../_helpers/getQueryString";
import { history } from "../_helpers/history";
import {
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
  validateUsername
} from "../_helpers/Validation";
import "./register.scss";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        institution: "",
        slug: null,
        confirm_password: "",
        fileupload: ""
      },
      errors: {},
      submitted: false,
      subscriptionError: "",
      subscriptionDetail: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }
  componentDidMount() {
    let { user, subscriptionError } = this.state;
    let subscription = getURLParameters(window.location.href);
    user["email"] = subscription.status === "success" ? subscription.email : "";
    subscriptionError =
      subscription.status === "error" ? subscription.error : "";

    this.setState({
      user,
      subscriptionError,
      subscriptionDetail: subscription
    });
  }
  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }
  handleValidation(e) {
    let { errors, user, subscriptionDetail } = this.state;
    const { name, value } = e.target;
    if (name === "email") errors.email = validateEmail(value);
    if (name === "firstName") errors.first_name = validateFirstName(value);
    if (name === "lastName") errors.last_name = validateLastName(value);
    if (name === "username") errors.username = validateUsername(value);

    if (name === "password") {
      // errors.password = validatePassword(value);
      if (validatePassword(value)) errors.password = validatePassword(value);
      else if (user.confirm_password && user.confirm_password !== value) {
        errors["password"] = "Password and confirm Password not matched.";
        errors["password_confirmation"] = "";
      } else errors["password"] = "";
    }
    if (name === "confirm_password") {
      if (validatePassword(value))
        errors.password_confirmation = validatePassword(value);
      else if (user.password !== value) {
        errors["password_confirmation"] =
          "Password and confirm Password not matched.";
        errors["password"] = "";
      } else errors["password_confirmation"] = "";
    }
    if (name === "institution") {
      if (subscriptionDetail["status"] === "success") {
        if (!value) {
          errors.institution = "Institution is Required";
        } else {
          errors.institution = "";
        }
      }
    }
    if (name === "slug") {
      if (subscriptionDetail["status"] === "success") {
        if (!value) {
          errors.slug = "Slug is Required";
        } else {
          errors.slug = "";
        }
      }
    }
    this.setState({
      errors
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const { user, errors, subscriptionDetail } = this.state;
    const obj = {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      password: user.password,
      password_confirmation: user.confirm_password,
      username: user.username,
      avatar: user.fileupload,
      owned_organization_attributes: {
        title: user.institution,
        slug: user.slug
      }
    };
    this.setState({ submitted: true });
    const { dispatch } = this.props;

    if (!obj.email) {
      errors.email = validateEmail(obj.email.trim());
    }
    if (!obj.first_name) {
      errors.first_name = validateFirstName(obj.first_name.trim());
    }
    if (!obj.last_name) {
      errors.last_name = validateLastName(obj.last_name.trim());
    }
    // if(!obj.username){
    //     errors.username = validateUsername(obj.username);
    // }
    if (!obj.password) {
      errors.password = validatePassword(obj.password);
    }
    if (!obj.password_confirmation) {
      if (validatePassword(obj.password_confirmation)) {
        errors.password_confirmation = validatePassword(
          obj.password_confirmation
        );
      } else if (user.password !== obj.password_confirmation)
        errors["password_confirmation"] =
          "Password and confirm Password not matched.";
      else errors["password_confirmation"] = "";
    }

    if (!obj.owned_organization_attributes.title) {
      if (subscriptionDetail["status"] === "success") {
        errors.institution = "Institution is Required";
      }
    }
    // if(!obj.owned_organization_attributes.slug)
    // {
    //     if(subscriptionDetail['status']==='success'){
    //         errors.slug = 'Slug is Required';
    //     }
    // }

    this.setState({
      errors
    });
    if (
      !(
        errors.email ||
        errors.first_name ||
        errors.last_name ||
        errors.username ||
        errors.password ||
        errors.password_confirmation ||
        errors.institution ||
        errors.slug
      )
    ) {
      dispatch(userActions.register(obj)).then((res, err) => {
        if (res.user && res.user.data.status === "success") {
          history.push({
            pathname: "/login",
            state: { show_popUp: true }
          });
        }
      });
    }
  }

  closeModal() {
    this.setState({
      subscriptionError: ""
    });
  }

  render() {
    const { userData, registering } = this.props;
    const {
      user,
      submitted,
      subscriptionError,
      subscriptionDetail,
      errors
    } = this.state;
    return (
      <div className="animated fadeIn margin-minus-left register-box">
        {userData && userData.length > 0 && (
          <div className="alert alert-danger">{userData[0]}</div>
        )}
        <div className="register-bx">
          <Row className="">
            <Col lg="5" md="8">
              <CardGroup>
                <Card>
                  <CardBody className="">
                    <h1>Register</h1>
                    <p className="text-muted">Create your Sidecar account</p>
                    <form name="form" onSubmit={this.handleSubmit}>
                      <InputGroup
                        className={
                          "mb-3" +
                          (submitted && !user.email ? " has-error" : "")
                        }
                      >
                        <div className="input-group-prepend">
                          <span className="input-group-text"> @</span>
                        </div>
                        <Input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={user.email}
                          onChange={this.handleChange}
                          onBlur={this.handleValidation}
                        />
                        {errors.email && (
                          <div className="help-block">{errors.email}</div>
                        )}
                        {userData && userData.email && (
                          <div className="help-block">{`email ${userData.email}`}</div>
                        )}
                      </InputGroup>
                      <Row>
                        <Col md="6">
                          <InputGroup
                            className={
                              "mb-3" +
                              (submitted && !user.firstName ? " has-error" : "")
                            }
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon-user"></i>
                              </span>
                            </div>
                            <Input
                              type="text"
                              placeholder="First Name"
                              name="firstName"
                              value={user.firstName}
                              onChange={this.handleChange}
                              onBlur={this.handleValidation}
                            />
                            {errors.first_name && (
                              <div className="help-block">
                                {errors.first_name}
                              </div>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md="6">
                          <InputGroup
                            className={
                              "mb-3" +
                              (submitted && !user.lastName ? " has-error" : "")
                            }
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon-user"></i>
                              </span>
                            </div>
                            <Input
                              type="text"
                              placeholder="Last Name"
                              name="lastName"
                              value={user.lastName}
                              onChange={this.handleChange}
                              onBlur={this.handleValidation}
                            />
                            {errors.last_name && (
                              <div className="help-block">
                                {errors.last_name}
                              </div>
                            )}
                          </InputGroup>
                        </Col>
                      </Row>
                      {/*<InputGroup*/}
                      {/*className={'mb-3' + (submitted && !user.username ? ' has-error' : '')}>*/}
                      {/*<div className="input-group-prepend">*/}
                      {/*<span className="input-group-text">*/}
                      {/*<i className="icon-user"></i>*/}
                      {/*</span>*/}
                      {/*</div>*/}
                      {/*<Input type="text" placeholder="User Name" name="username"*/}
                      {/*value={user.username} onChange={this.handleChange} onBlur={this.handleValidation}/>*/}
                      {/*{ errors.username &&*/}
                      {/*<div className="help-block">{errors.username}</div>*/}
                      {/*}*/}
                      {/*{userData && userData.username &&*/}
                      {/*<div className="help-block">{`username ${userData.username}`}</div>*/}
                      {/*}*/}
                      {/*</InputGroup>*/}
                      <InputGroup
                        className={
                          "mb-3" +
                          (submitted && !user.password ? " has-error" : "")
                        }
                      >
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                        </div>
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={user.password}
                          onChange={this.handleChange}
                          onBlur={this.handleValidation}
                        />
                        {errors.password && (
                          <div className="help-block">{errors.password}</div>
                        )}
                        {userData && userData.password && (
                          <div className="help-block">{`password ${userData.password}`}</div>
                        )}
                      </InputGroup>
                      <InputGroup
                        className={
                          "mb-3" +
                          (submitted && !user.confirm_password
                            ? " has-error"
                            : "")
                        }
                      >
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-lock"></i>
                          </span>
                        </div>
                        <Input
                          type="password"
                          placeholder="Confirm password"
                          name="confirm_password"
                          value={user.confirm_password}
                          onChange={this.handleChange}
                          onBlur={this.handleValidation}
                        />
                        {errors.password_confirmation && (
                          <div className="help-block">
                            {errors.password_confirmation}
                          </div>
                        )}
                      </InputGroup>
                      <InputGroup
                        className={
                          "mb-3" +
                          (submitted && !user.institution ? " has-error" : "")
                        }
                      >
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-university"></i>
                          </span>
                        </div>
                        <Input
                          type="text"
                          placeholder="Institution"
                          name="institution"
                          value={user.institution}
                          onChange={this.handleChange}
                          onBlur={this.handleValidation}
                        />
                        {errors.institution && (
                          <div className="help-block">{errors.institution}</div>
                        )}
                        {userData && userData["owned_organization.title"] && (
                          <div className="help-block">
                            {userData["owned_organization.title"]}
                          </div>
                        )}
                      </InputGroup>
                      {/*<InputGroup className={'mb-3'}>*/}
                      {/*<div className="input-group-prepend">*/}
                      {/*<span className="input-group-text">*/}
                      {/*<i className="fa fa-address-book-o"></i>*/}
                      {/*</span>*/}
                      {/*</div>*/}

                      {/*<Input type="text" placeholder="Slug Name" name="slug"*/}
                      {/*value={user.slug}*/}
                      {/*onChange={this.handleChange} onBlur={this.handleValidation}/>*/}
                      {/*{errors.slug &&*/}
                      {/*<div className="help-block">{errors.slug}</div>*/}
                      {/*}*/}
                      {/*{*/}
                      {/*userData && userData['owned_organization.slug']  &&*/}
                      {/*<div className="help-block">{userData['owned_organization.slug']}</div>*/}
                      {/*}*/}
                      {/*</InputGroup>*/}
                      <InputGroup className={"mb-3"}>
                        <Input
                          type="text"
                          placeholder="Department ( Optional )"
                          name="department"
                          value={user.department}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <Button color="success" id="" block>
                        Create Account
                      </Button>
                      {registering && (
                        <img
                          alt={"register"}
                          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                        />
                      )}
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </div>
        <Modal
          isOpen={Boolean(subscriptionError)}
          className="modal-danger"
          toggle={this.closeModal}
        >
          <ModalHeader toggle={this.closeModal}>Subscription Error</ModalHeader>
          <ModalBody>
            <p>subscriptionError</p>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.closeModal}>
              Okay
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    registering: state.registration.registering,
    userData: state.registration.userData
  };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
