import React from "react";
import renderHTML from "react-render-html";
import "./Tutorial-text.scss";

export default class TutorialText extends React.Component {
  render() {
    const { textData } = this.props;
    const tempArr = [];
    if (typeof textData === "object") {
      textData.ops &&
        textData.ops.forEach(a => {
          tempArr.push(<p>{a.insert}</p>);
        });
    }
    return (
      <div className="text-update animated fadeIn">
        {typeof textData === "string" ? (
          <p>{renderHTML(textData)}</p>
        ) : (
          <div>{tempArr}</div>
        )}
      </div>
    );
  }
}
