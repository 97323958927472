import { adminDashboardConstants } from "../../_constants/index";
const initial = {
  tutorialsData: [],
  isLoadingTutorialsDashboard: false,
  dashboardData: [],
  isLoadingDashboard: false,
  CCUsersData: [],
  isLoadingCCUsers: false,
  tutorialCompletedGraph: {},
  quizessCompletedGraph: {},
  tutorialCompletedColor: {
    borderColor: "#c8eaf4",
    pointBorderColor: "#c8eaf4",
    pointHoverBackgroundColor: "#63c1de",
    pointHoverBorderColor: "#c8eaf4"
  },
  quizzesCompletedColor: {
    borderColor: "#9dd9ee",
    pointBorderColor: "#9dd9ee",
    pointHoverBackgroundColor: "#00a5e0",
    pointHoverBorderColor: "#9dd9ee"
  },
  graph_attributes: {
    borderCapStyle: "butt",
    fill: false,
    borderDash: [],
    borderWidth: 1,
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 1,
    pointRadius: 5,
    pointHitRadius: 10
  }
};
export function adminDashboard(state = initial, action) {
  switch (action.type) {
    case adminDashboardConstants.GET_TUTORIALS_REQUEST:
      return { ...state, isLoadingTutorialsDashboard: true };
    case adminDashboardConstants.GET_TUTORIALS_SUCCESS:
      return {
        ...state,
        tutorialsData: action.data.data,
        isLoadingTutorialsDashboard: false
      };
    case adminDashboardConstants.GET_TUTORIALS_FAILURE:
      return {
        ...state,
        tutorialsData: [],
        isLoadingTutorialsDashboard: false
      };

    case adminDashboardConstants.GET_DASHBOARD_DATA_REQUEST:
      return { ...state, isLoadingDashboard: true };
    case adminDashboardConstants.GET_DASHBOARD_DATA_SUCCESS:
      let dataTutorialCompletedCC = {
        ...action.data.data.tutorials_completed.graph.datasets[0],
        ...state.tutorialCompletedColor,
        ...state.graph_attributes
      };
      state.tutorialCompletedGraph = {
        ...action.data.data.tutorials_completed.graph,
        datasets: [dataTutorialCompletedCC]
      };

      let dataQuizzesCompletedCC = {
        ...action.data.data.quizzes_completed.graph.datasets[0],
        ...state.quizzesCompletedColor,
        ...state.graph_attributes
      };
      state.quizessCompletedGraph = {
        ...action.data.data.quizzes_completed.graph,
        datasets: [dataQuizzesCompletedCC]
      };

      return {
        ...state,
        dashboardData: action.data.data,
        isLoadingDashboard: false
      };
    case adminDashboardConstants.GET_DASHBOARD_DATA_FAILURE:
      return { ...state, dashboardData: [], isLoadingDashboard: false };

    case adminDashboardConstants.GET_CC_USERS_REQUEST:
      return { ...state, isLoadingCCUsers: true };
    case adminDashboardConstants.GET_CC_USERS_SUCCESS:
      let ccUsers = [];
      if (action.data.data)
        ccUsers = Object.values(
          action.data.data.length &&
            action.data.data.find(
              c =>
                c["full_content_creator"] ||
                c["basic_content_creator"] ||
                c["individual_content_creator"]
            )
        )[0];
      return { ...state, CCUsersData: ccUsers, isLoadingCCUsers: false };
    case adminDashboardConstants.GET_CC_USERS_FAILURE:
      return { ...state, CCUsersData: [], isLoadingCCUsers: false };
    default:
      return state;
  }
}
