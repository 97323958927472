export const rolesConstants = {
  GETALL_USERS_SUCCESS: "USERS_NAME_GETALL_SUCCESS",
  GETALL_USERS_FAILURE: "USERS_NAMES_GETALL_FAILURE",
  GETALL_USERS_REQUEST: "GETALL_USERS_REQUEST",

  GETALL_USERS_ROLES_SUCCESS: "USERS_ROLES_GETALL_SUCCESS",
  GETALL_USERS__ROLES_FAILURE: "USERS_ROLES_GETALL_FAILURE",
  GETALL_USERS__ROLES_REQUEST: "GETALL_USERS__ROLES_REQUEST",

  GETALL_USERS_ROLES_NAVS_SUCCESS: "USERS_ROLES_NAVS_GETALL_SUCCESS",
  GETALL_USERS__ROLES__NAVS_FAILURE: "USERS_ROLES_NAVS_GETALL_FAILURE",

  DELETE_ROLE_USER_SUCCESS: "DELETE_ROLE_USER_SUCCESS",
  DELETE_ROLE_USER_FAILURE: "DELETE_ROLE_USER_FAILURE",

  ASSIGN_ROLES_TO_USER_SUCCESS: "ASSIGN_ROLES_TO_USER_SUCCESS",
  ASSIGN_ROLES_TO_USER_FAILURE: "ASSIGN_ROLES_TO_USER_FAILURE"
};
