import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  CardHeader,
  Collapse,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Badge,
  Table
} from "reactstrap";
import moment from "moment";
import StudentChart from "./studentChart";
import "./manageDashboard.scss";
import { Line } from "react-chartjs-2";
import Spinner from "../spinner/index";
import Select from "react-select";
import "../../../scss/vendors/react-select/react-select.scss";

class ManageDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: { test: true },
      CCUser: null
    };
    //this.toggleMenu = this.toggleMenu.bind(this);
    this.displayRating = this.displayRating.bind(this);
    this.getUsersOptions = this.getUsersOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const userLogin = localStorage.getItem("user");
    const userRole = userLogin && JSON.parse(userLogin).main_role;
    const role = localStorage.getItem("defaultRole");
    if (nextProps.defaultUser) {
      this.setState({
        CCUser: nextProps.defaultUser[0]
      });
    }
    if (this.props.defaultRole !== nextProps.defaultRole) {
      //if(role !== userRole){ //not update dashboard for same main_role and defaultRole while change role
      this.props.getSwitchData();
      //}
    }
  }
  //removed since no need to show attempt data as maintain in download Answer
  // toggleMenu(e) {
  //     this.setState({
  //         collapse: {...this.state.collapse, [e]: !this.state.collapse[e]}
  //     })
  // }
  displayRating(r) {
    let fillIcon = <i className="fa fa-star" style={{ color: "#f8cb00" }} />;
    let emptyIcon = <i className="fa fa-star-o" style={{ color: "#f8cb00" }} />;
    if (r === 0) {
      return (
        <div>
          {emptyIcon}
          {emptyIcon}
          {emptyIcon}
          {emptyIcon}
          {emptyIcon}
        </div>
      );
    }
    if (r === 1) {
      return (
        <div>
          {fillIcon}
          {emptyIcon}
          {emptyIcon}
          {emptyIcon}
          {emptyIcon}
        </div>
      );
    }
    if (r === 2) {
      return (
        <div>
          {fillIcon}
          {fillIcon}
          {emptyIcon}
          {emptyIcon}
          {emptyIcon}
        </div>
      );
    }
    if (r === 3) {
      return (
        <div>
          {fillIcon}
          {fillIcon}
          {emptyIcon}
          {emptyIcon}
          {emptyIcon}
        </div>
      );
    }
    if (r === 4) {
      return (
        <div>
          {fillIcon}
          {fillIcon}
          {fillIcon}
          {fillIcon}
          {emptyIcon}
        </div>
      );
    }
    if (r === 5) {
      return (
        <div>
          {fillIcon}
          {fillIcon}
          {fillIcon}
          {fillIcon}
          {fillIcon}
        </div>
      );
    }
  }

  getUsersOptions() {
    let options = [];
    this.props.CCUsersData
      ? this.props.CCUsersData.map((c, i) => {
          return options.push({
            value: c.id,
            label: c.first_name + " " + c.last_name
          });
        })
      : [];
    return options;
  }

  handleChange(selectedUserID) {
    if (
      selectedUserID &&
      this.state.CCUser !== null &&
      this.state.CCUser !== undefined &&
      selectedUserID.value !== this.state.CCUser.value
    )
      this.setState(
        {
          CCUser: selectedUserID
        },
        () => {
          this.props.getCCDashboard(this.state.CCUser.value, this.state.CCUser);
        }
      );
    else if (this.state.CCUser === null || this.state.CCUser === undefined)
      this.setState(
        {
          CCUser: selectedUserID
        },
        () => {
          this.props.getCCDashboard(this.state.CCUser.value, this.state.CCUser);
        }
      );
    if (selectedUserID === null) {
      this.setState(
        {
          CCUser: null
        },
        () => {
          this.props.getCCDashboard(this.state.CCUser);
        }
      );
    }
  }

  render() {
    const userLogin = localStorage.getItem("user");
    // const userRole=userLogin && JSON.parse(userLogin).main_role;
    const userRole = localStorage.getItem("defaultRole");
    const {
      isLoadingDashboard = false,
      dashboardData: {
        questions_missed: { questions = [] } = {},
        tutorials_completed: {
          average_minutes = 0,
          times_taken = 0,
          created: { data = [] } = {}
        } = {},
        quizzes_completed: {
          attempts = 0,
          failed = 0,
          minutes = 0,
          passed = 0,
          total: { data: tData = [] } = {}
        } = {}
      } = {},
      isLoadingCCUsers = false,
      CCUsersData = [],
      tutorialCompletedGraph = {},
      quizessCompletedGraph = {}
    } = this.props;
    const { collapse, CCUser } = this.state;
    return (
      <div className="animated fadeIn mt-2 mt-lg-0 w-100">
        <Row>
          <Col md={12} lg={12} xs={12} sm={24}>
            <div className="dashboard-header mb-3 pl-0 pb-3">
              <div className="col-lg-12">
                <div className="row align-items-center">
                  <div className="dashboard-header-title col-md-8">
                    <h4 className="d-inline-block">
                      {(userRole === "org_administrator" ||
                        userRole === "licensee") &&
                        "Instructor "}
                      Analytics
                      {(userRole === "org_administrator" ||
                        userRole === "licensee") &&
                        CCUser &&
                        " : " + CCUser.label}
                    </h4>
                  </div>
                  {(userRole === "org_administrator" ||
                    userRole === "licensee") && (
                    <div className="ml-auto col-md-4 col-sm-5 col-12">
                      <Select
                        placeholder={"Select Instructor"}
                        value={CCUser}
                        onChange={this.handleChange}
                        options={this.getUsersOptions()}
                        isSearchable={true}
                        isClearable={false}
                        aria-label={"Select Instructor"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Row>
              <Col xs="12" sm="12" md="12" lg="6" xl="4">
                <Card className="main-info bg-info">
                  <CardHeader>Tutorials Completed</CardHeader>
                  <CardBody className="py-1 px-2">
                    {isLoadingDashboard ? (
                      <Spinner />
                    ) : (
                      <div className="main-dashboard-component">
                        <div className="col-12 p-1 d-flex">
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{data.length}</h1>
                            <p className="mb-0">Created</p>
                          </div>
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{times_taken}</h1>
                            <p className="mb-0">Times Taken</p>
                          </div>
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{average_minutes}</h1>
                            <p className="mb-0">Average Minutes</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardBody>
                  {isLoadingDashboard ? (
                    ""
                  ) : (
                    <div className="custom-canvas">
                      <div className="chart-wrapper">
                        <Line
                          data={tutorialCompletedGraph}
                          options={{
                            responsive: true,
                            legend: false,
                            layout: {
                              padding: {
                                right: 15,
                                top: 15
                              }
                            },
                            scales: {
                              yAxes: [
                                {
                                  gridLines: {
                                    drawBorder: false,
                                    display: false
                                  },
                                  ticks: {
                                    display: false
                                  }
                                }
                              ],
                              xAxes: [
                                {
                                  gridLines: {
                                    drawBorder: false,
                                    display: false
                                  },
                                  ticks: {
                                    display: false
                                  }
                                }
                              ]
                            },
                            maintainAspectRatio: false
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Card>
                <Card className="">
                  <CardHeader className="bg-info">
                    <h6 className="font-weight-bold">Tutorials Completed</h6>
                  </CardHeader>
                  <CardBody className="admin-middle-card-body">
                    {isLoadingDashboard ? (
                      <Spinner />
                    ) : data.length > 0 ? (
                      <Table responsive className="middle-table">
                        <tbody>
                          {data.map((tutorial, index) => (
                            <tr key={index}>
                              <td data-event={index}>
                                <a href="#">{tutorial.title}</a>
                              </td>
                            </tr>
                          ))
                          // remove since no need to show attempt data as mentioned in download Answer
                          // data.map((tutorial,index) =>
                          //     <tr>
                          //         <td onClick={(e) => {
                          //             e.preventDefault();
                          //             this.toggleMenu(`${index}${tutorial.id}details`)
                          //         }}
                          //             data-event={index}>
                          //             <a href="#">
                          //                 {!collapse[`${index}${tutorial.id}details`] ?
                          //                     <i className="fa fa-angle-right mr-1"/> :
                          //                     <i className="fa fa-angle-down mr-1"/>}
                          //                 {tutorial.title}
                          //             </a>
                          //             <Collapse isOpen={collapse[`${index}${tutorial.id}details`]}>
                          //                 <div className="accordion-body">
                          //                     {
                          //                         tutorial.attempts.map((a,i)=>{
                          //                             return <div className=" pt-3 pr-3 pl-3 pb-0 mt-2 admin-collapse-data-card">
                          //                                 <div className="justify-content-between dis-flex flex-column">
                          //                                     <p className="mb-1">
                          //                                         Name : {a.name}
                          //                                     </p>
                          //                                     <p className="mb-1">Date : {a.finished_at?moment(a.finished_at).format('DD-MM-YYYY'):'----'}</p>
                          //                                     {/*<p className="mb-1">{a.grade+`/`+a.total_points}</p>*/}
                          //                                     <div className="dis-flex justify-content-between">
                          //                                         <p className="mb-1">Grade : {a.grade}</p>
                          //                                         <p className="mb-1">Total : {a.total_points}</p>
                          //                                     </div>
                          //                                 </div>
                          //                                 <div className="dis-flex justify-content-between">
                          //                                     <p>Review : {a.review?a.review:'----'}</p>
                          //                                     <p className="mb-0">
                          //                                         {this.displayRating(Number(a.rating))}
                          //                                     </p>
                          //                                 </div>
                          //                             </div>
                          //                         })
                          //                     }
                          //                 </div>
                          //             </Collapse>
                          //         </td>
                          //     </tr>)
                          }
                        </tbody>
                      </Table>
                    ) : (
                      <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                        You have no completed tutorials
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="4">
                <Card className="main-info bg-primary ">
                  <CardHeader>Quizzes Completed</CardHeader>
                  <CardBody className="py-1 px-2">
                    {isLoadingDashboard ? (
                      <Spinner />
                    ) : (
                      <div className="main-dashboard-component">
                        <div className="col-12 p-1 d-flex">
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{tData.length}</h1>
                            <p className="mb-0">Total</p>
                          </div>
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{attempts}</h1>
                            <p className="mb-0">Attempts</p>
                          </div>
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{passed}</h1>
                            <p className="mb-0">Passed</p>
                          </div>
                        </div>
                        <div className="col-12 p-1 d-flex">
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{failed}</h1>
                            <p className="mb-0">Failed</p>
                          </div>
                          <div className="col-4 p-1 count-table">
                            <h1 className="mb-0">{minutes}</h1>
                            <p className="mb-0">Minutes</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardBody>
                  {isLoadingDashboard ? (
                    ""
                  ) : (
                    <div className="custom-canvas">
                      <div className="chart-wrapper">
                        <Line
                          data={quizessCompletedGraph}
                          options={{
                            responsive: true,
                            legend: false,
                            layout: {
                              padding: {
                                right: 15,
                                top: 15
                              }
                            },
                            scales: {
                              yAxes: [
                                {
                                  gridLines: {
                                    drawBorder: false,
                                    display: false
                                  },
                                  ticks: {
                                    display: false
                                  }
                                }
                              ],
                              xAxes: [
                                {
                                  gridLines: {
                                    drawBorder: false,
                                    display: false
                                  },
                                  ticks: {
                                    display: false
                                  }
                                }
                              ]
                            },
                            maintainAspectRatio: false
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Card>
                <Card className="">
                  <CardHeader className="bg-primary">
                    <h6 className="font-weight-bold">Quizzes Completed</h6>
                  </CardHeader>
                  <CardBody className="admin-middle-card-body">
                    {isLoadingDashboard ? (
                      <Spinner />
                    ) : tData.length > 0 ? (
                      <Table responsive className="middle-table">
                        <tbody>
                          {tData.map((tutorial, index) => (
                            <tr>
                              <td data-event={index}>
                                <a href="#">{tutorial.title}</a>
                              </td>
                            </tr>
                          ))
                          // remove since no need to show attempt data as mentioned in download Answer
                          // tData.map((tutorial,index) =>
                          //     <tr>
                          //         <td onClick={(e) => {
                          //             e.preventDefault();
                          //             this.toggleMenu(`${index}${tutorial.id}details`)
                          //         }}
                          //             data-event={index}>
                          //             <a href="#">
                          //                 {!collapse[`${index}${tutorial.id}details`] ?
                          //                     <i className="fa fa-angle-right mr-1"/> :
                          //                     <i className="fa fa-angle-down mr-1"/>}
                          //                 {tutorial.title}
                          //             </a>
                          //             <Collapse isOpen={collapse[`${index}${tutorial.id}details`]}>
                          //                 <div className="accordion-body">
                          //                     {
                          //                         tutorial.attempts.map((a,i)=>{
                          //                             return <div className=" pt-3 pr-3 pl-3 pb-0 mt-2 admin-collapse-data-card">
                          //                                 <div className="justify-content-between dis-flex flex-column">
                          //                                     <p className="mb-1">
                          //                                         Name : {a.name}
                          //                                     </p>
                          //                                     <p className="mb-1">Date : {a.finished_at?moment(a.finished_at).format('DD-MM-YYYY'):'----'}</p>
                          //                                     {/*<p className="mb-1">{a.grade+`/`+a.total_points}</p>*/}
                          //                                     <div className="dis-flex justify-content-between">
                          //                                         <p className="mb-1">Grade : {a.grade}</p>
                          //                                         <p className="mb-1">Total : {a.total_points}</p>
                          //                                     </div>
                          //                                 </div>
                          //                                 <div className="dis-flex justify-content-between">
                          //                                     <p>Review : {a.review?a.review:'----'}</p>
                          //                                     <p className="mb-0">
                          //                                         {this.displayRating(Number(a.rating))}
                          //                                     </p>
                          //                                 </div>
                          //                             </div>
                          //                         })
                          //                     }
                          //                 </div>
                          //             </Collapse>
                          //         </td>
                          //     </tr>)
                          }
                        </tbody>
                      </Table>
                    ) : (
                      <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                        You have no quizzes completed
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="4">
                <Card className="main-info bg-warning ">
                  <CardHeader>Questions Missed</CardHeader>
                  <CardBody className="py-1 px-2">
                    {isLoadingDashboard ? (
                      <Spinner />
                    ) : (
                      <div className="main-dashboard-component w-100">
                        <div className="col-12 p-1 d-flex">
                          <div className="col-3 p-1 count-table">
                            <h1 className="mb-0">{questions.length}</h1>
                            <p className="mb-0">Questions</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="bg-warning">
                    <h6 className="font-weight-bold">Questions Missed</h6>
                  </CardHeader>
                  <CardBody className="admin-middle-card-body">
                    {isLoadingDashboard ? (
                      <Spinner />
                    ) : questions.length > 0 ? (
                      <Table responsive className="middle-table">
                        <tbody>
                          {questions.map((q, index) => (
                            <tr>
                              <td data-event={index}>
                                <a href="#">{q.tutorial.title}</a>
                              </td>
                            </tr>
                          ))
                          // remove since no need to show attempt data as mentioned in download Answer
                          // questions.map((q,index) =>
                          //     <tr>
                          //         <td onClick={(e) => {
                          //             e.preventDefault();
                          //             this.toggleMenu(`${index}${q.id}details`)
                          //         }}
                          //             data-event={index}>
                          //             <a href="#">
                          //                 {!collapse[`${index}${q.id}details`] ?
                          //                     <i className="fa fa-angle-right mr-1"/> :
                          //                     <i className="fa fa-angle-down mr-1"/>}
                          //                 {q.tutorial.title}
                          //             </a>
                          //             <Collapse isOpen={collapse[`${index}${q.id}details`]}>
                          //                 <div className="accordion-body">
                          //                     {
                          //                         q.tutorial.questions&&q.tutorial.questions.map((a,i)=>{
                          //                             return <div className=" pt-3 pr-3 pl-3 pb-0 mt-2 admin-collapse-data-card">
                          //                                 <div className="dis-flex">
                          //                                     <p className="mb-1">
                          //                                         <b>Q. {a.text}?</b>
                          //                                     </p>
                          //                                     <p className="mb-1">{ a.threshold !== 0 && a.threshold+'%' }</p>
                          //                                 </div>
                          //                             </div>
                          //                         })
                          //                     }
                          //                 </div>
                          //             </Collapse>
                          //         </td>
                          //     </tr>)
                          }
                        </tbody>
                      </Table>
                    ) : (
                      <div className="text-center assigned-tutorials d-flex h-100 justify-content-center align-items-center">
                        You have no questions missed
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/*<div className="chart-wrapper-card">*/}
            {/*<StudentChart/>*/}
            {/*</div>*/}
          </Col>
        </Row>
        <Row></Row>
      </div>
    );
  }
}

export default ManageDashboard;
