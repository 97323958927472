import React from "react";

export default class FreeCurriculumCreator extends React.Component {
  render() {
    return (
      <div className="animated fadeIn">
        <h1>Free Curriculum Creator</h1>
      </div>
    );
  }
}
