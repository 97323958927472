let id = 4;
const routes = {
  "/": "Home",
  "/login": "Login",
  "/register": "Register",
  "/thank-you": "Thank You",
  "/tutorial-management": "Tutorial Management",
  "/create-tutorial": "Create Tutorial",
  "/reset-password": "Reset Password",
  "/help-center": "Help Center",
  [`/learner-quiz/${id}`]: "Quiz",
  [`/tutorial-page/${id}/tutorialViewer`]: "Tutorials",
  [`/tutorial-page/${id}/QuizViewer`]: "Summative Quiz",
  [`/tutorial-table-of-content/${id}`]: "Table of Content",
  "/role-management": "Role Management",
  "/credentials-management": "User Management",
  "/credentials-management/adduser": "Add User",
  "/credentials-management/import-users": "Import Users",
  [`/tutorial-management/edit/${id}`]: "Edit Tutorial",
  [`/tutorial-management/edit/${id}/addchapter`]: "Chapter",
  "/account-management": "Account Management",
  "/my-profile": "My Profile",
  "/free-curriculum-creator": "Free curriculum Creator",
  "/account-management/change-profile": "Change Profile",
  "/account-management/change-Account": "Cancel Account",
  "/account-management/change-Email": "Change Email",
  "/account-management/change-Institution": "Change Institution",
  "/course-management": "Course Management",
  "/course-management/addCourses": "Add Course",
  "/learner-management": "Learner Management",
  "/tutorial-look-feel": "Tutorial Look & Feel",
  "/import-tutorials": "Import Tutorials",
  "/notification": "Notification",
  "/learner-management/addLearner-Form": "Add Learners",
  "/learner-management/import-students": "Import Learners",
  "/learner-management/": "Learner Management"
};
export default routes;
