import React from "react";
import { Route, Redirect } from "react-router-dom";

export const Authorized = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.allowed ? (
        rest.allowed.some(e =>
          JSON.parse(localStorage.getItem("user"))
            .roles.map(e => e.name)
            .includes(e)
        ) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);
