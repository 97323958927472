import { userConstants, toastConstants } from "../_constants";
import { userService, contentCreatorService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

const roleMainRoute = [
  {
    role: "administrator",
    mainRoute: "/admin"
  },
  {
    role: "org_administrator",
    mainRoute: "/"
  },
  {
    role: "full_basic_content_creator",
    mainRoute: "/"
  },
  {
    role: "full_content_creator",
    mainRoute: "/"
  },
  {
    role: "basic_content_creator",
    mainRoute: "/"
  },
  {
    role: "individual_content_creator",
    mainRoute: "/"
  },
  {
    role: "learner",
    mainRoute: "/"
  },
  {
    role: "licensee",
    mainRoute: "/"
  },
  {
    role: "basic_content_creator",
    mainRoute: "/"
  },
  {
    role: "content_creator",
    mainRoute: "/create-tutorial"
  }
];

export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  setDefaultRole,
  updateUser,
  getById,
  changePassword,
  resetPassword,
  confirmChangePassword,
  getTutorial,
  changeRole
};

function login(email, password) {
  return dispatch => {
    dispatch(request({ email }));
    return userService.login(email, password).then(
      user => {
        // dispatch(success(user));
        dispatch({ type: userConstants.DEFAULT_ROLE, payload: user.main_role });
        return dispatch(success(user));

        const route = roleMainRoute.find(e => e.role === user.main_role);
        // history.push(route.mainRoute);
      },
      error => {
        let errMsg;
        const [
          { response: { data: { errors = [] } = {} } = {} },
          message = ""
        ] = [error, error.message];

        if (errors.length !== 0) errMsg = errors.join(",");
        else errMsg = message;

        dispatch(failure(errMsg));
        dispatch(alertActions.error(errMsg));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function changeRole(role) {
  return dispatch => {
    localStorage.setItem("defaultRole", role);
    dispatch({
      type: userConstants.DEFAULT_ROLE,
      payload: role
    });
  };
}

function getTutorial(id) {
  return dispatch => {
    dispatch({ type: "GET_LOGIN_TUTORIAL_REQUEST" });
    return userService.getTutorialByID(id).then(
      data => {
        return dispatch(success(data));
      },
      error => {
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: "GET_LOGIN_TUTORIAL_SUCCESS", data };
  }
  function failure(error) {
    return { type: "GET_LOGIN_TUTORIAL_FAILURE", error };
  }
}

function logout() {
  return dispatch => {
    userService.logout();
    dispatch({ type: userConstants.LOGOUT });
  };
}

function register(user) {
  return dispatch => {
    dispatch(request(user));

    return userService.register(user).then(
      user => {
        return dispatch(success(user));
        history.push("/thank-you");
        dispatch(
          alertActions.success(
            "Please check your email to verify your account."
          )
        );
      },
      error => {
        return dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}
function setDefaultRole(role) {
  return dispatch => {
    dispatch({
      type: userConstants.DEFAULT_ROLE,
      payload: role
    });
  };
}

function getAll() {
  return dispatch => {
    dispatch(request());
    userService.getAll().then(
      users => dispatch(success(users)),
      error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getById(uid, client, token) {
  return dispatch => {
    dispatch(request());
    userService.getById(uid, client, token).then(
      users => dispatch(success(users)),
      error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error };
  }
}

function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService.delete(id).then(
      user => dispatch(success(id)),
      error => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

function updateUser(obj) {
  return dispatch => {
    return userService.changeName(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully updated." }
        });
        dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: userConstants.CHANGE_NAME_REQUEST };
  }
  function success(data) {
    return { type: userConstants.CHANGE_NAME_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_NAME_FAILURE, error };
  }
}
function changePassword(obj) {
  return dispatch => {
    return userService.changePassword(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully updated." }
        });
        return dispatch(success(data));
      },
      error => {
        console.log(error.response)
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };
  function success(data) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}
function resetPassword(obj) {
  return dispatch => {
    return userService.resetPassword(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: {
            type: "SUCCESS",
            data: data.data.message
          }
        });
        return dispatch(success(data));
      },
      error => {
        const err = error?.response?.data?.errors?.join(",") || error.message;
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: err }
        });
        return dispatch(failure(err));
      }
    );
  };
  function success(data) {
    return { type: userConstants.RESET_USER_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.RESET_USER_PASSWORD_FAILURE, error };
  }
}
function confirmChangePassword(headers, obj) {
  return dispatch => {
    return userService.confirmChangePassword(headers, obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully updated." }
        });
        history.push("/login");
        // return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        // return dispatch(failure(error.response.data));
      }
    );
  };
}
