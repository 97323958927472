import React from "react";
import LearnerHome from "../../../components/Learner/Home-Learner/LearnerHome";
import { menuTogglerAction } from "../../../_actions";
import { connect } from "react-redux";
import { learnerDashboardActions } from "../../../_actions/LearnerActions/learnerDashboard.action";

class DashboardLearner extends React.Component {
  constructor(props) {
    super(props);
    this.isToggleMethod = this.isToggleMethod.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(learnerDashboardActions.getAssignedTutorialData());
    dispatch(learnerDashboardActions.getLearnerDashboardData());
  }
  isToggleMethod(bool) {
    let { dispatch } = this.props;
    dispatch(menuTogglerAction.toggleMenu(bool));
  }
  render() {
    const {
      tutorialData,
      dashboardData,
      doNotShow,
      dispatch,
      isDashboardLoading
    } = this.props;
    return (
      <div className="animated fadeIn pl-3 pr-3">
        <LearnerHome
          isToggleMethod={this.isToggleMethod}
          tutorialData={tutorialData}
          dashboardData={dashboardData}
          isDashboardLoading={isDashboardLoading}
          doNotShow={doNotShow}
          dispatch={dispatch}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tutorialData: state.learnerDashboard.tutorialsData,
    dashboardData: state.learnerDashboard.dashboardData,
    isDashboardLoading: state.learnerDashboard.isDashboardLoading,
    doNotShow: state.learnerDashboard.doNotShow
  };
}
const connectedLearner = connect(mapStateToProps, null)(DashboardLearner);
export { connectedLearner as DashboardLearner };
