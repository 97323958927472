import { quizConstants } from "../../_constants/index";
const initial = {
  quizzesData: []
};
export function quiz(state = initial, action) {
  switch (action.type) {
    case quizConstants.GETALL_QUIZ_SUCCESS:
      return { ...state, quizzesData: action.data };
    case quizConstants.GETALL_QUIZ_FAILURE:
      return { ...state, quizzesData: [] };
    default:
      return state;
  }
}
