import axiosI from "../../_helpers/service";
export const learnerService = {
  getLearner,
  getCourse,
  assignLearnerToCourse,
  addLearnersService,
  deleteLearnerCourseService
};

function getLearner() {
  return axiosI.get(`/learners`).then(response => {
    return new Promise((res, rej) => {
      res(response.data);
    });
  });
}
function getCourse() {
  return axiosI.get(`/courses`).then(response => {
    return new Promise((res, rej) => {
      res(response.data);
    });
  });
}
function assignLearnerToCourse(obj) {
  return axiosI.post(`/courses/assign_learners`, obj).then(response => {
    return new Promise((res, rej) => {
      res(response.data);
    });
  });
}
function addLearnersService(obj) {
  return axiosI.post("/learners", obj).then(response => {
    return response;
  });
}
function deleteLearnerCourseService(obj) {
  return axiosI.post("/courses/remove_learners", obj).then(response => {
    return response;
  });
}
