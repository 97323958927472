import { connect } from "react-redux";
import React, { Component } from "react";
import ImportTutorials from "../../components/ImportTutorials/importTutorials";
import { importTutorialsActions } from "../../_actions/index";
import Spinner from "../../components/spinner/index";

class ImportTutorialContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(importTutorialsActions.getCourseData());
    dispatch(importTutorialsActions.getUserData());
  }

  render() {
    const {
      userData,
      courseData,
      isLoadingCourseImport,
      isLoadingUserImport,
      dispatch,
      DefaultRole
    } = this.props;
    return (
      <div className="animated fadeIn">
        {isLoadingCourseImport || isLoadingUserImport ? (
          <Spinner />
        ) : (
          <ImportTutorials
            courseData={courseData}
            dispatch={dispatch}
            DefaultRole={DefaultRole}
            userData={userData}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.importTutorials.userData,
    courseData: state.importTutorials.courseData,
    isLoadingUserImport: state.importTutorials.isLoadingUserImport,
    isLoadingCourseImport: state.importTutorials.isLoadingCourseImport,
    DefaultRole: state.authentication.DefaultRole
  };
}

const connectedImportTutorialContainer = connect(
  mapStateToProps,
  null
)(ImportTutorialContainer);
export { connectedImportTutorialContainer as ImportTutorialContainer };
