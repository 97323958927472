import React, { Component } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import { Button } from "reactstrap";
import IframeLink from '../IframeLink'
import AbbrTagQuill from "../AbbrTagQuill"
import "./tutorialManagement.scss";
import { isElectron } from "../../utils";
import smalltalk from 'smalltalk'

var icons = Quill.import('ui/icons')

icons['iframe-link'] = 'IL'
icons['abbr-tag'] = 'AB'

Quill.register('formats/iframe-link', IframeLink)
Quill.register('formats/abbr-tag', AbbrTagQuill)

async function iframeLinkHandler(value) {
  if (value) {
    let href
    if (isElectron) {
      href = await smalltalk
        .prompt('Enter the URL', 'Link will open on the right hand side', 'https://google.com')
    } else {
      href = prompt('Enter the URL');
    }
    this.quill.format('iframe-link', href);
  } else {
    this.quill.format('iframe-link', false);
  }
}

async function abbrTagHandler(value) {
  if (value) {
    let abbrTag
    if (isElectron) {
      abbrTag = await smalltalk
        .prompt('Enter the abbreviation', 'Will show abbreviation to students', 'Enter abbreviation text')
    } else {
      abbrTag = prompt('Enter the abbreviation');
    }
    this.quill.format('abbr-tag', abbrTag);
  } else {
    this.quill.format('abbr-tag', false);
  }
}

const handlers = {
  'iframe-link': iframeLinkHandler,
  'abbr-tag': abbrTagHandler,
}

const container = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["link", "iframe-link", "abbr-tag"],
  ["clean"]
]

const toolbarOptions = {
  container,
  handlers
}

const style = {
  minHeight: "200px"
};

class AddText extends Component {
  constructor() {
    super();
    this.state = {
      text: {
        type: "text",
        text: "",
        index: 0
      },
      errors: []
    };
    this.modules = {
      toolbar: toolbarOptions
    };
  }

  handleChange = (e) => {
    let { text } = this.state;
    text.text = e;
    this.setState({ text });
  }

  displayText = (val) => {
    if (typeof val === "object") return val.join(" ");
    else return val;
  }

  onSave = () => {
    this.props.handleSubmit(this.state.text, "addText");
  }

  componentDidMount() {
    let { editData, index } = this.props;
    let text = "";
    if ("data" in editData) {
      if (typeof editData.data.text === "object")
        text = editData.data.text.ops.map(e => e.insert);
      else text = editData.data.text;
      // this.setState({text: {...this.state.text, text: text,index:editData.index?editData.index:index-1}})
      this.setState({
        text: { ...this.state.text, text: text, index: editData.index }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) this.setState({ errors: nextProps.errors });
  }

  render() {
    let { text, errors } = this.state;
    return (
      <div className="mt-3 mb-3">
        <ReactQuill
          value={this.displayText(text.text)}
          modules={this.modules}
          onChange={this.handleChange}
          placeholder={"Write something..."}
          style={style}
          ref={e => e && e.focus()}
        />
        {errors["text"] && <div className="help-block">{errors["text"]}</div>}
        <div className="save-btn-tutorial mb-2 mt-2 text-right w-100">
          <Button
            type="button"
            color="primary"
            className="btn btn-primary custom-save-btn w-25"
            onClick={this.onSave}
          >
            {" "}
            Save{" "}
          </Button>
        </div>
      </div>
    );
  }
}
export default AddText;
