import { menuTogglerConstants } from "../../_constants/Learner/menuToggler.constants";

export const menuTogglerAction = {
  toggleMenu
};
function toggleMenu(isToggle) {
  return dispatch =>
    dispatch({
      type: menuTogglerConstants.MENU_TOGGLER,
      payload: { toggleBool: isToggle }
    });
}
