import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import Spinner from "../../components/spinner";
import ManageCourse from "../../components/CourseManagement/manageCourse";
import CourseForm from "../../components/CourseManagement/courseForm";
import { courseActions } from "../../_actions";
import { Authorized } from "../../_components/Authorized";
import { history } from "../../_helpers/history";
import { FileActions } from "../../_actions/file_selection.action";

class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: { test: true },
      courses: [
        {
          title: "",
          description: "",
          user_id: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")).id
            : ""
        }
      ],
      errors: [{ title: "" }],
      selectedTutorialIds: [],
      selectedCourseIds: [],
      scroll: 0
    };
    this.cancelForm = this.cancelForm.bind(this);
    this.saveCourses = this.saveCourses.bind(this);
    this.saveManageCourse = this.saveManageCourse.bind(this);
    this.saveEditCourse = this.saveEditCourse.bind(this);
    this.deleteCourse = this.deleteCourse.bind(this);
    this.copyCourse = this.copyCourse.bind(this);
    this.deleteCourseTutorial = this.deleteCourseTutorial.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if (!this.props.courseData.length) dispatch(courseActions.getCourseData());
    if (!this.props.tutorialData.length)
      dispatch(courseActions.getTutorialData());
  }

  cancelForm() {
    this.setState({
      courses: [
        {
          title: "",
          description: "",
          user_id: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")).id
            : ""
        }
      ],
      errors: [{ title: "" }]
    });
    history.push("/course-management");
  }
  saveCourses(courses, errors) {
    this.setState({
      courses: courses,
      errors: errors
    });
    let data = {
      courses: this.state.courses
    };
    const { dispatch, errorData } = this.props;
    dispatch(courseActions.addCoursesData(data)).then((res, err) => {
      if (res.data.data) {
        history.push("/course-management");
      }
    });
  }
  saveManageCourse(
    selectedTutorialIds,
    selectedCourseIds,
    collapse,
    scrollValue
  ) {
    let obj = {
      tutorial_ids: selectedTutorialIds,
      course_ids: selectedCourseIds
    };
    const { dispatch } = this.props;
    if (selectedTutorialIds.length > 0 && selectedCourseIds.length > 0) {
      dispatch(courseActions.assignedTutorialsToCoursesData(obj)).then(
        (res, err) => {
          this.setState({
            collapse: collapse,
            selectedCourseIds: selectedCourseIds,
            selectedTutorialIds: [],
            scroll: scrollValue
          });
        }
      );
    } else {
      dispatch(FileActions.showFileMessage("course-management"));
    }
  }
  saveEditCourse(courseData) {
    let tutorialsIds = [];
    courseData.tutorials.find(c => tutorialsIds.push(c.id));
    let obj = {
      title: courseData.title,
      description: courseData.description,
      learner_ids: [],
      tutorial_ids: tutorialsIds
    };
    const { dispatch } = this.props;
    dispatch(courseActions.editCoursesData(obj, courseData.id)).then(
      (res, err) => {
        if (res) {
        }
      }
    );
  }
  deleteCourse(id) {
    const { dispatch } = this.props;
    dispatch(courseActions.deleteCoursesData(id));
  }
  deleteCourseTutorial(tid, cid) {
    const { dispatch } = this.props;
    let obj = { course_ids: [cid], learner_ids: [] };
    dispatch(courseActions.deleteCoursesTutorialData(tid, obj));
  }
  copyCourse(cid, courseName) {
    let obj = { title: courseName };
    const { dispatch } = this.props;
    return dispatch(courseActions.copyCoursesData(cid, obj));
  }
  render() {
    const {
      courses,
      errors,
      showCopyModal,
      collapse,
      selectedTutorialIds,
      selectedCourseIds,
      scroll
    } = this.state;

    const {
      courseData,
      tutorialData,
      errorData,
      isLoadingCourses,
      isLoadingTutorials,
      location: { pathname = "" }
    } = this.props;
    const userLogin = localStorage.getItem("user");
    return (
      <div className="animated fadeIn">
        {isLoadingCourses || isLoadingTutorials ? (
          <Spinner />
        ) : (
          <Row className="custom-row bg-white pt-4">
            <Col md={12}>
              <Authorized
                exact
                path="/course-management"
                component={() => (
                  <ManageCourse
                    tutorialData={tutorialData}
                    isLoadingTutorials={isLoadingTutorials}
                    courseData={courseData}
                    isLoadingCourses={isLoadingCourses}
                    errorData={errorData}
                    saveManageCourse={this.saveManageCourse}
                    deleteCourse={this.deleteCourse}
                    saveEditCourse={this.saveEditCourse}
                    copyCourse={this.copyCourse}
                    showCopyModal={showCopyModal}
                    deleteCourseTutorial={this.deleteCourseTutorial}
                    collapse={collapse}
                    selectedTutorialIds={selectedTutorialIds}
                    selectedCourseIds={selectedCourseIds}
                    scroll={scroll}
                  />
                )}
                allowed={
                  userLogin && [
                    "org_administrator",
                    "full_content_creator",
                    "licensee"
                  ]
                }
                pathname={pathname}
              />

              <Authorized
                exact
                path="/course-management/addCourses"
                component={() => (
                  <CourseForm
                    cancelForm={this.cancelForm}
                    saveCourses={this.saveCourses}
                    errorData={errorData}
                    errors={errors}
                    courses={courses}
                  />
                )}
                allowed={
                  userLogin && [
                    "org_administrator",
                    "full_content_creator",
                    "licensee"
                  ]
                }
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    courseData: state.courses.courseData,
    isLoadingCourses: state.courses.isLoadingCourses,
    tutorialData: state.courses.tutorialData,
    isLoadingTutorials: state.courses.isLoadingTutorials,
    errorData: state.courses.errorData
  };
}

const connectedCourse = connect(mapStateToProps, null)(Course);
export { connectedCourse as Course };
