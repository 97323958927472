export const tutorialConstants = {
  GETALL_TUTORIAL_REQUEST: "GETALL_TUTORIAL_REQUEST",
  GETALL_TUTORIAL_SUCCESS: "LEARNER_TUTORIAL_SUCCESS",
  GETALL_TUTORIAL_FAILURE: "LEARNER_TUTORIAL_FAILURE",
  CLEAR_TUTORIAL: "CLEAR_TUTORIAL",
  ADD_COLLABORATOR_TO_TUTORIAL_REQUEST: "ADD_COLLABORATOR_TO_TUTORIAL",
  ADD_COLLABORATOR_TO_TUTORIAL_SUCCESS: "ADD_COLLABORATOR_TO_TUTORIAL_SUCCESS",
  ADD_COLLABORATOR_TO_TUTORIAL_FAILURE: "ADD_COLLABORATOR_TO_TUTORIAL_FAILURE",
  STORE_CHANNEL_IN_REDUX: "STORE_CHANNEL_IN_REDUX",
  SOMEONE_IS_EDITING_TUTORIAL: "SOMEONE_IS_EDITING_TUTORIAL",
  REMOVE_COLLABORATOR_FROM_TUTORIAL_REQUEST:
    "REMOVE_COLLABORATOR_FROM_TUTORIAL_REQUEST",
  REMOVE_COLLABORATOR_FROM_TUTORIAL_SUCCESS:
    "REMOVE_COLLABORATOR_FROM_TUTORIAL_SUCCESS",
  REMOVE_COLLABORATOR_FROM_TUTORIAL_FAILURE:
    "REMOVE_COLLABORATOR_FROM_TUTORIAL_FAILURE",
};
