export const learnerConstants = {
  GET_LEARNER_SUCCESS: "GET_LEARNER_SUCCESS",
  GET_LEARNER_FAILURE: "GET_LEARNER_FAILURE",
  GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",
  GET_COURSE_FAILURE: "GET_COURSE_FAILURE",
  COURSE_REQUEST: "COURSE_REQUEST",
  LEARNER_REQUEST: "LEARNER_REQUEST",
  ASSIGN_LEARNER_TO_COURSE_SUCCESS: "ASSIGN_LEARNER_TO_COURSE_SUCCESS",
  ASSIGN_LEARNER_TO_COURSE_FAILURE: "ASSIGN_LEARNER_TO_COURSE_FAILURE",
  ADD_LEARNER_SUCCESS: "ADD_LEARNER_SUCCESS",
  ADD_LEARNER_FAILURE: "ADD_LEARNER_FAILURE",
  DELETE_LEARNER_COURSES_SUCCESS: "DELETE_LEARNER_COURSES_SUCCESS",
  DELETE_LEARNER_COURSES_FAILURE: "DELETE_LEARNER_COURSES_FAILURE"
};
