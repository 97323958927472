import { learnerDashboardConstants } from "../../_constants/index";
const initial = {
  tutorialsData: [],
  dashboardData: [],
  isDashboardLoading: false,
  doNotShow: false,
  tutorial_color: {
    borderColor: "#c8eaf4",
    pointBorderColor: "#c8eaf4",
    pointHoverBackgroundColor: "#26cbfd",
    pointHoverBorderColor: "#c8eaf4"
  },
  quizzes_color: {
    borderColor: "#9dd9ee",
    pointBorderColor: "#9dd9ee",
    pointHoverBackgroundColor: "#00a5e0",
    pointHoverBorderColor: "#9dd9ee"
  },
  public_tutorial_color: {
    borderColor: "#fff",
    pointHoverBackgroundColor: "#ffcd39",
    pointHoverBorderColor: "#ffe8a4"
  },
  graph_attributes: {
    borderCapStyle: "butt",
    fill: false,
    borderDash: [],
    borderWidth: 1,
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 1,
    pointRadius: 5,
    pointHitRadius: 10
  }
};
export function learnerDashboard(state = initial, action) {
  switch (action.type) {
    case learnerDashboardConstants.GET_ASSIGNED_TUTORIALS_SUCCESS:
      return { ...state, tutorialsData: action.data.data.tutorials_shared };
    case learnerDashboardConstants.GET_ASSIGNED_TUTORIALS_FAILURE:
      return { ...state, tutorialsData: [] };
    case learnerDashboardConstants.GET_LEARNER_DASHBOARD_DETAILS_SUCCESS:
      let dashboard = action.data.data.graphs;
      dashboard &&
        Object.keys(dashboard).map(d => {
          switch (d) {
            case "tutorials_completed":
              dashboard[d].datasets[0] = {
                ...dashboard[d].datasets[0],
                ...state.tutorial_color,
                ...state.graph_attributes
              };
              break;
            case "quizzes_completed":
              dashboard[d].datasets[0] = {
                ...dashboard[d].datasets[0],
                ...state.quizzes_color,
                ...state.graph_attributes
              };
              break;
            case "public_tutorials_completed":
              dashboard[d].datasets[0] = {
                ...dashboard[d].datasets[0],
                ...state.public_tutorial_color,
                ...state.graph_attributes
              };
              break;
            default:
          }
        });
      return {
        ...state,
        dashboardData: action.data.data,
        isDashboardLoading: false
      };
    case learnerDashboardConstants.GET_LEARNER_DASHBOARD_DETAILS_REQUEST:
      return { ...state, isDashboardLoading: true };
    case learnerDashboardConstants.GET_LEARNER_DASHBOARD_DETAILS_FAILURE:
      return { ...state, dashboardData: [], isDashboardLoading: false };
    case learnerDashboardConstants.TOGGLE_DO_NOT_SHOW:
      return { ...state, doNotShow: !state.doNotShow };
    default:
      return state;
  }
}
