import {
  importTutorialsConstants,
  contentCreatorConstants,
  toastConstants
} from "../../_constants/index";
import { importTutorialsService } from "../../_services/index";

export const importTutorialsActions = {
  getUserData,
  getCourseData,
  importTutorialData,
  shareTutorialToCourses,
  shareTutorialToUsers,
  selectUser
};

function getUserData() {
  return dispatch => {
    dispatch({ type: importTutorialsConstants.GET_TUTORIALS_ROLES_REQUEST });
    importTutorialsService.getCurriculumCreators().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: importTutorialsConstants.GET_TUTORIALS_ROLES_SUCCESS, data };
  }
  function failure(error) {
    return {
      type: importTutorialsConstants.GET_TUTORIALS_ROLES_FAILURE,
      error
    };
  }
}
function getCourseData() {
  return dispatch => {
    dispatch({ type: importTutorialsConstants.GET_TUTORIALS_COURSE_REQUEST });
    importTutorialsService.getCourse().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return {
      type: importTutorialsConstants.GET_TUTORIALS_COURSE_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: importTutorialsConstants.GET_TUTORIALS_COURSE_FAILURE,
      error
    };
  }
}
function importTutorialData(tutorialData) {
  return dispatch => {
    return importTutorialsService.importTutorials(tutorialData).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully Imported." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data.base));
      }
    );
  };

  function success(data) {
    return { type: contentCreatorConstants.CREATE_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return {
      type: importTutorialsConstants.IMPORT_TUTORIAL_DATA_FAILURE,
      error
    };
  }
}
function shareTutorialToCourses(obj) {
  return dispatch => {
    return importTutorialsService.shareTutorialsToCourses(obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Tutorials share successfully." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return {
      type: importTutorialsConstants.SHARE_TUTORIAL_TO_COURSES_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: importTutorialsConstants.SHARE_TUTORIAL_TO_COURSES_FAILURE,
      error
    };
  }
}
function shareTutorialToUsers(id, obj) {
  return dispatch => {
    return importTutorialsService.shareTutorialsToUsers(id, obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Tutorials share successfully." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return {
      type: importTutorialsConstants.SHARE_TUTORIAL_TO_USERS_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: importTutorialsConstants.SHARE_TUTORIAL_TO_USERS_FAILURE,
      error
    };
  }
}
function selectUser() {
  return dispatch => {
    dispatch({
      type: toastConstants.SHOW_TOAST,
      message: { type: "ERROR", data: "Please select at least one user" }
    });
  };
}
