import React, { Component } from "react";
import "./mainTutorial.scss";
import { Row, Col, Input, Form, FormGroup, Label, Button } from "reactstrap";
let colorScheme = [
  { id: "1", name: "Primary", value: "#0275d8", className: "bg-primary" },
  { id: "2", name: "Secondary", value: "#818a91", className: "bg-secondary" },
  { id: "3", name: "Success", value: "#5cb85c", className: "bg-success" },
  { id: "4", name: "Danger", value: "#d9534f", className: "bg-danger" },
  { id: "5", name: "Warning", value: "#f0ad4e", className: "bg-warning" },
  { id: "6", name: "Info", value: "#5bc0de", className: "bg-info" },
  { id: "6", name: "Light", value: "#f8f9fa", className: "bg-light" },
  { id: "6", name: "Dark", value: "#2a2c36", className: "bg-dark" },
  { id: "6", name: "No Color", value: "", className: "" }
];
class MainTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      tagline: "0",
      data: {},
      error: ""
    };
  }

  componentDidMount() {
    const { user, tab } = this.props;
    if (tab !== "tutorial") {
      if (user.configuration && user.configuration.id) {
        this.setState({
          data: user.configuration.look_and_feel,
          fileName: user.configuration && user.configuration.logo.url
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let {
      TabsData = {},
      data: {
        editTutorial: {
          data: {
            configuration: {
              look_and_feel = {},
              configurable_type = "",
              logo,
              logo: { url = "" } = {}
            } = {}
          } = {}
        } = {}
      } = {},
      errors,
      user = {},
      tab
    } = nextProps;
    if (Object.keys(TabsData).length > 0) {
      this.setState({ data: TabsData, errors: errors });
    } else if (
      Object.keys(look_and_feel || {}).length > 0 &&
      configurable_type === "Tutorial"
    ) {
      this.setState({ data: look_and_feel, fileName: logo && url });
    } else if (tab !== "tutorial") {
      if (user.configuration && user.configuration.id) {
        this.setState({
          data: user.configuration.look_and_feel,
          fileName: user.configuration && user.configuration.logo.url
        });
      }
    }
  }

  clearLogoUrl = () => {
    this.setState({ fileName: "", data: { ...this.state.data, logo: "" } });
  };

  openDialogue = () => {
    let div = document.getElementById("file-upload");
    div.click();
  };

  handleFile = e => {
    let { data } = this.state;
    let ext = e.target.files ? e.target.files[0].name.split(".") : {};
    let extension = ["png", "gif", "bmp", "jpg", "jpeg"];
    if (extension.includes(ext[ext.length - 1])) {
      if (e.target.files && e.target.files[0]) {
        var FR = new FileReader();
        FR.addEventListener("load", function(e) {
          data["logo"] = e.target.result;
        });
        FR.readAsDataURL(e.target.files[0]);
      }
      let filename = e.target.files && e.target.files[0].name;
      this.setState({
        data,
        fileName: filename,
        error: ""
      });
    } else {
      this.setState({
        data,
        fileName: "",
        error: "Invalid Image file"
      });
    }
    this.props.removeBlankFormErrorMessage();
  };

  handleChange = e => {
    let { data } = this.state;
    let { name, value } = e.target;
    if (!name) {
      data[e.target.getAttribute("name")] = e.target.getAttribute("value");
    } else {
      data[name] = value;
    }
    this.setState({
      data
    });
    this.props.removeBlankFormErrorMessage();
  };

  render() {
    const { data, fileName, error } = this.state;
    return (
      <div className="animated fadeIn full-width-main-tutorial">
        <Row>
          <Col md={3}>
            <p className="tutorial-heading-border">Branding</p>
            <div className="branding mt-2">
              <Form>
                <FormGroup>
                  <Label htmlFor={"file-upload"}>Logo Image Source</Label>
                  <input
                    id={"file-upload"}
                    type="file"
                    className="get-file-from-local"
                    onChange={this.handleFile}
                  />
                  <Input
                    type="text"
                    value={fileName}
                    onClick={this.openDialogue}
                    name="logo_url"
                    aria-label={"Select Logo"}
                  />
                  {error && <div className="help-block">{error}</div>}
                  <Button
                    className={"mt-3"}
                    color={"primary"}
                    onClick={this.clearLogoUrl}
                  >
                    Clear
                  </Button>
                </FormGroup>
                <FormGroup>
                  <div className="W-100">
                    <span className="tut-width-30 d-block">
                      <p>Tagline</p>
                    </span>
                    <div className="d-flex justify-content-lg-start flex-column mb-2">
                      <span
                        className={
                          data && data.tagline_position === "below_logo"
                            ? "font-bold w-50 ml-4"
                            : "ml-4 w-50"
                        }
                      >
                        <Input
                          type="radio"
                          checked={
                            data && data.tagline_position === "below_logo"
                          }
                          name="tagline_position"
                          value={"below_logo"}
                          onChange={this.handleChange}
                          aria-label={"Tagline Position below logo"}
                        />
                        Below logo
                      </span>
                      <span
                        className={
                          data && data.tagline_position === "right_to_logo"
                            ? "font-bold w-50 ml-4"
                            : "w-50 ml-4"
                        }
                      >
                        <Input
                          type="radio"
                          checked={
                            data && data.tagline_position === "right_to_logo"
                          }
                          value={"right_to_logo"}
                          name="tagline_position"
                          onChange={this.handleChange}
                          aria-label={"Tagline Position Right of logo"}
                        />
                        Right of logo
                      </span>
                    </div>
                  </div>
                  <Input
                    type="text"
                    name="tagline"
                    value={data && data.tagline}
                    onChange={this.handleChange}
                    aria-label={"Enter Tagline"}
                  />
                </FormGroup>
              </Form>
            </div>
          </Col>
          <Col md={4}>
            <p className="tutorial-heading-border">Color Scheme</p>
            <div className="color-scheme mt-2">
              <div className="dis-flex">
                {colorScheme.map((c, i) => {
                  return (
                    i < 3 && (
                      <div
                        className={`col-md-4 col-xs-0 col-sm-0`}
                        style={{ cursor: "pointer" }}
                        name="color_scheme"
                        value={c.value}
                        onClick={e => this.handleChange(e)}
                        key={i}
                      >
                        <Row>
                          <Button
                            color={c.value}
                            className={
                              data && data["color_scheme"] === c.value
                                ? `${c.className} border-color-activate one-px-outline btn btn-block btn-color-scheme`
                                : `${c.className} btn btn-block btn-color-scheme`
                            }
                            name="color_scheme"
                            value={c.value}
                            onClick={e => this.handleChange(e)}
                          >
                            {c.name}
                          </Button>
                        </Row>
                      </div>
                    )
                  );
                })}
              </div>
              <div className="dis-flex">
                {colorScheme.map((c, i) => {
                  return (
                    i > 2 &&
                    i < 6 && (
                      <div
                        className={`col-md-4 col-xs-0 col-sm-0`}
                        style={{ cursor: "pointer" }}
                        key={i}
                      >
                        <Row>
                          <Button
                            color={c.value}
                            className={
                              data && data["color_scheme"] === c.value
                                ? `${c.className} border-color-activate one-px-outline btn btn-block btn-color-scheme`
                                : `${c.className} btn btn-block btn-color-scheme`
                            }
                            name="color_scheme"
                            value={c.value}
                            onClick={e => this.handleChange(e)}
                          >
                            {c.name}
                          </Button>
                        </Row>
                      </div>
                    )
                  );
                })}
              </div>
              <div className="dis-flex">
                {colorScheme.map((c, i) => {
                  return (
                    i > 5 &&
                    i < 9 && (
                      <div
                        className={`col-md-4 col-xs-0 col-sm-0`}
                        style={{ cursor: "pointer" }}
                        name="color-scheme"
                        value={c.value}
                        onClick={e => this.handleChange(e)}
                        key={i}
                      >
                        <Row>
                          <Button
                            color={c.value}
                            className={
                              data && data["color_scheme"] === c.value
                                ? `${c.className} border-color-activate one-px-outline btn btn-block btn-color-scheme`
                                : `${c.className} btn btn-block btn-color-scheme`
                            }
                            name="color_scheme"
                            value={c.value}
                            onClick={e => this.handleChange(e)}
                          >
                            {c.name}
                          </Button>
                        </Row>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </Col>
          <Col md={5}>
            <p className="tutorial-heading-border">Chapter Font</p>
            <div className="typography mt-2">
              <Form>
                <FormGroup className="tutorial-selection-border">
                  <label style={{ display: "none" }} htmlFor={"fontStyle"}>
                    Select Font Style
                  </label>
                  <select
                    className="form-control"
                    name="font_style"
                    id={"fontStyle"}
                    onChange={this.handleChange}
                    value={data && data.font_style}
                    aria-label={"Select Font Style for Tutorial"}
                  >
                    <option>Roboto, sans-serif</option>
                    <option>Lato, sans-serif</option>
                    <option>Montserrat, sans-serif</option>
                    <option>Playfair Display, serif</option>
                    <option>Indie Flower, cursive</option>
                    <option>Comfortaa, cursive</option>
                  </select>
                </FormGroup>
                <div className="dis-flex">
                  <p className="p-heading typography-heading-20 table-header-bold">
                    Heading
                  </p>
                  <p className="p-example typography-heading-80 table-header-bold">
                    Example
                  </p>
                </div>
                <div className="headings">
                  <div
                    className={
                      data && data.headline_tag === "h1"
                        ? `dis-flex border-headings border-heading-activate`
                        : `dis-flex border-headings`
                    }
                  >
                    <div className="typography-heading-25 code-red mr-2">{`<h1></h1>`}</div>
                    <div className="typography-heading-75">
                      <h1
                        name="headline_tag"
                        value="h1"
                        style={{ cursor: "pointer" }}
                        onClick={e => this.handleChange(e)}
                      >
                        h1. Bootstrap heading
                      </h1>
                    </div>
                  </div>
                  <div
                    className={
                      data && data.headline_tag === "h2"
                        ? `dis-flex border-headings border-heading-activate`
                        : `dis-flex border-headings`
                    }
                  >
                    <div className="typography-heading-25 code-red mr-2">{`<h2></h2>`}</div>
                    <div className="typography-heading-75">
                      <h2
                        name="headline_tag"
                        value="h2"
                        className="h2-heading"
                        onClick={e => this.handleChange(e)}
                      >
                        h2. Bootstrap heading
                      </h2>
                    </div>
                  </div>
                  <div
                    className={
                      data && data.headline_tag === "h3"
                        ? `dis-flex border-headings border-heading-activate`
                        : `dis-flex border-headings`
                    }
                  >
                    <div className="typography-heading-25 code-red mr-2">{`<h3></h3>`}</div>
                    <div className="typography-heading-75">
                      <h3
                        name="headline_tag"
                        value="h3"
                        style={{ cursor: "pointer" }}
                        onClick={e => this.handleChange(e)}
                      >
                        h3. Bootstrap heading
                      </h3>
                    </div>
                  </div>
                  <div
                    className={
                      data && data.headline_tag === "h4"
                        ? `dis-flex border-headings border-heading-activate`
                        : `dis-flex border-headings`
                    }
                  >
                    <div className="typography-heading-25 code-red mr-2">{`<h4></h4>`}</div>
                    <div className="typography-heading-75">
                      <h4
                        name="headline_tag"
                        value="h4"
                        style={{ cursor: "pointer" }}
                        onClick={e => this.handleChange(e)}
                      >
                        h4. Bootstrap heading
                      </h4>
                    </div>
                  </div>
                  <div
                    className={
                      data && data.headline_tag === "h5"
                        ? `dis-flex border-headings border-heading-activate`
                        : `dis-flex border-headings`
                    }
                  >
                    <div className="typography-heading-25 code-red mr-2">{`<h5></h5>`}</div>
                    <div className="typography-heading-75">
                      <h5
                        name="headline_tag"
                        value="h5"
                        style={{ cursor: "pointer" }}
                        onClick={e => this.handleChange(e)}
                      >
                        h5. Bootstrap heading
                      </h5>
                    </div>
                  </div>
                  <div
                    className={
                      data && data.headline_tag === "h6"
                        ? `dis-flex border-headings border-heading-activate`
                        : `dis-flex border-headings`
                    }
                  >
                    <div className="typography-heading-25 code-red mr-2">{`<h6></h6>`}</div>
                    <div className="typography-heading-75">
                      <h6
                        name="headline_tag"
                        value="h6"
                        style={{ cursor: "pointer" }}
                        onClick={e => this.handleChange(e)}
                      >
                        h6. Bootstrap heading
                      </h6>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MainTutorial;
