// import Inline from 'quill/blots/inline';
import { Quill } from 'react-quill';

const Inline = Quill.import('blots/inline')

class IframeLink extends Inline {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', this.sanitize(value));
    node.setAttribute('rel', 'noopener noreferrer');
    node.setAttribute('target', 'mainFrame');
    node.setAttribute('class', 'iframeLink')
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('href');
  }

  static sanitize(url) {
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) {
      super.format(name, value);
    } else {
      this.domNode.setAttribute('href', this.constructor.sanitize(value));
    }
  }
}
IframeLink.blotName = 'iframe-link';
IframeLink.tagName = 'A';
IframeLink.SANITIZED_URL = 'about:blank';
IframeLink.PROTOCOL_WHITELIST = ['http', 'https'];

function sanitize(url, protocols) {
  const anchor = document.createElement('a');
  anchor.href = url;
  const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return protocols.indexOf(protocol) > -1;
}

export { IframeLink as default, sanitize };