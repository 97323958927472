import React from "react";
import { Button } from "reactstrap";
import "./LegalPage.scss";
import { history } from "../../_helpers/history";
import { getURLParameters } from "../../_helpers";

class LegalPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionError: "",
      subscriptionDetail: {}
    };
    this.handleIAgree = this.handleIAgree.bind(this);
  }

  componentDidMount() {
    let { subscriptionError } = this.state;
    let subscription = getURLParameters(window.location.href);
    subscriptionError =
      subscription.status === "error" ? subscription.error : "";

    this.setState({
      subscriptionError,
      subscriptionDetail: subscription
    });
  }

  handleIAgree() {
    const { subscriptionDetail } = this.state;
    history.push(
      `/register?status=${subscriptionDetail.status}&email=${subscriptionDetail.email}`
    );
  }

  render() {
    return (
      <div className={"animated fadeIn margin-minus-left"}>
        <div className={"row top-space"}>
          <div className={"col-md-3"}></div>
          <div className={"col-md-5 info-from"}>
            <p>
              This Acceptable Use Policy (“Policy”) outlines unacceptable use of
              Sidecar Learning Software-as-a-Service (SaaS), which interact
              with, or access, the Internet (the “Services”).This Policy is in
              addition to any other terms and conditions under which Sidecar
              Learning provides the Services to you
            </p>
            <p>
              Sidecar Learning may make reasonable modifications to this Policy
              from time to time by posting a new version of this document on the
              Sidecar Learning website at the current URL. Revisions are
              effective immediately upon posting. Accordingly, we recommend that
              you visit the Sidecar Learning website regularly to ensure that
              your activities conform to the most recent version.
            </p>
            <p>
              Questions about this Policy (e.g., whether any contemplated use is
              permitted) and reports of violations of this Policy should be
              directed to <a href="#">info@sidecarlearning.com</a>
            </p>
            <p>
              The examples listed in this Policy are not exhaustive. Prohibited
              uses and activities include, without limitation, any use of the
              Services in a manner that, in Sidecar Learning’s reasonable
              judgment, involves, facilitates, or attempts any of the following:
            </p>
            <ul>
              <li>
                violating any law of, or committing conduct that is tortuous or
                unlawful in, any applicable jurisdiction;
              </li>
              <li>
                displaying, performing, sending, receiving or storing any
                content that is obscene, pornographic, lewd, lascivious, or
                excessively violent, regardless of whether the material or its
                dissemination is unlawful;
              </li>
              <li>
                advocating or encouraging violence against any government,
                organization, group, individual or property, or providing
                instruction, information, or assistance in causing or carrying
                out such violence, regardless of whether such activity is
                unlawful;
              </li>
              <li>
                accessing, sending, receiving, displaying, performing,
                disclosing, storing, or executing any content a) in violation of
                any copyright, right of publicity, patent, trademark, service
                mark, trade name, trade secret or other intellectual property
                right, b) in violation of any applicable agreement, or c)
                without authorization;
              </li>
              <li>
                deleting or altering author attributions, copyright notices, or
                trademark notices, unless expressly permitted in writing by the
                owner;
              </li>
              <li>
                obtaining unauthorized access to any system, network, service,
                or account;
              </li>
              <li>
                interfering with service to any user, site, account, system, or
                network by use of any program, script, command, or otherwise;
              </li>
              <li>
                introducing or activating any viruses, worms, harmful code
                and/or Trojan horses;
              </li>
              <li>
                sending or posting unsolicited messages or e-mail, whether
                commercial or not, a) to any recipients who have requested that
                messages not be sent to them, or b) to a large number of
                recipients, including users, newsgroups, or bulletin boards, at
                one time;
              </li>
              <li>
                evading spam filters, or sending or posting a message or e-mail
                with deceptive, absent, or forged header or sender
                identification information;
              </li>
              <li>
                reselling Sidecar Learning’s services, in whole or in part, to
                any entity or individual, without Sidecar Learning’s prior
                written consent, or misrepresenting your relationship with
                Sidecar Learning.
              </li>
            </ul>
            <Button
              color="primary"
              onClick={this.handleIAgree}
              className={"float-right"}
            >
              I Agree
            </Button>
          </div>
          <div className={"col-md-4"}></div>
        </div>
      </div>
    );
  }
}

export default LegalPage;
