import { AttempTutorialConstants } from "../../_constants";
import { AttempQuizConstants } from "../../_constants";
import { attemptService, attemptQuizService } from "../../_services";
import { toastConstants } from "../../_constants/toast.constants";

export const attemptAPIAction = {
  attemptAPI,
  finishAttempt,
  attemptQuiz,
  finishQuizAttempt,
  updateTutorialAttemptGuestDetails
};
function attemptAPI(id) {
  return dispatch => {
    dispatch({ type: AttempTutorialConstants.ATTEMPT_TUTORIAL });
    attemptService.AttemptTutorial(id).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function success(data) {
    return { type: AttempTutorialConstants.ATTEMPT_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: AttempTutorialConstants.ATTEMPT_TUTORIAL_FAILURE, error };
  }
}
function finishAttempt(tutorialId, attemptId, type_of_formative_quiz) {
  return dispatch => {
    dispatch({ type: AttempTutorialConstants.FINISH_TUTORIAL });
    return attemptService
      .FinishAttemptedTutorial(tutorialId, attemptId, type_of_formative_quiz)
      .then(
        data => {
          return dispatch(success(data));
        },
        error => {
          return dispatch(failure(error.toString()));
        }
      );
  };
  function success(data) {
    return { type: AttempTutorialConstants.FINISH_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: AttempTutorialConstants.FINISH_TUTORIAL_FAILURE, error };
  }
}

function updateTutorialAttemptGuestDetails(tutorialId, attemptId, guestData) {
  return dispatch => {
    dispatch({
      type: AttempTutorialConstants.UPDATE_TUTORIAL_ATTEMPT_GUEST_DETAILS
    });
    return attemptService
      .UpdateTutorialAttemptGuestDetails(tutorialId, attemptId, guestData)
      .then(
        data => {
          return dispatch(success(data));
        },
        error => {
          return dispatch(failure(error.toString()));
        }
      );
  };
  function success(data) {
    return {
      type: toastConstants.SHOW_TOAST,
      message: {
        type: "SUCCESS",
        data: "Your certificate has been emailed to you."
      }
    };
  }
  function failure(error) {
    return {
      type: toastConstants.SHOW_TOAST,
      message: { type: "ERROR", data: error.toString() }
    };
  }
}
function attemptQuiz(id) {
  return dispatch => {
    dispatch({ type: AttempQuizConstants.ATTEMPT_QUIZ });
    attemptQuizService.AttemptQuiz(id).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function success(data) {
    return { type: AttempQuizConstants.ATTEMPT_QUIZ_SUCCESS, data };
  }
  function failure(error) {
    return { type: AttempQuizConstants.FINISH_QUIZ_FAILURE, error };
  }
}
function finishQuizAttempt(quizId, attemptId) {
  return dispatch => {
    dispatch({ type: AttempQuizConstants.FINISH_QUIZ });
    return attemptQuizService.FinishAttemptedQuiz(quizId, attemptId).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Submitted Successfully!" }
        });
        dispatch(success(data));
        return data;
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong !" }
        });
        dispatch(failure(error.toString()));
        return error;
      }
    );
  };
  function success(data) {
    return { type: AttempQuizConstants.FINISH_QUIZ_SUCCESS, data };
  }
  function failure(error) {
    return { type: AttempQuizConstants.FINISH_QUIZ_SUCCESS, error };
  }
}
