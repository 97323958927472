import { createConsumer } from "@rails/actioncable";
import { config } from "../config";

function toQueryString(paramsObject) {
  return Object.keys(paramsObject)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`
    )
    .join("&");
}

const createAuthQuery= () => {
  const user = localStorage.getItem("headerJson");
  let queryString;

  if (user) {
    const userHeader = JSON.parse(user);
    queryString = toQueryString(userHeader);
  }

  return queryString
}

export const connectionUrl = () => {
  const queryString = createAuthQuery()

  return `${config.channelUrl}?${queryString}`;
};

export default createConsumer(connectionUrl);
