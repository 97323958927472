import { tutorialConstants } from "../../_constants/index";
import { quizSetAnswersConstants } from "../../_constants/Learner/quizSetAnswers.constants";
const initial = {
  tutorialData: {},
  summativeQuizData: [],
  privatePath: "",
  isLoading: false,
};
export function tutorial(state = initial, action) {
  switch (action.type) {
    case tutorialConstants.STORE_CHANNEL_IN_REDUX:
      return { ...state, channel: action.channel };

    case tutorialConstants.GETALL_TUTORIAL_REQUEST:
      return { ...state, isLoading: true };

    case tutorialConstants.GETALL_TUTORIAL_SUCCESS:
      let tutorialArray = action.data.data;
      return { ...state, tutorialData: tutorialArray, isLoading: false };

    case tutorialConstants.GETALL_TUTORIAL_FAILURE:
      return { ...state, tutorialData: {}, isLoading: false };

    case tutorialConstants.CLEAR_TUTORIAL:
      return { ...state, tutorialData: {} };

    case quizSetAnswersConstants.SETALL_QUIZ_SELECTION:
      const { tutorialData } = state;
      tutorialData.content &&
        tutorialData.content.tutorial.forEach((chap) => {
          chap.elements.forEach((ele) => {
            if (ele.type === "quiz") {
              ele.quiz_data.questions.forEach((ques) => {
                action.payload.forEach((selQue) => {
                  let selId = selQue.qId;
                  let selVal = selQue.selectedValues;
                  if (ques.id === selId) ques["selectedValue"] = selVal;
                });
              });
            }
          });
        });
      return { ...state, tutorialData: _.cloneDeep(tutorialData) };

    case quizSetAnswersConstants.SET_SUMMATIVE_QUIZ_SELECTION:
      let {
        summativeQuizData,
        tutorialData: {
          summative_test: { summative_test = {} },
        },
      } = state;
      summativeQuizData = summative_test;
      summativeQuizData &&
        summativeQuizData.forEach((chap) => {
          chap.elements.forEach((ele) => {
            if (ele.type === "quiz") {
              ele.quiz_data.questions.forEach((ques) => {
                action.payload.forEach((selQue) => {
                  let selId = selQue.qId;
                  let selVal = selQue.selectedValues;
                  if (ques.id === selId) ques["selectedValue"] = selVal;
                });
              });
            }
          });
        });
      return { ...state, summativeQuizData: _.cloneDeep(summativeQuizData) };

    case "STORE_PRIVATE_PATH":
      return { ...state, privatePath: action.data };
    default:
      return state;
  }
}
