export const credentialsConstants = {
  GETALL_CREDENTIALS_SUCCESS: "GETALL_CREDENTIALS_SUCCESS",
  GETALL_CREDENTIALS_FAILURE: "GETALL_CREDENTIALS_FAILURE",
  GETALL_CREDENTIALS_REQUEST: "GETALL_CREDENTIALS_REQUEST",

  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE"
};
