import { courseConstants, toastConstants } from "../../_constants/index";
import { courseService } from "../../_services/index";

export const courseActions = {
  getTutorialData,
  getCourseData,
  addCoursesData,
  assignedTutorialsToCoursesData,
  editCoursesData,
  deleteCoursesData,
  copyCoursesData,
  deleteCoursesTutorialData
};
function getTutorialData() {
  return dispatch => {
    dispatch({ type: courseConstants.TUTORIALS_REQUEST });
    courseService.getTutorials().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: courseConstants.TUTORIALS_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.TUTORIALS_FAILURE, error };
  }
}
function getCourseData() {
  return dispatch => {
    dispatch({ type: courseConstants.COURSE_TUTORIAL_REQUEST });
    courseService.getCourses().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: courseConstants.COURSE_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.COURSE_TUTORIAL_FAILURE, error };
  }
}
function addCoursesData(courses) {
  return dispatch => {
    return courseService.addCourses(courses).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Successfully saved." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: courseConstants.ADD_COURSE_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.ADD_COURSE_FAILURE, error };
  }
}
function assignedTutorialsToCoursesData(obj) {
  return dispatch => {
    if (!obj.tutorial_ids.length || !obj.course_ids.length)
      dispatch({
        type: toastConstants.SHOW_TOAST,
        message: { type: "ERROR", data: "Something went wrong" }
      });
    else {
      return courseService.assignedTutorialsToCourses(obj).then(
        data => {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "SUCCESS", data: "Successfully saved." }
          });
          return dispatch(success(obj));
        },
        error => {
          dispatch({
            type: toastConstants.SHOW_TOAST,
            message: { type: "ERROR", data: "Something went wrong." }
          });
          return dispatch(failure(error.response.data));
        }
      );
    }
  };

  function success(data) {
    return { type: courseConstants.ASSIGNED_TUTORIALS_COURSES_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.ASSIGNED_TUTORIALS_COURSES_FAILURE, error };
  }
}
function editCoursesData(courses, id) {
  return dispatch => {
    return courseService.editCourses(courses, id).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Course updated successfully." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: courseConstants.EDIT_COURSE_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.EDIT_COURSE_FAILURE, error };
  }
}
function deleteCoursesData(id) {
  return dispatch => {
    return courseService.deleteCourse(id).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Course deleted Successfully" }
        });
        return dispatch(success({ id }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: courseConstants.DELETE_COURSE_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.DELETE_COURSE_FAILURE, error };
  }
}
function copyCoursesData(id, obj) {
  return dispatch => {
    return courseService.copyCourse(id, obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Course successfully copied." }
        });
        return dispatch(success(data));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: {
            type: "ERROR",
            data: "Course " + error.response.data.title.toString()
          }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };
  function success(data) {
    return { type: courseConstants.COPY_COURSE_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.COPY_COURSE_FAILURE, error };
  }
}
function deleteCoursesTutorialData(tid, obj) {
  return dispatch => {
    return courseService.deleteCourseTutorial(tid, obj).then(
      data => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "SUCCESS", data: "Tutorial deleted successfully" }
        });
        return dispatch(success({ tid, obj }));
      },
      error => {
        dispatch({
          type: toastConstants.SHOW_TOAST,
          message: { type: "ERROR", data: "Something went wrong." }
        });
        return dispatch(failure(error.response.data));
      }
    );
  };

  function success(data) {
    return { type: courseConstants.DELETE_COURSE_TUTORIAL_SUCCESS, data };
  }
  function failure(error) {
    return { type: courseConstants.DELETE_COURSE_TUTORIAL_FAILURE, error };
  }
}
