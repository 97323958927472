import React, { Component } from "react";
import { connect } from "react-redux";
import AdminDashboardSidebar from "../../components/AdminDashboard/adminDashboardSidebar";
import ManageDashboard from "../../components/AdminDashboard/manageDashboard";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { adminDashboardActions, userActions } from "../../_actions";
import "./AdminDashboard.scss";

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: { test: true },
      defaultUser: [],
      first_popUp: null
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getCCDashboard = this.getCCDashboard.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.getSwitchData = this.getSwitchData.bind(this);
  }

  componentDidMount() {
    this.getSwitchData();
  }

  getSwitchData() {
    const userLogin = localStorage.getItem("user");
    const userRole = userLogin && JSON.parse(userLogin).main_role;
    const userId = userLogin && JSON.parse(userLogin).id;
    const currentRole = localStorage.getItem("defaultRole");
    const { dispatch } = this.props;
    if (userRole === currentRole) {
      if (userRole === "org_administrator" || userRole === "licensee") {
        dispatch(adminDashboardActions.getCCUsersData()).then((res, err) => {
          if (res.data.data.length) {
            let ccUsers = Object.values(
              res.data.data.find(
                c =>
                  c["full_content_creator"] ||
                  c["basic_content_creator"] ||
                  c["individual_content_creator"]
              )
            )[0];
            if (ccUsers.length > 0) {
              const index = ccUsers.findIndex(i => i.id === userId); //take index for set  by default dropdown value to user[before take 0]
              if (index !== -1) {
                this.setState({
                  defaultUser: [
                    {
                      value: ccUsers[index].id,
                      label:
                        ccUsers[index].first_name +
                        " " +
                        ccUsers[index].last_name
                    }
                  ]
                });
                this.getCCDashboard(ccUsers[index].id);
              } else {
                this.setState({
                  defaultUser: [
                    {
                      value: ccUsers[0].id,
                      label: ccUsers[0].first_name + " " + ccUsers[0].last_name
                    }
                  ]
                });
                this.getCCDashboard(ccUsers[0].id);
              }
            } else {
              this.getCCDashboard();
            }
          }
        });
      } else {
        dispatch(adminDashboardActions.getDashboardData());
      }
    } else {
      if (currentRole === "org_administrator" || currentRole === "licensee") {
        dispatch(adminDashboardActions.getCCUsersData()).then((res, err) => {
          if (res.data.data.length) {
            let ccUsers = Object.values(
              res.data.data.find(
                c =>
                  c["full_content_creator"] ||
                  c["basic_content_creator"] ||
                  c["individual_content_creator"]
              )
            )[0];
            if (ccUsers.length > 0) {
              const index = ccUsers.findIndex(i => i.id === userId);
              this.setState({
                defaultUser: [
                  {
                    value: ccUsers[index].id,
                    label:
                      ccUsers[index].first_name + " " + ccUsers[index].last_name
                  }
                ]
              });
              this.getCCDashboard(ccUsers[index].id);
            } else {
              this.getCCDashboard();
            }
          }
        });
      } else {
        dispatch(adminDashboardActions.getDashboardData());
      }
    }
  }

  toggleMenu(e) {
    this.setState({
      collapse: { ...this.state.collapse, [e]: !this.state.collapse[e] }
    });
  }

  toggleModal() {
    localStorage.setItem("first-time", "0");
    this.setState({
      first_popUp: localStorage.getItem("first-time")
    });
  }

  getCCDashboard(id, user) {
    const { dispatch } = this.props;
    if (user) {
      this.setState({
        defaultUser: [
          {
            value: user && user.value,
            label: user && user.label
          }
        ]
      });
    }
    if (id !== null && id !== undefined)
      dispatch(adminDashboardActions.getCCDashboardData(id)).then(
        (res, err) => {
          if (res) {
            this.setState({
              defaultUser: [
                {
                  value: user.value,
                  label: user.label
                }
              ]
            });
          } else {
            this.getCCDashboard();
            this.setState({
              defaultUser: []
            });
          }
        }
      );
    else {
      dispatch(adminDashboardActions.getCCDashboardData());
      this.setState({
        defaultUser: []
      });
    }
  }

  render() {
    const {
      dashboardData = [],
      isLoadingDashboard = false,
      CCUsersData = [],
      isLoadingCCUsers = false,
      tutorialCompletedGraph = {},
      quizessCompletedGraph = {},
      loggingInData: { user },
      defaultRole
    } = this.props;
    const { collapse, defaultUser, first_popUp } = this.state;
    const popUp = localStorage.getItem("first-time");
    return (
      <div className="animated fadeIn w-100">
        <Row className="custom-row bg-white p-4" id="tut-analytics">
          <Col xs={24} md={12} xl={12} lg={12}>
            <Row className="tut-analytic-removable-row">
              <ManageDashboard
                dashboardData={dashboardData}
                isLoadingDashboard={isLoadingDashboard}
                CCUsersData={CCUsersData}
                isLoadingCCUsers={isLoadingCCUsers}
                getCCDashboard={this.getCCDashboard}
                tutorialCompletedGraph={tutorialCompletedGraph}
                quizessCompletedGraph={quizessCompletedGraph}
                defaultUser={defaultUser}
                getSwitchData={this.getSwitchData}
                defaultRole={defaultRole}
              />
            </Row>
          </Col>
        </Row>
        <Modal
          isOpen={user && user.first_time && popUp === null}
          toggle={this.toggleModal}
          className={"modal-primary modal-lg"}
        >
          <ModalHeader toggle={this.toggleModal}>
            To create a tutorial, you must change to instructor mode.
          </ModalHeader>
          <ModalBody>
            <div className="modal-wrap">
              <img
                src={require("../../../public/img/pop-up-dash.gif")}
                alt={"gear"}
              />
              <p className={"pt-3"}>
                <b>
                  To change to instructor mode hover over the cog/gear icon and
                  then select Instructor from the drop-down options.
                </b>
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color={"primary"} onClick={this.toggleModal}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tutorialsData: state.adminDashboard.tutorialsData,
    isLoadingTutorialsDashboard:
      state.adminDashboard.isLoadingTutorialsDashboard,
    dashboardData: state.adminDashboard.dashboardData,
    isLoadingDashboard: state.adminDashboard.isLoadingDashboard,
    CCUsersData: state.adminDashboard.CCUsersData,
    isLoadingCCUsers: state.adminDashboard.isLoadingCCUsers,
    tutorialCompletedGraph: state.adminDashboard.tutorialCompletedGraph,
    quizessCompletedGraph: state.adminDashboard.quizessCompletedGraph,
    loggingInData: state.authentication,
    defaultRole: state.users.DefaultRole
  };
}

const connectedAdminDashboard = connect(mapStateToProps, null)(AdminDashboard);
export { connectedAdminDashboard as AdminDashboard };
