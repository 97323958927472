export default {
  roles: [
    {
      name: "full_content_creator",
      id: 1
    },
    {
      name: "learner",
      id: 2
    },
    {
      name: "org_administrator",
      id: 3
    },
    {
      name: "basic_content_creator",
      id: 4
    },
    {
      name: "individual_content_creator",
      id: 5
    },
    {
      name: "licensee",
      id: 6
    }
  ]
};
