export const adminDashboardConstants = {
  GET_TUTORIALS_SUCCESS: "GET_TUTORIALS_SUCCESS",
  GET_TUTORIALS_FAILURE: "GET_TUTORIALS_FAILURE",
  GET_TUTORIALS_REQUEST: "GET_TUTORIALS_REQUEST",

  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_DATA_FAILURE: "GET_DASHBOARD_DATA_FAILURE",
  GET_DASHBOARD_DATA_REQUEST: "GET_DASHBOARD_DATA_REQUEST",

  GET_CC_USERS_SUCCESS: "GET_CC_USERS_SUCCESS",
  GET_CC_USERS_FAILURE: "GET_CC_USERS_FAILURE",
  GET_CC_USERS_REQUEST: "GET_CC_USERS_REQUEST",

  GET_CC_DASHBOARD_SUCCESS: "GET_CC_DASHBOARD_SUCCESS",
  GET_CC_DASHBOARD_FAILURE: "GET_CC_DASHBOARD_FAILURE",
  GET_CC_DASHBOARD_REQUEST: "GET_CC_DASHBOARD_REQUEST"
};
