import React, { Component } from "react";
import NoUserImage from "../../../public/img/avatars/no-user.png";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
} from "../../_helpers/Validation";
import "./manageCredentials.scss";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Card,
  CardHeader,
  CardText,
  CardBody,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
  Tooltip,
} from "reactstrap";
import Spinner from "../../components/spinner";
import BackButton from "../../_components/BackButton";

class ManageCredentials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData,
      errors: {},
      isOpen: props.isOpen,
      activeRole: "",
      selectedUser: props.formData.id,
      isDeleteUser: false,
      selectedRole: "",
      names: props.names,
      editError: props.editError,
      openToolTip: { test: false },
    };
    this.handleChange = this.handleChange.bind(this);
    this.openForm = this.openForm.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.update = this.update.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(e) {
    this.setState({ openToolTip: { [e]: !this.state.openToolTip[e] } });
  }
  handleChange(e) {
    const { formData, errors } = this.state;
    const { name, value } = e.target;
    if (name === "avatar") {
      if (e.target.files && e.target.files[0]) {
        var FR = new FileReader();
        FR.addEventListener("load", function(e) {
          formData[name] = e.target.result;
        });
        FR.readAsDataURL(e.target.files[0]);
      }
    } else {
      errors[name] = "";
      formData[name] = value;
    }
    this.setState({
      formData,
    });
  }
  openForm(formData) {
    this.setState({
      editError: {},
      isOpen: true,
      formData: { ...formData },
      errors: {},
      selectedUser: formData.id,
      names: { first_name: formData.first_name, last_name: formData.last_name },
    });
  }
  update() {
    const { formData, errors } = this.state;
    if (formData.first_name)
      errors.first_name = validateFirstName(formData.first_name);

    if (formData.last_name)
      errors.last_name = validateLastName(formData.last_name);

    if (formData.email) errors.email = validateEmail(formData.email.trim());

    // if (formData.username)
    //     errors.username = validateUsername(formData.username);

    this.setState({
      errors,
    });
    if (
      errors.first_name.length === 0 &&
      errors.last_name.length === 0 &&
      errors.email.length === 0
    ) {
      this.props.updateUser(
        formData,
        this.props.activeRole,
        formData,
        this.props.selectedCredentials
      );
    }
  }
  save() {
    this.setState({
      isOpen: false,
      selectedUser: "",
      activeRole: this.props.credentialsData[0],
    });
  }
  cancel() {
    this.setState({
      isOpen: false,
      selectedUser: "",
      activeRole: this.props.credentialsData[0],
    });
  }
  confirmDelete() {
    const { deleteUserId, selectedRole } = this.state;
    this.props.deleteUser(deleteUserId, selectedRole);
  }
  cancelDelete() {
    this.setState({
      isDeleteUser: false,
    });
  }
  deleteConfirmation(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDeleteUser: true,
      deleteUserId: id,
      selectedRole: Object.keys(this.props.selectedCredentials).toString(),
    });
  }
  handleValidation(e, i) {
    let { errors } = this.state;
    const { name, value } = e.target;

    if (name === "first_name") errors.first_name = validateFirstName(value);

    if (name === "last_name") errors.last_name = validateLastName(value);

    if (name === "email") errors.email = validateEmail(value.trim());

    // if (name === 'username')
    //     errors.username = validateUsername(value);

    this.setState({
      errors,
    });
  }
  render() {
    const { selectedCredentials, credentialsData } = this.props;
    const {
      isOpen,
      formData,
      selectedUser,
      isDeleteUser,
      names,
      errors,
      editError,
      openToolTip,
    } = this.state;
    return (
      <div className="animated fadeIn">
        <Row>
          <div className={"animated fadeIn col-lg-5 col-md-7"}>
            <Card>
              <div className="left-cred">
                <CardBody>
                  <CardText>
                    <Form className="custom-main-form user-scroll">
                      {Object.values(selectedCredentials)[0] &&
                        Object.values(selectedCredentials)[0].map((u, i) => {
                          return (
                            <div className="animated fadeIn">
                              <FormGroup
                                check
                                inline
                                className="custom-form w-100"
                              >
                                <div
                                  check
                                  className="checkbox-avt-username"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.openForm(u)}
                                >
                                  <div className="checkbox-img">
                                    {u.avatar.url ? (
                                      <img
                                        src={u.avatar.url}
                                        className="checkbox-user-avt"
                                        alt="Avatar"
                                        onError={(e) => {
                                          e.target.src = NoUserImage;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={NoUserImage}
                                        className="checkbox-user-avt"
                                        alt="Avatar"
                                      />
                                    )}
                                  </div>
                                  <p
                                    id={`u-${u.id}`}
                                    className={
                                      selectedUser === u.id
                                        ? "check-username active-user"
                                        : "check-username"
                                    }
                                  >
                                    {u.first_name}&nbsp;{u.last_name}
                                  </p>
                                  <Tooltip
                                    placement="top"
                                    isOpen={openToolTip[`u-${u.id}`]}
                                    target={`u-${u.id}`}
                                    toggle={() =>
                                      this.toggleTooltip(`u-${u.id}`)
                                    }
                                  >
                                    {u.email}
                                  </Tooltip>
                                </div>
                              </FormGroup>
                            </div>
                          );
                        })}
                    </Form>
                  </CardText>
                </CardBody>
              </div>
            </Card>
          </div>
          {isOpen && (
            <div className="animated fadeIn col-lg-7 col-md-5  ">
              <Card className="custome-card manage-custom-card">
                <CardHeader className="header-title">
                  <span className="username-header">
                    {names.first_name}&nbsp;{names.last_name}
                  </span>
                  <span
                    className="delete-icon"
                    onClick={(e) => this.deleteConfirmation(e, formData.id)}
                  >
                    <i className="fa fa-trash" />
                  </span>
                </CardHeader>
                <CardBody>
                  <div className="no-overflow">
                    <div className="row">
                      <Col md={6}>
                        <InputGroup className="custom-inputs">
                          <InputGroupAddon addonType="prepend">
                            <i className="fa fa-user" />
                          </InputGroupAddon>
                          <Input
                            placeholder="First name"
                            name={"first_name"}
                            value={formData.first_name}
                            onChange={this.handleChange}
                            onBlur={this.handleValidation}
                          />
                          {errors.first_name && (
                            <div className="help-block">
                              {errors.first_name}
                            </div>
                          )}
                        </InputGroup>
                      </Col>
                      <Col md={6}>
                        <InputGroup className="custom-inputs">
                          <InputGroupAddon addonType="prepend">
                            <i className="fa fa-user" />
                          </InputGroupAddon>
                          <Input
                            placeholder="Last name"
                            name={"last_name"}
                            value={formData.last_name}
                            onChange={this.handleChange}
                            onBlur={this.handleValidation}
                          />
                          {errors.last_name && (
                            <div className="help-block">{errors.last_name}</div>
                          )}
                        </InputGroup>
                      </Col>
                    </div>
                    <InputGroup className="custom-inputs">
                      <InputGroupAddon addonType="prepend">
                        <i className="fa fa-envelope" />
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        name={"email"}
                        value={formData.email}
                        onChange={this.handleChange}
                        onBlur={this.handleValidation}
                      />
                      {errors.email && (
                        <div className="help-block">{errors.email}</div>
                      )}
                      {editError.email && (
                        <div className="help-block">{editError.email}</div>
                      )}
                    </InputGroup>
                    <InputGroup className="custom-inputs">
                      <InputGroupAddon addonType="prepend">
                        <i className="fa fa-lock" />
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Password"
                        name={"password"}
                        value={formData.password}
                        onChange={this.handleChange}
                        onBlur={this.handleValidation}
                      />
                      {errors.password && (
                        <div className="help-block">{errors.password}</div>
                      )}
                    </InputGroup>
                  </div>
                </CardBody>
              </Card>
              <div className="footer-btn text-right">
                <BackButton />
                <Button
                  className="cancel-btn mr-2"
                  color="secondary"
                  onClick={this.cancel}
                >
                  Reset
                </Button>
                <Button
                  className="cancel-btn"
                  color="success"
                  onClick={this.update}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Row>
        <Modal isOpen={isDeleteUser} className="modal-danger">
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <h4>Are you sure you want to delete this ? </h4>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.confirmDelete}>
              Yes
            </Button>
            <Button color="secondary" onClick={this.cancelDelete}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ManageCredentials;
