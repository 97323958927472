import React, { Component } from "react";
import "./importTutorials.scss";
import {
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import { Checkbox } from "../../_components/InputComponents";
import { history } from "../../_helpers/history";
import BackButton from "../../_components/BackButton";
import NoUserImage from "../../../public/img/avatars/no-user.png";
import { importTutorialsActions } from "../../_actions/index";
import _ from "lodash";

class ImportTutorials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseItems: [],
      usersItems: [],
      tutorialsItems: [],
      fileName: "",
      csvError: "",
      errors: [],
      isOpen: false,
      str: ""
    };
    this.handleCSV = this.handleCSV.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.saveImportTutorial = this.saveImportTutorial.bind(this);
    this.openDialogue = this.openDialogue.bind(this);
    this.selectAllCourses = this.selectAllCourses.bind(this);
    this.selectAllUsers = this.selectAllUsers.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleCSV(e) {
    let filename = e.target.files[0].name;
    let ext = filename.split(".");
    let t1;
    if (ext[ext.length - 1].toLowerCase() === "json") {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = e => {
        t1 = e.target.result;
        this.setState({
          fileName: filename,
          csvError: "",
          str: t1
        });
      };
      reader.readAsText(e.target.files[0]);
    } else {
      this.setState({
        csvError: "Please choose JSON file only"
      });
    }
    e.target.value = "";
  }

  saveCourse(cid) {
    const { courseItems } = this.state;
    let bool = _.includes(courseItems, cid);
    if (bool) {
      var index = courseItems.indexOf(cid);
      index !== -1 && courseItems.splice(index, 1);
    } else courseItems.push(cid);
    this.setState({
      courseItems
    });
  }

  saveUser(uid) {
    const { usersItems } = this.state;
    let bool = _.includes(usersItems, uid);
    if (bool) {
      var index = usersItems.indexOf(uid);
      index !== -1 && usersItems.splice(index, 1);
    } else usersItems.push(uid);
    this.setState({
      usersItems
    });
  }

  saveImportTutorial() {
    const { courseItems, errors, str, usersItems } = this.state;
    const { dispatch, DefaultRole } = this.props;
    if (str) {
      const user = JSON.parse(localStorage.getItem("user"));
      const tutorialPayload = JSON.parse(str);
      tutorialPayload["tutorials"][0]["user_id"] = user && user.id;
      //When Instructor then share Tutorial to Courses
      if (DefaultRole === "full_content_creator") {
        try {
          dispatch(
            importTutorialsActions.importTutorialData(tutorialPayload)
          ).then((res, err) => {
            if (res.data && res.data.data) {
              let req = {
                tutorial_ids: _.map(res.data.data, "id"),
                course_ids: courseItems
              };
              if (courseItems.length > 0) {
                dispatch(
                  importTutorialsActions.shareTutorialToCourses(req)
                ).then((response, err) => {
                  if (response)
                    history.push({
                      pathname: "/tutorial-management",
                      state: res.data.data[0].id
                    });
                });
              } else
                history.push({
                  pathname: "/tutorial-management",
                  state: res.data.data[0].id
                });
            }
            if (res.error) {
              this.setState({
                errors: res.error,
                isOpen: true
              });
            }
          });
        } catch (e) {
          this.setState({
            csvError: "Invalid json format"
          });
        }
      }
      //When Org/Licensee then share Tutorial to Users
      else if (
        DefaultRole === "org_administrator" ||
        DefaultRole === "licensee"
      ) {
        if (usersItems.length > 0) {
          try {
            dispatch(
              importTutorialsActions.importTutorialData(tutorialPayload)
            ).then((res, err) => {
              if (res.data && res.data.data) {
                let req = {
                  learner_ids: usersItems
                };
                let id = _.map(res.data.data, "id")[0];
                dispatch(
                  importTutorialsActions.shareTutorialToUsers(id, req)
                ).then((response, err) => {
                  if (response) history.push("/");
                });
              }
              if (res.error) {
                this.setState({
                  errors: res.error,
                  isOpen: true
                });
              }
            });
          } catch (e) {
            this.setState({
              csvError: "Invalid json format"
            });
          }
        } else {
          dispatch(importTutorialsActions.selectUser());
        }
      }
    } else {
      this.setState({
        csvError: "Please select a json file."
      });
    }
  }

  openDialogue() {
    let div = document.getElementById("file-upload");
    div.click();
  }
  selectAllCourses(e) {
    const { courseData } = this.props;
    let { courseItems } = this.state;
    const { checked } = e.target;
    if (checked) {
      courseItems = [];
      courseData.map((l, i) => {
        courseItems.push(l.id);
      });
      this.setState({
        courseItems
      });
    } else {
      this.setState({
        courseItems: []
      });
    }
  }
  selectAllUsers(e) {
    const { userData } = this.props;
    let { usersItems } = this.state;
    const { checked } = e.target;
    if (checked) {
      usersItems = [];
      userData.map((l, i) => {
        usersItems.push(l.id);
      });
      this.setState({
        usersItems
      });
    } else {
      this.setState({
        usersItems: []
      });
    }
  }
  toggleModal() {
    this.setState({
      isOpen: false,
      errors: []
    });
  }
  render() {
    const { courseData, userData = {}, DefaultRole } = this.props;
    const {
      courseItems,
      usersItems,
      isOpen,
      errors = [],
      csvError,
      fileName
    } = this.state;
    return (
      <div className="custom-row bg-white py-sm-3 px-sm-4">
        <Col md={12} className="tutorial-heading-border mb-3">
          <Row>
            <div className="col-md-6">
              <h4 className="mb-3">{`Imports Tutorials`}</h4>
            </div>
            <div className="col-md-6">
              <div className="float-sm-right">
                <BackButton />
                <Button color={"secondary"} className="cancel-btn mr-2">
                  Reset
                </Button>
                <Button
                  color={"success"}
                  className="cancel-btn"
                  onClick={this.saveImportTutorial}
                >
                  Save
                </Button>
              </div>
            </div>
          </Row>
        </Col>
        <div className="mt-4 col-md-9">
          <Row className="custom-import-border">
            <div className="col-md-6 col-12">
              <p className="custom-selection-border mb-3">
                Select Tutorial(s) Source
              </p>
              <input
                id={"file-upload"}
                type="file"
                className="get-file-from-local"
                onChange={this.handleCSV}
                aria-label={"Select Tutorial Source"}
              />
              <Input
                type="text"
                value={fileName}
                onClick={this.openDialogue}
                aria-label={"Select Tutorial Source"}
              />
              {csvError && <div className="help-block">{csvError}</div>}
            </div>
            {DefaultRole === "full_content_creator" ||
              DefaultRole === "individual_content_creator" ||
              (DefaultRole === "basic_content_creator" && (
                <div className="col-md-6 col-12">
                  <div className="select-courses-div pt-3 pb-1">
                    <h6>Assign Tutorials to Courses (Optional)</h6>
                    <div className="select-all">
                      <FormGroup check>
                        {courseData.length > 0 && (
                          <Checkbox className="dis-flex">
                            <Input
                              type="checkbox"
                              onClick={this.selectAllCourses}
                              checked={courseItems.length === courseData.length}
                            />
                            <p className="mb-0 mt-1 imprt-fnt">Select All</p>
                          </Checkbox>
                        )}
                      </FormGroup>
                    </div>
                    <p className="mb-0 imprt-fnt">
                      Select Tutorial(s) source file and then Select Course(s)
                    </p>
                  </div>
                  <div className="select-course">
                    {courseData.length > 0 &&
                      courseData.map((c, i) => {
                        return (
                          <Checkbox className="dis-flex">
                            <Input
                              type="checkbox"
                              onClick={() => {
                                this.saveCourse(c.id);
                              }}
                              checked={courseItems.includes(c.id)}
                            />
                            <p
                              className={
                                courseItems.includes(c.id)
                                  ? "active-student course-selection-area"
                                  : "course-selection-area"
                              }
                            >
                              {c.title}
                            </p>
                          </Checkbox>
                        );
                      })}
                  </div>
                </div>
              ))}
            {(DefaultRole === "org_administrator" ||
              DefaultRole === "licensee") && (
              <div className="col-md-6 col-12">
                <div className="select-courses-div pt-3 pb-1">
                  <h6>Assign Tutorials to Content Creators</h6>
                  <div className="select-all">
                    <FormGroup check>
                      {userData.length > 0 && (
                        <Checkbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={this.selectAllUsers}
                            checked={usersItems.length === userData.length}
                          />
                          <p className="mb-0 mt-1 imprt-fnt">Select All</p>
                        </Checkbox>
                      )}
                    </FormGroup>
                  </div>
                  <p className="mb-0 imprt-fnt">
                    Select Tutorial(s) source file and then Select Content
                    Creators(s)
                  </p>
                </div>
                <div className="select-course">
                  {userData.length > 0 &&
                    userData.map((c, i) => {
                      return (
                        <Checkbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={() => {
                              this.saveUser(c.id);
                            }}
                            checked={usersItems.includes(c.id)}
                          />
                          <p
                            className={
                              usersItems.includes(c.id)
                                ? "active-student course-selection-area"
                                : "course-selection-area"
                            }
                          >
                            {c.first_name + " " + c.last_name}
                          </p>
                        </Checkbox>
                      );
                    })}
                </div>
              </div>
            )}
          </Row>
        </div>

        <Modal
          isOpen={isOpen}
          toggle={this.toggleModal}
          className="modal-danger"
        >
          <ModalHeader>Tutorials Validations</ModalHeader>
          <ModalBody>
            <div>
              {errors.map((e, i) => {
                return (
                  e.length > 0 && (
                    <div>
                      {`${i + 1}) `}
                      {e.toString()}
                    </div>
                  )
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleModal}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ImportTutorials;
