import React, { Component } from "react";
import FileDownload from "js-file-download";
import { connect } from "react-redux";
import ChatpterSideBar from "../../components/tutorial_management/chapterSidebar";
import { contentCreatorActions } from "../../_actions";
import "./tutorialManagement.scss";
import DisplayTutorial from "../../components/tutorial_management/displayTutorial";
import { Col, Row, Button } from "reactstrap";
import Spinner from "../../components/spinner";

class TutorialManagement extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      activeSubTab: "1",
      showChapter: false,
      showTab: "0",
      index: 0,
      tutorialIndex: 0,
      setLoader: false,

      isSearch: false,
      tutorialData: [],
      searchText: "",
      searchResult: "",
      callTutorialAPI: true,
      isDownload: false
    };
  }

  componentDidMount() {
    let {
      CurriculumTutorial: { tutorialList: { data = {} } = {} } = {},
      location: { state } = {}
    } = this.props;
    if (Object.keys(data).length === 0)
      this.props.dispatch(contentCreatorActions.getTutorialsList());
    else if (state && Object.keys(data).length > 0) {
      this.setState({ callTutorialAPI: !this.state.callTutorialAPI });
      this.setIndex(state);
    } else if (
      Object.keys(data).length > 0 &&
      Object.keys(data.tutorials_created)
    ) {
      this.setState({ callTutorialAPI: !this.state.callTutorialAPI });
      this.props.dispatch(
        contentCreatorActions.getTutorialDataById(data.tutorials_created[0].id)
      );
    }
    this.props.dispatch(contentCreatorActions.getCoursesData());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let {
      CurriculumTutorial: { tutorialList: { data = {} } = {} } = {},
      location: { state } = {}
    } = nextProps;
    let id = state
      ? state.index
      : Object.keys(data).length > 0 &&
        Object.keys(data.tutorials_created).length > 0
        ? data.tutorials_created[0].id
        : "";
    if (id && Object.keys(data).length > 0) {
      if (this.state.callTutorialAPI) {
        this.setState({ callTutorialAPI: !this.state.callTutorialAPI });
        this.setIndex(id);
      }
    }
    if (data.tutorials_created) {
      this.setState({
        tutorialData: data.tutorials_created
      });
    }
  }

  onDeleteTutorial = (id) => {
    this.setIndex(this.state.tutorialData[0].id);
    this.props
      .dispatch(contentCreatorActions.deleteTutorialData(id))
      .then((res, err) => {
        this.setState({ tutorialIndex: 0 });
      });
  }

  setIndex = (index) => {
    this.setState({ setLoader: true });
    this.props
      .dispatch(contentCreatorActions.getTutorialDataById(index))
      .then(() => {
        this.setState({ tutorialIndex: index, setLoader: false });
      });
  }

  onShare = (tutorialId, tutorialData) => {
    this.props
      .dispatch(
        contentCreatorActions.shareTutorialWithLearnersAndCourses(
          tutorialId,
          tutorialData
        )
      )
      .then((res, err) => { });
  }

  onNext = () => {
    this.setState({ index: this.state.index + 1 });
  }

  onPrevious = () => {
    this.setState({ index: this.state.index - 1 });
  }

  onCopyTutorial = (Copydata) => {
    this.props
      .dispatch(contentCreatorActions.copyTutorialData(Copydata))
      .then((res, err) => {
        if (res) this.setIndex(res.data.data.id);
      });
  }

  openSearch = () => {
    this.setState({
      isSearch: true
    });
  }
  search = (e) => {
    const { CurriculumTutorial: { tutorialList = {} } = {} } = this.props;
    const Tutorial = tutorialList.data && tutorialList.data.tutorials_created;
    this.setState(
      {
        searchText: e.target.value
      },
      () => {
        let newSearch = Tutorial.filter(
          t =>
            (t.title &&
              t.title
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (t.description &&
              t.description
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
        if (this.state.searchText === "") {
          this.setState({ tutorialData: Tutorial }, () => {
            this.state.tutorialData.length !== 0 &&
              this.setState({
                searchResult: ""
              });
          });
        } else {
          if (newSearch.length === 0) {
            this.setState({
              searchResult: "No matching records found"
            });
          }
          this.setState({ tutorialData: newSearch });
        }
      }
    );
  }
  clearSearch = () => {
    let {
      CurriculumTutorial: { tutorialList: { data = {} } = {} } = {},
      location: { state } = {}
    } = this.props;

    if (data.tutorials_created) {
      this.setState({
        searchText: "",
        isSearch: false,
        tutorialData: data.tutorials_created,
        startIndex: 0
      });
    }
  }
  downloadTutorialAnswer = (tutorialData) => {
    this.props
      .dispatch(contentCreatorActions.downloadTutorialAnswer(tutorialData.id))
      .then((res, err) => {
        if (res) {
          FileDownload(
            res.data.data,
            `SidecarLearning-Answer for Tutorial-${tutorialData.title
            }-${new Date().getTime()}.csv`
          );
          window.location.reload();
        } else {
          this.setState({ isDownload: true });
        }
      });
  }

  showDownload = () => {
    const { isDownload } = this.state;
    this.setState({ isDownload: !isDownload }, () => {
      window.location.reload();
    });
  }

  render() {
    const {
      CurriculumTutorial: {
        isLoading,
        tutorialList = {},
        tutorialById = {}
      } = {},
      Tutorialcourses,
      user
    } = this.props;
    const { tutorialIndex, setLoader, isDownload } = this.state;
    return isLoading ? (
      <Spinner />
    ) : tutorialList ? (
      <div className="animated fadeIn">
        <Row className="base-row">
          <Col md={3}>
            <div className="left-col custom-col">
              {tutorialList && (
                <ChatpterSideBar
                  courses={Tutorialcourses}
                  tutorialData={
                    this.state.tutorialData && this.state.tutorialData
                  }
                  tutorialIndex={tutorialIndex}
                  setIndex={this.setIndex}
                  openSearch={this.openSearch}
                  search={this.search}
                  clearSearch={this.clearSearch}
                  isSearch={this.state.isSearch}
                  searchText={this.state.searchText}
                  searchResult={this.state.searchResult}
                  user={user}
                />
              )}
            </div>
          </Col>
          <Col md={9}>
            {this.state.tutorialData.length > 0 && (
              <DisplayTutorial
                tutorialData={
                  Object.keys(tutorialById).length > 0 ? tutorialById.data : {}
                }
                index={this.state.index}
                courseData={Tutorialcourses}
                copyTutorial={this.onCopyTutorial}
                deleteTutorial={this.onDeleteTutorial}
                tutorialIndex={tutorialIndex}
                setLoader={setLoader}
                onShare={this.onShare}
                searchResult={this.state.searchResult}
                tutorialList={tutorialList}
                dispatch={this.props.dispatch}
                doNotShow={this.props.doNotShow}
                downloadTutorialAnswer={this.downloadTutorialAnswer}
                isDownload={isDownload}
                showDownload={this.showDownload}
                user={user}
              />
            )}
          </Col>
        </Row>
        <hr className="custom-hr" />
      </div>
    ) : (
      ""
    );
  }
}
function mapStateToProps(state) {
  const {
    users,
    authentication: { user },
    CurriculumTutorial,
    Tutorialcourses
  } = state;
  return {
    user,
    users,
    CurriculumTutorial,
    Tutorialcourses,
    doNotShow: state.learnerDashboard.doNotShow
  };
}

const connectedTutorialManagement = connect(mapStateToProps)(
  TutorialManagement
);
export { connectedTutorialManagement as TutorialManagement };
