import axiosI from "../_helpers/service";
export const messageService = {
  getAllMessages,
  deleteMessageService,
  readMessage,
  readAllMessage
};

function getAllMessages() {
  return axiosI.get(`/messages`).then(response => {
    return response;
  });
}

function deleteMessageService(id) {
  return axiosI.delete(`/messages/${id}`).then(response => {
    return response;
  });
}

function readMessage(id) {
  return axiosI.get(`/messages/${id}`).then(response => {
    return response;
  });
}

function readAllMessage() {
  return axiosI.post(`/messages/mark_all_read`).then(response => {
    return response;
  });
}
