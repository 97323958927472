import { learnerDashboardConstants } from "../../_constants/index";
import { learnerDashboardService } from "../../_services/index";

export const learnerDashboardActions = {
  getAssignedTutorialData,
  getLearnerDashboardData,
  toggleDoNotShow
};

function getAssignedTutorialData() {
  return dispatch => {
    learnerDashboardService.getAssignedTutorials().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return {
      type: learnerDashboardConstants.GET_ASSIGNED_TUTORIALS_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: learnerDashboardConstants.GET_ASSIGNED_TUTORIALS_FAILURE,
      error
    };
  }
}

function getLearnerDashboardData() {
  return dispatch => {
    dispatch({
      type: learnerDashboardConstants.GET_LEARNER_DASHBOARD_DETAILS_REQUEST
    });
    learnerDashboardService.getLearnerDashboard().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return {
      type: learnerDashboardConstants.GET_LEARNER_DASHBOARD_DETAILS_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: learnerDashboardConstants.GET_LEARNER_DASHBOARD_DETAILS_FAILURE,
      error
    };
  }
}
function toggleDoNotShow() {
  return dispatch => {
    return dispatch({ type: learnerDashboardConstants.TOGGLE_DO_NOT_SHOW });
  };
}
