import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Authorized } from "../../_components/Authorized";
import authorize from "../../App/authorize";
import ChangePassword from "../../components/AccountManagement/AccountComponents/ChangePassword";
import ChangeName from "../../components/AccountManagement/AccountComponents/ChangeName";
import ChangeInstitution from "../../components/AccountManagement/AccountComponents/ChangeInstitution";
import ChangeEmail from "../../components/AccountManagement/AccountComponents/ChangeEmail";
import CancelAccount from "../../components/AccountManagement/AccountComponents/CancelAccount";
import AccountManagementPanel from "../../components/AccountManagement/AccountManagementPanel";
class AccountManagementContainer extends React.Component {
  render() {
    const userLogin = localStorage.getItem("user");
    const { dispatch, user, isLoadingChangeName, errorData } = this.props;
    return (
      <div className="animated fadeIn">
        <div className="acc-mgmt animated fadeIn">
          <Row className="custom-row bg-white p-sm-4 pt-3">
            <div className="col-lg-3 col-md-3">
              <AccountManagementPanel />
            </div>
            <div className="col-lg-9 col-md-9">
              <Authorized
                exact
                path="/account-management/"
                component={() => (
                  <ChangePassword
                    dispatch={dispatch}
                    user={user}
                    errorData={errorData}
                  />
                )}
                allowed={userLogin && authorize.roles.map(e => e.name)}
              />
              <Authorized
                exact
                path="/account-management/change-profile"
                component={() => (
                  <ChangeName
                    dispatch={dispatch}
                    user={user}
                    isLoadingChangeName={isLoadingChangeName}
                  />
                )}
                allowed={userLogin && authorize.roles.map(e => e.name)}
              />
              <Authorized
                exact
                path="/account-management/change-Institution"
                component={ChangeInstitution}
                allowed={userLogin && authorize.roles.map(e => e.name)}
              />
              <Authorized
                exact
                path="/account-management/change-Account"
                component={CancelAccount}
                allowed={userLogin && authorize.roles.map(e => e.name)}
              />
              <Authorized
                exact
                path="/account-management/change-Email"
                component={() => (
                  <ChangeEmail dispatch={dispatch} user={user} />
                )}
                allowed={userLogin && authorize.roles.map(e => e.name)}
              />
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    isLoadingChangeName: state.authentication.isLoadingChangeName,
    errorData: state.authentication.errorData
  };
}

const connectedAccount = connect(
  mapStateToProps,
  null
)(AccountManagementContainer);
export { connectedAccount as AccountManagementContainer };
