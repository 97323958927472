import { adminDashboardConstants } from "../../_constants/index";
import { adminDashboardService } from "../../_services/index";

export const adminDashboardActions = {
  getTutorialsData,
  getDashboardData,
  getCCUsersData,
  getCCDashboardData
};

function getTutorialsData() {
  return dispatch => {
    dispatch({ type: adminDashboardConstants.GET_TUTORIALS_REQUEST });
    adminDashboardService.getTutorials().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: adminDashboardConstants.GET_TUTORIALS_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminDashboardConstants.GET_TUTORIALS_FAILURE, error };
  }
}

function getDashboardData() {
  return dispatch => {
    dispatch({ type: adminDashboardConstants.GET_DASHBOARD_DATA_REQUEST });
    adminDashboardService.getDashboardData().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function success(data) {
    return { type: adminDashboardConstants.GET_DASHBOARD_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminDashboardConstants.GET_DASHBOARD_DATA_FAILURE, error };
  }
}

function getCCUsersData() {
  return dispatch => {
    dispatch({ type: adminDashboardConstants.GET_CC_USERS_REQUEST });
    return adminDashboardService.getCCUsersData().then(
      data => {
        return dispatch(success(data));
      },
      error => {
        return dispatch(failure(error.toString()));
      }
    );
  };
  function success(data) {
    return { type: adminDashboardConstants.GET_CC_USERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminDashboardConstants.GET_CC_USERS_FAILURE, error };
  }
}

function getCCDashboardData(userId) {
  if (userId !== undefined && userId !== null) {
    return dispatch => {
      dispatch({ type: adminDashboardConstants.GET_DASHBOARD_DATA_REQUEST });
      return adminDashboardService.getCCDashboardData(userId).then(
        data => {
          return dispatch(success(data));
        },
        error => {
          return dispatch(failure(error.toString()));
        }
      );
    };
    function success(data) {
      return { type: adminDashboardConstants.GET_DASHBOARD_DATA_SUCCESS, data };
    }

    function failure(error) {
      return {
        type: adminDashboardConstants.GET_DASHBOARD_DATA_FAILURE,
        error
      };
    }
  } else {
    return dispatch => {
      dispatch({ type: adminDashboardConstants.GET_DASHBOARD_DATA_REQUEST });
      return dispatch({
        type: adminDashboardConstants.GET_DASHBOARD_DATA_FAILURE
      });
    };
    function failure(error) {
      return {
        type: adminDashboardConstants.GET_DASHBOARD_DATA_FAILURE,
        error
      };
    }
  }
}
