import * as XLSX from "xlsx";
import Papa from "papaparse";

export const getImportFile = event => {
  return new Promise((resolve, reject) => {
    let fileName = event.target.files[0].name;
    let file = event.target.files[0].name.split(".");
    let fileType = file[file.length - 1].toLowerCase();
    let fileData = [];
    if (fileType === "csv" || fileType === "txt") {
      try {
        Papa.parse(event.target.files[0], {
          complete: result => {
            fileData = result.data.slice(0, result.data.length - 1);
            let dataObj = { fileResponse: fileData, fileName: fileName };
            return resolve(dataObj);
          },
          header: true
        });
      } catch (err) {
        return reject(err);
      }
    } else if (fileType === "xlsx") {
      try {
        const reader = new FileReader();
        reader.onload = function(e) {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          // wb.SheetNames.forEach((sheetname)=>{
          //     const ws = wb.Sheets[sheetname];
          //     const data = XLSX.utils.sheet_to_json(ws);
          //
          // })
          const ws = wb.Sheets[wsname];
          fileData = XLSX.utils.sheet_to_json(ws);
          let dataObj = { fileResponse: fileData, fileName: fileName };
          return resolve(dataObj);
        };
        reader.readAsBinaryString(event.target.files[0]);
      } catch (err) {
        return reject(err);
      }
    } else {
      let csvError = "Please upload a CSV/XLSX/TXT file";
      return resolve(csvError);
    }
  });
};
