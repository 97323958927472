export const importTutorialsConstants = {
  GET_TUTORIALS_COURSE_SUCCESS: "GET_TUTORIALS_COURSE_SUCCESS",
  GET_TUTORIALS_COURSE_FAILURE: "GET_TUTORIALS_COURSE_FAILURE",
  GET_TUTORIALS_COURSE_REQUEST: "GET_TUTORIALS_COURSE_REQUEST",
  GET_TUTORIALS_ROLES_SUCCESS: "GET_TUTORIALS_ROLES_SUCCESS",
  GET_TUTORIALS_ROLES_FAILURE: "GET_TUTORIALS_ROLES_FAILURE",
  GET_TUTORIALS_ROLES_REQUEST: "GET_TUTORIALS_ROLES_REQUEST",

  IMPORT_TUTORIAL_DATA_SUCCESS: "IMPORT_TUTORIAL_DATA_SUCCESS",
  IMPORT_TUTORIAL_DATA_FAILURE: "IMPORT_TUTORIAL_DATA_FAILURE",

  SHARE_TUTORIAL_TO_COURSES_SUCCESS: "SHARE_TUTORIAL_TO_COURSES_SUCCESS",
  SHARE_TUTORIAL_TO_COURSES_FAILURE: "SHARE_TUTORIAL_TO_COURSES_FAILURE",

  SHARE_TUTORIAL_TO_USERS_SUCCESS: "SHARE_TUTORIAL_TO_USERS_SUCCESS",
  SHARE_TUTORIAL_TO_USERS_FAILURE: "SHARE_TUTORIAL_TO_USERS_FAILURE"
};
