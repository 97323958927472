export const courseConstants = {
  TUTORIALS_SUCCESS: "TUTORIALS_SUCCESS",
  TUTORIALS_FAILURE: "TUTORIALS_FAILURE",
  TUTORIALS_REQUEST: "TUTORIALS_REQUEST",
  COURSE_TUTORIAL_SUCCESS: "GETALL_COURSE_TUTORIAL__SUCCESS",
  COURSE_TUTORIAL_FAILURE: "GETALL_COURSE_TUTORIAL__FAILURE",
  COURSE_TUTORIAL_REQUEST: "COURSE_TUTORIAL_REQUEST",
  ADD_COURSE_SUCCESS: "ADD_COURSE_SUCCESS",
  ADD_COURSE_FAILURE: "ADD_COURSE_FAILURE",
  ASSIGNED_TUTORIALS_COURSES_SUCCESS: "ASSIGNED_TUTORIALS_COURSES_SUCCESS",
  ASSIGNED_TUTORIALS_COURSES_FAILURE: "ASSIGNED_TUTORIALS_COURSES_FAILURE",
  EDIT_COURSE_SUCCESS: "EDIT_COURSE_SUCCESS",
  EDIT_COURSE_FAILURE: "EDIT_COURSE_FAILURE",
  DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",
  DELETE_COURSE_FAILURE: "DELETE_COURSE_FAILURE",
  COPY_COURSE_SUCCESS: "COPY_COURSE_SUCCESS",
  COPY_COURSE_FAILURE: "COPY_COURSE_FAILURE",
  DELETE_COURSE_TUTORIAL_SUCCESS: "DELETE_COURSE_TUTORIAL_SUCCESS",
  DELETE_COURSE_TUTORIAL_FAILURE: "DELETE_COURSE_TUTORIAL_FAILURE"
};
