import React, { Component } from "react";
import { FormGroup, Input, Label, Button } from "reactstrap";
import _ from "lodash";
import Checkbox from "../../_components/InputComponents/Checkbox";
import "./tutorialManagement.scss";
import Spinner from "../../components/spinner/index";

class ShareTutorial extends Component {
  constructor() {
    super();
    this.state = {
      Due: { test: false },
      selectedCourses: [],
      course_ids: [],
      learner_ids: []
    };

    this.setDue = this.setDue.bind(this);
    this.onSelectCourses = this.onSelectCourses.bind(this);
    this.onShare = this.onShare.bind(this);
  }

  onSelectCourses(c) {
    let { course_ids, learner_ids } = this.state;
    let bool = _.includes(course_ids, c.id);
    if (bool) {
      let index = course_ids.indexOf(c.id);
      index !== -1 && course_ids.splice(index, 1);
    } else {
      course_ids.push(c.id);
      c.learners.forEach(d => {
        let bool = _.includes(learner_ids, d.id);
        if (!bool) learner_ids.push(d.id);
      });
    }
    this.setState({
      course_ids,
      learner_ids
    });
  }

  setDue(e) {
    let { value, id } = e.target;
    if (value === "yes")
      this.setState({ Due: { ...this.state.Due, [id]: true } });
    else this.setState({ Due: { ...this.state.Due, [id]: false } });
  }

  onShare(e) {
    let { course_ids, learner_ids } = this.state;
    let assignObj = {
      course_ids: course_ids,
      learner_ids: learner_ids
    };
    this.props.onShare(this.props.tutorialId, assignObj);
    this.props.toggleModal(e);
  }

  render() {
    const {
      courses: { courseData: { data = [] } = {}, isLoading },
      tutorialData
    } = this.props;
    const { Due, course_ids } = this.state;
    return (
      <div className="custom-bg">
        <div className="pb-2 custom-text">
          This tutorial is not yet shared with students of any course(s). Please
          select from the course(s) below to share it with students.
        </div>
        <div className="share-tutorial-student">
          {isLoading ? (
            <Spinner />
          ) : data.length > 0 ? (
            data.map((c, i) => {
              return (
                c.learners.length > 0 && (
                  <div>
                    <div>
                      <div className="d-flex pt-2">
                        <span className="d-flex mr-1">
                          <FormGroup
                            check
                            className="custom-form custome-checkbox"
                          >
                            <Checkbox className="dis-flex ">
                              <Input
                                type="checkbox"
                                onClick={() => this.onSelectCourses(c)}
                              />
                            </Checkbox>
                          </FormGroup>
                          <p
                            className={
                              course_ids.includes(c.id)
                                ? "active-course mb-0"
                                : "mb-0"
                            }
                          >
                            {c.title}
                          </p>
                        </span>
                        <span>{`(${c.learners.length} students)`}</span>
                      </div>
                    </div>
                    <div className="Share-tutorial">
                      <p>Do You want have a due date for this course?</p>
                      <span className="custom-space">
                        <FormGroup check className="radio">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id={i}
                            name={`${c.id}`}
                            value="yes"
                            onChange={this.setDue}
                          />
                          <Label check className="form-check-label">
                            Yes
                          </Label>
                        </FormGroup>
                        <FormGroup check className="radio">
                          <Input
                            className="form-check-input"
                            type="radio"
                            name={`${c.id}`}
                            value="no"
                            id={i}
                            onChange={this.setDue}
                          />
                          <Label check className="form-check-label">
                            No
                          </Label>
                        </FormGroup>
                      </span>
                      {Due[i] && (
                        <div className="d-flex">
                          <p className="pt-2">Course Due Date:</p>
                          <Input className="custome-input" type="text" />
                        </div>
                      )}
                    </div>
                  </div>
                )
              );
            })
          ) : (
            ""
          )}
          <div className="pt-2">
            You can also copy the tutorial or quiz link from below to share with everyone.
          </div>
          <div className="pt-2">
            <b>Tutorial Link</b>
          </div>
          {tutorialData.url && (
            <div>
              <div class="url-input w-100">
                <a
                  href={
                    tutorialData.is_public
                      ? `${document.location.origin}/tutorials/${tutorialData.url}`
                      : `${document.location.origin}/tutorial/${tutorialData.url}`
                  }
                  target="_blank"
                >
                  {tutorialData.is_public
                    ? `${document.location.origin}/tutorials/${tutorialData.url}`
                    : `${document.location.origin}/tutorial/${tutorialData.url}`}
                </a>
              </div>
            </div>
          )}
          {/* http://127.0.0.1:8081/tutorial-page/885/QuizViewer# */}
          {tutorialData.id && tutorialData?.settings?.is_quiz_public && (
            <>
            <div className="pt-2">
              <b>Quiz Link</b>
            </div>
            <div>
              <div class="url-input w-100">
                <a
                  href={
                    tutorialData.is_public
                      ? `${document.location.origin}/tutorial-page/${tutorialData.id}/QuizViewer`
                      : `${document.location.origin}/tutorial-page/${tutorialData.id}/QuizViewer`
                  }
                  target="_blank"
                >
                  {tutorialData.is_public
                    ? `${document.location.origin}/tutorial-page/${tutorialData.id}/QuizViewer`
                    : `${document.location.origin}/tutorial-page/${tutorialData.id}/QuizViewer`}
                </a>
              </div>
            </div>
            </>
          )}
        </div>
        <div className="text-center pt-2">
          <Button
            className="custom-btn"
            color="primary"
            onClick={e => this.onShare(e)}
          >
            Share
          </Button>
        </div>
      </div>
    );
  }
}
export default ShareTutorial;
