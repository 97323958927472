import React, { Component } from "react";
import "./Collaborate.scss";

export default class Collaborate extends Component {
  componentDidMount() {
    this.props.getAll();
  }

  addCollaborator = userId => {
    this.props.addCollaborator({ userId, tutorialId: this.props.tutorial.id });
  };

  removeCollaborator = userId => {
    this.props.removeCollaborator({
      userId,
      tutorialId: this.props.tutorial.id
    });
  };

  isUserCollaborator(user, roles) {
    const { isCollaboratorRequestInProgress } = this.props;
    const resourceIds = roles.map(role => role.resource_id);
    return isCollaboratorRequestInProgress ? (
      <div className="loader sm"></div>
    ) : resourceIds.includes(user.id) ? (
      <i
        className="fa fa-user-times pointer"
        aria-hidden="true"
        onClick={() => this.removeCollaborator(user.id)}
      ></i>
    ) : (
      <i
        className="fa fa-user-plus pointer"
        aria-hidden="true"
        onClick={() => this.addCollaborator(user.id)}
      ></i>
    );
  }

  userRecord(user) {
    const { tutorial: { roles } = { roles: [] } } = this.props;
    return (
      <div key={user.id} className="collaborator-list-item">
        <div className="collaborator-user-name">
          {`${user.first_name} ${user.last_name}`}
        </div>
        <div className="collaborator-user-email">{user.email}</div>
        <div className="collaborator-status">
          {this.isUserCollaborator(user, roles)}
        </div>
      </div>
    );
  }

  render() {
    const { isFetchingCollaboratorsList, collaboratorUsers = [] } = this.props;

    if (isFetchingCollaboratorsList) return <div className="loader"></div>;
    return (
      <div className="collaborators-list">
        {collaboratorUsers.map(user => this.userRecord(user))}
      </div>
    );
  }
}
