import React from "react";
import { Form, Button, Input, Label } from "reactstrap";

export default class CertificateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guestUserFirstName: "",
      guestUserLastName: "",
      guestUserEmail: "",
      displayForm: true
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitGuestDetails = this.submitGuestDetails.bind(this);
  }

  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  submitGuestDetails(e) {
    e.preventDefault();
    const { updateTutorialAttemptGuestDetails } = this.props;
    const {
      guestUserFirstName,
      guestUserLastName,
      guestUserEmail
    } = this.state;
    updateTutorialAttemptGuestDetails({
      guestUserFirstName,
      guestUserLastName,
      guestUserEmail
    });
    this.setState({
      guestUserFirstName: "",
      guestUserLastName: "",
      guestUserEmail: "",
      displayForm: false
    });
  }

  render() {
    const {
      guestUserFirstName,
      guestUserLastName,
      guestUserEmail,
      displayForm
    } = this.state;
    return displayForm ? (
      <div className="guest-user-details-from">
        <div>
          Your certificate will be emailed to you after you provide the
          necessary details.
        </div>
        <h6>
          <Label>First Name</Label>
        </h6>
        <Form onSubmit={this.submitGuestDetails}>
          <Input
            type="text"
            className="inputText"
            name="guestUserFirstName"
            value={guestUserFirstName}
            onChange={this.handleFormChange}
            aria-label={"First Name for Certificate"}
            required={true}
          />

          <h6>
            <Label>Last Name</Label>
          </h6>
          <Input
            type="text"
            className="inputText"
            name="guestUserLastName"
            value={guestUserLastName}
            onChange={this.handleFormChange}
            aria-label={"Last Name for Certificate"}
            required={true}
          />

          <h6>
            <Label>Email</Label>
          </h6>
          <Input
            type="text"
            className="inputText"
            name="guestUserEmail"
            value={guestUserEmail}
            onChange={this.handleFormChange}
            aria-label={"Email for Certificate"}
            required={true}
          />

          <Button type="submit" color="primary">
            Submit
          </Button>
        </Form>
      </div>
    ) : (
      <div>Your certificate has been emailed to you!</div>
    );
  }
}
