import React, { Component } from "react";
import "./tutorialManagement.scss";
import { Label, Input, Button } from "reactstrap";
class AddChapter extends Component {
  constructor() {
    super();
    this.state = {
      data: { type: "chapter", title: "", index: 0 },
      errors: []
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  }

  componentDidMount() {
    let { editData } = this.props;
    let { data } = this.state;
    if ("data" in editData) {
      data.title = editData.data.title;
      data.description = editData.data.description;
      this.setState({ data });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) this.setState({ errors: nextProps.errors });
  }

  onSave = () => {
    let { data } = this.state;
    let obj = {};
    if (Boolean(data.description)) obj = { ...data };
    else obj = { type: "chapter", title: data.title, index: data.index };
    this.props.handleSubmit(obj, "addChapter");
  }

  render() {
    const { data, errors } = this.state;
    const { activeButton } = this.props;
    return (
      <div>
        <div className="custom-col">
          <Label htmlFor="tutorialTitle" className="form-label-strong">
            {`${activeButton === "Add Chapter" ? "Chapter" : "Section"} Title`}
          </Label>
          <Input
            type="text"
            id="uniqueTutorial"
            className="mt-2 mb-2"
            name="title"
            onChange={this.handleChange}
            value={data.title}
          />
          {errors["chapterTitle"] && (
            <div className="help-block">{errors["chapterTitle"]}</div>
          )}
          <Label htmlFor="tutorialTitle" className="form-label-strong">
            {`${activeButton === "Add Chapter" ? "Chapter" : "Section"} Description:`}
          </Label>
          <Input
            type="textarea"
            name="description"
            className="mt-2"
            id="tutorialDescription"
            rows="4"
            onChange={this.handleChange}
            value={data.description && data.description}
          />
          {errors["description"] && (
            <div className="help-block">{errors["description"]}</div>
          )}
          <div className="save-btn-tutorial mt-3 text-right w-100">
            <Button
              type="button"
              color="primary"
              className="btn btn-primary custom-save-btn w-25"
              onClick={this.onSave}
            >
              {" "}
              Save{" "}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default AddChapter;
