import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import "./credentialsSidebar.scss";
import Spinner from "../spinner/index";

class CredentialsSideBar extends Component {
  render() {
    const {
      credentialsData,
      selectRole,
      activeRole,
      isLoadingCredentials
    } = this.props;
    return (
      <div className="main-full-sidebar animated fadeIn">
        <Card className="role-sidebar-card">
          <CardHeader>User Management</CardHeader>
          <ListGroup>
            {credentialsData.map((c, i) => {
              let role = Object.keys(c).toString();
              return (
                <ListGroupItem
                  tag="a"
                  href="#"
                  style={{ cursor: "pointer" }}
                  active={activeRole === role}
                  onClick={e => {
                    e.preventDefault();
                    selectRole(role);
                  }}
                >
                  {role === "full_content_creator" ||
                  role === "basic_content_creator" ||
                  role === "individual_content_creator"
                    ? "Instructor"
                    : role
                        .split("_")
                        .join(" ")
                        .charAt(0)
                        .toUpperCase() +
                      role
                        .split("_")
                        .join(" ")
                        .slice(1)}
                  &nbsp;({c[role].length})
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </Card>
      </div>
    );
  }
}

export default CredentialsSideBar;
