import { toastConstants } from "../_constants";

export function toast(state = {}, action) {
  switch (action.type) {
    case toastConstants.SHOW_TOAST:
      return {
        ...state,
        toastData: action.message,
        autoClose: action.autoClose
      };
    default:
      return state;
  }
}
