import React from "react";

export default class MyProfile extends React.Component {
  render() {
    return (
      <div className="animated fadeIn">
        <h1>My Profile</h1>
      </div>
    );
  }
}
