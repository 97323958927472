import React, { Component } from "react";
import { connect } from "react-redux";
import Home from "../../components/HomePage/home";

class SideCarHome extends Component {
  render() {
    return (
      <div className="animated fadeIn">
        <Home />
      </div>
    );
  }
}
const connectedSideCarHome = connect(null, null)(SideCarHome);
export { connectedSideCarHome as SideCarHome };
