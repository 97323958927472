import { menuTogglerConstants } from "../../_constants/index";

const initial = {
  menuToggle: true
};
export function menuToggler(state = initial, action) {
  switch (action.type) {
    case menuTogglerConstants.MENU_TOGGLER:
      return { ...state, menuToggle: action.payload.toggleBool };
    default:
      return state;
  }
}
