import React, { Component } from "react";
import { Col, Form, FormGroup, Input, Label, Row, Tooltip } from "reactstrap";

import { validateStartingTutorial } from "../../_helpers/Validation";
import Spinner from "../../components/spinner/index";
import "./createTutorial.scss";

class TutorialDetail extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        is_public: true,
        course_ids: [],
        is_frame_busted: "",
        settings: {
          opt_out_of_linked_table_of_content: false,
          is_wide_iframe: false,
        },
      },
      submitted: false,
      errors: [],
      tutorial: {},
      url: "",
      isIframeLoaded: false,
      openToolTip: { test: false },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let {
      TabsData,
      data: { editTutorial = {} },
      errors,
    } = nextProps;
    if (Object.keys(TabsData).length > 0) {
      this.setState({
        errors: errors,
        tutorial: TabsData,
        data: TabsData,
      });
    } else if (Object.keys(editTutorial).length > 0) {
      this.setState({
        errors: errors,
        tutorial: TabsData,
        data: editTutorial.data,
      });
    }
    this.setState({ errors: errors });
  }

  handleCustomUrl = () => {
    let { data } = this.state;
    let uniqueString =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 1) +
      Math.round(Math.random() * 9) +
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 1) +
      Math.round(Math.random() * 9);
    data["url"] =
      (data.title && data.title.replace(/[^a-zA-Z0-9 ]/g, ""))
        .toLowerCase()
        .split(" ")
        .join("-") +
      "-" +
      uniqueString;
    this.setState({ data });
  };

  handleChange = (e) => {
    let { data, errors } = this.state;
    let { name, value, selectedOptions } = e.target;

    console.log("console, name, value", name, value);

    if (value === "true" || value === "false") {
      if (
        ["is_wide_iframe", "opt_out_of_linked_table_of_content"].includes(name)
      ) {
        data.settings[name] = JSON.parse(value);
      } else {
        data[name] = JSON.parse(value);
      }
    } else if (name === "course_ids") {
      if (data[name]) data[name][0] = value;
      else
        data["courses"][0] = {
          id: Number(value),
          title: selectedOptions[0].innerText,
          description: selectedOptions[0].id,
        };
    } else data[name] = value;
    if (name === "starting_url") {
      this.setState({
        isIframeLoaded: true,
      });
    }
    if (name === "is_frame_busted") errors["is_frame_busted"] = "";

    if (name === "url") data["url"] = value;

    // if (name === 'is_wide_iframe') {
    //     data['newWidth'] = '1000px'
    // }

    this.setState({ data, errors });
  };

  onNext = () => {
    this.onValidate();
    this.props.onEnableTabs();
  };

  checkFrameBusting = () => {
    const { data = {}, errors } = this.state;
    errors["starting_url"] = validateStartingTutorial(data.starting_url);
    data["starting_url"] = data.starting_url;
    this.setState({
      url: data.starting_url,
      isIframeLoaded: false,
      data,
      errors,
    });
  };

  toggleTooltip = (e) => {
    this.setState({ openToolTip: { [e]: !this.state.openToolTip[e] } });
  };

  render() {
    const {
      courses: { courseData = [] },
    } = this.props;
    const { errors, data, isIframeLoaded, openToolTip } = this.state;
    const {
      courses = [],
      course_ids = [],
      title = "",
      url = "",
      starting_url = "",
      description = "",
      published_at = "",
      tags = [],
      is_public = true,
      is_frame_busted = false,
      settings: {
        opt_out_of_linked_table_of_content = false,
        is_wide_iframe = false,
      },
    } = data;
    return (
      <div className="tutorial-detail animated fadeIn p-3">
        <Row>
          <div className="col-md-9 col-12">
            <div>
              <Form
                action=""
                method="post"
                encType="multipart/form-data"
                className="form-horizontal pt-3"
              >
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor={"tutorialTitle"}>
                      Tutorial Title:<span className="required">*</span>
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <div>
                      <Input
                        type="text"
                        id="tutorialTitle"
                        name="title"
                        onChange={this.handleChange}
                        onBlur={this.handleCustomUrl}
                        value={title}
                      />
                    </div>
                    {errors["title"] && (
                      <div className="help-block">{errors["title"]}</div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label className="pr-2" htmlFor={"settingTutorial"}>
                      Paste Starting Webpage:<span className="required">*</span>
                    </Label>
                    <i className="icon-question" id="one" />
                    <Tooltip
                      placement="right"
                      isOpen={openToolTip["one"]}
                      target="one"
                      toggle={() => this.toggleTooltip("one")}
                    >
                      The starting web page is the page users will see on the
                      right side of their screen
                    </Tooltip>
                  </Col>
                  <Col xs="12" md="9">
                    <div>
                      <Input
                        type="text"
                        aria-label={"Tutorial Starting WebPage Name"}
                        id="settingTutorial"
                        name="starting_url"
                        onChange={this.handleChange}
                        onBlur={this.checkFrameBusting}
                        value={starting_url}
                      />
                    </div>
                    {errors["starting_url"] && (
                      <div className="help-block">{errors["starting_url"]}</div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label className="pr-2" htmlFor="uniqueTutorial">
                      Unique Tutorial URL:
                    </Label>
                    <i className="icon-question" id="two" />
                    <Tooltip
                      placement="right"
                      isOpen={openToolTip["two"]}
                      target="two"
                      toggle={() => this.toggleTooltip("two")}
                    >
                      Your tutorial will be automatically assigned a unique URL
                      that you can share with students. You can customize this
                      URL by changing the ending.
                    </Tooltip>
                  </Col>
                  <Col xs="12" md="9">
                    <div className="unique_tutorial_wrap">
                      <div className="unique_tutorial_url">
                        {"http://www.sidecarlearning.com/tutorials"}
                      </div>
                      <Input
                        className="unique_tutorial_input"
                        aria-label={"Tutorial URL"}
                        type="text"
                        id="uniqueTutorial"
                        name="url"
                        onChange={this.handleChange}
                        value={url}
                      />
                      {errors["url"] && (
                        <div className="help-block">{errors["url"]}</div>
                      )}
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="tutorialDescription">
                      Tutorial Description:
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="textarea"
                      name="description"
                      id="tutorialDescription"
                      rows="2"
                      onChange={this.handleChange}
                      value={description}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor={"publishDate"}>Published Date:</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Row>
                      <Col xs="12" md="6">
                        <Input
                          type="date"
                          id={"publishDate"}
                          aria-label={"Tutorial Publish Date"}
                          name="published_at"
                          onChange={this.handleChange}
                          value={published_at}
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="tagsSubject">Tags/Subject:</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      aria-label={"Tutorial Tag/Subject"}
                      id="tagsSubject"
                      name="tags"
                      onChange={this.handleChange}
                      value={typeof tags === "object" ? tags.join(",") : tags}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label className="pr-2" htmlFor="tutorialCourse">
                      Select Course(s):
                    </Label>
                    <i className="icon-question" id="three" />
                    <Tooltip
                      placement="right"
                      isOpen={openToolTip["three"]}
                      target="three"
                      toggle={() => this.toggleTooltip("three")}
                    >
                      If you have created courses, you can assign this tutorial
                      to any course.
                    </Tooltip>
                  </Col>
                  <Col xs="12" md="4">
                    <div className="input-select">
                      <Input
                        type="select"
                        id="tutorialCourse"
                        aria-label={"Tutorial Course"}
                        name="course_ids"
                        onChange={this.handleChange}
                        value={
                          course_ids.length > 0
                            ? course_ids[0]
                            : courses.length > 0
                            ? courses[0].id
                            : ""
                        }
                      >
                        <option value={courses[0] ? courses[0].id : "0"}>
                          {courses[0] ? courses[0].title : `Please select`}
                        </option>
                        {courseData.data &&
                          courseData.data.map((c, i) => {
                            return (
                              <option
                                key={`course_${c.id}`}
                                value={c.id}
                                id={c.description}
                              >
                                {c.title}
                              </option>
                            );
                          })}
                      </Input>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <div className="pr-2">Login Required?:</div>
                    <i className="icon-question" id="four" />
                    <Tooltip
                      placement="right"
                      isOpen={openToolTip["four"]}
                      target="four"
                      toggle={() => this.toggleTooltip("four")}
                    >
                      If you would like users to login to SidecarLearning before
                      being able to access the tutorial, select “Yes.” “No”
                      indicates that any user with the URL can access the
                      tutorial.
                    </Tooltip>
                  </Col>
                  <Col xs="12" md="3" className="ml-3">
                    <Row>
                      <FormGroup check className="radio">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="radio1"
                          name="is_public"
                          value={false}
                          onChange={this.handleChange}
                          checked={is_public === false}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="radio1"
                        >
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check className="radio">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="radio11"
                          name="is_public"
                          value={true}
                          onChange={this.handleChange}
                          checked={is_public === true}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="radio11"
                        >
                          No
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <div>
                      Opt out of linked table of contents:{" "}
                      <i className="icon-question" id="five" />
                    </div>
                    <Tooltip
                      placement="right"
                      isOpen={openToolTip["five"]}
                      target="five"
                      toggle={() => this.toggleTooltip("five")}
                    >
                      You can select whether or not users will see a navigable
                      table of contents.
                    </Tooltip>
                  </Col>
                  <Col xs="12" md="3" className="ml-3">
                    <Row>
                      <FormGroup check className="radio">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="radio2"
                          name="opt_out_of_linked_table_of_content"
                          value={true}
                          checked={opt_out_of_linked_table_of_content === true}
                          onChange={this.handleChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="radio2"
                        >
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check className="radio">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="radio21"
                          name="opt_out_of_linked_table_of_content"
                          value={false}
                          checked={opt_out_of_linked_table_of_content === false}
                          onChange={this.handleChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="radio21"
                        >
                          No
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <div
                      aria-label={
                        "Starting web page displayed in the window or not."
                      }
                    >
                      Is your starting web page displayed in the window?
                      <span className="required">*</span>
                    </div>
                  </Col>
                  <Col xs="12" md="9">
                    <FormGroup check className="radio">
                      <Input
                        className="form-check-input"
                        type="radio"
                        id="radio3"
                        name="is_frame_busted"
                        value={false}
                        checked={is_frame_busted === false}
                        onChange={this.handleChange}
                      />
                      <Label
                        check
                        className="form-check-label"
                        htmlFor="radio3"
                      >
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check className="radio">
                      <Input
                        className="form-check-input"
                        type="radio"
                        id="radio4"
                        name="is_frame_busted"
                        value={true}
                        checked={is_frame_busted === true}
                        onChange={this.handleChange}
                      />
                      <Label
                        check
                        className="form-check-label"
                        htmlFor="radio4"
                      >
                        No
                      </Label>
                    </FormGroup>
                    {is_frame_busted === true && (
                      <FormGroup
                        check
                        className="radio pr-0 pr-sm-0 pr-md-0 pr-lg-0"
                      >
                        <p className="bg-red">
                          Your tutorial will be displayed in two popup windows.
                        </p>
                      </FormGroup>
                    )}
                    {is_frame_busted === false && (
                      <FormGroup
                        check
                        className="radio pr-0 pr-sm-0 pr-md-0 pr-lg-0"
                      >
                        <p className="bg-green">
                          Your tutorial will be displayed in one frame.
                        </p>
                      </FormGroup>
                    )}
                    {errors["is_frame_busted"] && (
                      <div className="help-block">
                        {errors["is_frame_busted"]}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <div>
                      Do you want wide frame:{" "}
                      <i className="icon-question" id="six" />
                    </div>
                    <Tooltip
                      placement="right"
                      isOpen={openToolTip["six"]}
                      target="six"
                      toggle={() => this.toggleTooltip("six")}
                    >
                      You can select whether or not users will see a wide frame.
                    </Tooltip>
                  </Col>
                  <Col xs="12" md="3" className="ml-3">
                    <Row>
                      <FormGroup check className="radio">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="radiof1"
                          name="is_wide_iframe"
                          value={true}
                          checked={is_wide_iframe === true}
                          onChange={this.handleChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="radiof1"
                        >
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check className="radio">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="radiof2"
                          name="is_wide_iframe"
                          value={false}
                          checked={is_wide_iframe === false}
                          onChange={this.handleChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="radiof2"
                        >
                          No
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </FormGroup>
              </Form>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div>
              <Form
                action=""
                method="post"
                encType="multipart/form-data"
                className="form-horizontal pt-3"
              >
                <FormGroup row>
                  <p className="pr-2">Website Preview:</p>
                  {(errors["starting_url"] || isIframeLoaded) && (
                    <div id="spinner" className="spinner-div">
                      <div className="loader-div" id="create-tut-loader">
                        <Spinner />
                      </div>
                    </div>
                  )}
                  {!errors["starting_url"] && !isIframeLoaded && (
                    <iframe
                      src={starting_url}
                      width="100%"
                      height="63vh"
                      className="iframe-preview"
                      sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
                      // onLoad={() => {
                      //      document.getElementById('spinner').style.display = 'none'
                      // }}
                    />
                  )}
                </FormGroup>
              </Form>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default TutorialDetail;
