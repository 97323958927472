import {
  courseConstants,
  contentCreatorConstants
} from "../../_constants/index";
import _ from "lodash";
const initial = {
  courseData: [],
  tutorialData: [],
  errorData: [],
  isLoadingCourses: false,
  isLoadingTutorials: false
};
export function courses(state = initial, action) {
  switch (action.type) {
    case courseConstants.TUTORIALS_SUCCESS:
      return {
        ...state,
        tutorialData: action.data.data.tutorials_created,
        isLoadingTutorials: false
      };
    case courseConstants.TUTORIALS_FAILURE:
      return { ...state, tutorialData: [], isLoadingTutorials: false };
    case courseConstants.TUTORIALS_REQUEST:
      return { ...state, isLoadingTutorials: true };
    case courseConstants.COURSE_TUTORIAL_SUCCESS:
      return {
        ...state,
        courseData: action.data.data,
        isLoadingCourses: false
      };
    case courseConstants.COURSE_TUTORIAL_FAILURE:
      return { ...state, courseData: [], isLoadingCourses: false };
    case courseConstants.COURSE_TUTORIAL_REQUEST:
      return { ...state, isLoadingCourses: true };
    case courseConstants.ASSIGNED_TUTORIALS_COURSES_SUCCESS:
      let arr = [];
      let {
        data: { tutorial_ids, course_ids }
      } = action;
      let { tutorialData, courseData } = state;
      tutorial_ids.forEach(a =>
        arr.push(_.find(tutorialData, t => t.id === a))
      );
      course_ids.forEach(c => {
        let index = _.findIndex(courseData, d => d.id === c);
        courseData[index].tutorials = _.uniqBy(
          courseData[index].tutorials.concat(arr),
          "id"
        );
      });
      return { ...state };
    case courseConstants.ASSIGNED_TUTORIALS_COURSES_FAILURE:
      return { ...state };
    case courseConstants.ADD_COURSE_SUCCESS:
      let data = state.courseData;
      action.data.data.map((d, i) => {
        data.push(d);
      });
      return { ...state, courseData: data, errorData: [] };
    case courseConstants.ADD_COURSE_FAILURE:
      return { ...state, errorData: action.error };
    case courseConstants.COPY_COURSE_SUCCESS:
      let copyData = state.courseData;
      copyData.push(action.data.data);
      return { ...state, courseData: copyData, errorData: [] };
    case courseConstants.COPY_COURSE_FAILURE:
      return { ...state };
    case courseConstants.EDIT_COURSE_SUCCESS:
      let ind = state.courseData.findIndex(c => c.id === action.data.data.id);
      state.courseData.splice(ind, 1, action.data.data);
      return { ...state, errorData: [] };
    case courseConstants.EDIT_COURSE_FAILURE:
      return { ...state, errorData: action.error };
    case courseConstants.DELETE_COURSE_SUCCESS:
      let removeData = state.courseData;
      let index = removeData.findIndex(d => d.id === action.data.id);
      removeData.splice(index, 1);
      return { ...state, courseData: removeData, errorData: [] };
    case courseConstants.DELETE_COURSE_FAILURE:
      return { ...state };
    case courseConstants.DELETE_COURSE_TUTORIAL_SUCCESS:
      state.courseData.find(
        c =>
          c.id === action.data.obj.course_ids[0] &&
          c.tutorials.splice(
            c.tutorials.findIndex(f => f.id === action.data.tid),
            1
          )
      );
      return { ...state };
    case courseConstants.DELETE_COURSE_TUTORIAL_FAILURE:
      return { ...state };
    case contentCreatorConstants.SHARE_TUTORIAL_SUCCESS:
      return { ...state, courseData: action.data.data };
    case contentCreatorConstants.SHARE_TUTORIAL_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
