import { connect } from "react-redux";
import React, { Component } from "react";
import { rolesActions } from "../../_actions";
import ManageRoles from "../../components/RoleManagement/manageRoles";
import { Col, Row, Button } from "reactstrap";
import Spinner from "../../components/spinner";

class Roles extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(rolesActions.getUserData());
    dispatch(rolesActions.getUserRolesData());
    dispatch(rolesActions.getUserRolesNavData());
  }
  render() {
    const {
      dispatch,
      userData,
      userRoleData,
      isLoadingUserData,
      isLoadinguserRoleData
    } = this.props;
    return (
      <React.Fragment>
        {isLoadingUserData || isLoadinguserRoleData ? (
          <Spinner />
        ) : (
          <div className="animated fadeIn">
            <Row className="custom-row bg-white p-sm-4">
              <Col md={12}>
                <ManageRoles
                  userData={userData}
                  dispatch={dispatch}
                  cards={userRoleData}
                />
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userRoleData: state.roles.userRoleData,
    isLoadinguserRoleData: state.roles.isLoadinguserRoleData,
    userData: state.roles.userData,
    isLoadingUserData: state.roles.isLoadingUserData,
    userRolesNavData: state.roles.userRolesNavData
  };
}

const connectedRoleMangement = connect(mapStateToProps, null)(Roles);
export { connectedRoleMangement as Roles };
