import React, { Component } from "react";
import { Checkbox as CustomeCheckbox } from "../../_components/InputComponents";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
} from "../../_helpers/Validation";
import "./userform.scss";
import {
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup,
  ListGroup,
} from "reactstrap";
import { history } from "../../_helpers/history";
import _ from "lodash";
import Spinner from "../spinner/index";
import BackButton from "../../_components/BackButton";
import { getImportFile } from "../../_helpers/importFile";

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: _.cloneDeep(props.newUser),
      userFormErrors: _.cloneDeep(props.errors),
      selectedRoles: _.cloneDeep(props.selectedRoles),
      totalCC: 0,
      isLimitExceed: false,
      csvError: "",
      fileName: "",
      tooltipOpen: false,
      userData: [],
    };
  }
  componentDidMount() {
    const { credentialsData = {} } = this.props;
    let { totalCC } = this.state;
    credentialsData &&
      credentialsData.map((c, i) => {
        let role = Object.keys(c).toString();
        if (role === "full_content_creator") totalCC += c[role].length;
        if (role === "basic_content_creator") totalCC += c[role].length;
        if (role === "individual_content_creator") totalCC += c[role].length;
      });
    this.setState({
      totalCC,
    });
  }

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  toggleModal = () => {
    this.setState({
      isLimitExceed: !this.state.isLimitExceed,
    });
  };

  handleFile = (e) => {
    getImportFile(e)
      .then((res) => {
        if (typeof res !== "string") {
          this.setState({
            userData: res.fileResponse,
            fileName: res.fileName,
            csvError: "",
          });
        } else {
          this.setState({
            csvError: res,
          });
        }
      })
      .catch((err) => {});
  };

  openDialogue = () => {
    let div = document.getElementById("file-upload");
    div.click();
  };

  generateForm = () => {
    const { newUser, userFormErrors } = this.state;
    //remove password:'' as auto generated
    let obj = {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      organization_id:
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).organization !== null
          ? JSON.parse(localStorage.getItem("user")).organization.id
          : JSON.parse(localStorage.getItem("user")).owned_organization.id,
    };
    // remove password:'' as auto generated
    let err = { first_name: "", last_name: "", username: "", email: "" };
    newUser.push(obj);
    userFormErrors.push(err);
    this.setState({
      newUser,
      userFormErrors,
    });
  };

  deleteUserForm = (index) => {
    const { newUser, userFormErrors } = this.state;
    newUser.splice(index, 1);
    userFormErrors.splice(index, 1);
    this.setState({
      newUser,
      userFormErrors,
    });
  };

  handleChange = (e, i) => {
    let { userFormErrors, newUser } = this.state;
    const { name, value } = e.target;

    if (name === "avatar") {
      if (e.target.files && e.target.files[0]) {
        var FR = new FileReader();
        FR.addEventListener("load", function(e) {
          newUser[i]["avatar"] = e.target.result;
        });
        FR.readAsDataURL(e.target.files[0]);
      }
    } else {
      userFormErrors[i][name] = "";
      newUser[i][name] = value;
    }
    this.setState({
      newUser,
      userFormErrors,
    });
  };

  handleValidation = (e, i) => {
    let { userFormErrors, newUser } = this.state;
    const { name, value } = e.target;

    if (name === "first_name")
      userFormErrors[i].first_name = validateFirstName(value);

    if (name === "last_name")
      userFormErrors[i].last_name = validateLastName(value);

    if (name === "email") userFormErrors[i].email = validateEmail(value.trim());

    this.setState({
      userFormErrors,
    });
  };

  handleClick = () => {
    let { userFormErrors, newUser, selectedRoles, totalCC } = this.state;
    const { seats = 0 } = this.props;
    let user = JSON.parse(localStorage.getItem("user"));
    if (selectedRoles.length) {
      const orgIndex = selectedRoles.findIndex(
        (u) => u.name === "org_administrator"
      );
      if (orgIndex !== -1) {
        selectedRoles[orgIndex].record_id = (
          user.organization || user.owned_organization
        ).id;
        selectedRoles[orgIndex].record_type = "Organization";
      }
    }
    newUser.map((c, i) => {
      if (!c.first_name)
        userFormErrors[i].first_name = validateFirstName(c.first_name);

      if (!c.last_name)
        userFormErrors[i].last_name = validateLastName(c.last_name);

      if (!c.email) userFormErrors[i].email = validateEmail(c.email.trim());
    });
    this.setState({
      userFormErrors,
    });
    if (
      !userFormErrors.find((e) => e.email.length > 0) &&
      selectedRoles.length > 0
    ) {
      if (
        selectedRoles.filter(
          (c) =>
            c.name === "full_content_creator" ||
            c.name === "basic_content_creator" ||
            c.name === "individual_content_creator"
        ).length > 0
      ) {
        if (totalCC + newUser.length <= seats) {
          this.props.saveAddUser(newUser, userFormErrors, selectedRoles);
        } else {
          this.toggleModal();
        }
      } else {
        this.props.saveAddUser(newUser, userFormErrors, selectedRoles);
      }
    }

    if (selectedRoles.length === 0) {
      this.props.roleValidation(newUser, userFormErrors, selectedRoles);
    }
  };

  cancelForm = () => {
    this.props.cancelAddForm();
    history.push("/credentials-management");
  };

  selectRoles = (e, roleName) => {
    let { selectedRoles } = this.state;
    let bool = selectedRoles.some((a) => a.name === roleName);
    if (bool) {
      var index = selectedRoles.findIndex((r) => r.name === roleName);
      index !== -1 && selectedRoles.splice(index, 1);
    } else selectedRoles.push({ name: roleName });
    this.setState({
      selectedRoles,
    });
  };

  selectAllRoles = (e) => {
    const { credentialsData } = this.props;
    let { selectedRoles } = this.state;
    const { checked } = e.target;
    if (checked) {
      selectedRoles = [];
      credentialsData &&
        credentialsData.map((l, i) => {
          let role = Object.keys(l).toString();
          role !== "licensee" && selectedRoles.push({ name: role });
        });
      this.setState({
        selectedRoles,
      });
    } else {
      this.setState({
        selectedRoles: [],
      });
    }
  };

  saveImportUser = () => {
    let { selectedRoles, userData } = this.state;
    if (selectedRoles.length > 0 && userData.length > 0) {
      this.props.saveAddUser(userData, "", selectedRoles);
    }
    if (userData.length === 0 || selectedRoles.length === 0) {
      this.props.fileValidation("user");
    }
  };
  render() {
    const {
      newUser,
      userFormErrors,
      selectedRoles,
      isLimitExceed,
      hover,
    } = this.state;
    const {
      credentialsData,
      isLoadingCredentials,
      pageFrom,
      errorData,
    } = this.props;
    return (
      <div className="animated fadeIn">
        {isLoadingCredentials ? (
          <Spinner />
        ) : (
          <Row>
            <div className="col-md-4 col-lg-4">
              <Card className="manage-assign-card">
                <div className="p-lg-4">
                  <div className="assign-title-header">
                    <h4>Assign Roles to User</h4>
                    <div className="select-all">
                      <FormGroup check>
                        <CustomeCheckbox className="dis-flex">
                          <Input
                            type="checkbox"
                            onClick={this.selectAllRoles}
                            checked={
                              selectedRoles &&
                              selectedRoles.length === credentialsData &&
                              credentialsData.length
                            }
                          />
                          <p className="pt-1">Select All</p>
                        </CustomeCheckbox>
                      </FormGroup>
                    </div>
                    <p>Select Role(s) and then Add User</p>
                  </div>
                  <Form>
                    <ListGroup>
                      {credentialsData &&
                        credentialsData.map((c, i) => {
                          let role = Object.keys(c).toString();
                          return (
                            role !== "licensee" && (
                              <FormGroup
                                check
                                className="assign-form"
                                key={`${i}${c.id}`}
                              >
                                <CustomeCheckbox className="dis-flex">
                                  <Input
                                    type="checkbox"
                                    onClick={(e) => {
                                      this.selectRoles(e, role);
                                    }}
                                    checked={selectedRoles.find(
                                      (s) => s.name === role
                                    )}
                                  />{" "}
                                  <p className="mb-0">
                                    {role === "full_content_creator" ||
                                    role === "basic_content_creator" ||
                                    role === "individual_content_creator"
                                      ? "Instructor"
                                      : role
                                          .split("_")
                                          .join(" ")
                                          .charAt(0)
                                          .toUpperCase() +
                                        role
                                          .split("_")
                                          .join(" ")
                                          .slice(1)}
                                  </p>
                                </CustomeCheckbox>
                              </FormGroup>
                            )
                          );
                        })}
                    </ListGroup>
                  </Form>
                </div>
              </Card>
            </div>
            {pageFrom === "add-form" && (
              <div className="col-md-8">
                <Card className="custome-card manage-custom-card user-form-scroll">
                  <CardHeader className="header-title">
                    <span className="username-header">{"Add New User"}</span>
                    <span
                      className="delete-icon add-user-btn"
                      onClick={this.generateForm}
                    >
                      <i className="fa fa-plus-circle" />
                      Add More
                    </span>
                  </CardHeader>
                  <CardBody>
                    {errorData &&
                      errorData.map((e, i) => {
                        return (
                          <div key={i} className="user-form-custom-alert">
                            {e.map((l, index) => {
                              return <li className="mb-0">{l}</li>;
                            })}
                          </div>
                        );
                      })}
                    <Form>
                      {newUser &&
                        newUser.map((u, i) => {
                          return (
                            <div className="no-overflow">
                              <div className="row">
                                <div className="col-md-12">
                                  <i
                                    className="fa fa-trash font-xl"
                                    onClick={() => {
                                      this.deleteUserForm(i);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <Col md={6}>
                                  <InputGroup className="custom-inputs">
                                    <InputGroupAddon addonType="prepend">
                                      <i className="fa fa-user" />
                                    </InputGroupAddon>
                                    <Input
                                      placeholder="First name"
                                      name={"first_name"}
                                      value={u.first_name}
                                      onChange={(e) => {
                                        this.handleChange(e, i);
                                      }}
                                      onBlur={(e) => {
                                        this.handleValidation(e, i);
                                      }}
                                      aria-label={"Enter First name of user"}
                                    />
                                    {userFormErrors &&
                                      userFormErrors[i].first_name && (
                                        <div className="help-block">
                                          {userFormErrors[i].first_name}
                                        </div>
                                      )}
                                  </InputGroup>
                                </Col>
                                <Col md={6}>
                                  <InputGroup className="custom-inputs">
                                    <InputGroupAddon addonType="prepend">
                                      <i className="fa fa-user" />
                                    </InputGroupAddon>
                                    <Input
                                      placeholder="Last name"
                                      name={"last_name"}
                                      value={u.last_name}
                                      onChange={(e) => {
                                        this.handleChange(e, i);
                                      }}
                                      onBlur={(e) => {
                                        this.handleValidation(e, i);
                                      }}
                                      aria-label={"Enter Last name of user"}
                                    />
                                    {userFormErrors &&
                                      userFormErrors[i].last_name && (
                                        <div className="help-block">
                                          {userFormErrors[i].last_name}
                                        </div>
                                      )}
                                  </InputGroup>
                                </Col>
                              </div>
                              <InputGroup className="custom-inputs">
                                <InputGroupAddon addonType="prepend">
                                  <i className="fa fa-envelope" />
                                </InputGroupAddon>
                                <Input
                                  placeholder="Email"
                                  name={"email"}
                                  value={u.email}
                                  onChange={(e) => {
                                    this.handleChange(e, i);
                                  }}
                                  onBlur={(e) => {
                                    this.handleValidation(e, i);
                                  }}
                                  aria-label={"Enter Email Id of user"}
                                />
                                {userFormErrors && userFormErrors[i].email && (
                                  <div className="help-block">
                                    {userFormErrors[i].email}
                                  </div>
                                )}
                              </InputGroup>
                              {/*Remove password because it auto generated*/}
                              {/*<InputGroup className="custom-inputs">*/}
                              {/*    <InputGroupAddon addonType="prepend"><i*/}
                              {/*        className="fa fa-lock"/></InputGroupAddon>*/}
                              {/*    <Input type="password" placeholder="Password" name={'password'}*/}
                              {/*           value={u.password} onChange={(e) => {*/}
                              {/*        this.handleChange(e, i)*/}
                              {/*    }} onBlur={(e) => {*/}
                              {/*        this.handleValidation(e, i)*/}
                              {/*    }}/>*/}
                              {/*    {userFormErrors[i].password &&*/}
                              {/*    <div className="help-block">{userFormErrors[i].password}</div>*/}
                              {/*    }*/}
                              {/*</InputGroup>*/}
                            </div>
                          );
                        })}
                    </Form>
                  </CardBody>
                </Card>
                <div className="pull-right pb-sm-0 pb-3">
                  <Button color="secondary mr-3" onClick={this.cancelForm}>
                    Cancel
                  </Button>
                  <Button color="success" onClick={this.handleClick}>
                    Save
                  </Button>
                </div>
              </div>
            )}
            {pageFrom === "import" && (
              <div className="col-md-6 col-12">
                <div className="card p-3 manage-assign-card tooltip-custom scroll-er">
                  <p className="custom-selection-border mb-3">
                    Select User(s) Source
                  </p>
                  <input
                    id={"file-upload"}
                    type="file"
                    className="get-file-from-local"
                    onChange={this.handleFile}
                    aria-label={"Import User for assigning a Role"}
                  />
                  <Input
                    type="text"
                    value={this.state.fileName}
                    onClick={this.openDialogue}
                    id={"fileTooltip"}
                    aria-label={"Import User for assigning a Role"}
                  />
                  <Tooltip
                    placement="bottom"
                    isOpen={this.state.tooltipOpen}
                    target="fileTooltip"
                    toggle={this.toggleTooltip}
                  >
                    Please upload a CSV/XLSX/TXT [tab delinated] file with the
                    following fields, 'email', 'first_name', 'last_name'. With
                    proper column headings.
                  </Tooltip>
                  {this.state.csvError && (
                    <div className="help-block">{this.state.csvError}</div>
                  )}
                  {errorData &&
                    errorData.map((e, i) => {
                      return (
                        <div key={i} className="user-form-custom-alert">
                          {e.map((l, index) => {
                            return (
                              <li className="mb-0">
                                User {i + 1}: {l}
                              </li>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>

                <div className="float-sm-right float-right">
                  <BackButton />
                  <Button
                    color={"success"}
                    className="cancel-btn"
                    onClick={this.saveImportUser}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Row>
        )}
        <Modal isOpen={isLimitExceed} className="modal-warning">
          <ModalHeader toggle={this.toggleModal}>
            Sidecar subscription limit
          </ModalHeader>
          <ModalBody>
            <div>
              <p>
                All Instructor seats available in your Sidecar subscription are
                currently in use.
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.toggleModal}>
              Okay
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UserForm;
